import React, { useRef, useContext, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useForm } from 'react-hook-form';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';

import api from '../../api/api';
import UserContext from '../../Context/UserContext';
import SnackBarContext from '../../Context/SnackBarContext';

const useStyles = makeStyles(theme => ({
  container: {
    margin: '15px  15px 0px',
  },
  titleRow: {
    padding: '15px 30px',
  },
  textField: {
    marginTop: 20,
  },
  buttonContainer: {
    marginTop: 40,
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttons: {
    padding: '11px 15px',
  },
  helperText: {
    color: 'red',
    marginLeft: 5,
  },
}));

const ChangePassword = () => {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const password = useRef({});
  password.current = watch('newPassword');
  const { authState } = useContext(UserContext);
  const { alerts } = useContext(SnackBarContext);
  const [loading, setLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visibleConfirmPassword, setVisibileConfirmPassword] = useState(false);

  const onSubmit = formData => {
    setLoading(true);
    const data = {
      user_account_id: authState[0].user_account_id,
      password: formData.currentPassword,
      new_password: formData.confirmPassword,
    };
    api({ path: '/admin/setting/changePassword', data: data })
      .then(res => {
        setLoading(false);
        if (res.hasError) {
          alerts[0][1]({
            error: true,
            state: true,
            message: res.data.message,
          });
        }
        reset();
        alerts[1]({ error: false, state: true, message: res.data.message });
      })
      .catch(err => setLoading(false));
  };

  return (
    <Container className={classes.container} maxWidth="md">
      <Paper className={classes.titleRow}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container className={classes.titleRow}>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', alignItems: 'center' }}>
              <ArrowBackIcon style={{ color: '#000', marginRight: 5 }} />
              <Typography variant="subtitle1">Change Password</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                style={{ marginLeft: 30, fontWeight: 'normal' }}
                variant="body1">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                name="currentPassword"
                {...register('currentPassword', {
                  required: ' Please Enter Current Password',
                })}
                placeholder="Current Password"
                className={classes.textField}
                type={currentPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        onClick={() => setCurrentPassword(!currentPassword)}>
                        {currentPassword ? (
                          <VisibilityIcon style={{ color: '#707070' }} />
                        ) : (
                          <VisibilityOffIcon style={{ color: '#707070' }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.currentPassword && (
                <FormHelperText>
                  <Typography variant="body1" className={classes.helperText}>
                    {errors.currentPassword.message}
                  </Typography>
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                name="newPassword"
                {...register('newPassword', {
                  required: 'Please Enter New Password',
                })}
                placeholder="New Password"
                className={classes.textField}
                type={visiblePassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        onClick={() => setVisiblePassword(!visiblePassword)}>
                        {visiblePassword ? (
                          <VisibilityIcon style={{ color: '#707070' }} />
                        ) : (
                          <VisibilityOffIcon style={{ color: '#707070' }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {errors.newPassword && (
                <FormHelperText>
                  <Typography variant="body1" className={classes.helperText}>
                    {errors.newPassword.message}
                  </Typography>
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                name="confirmPassword"
                placeholder="Confirm Password"
                type={visibleConfirmPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        onClick={() =>
                          setVisibileConfirmPassword(!visibleConfirmPassword)
                        }>
                        {visibleConfirmPassword ? (
                          <VisibilityIcon style={{ color: '#707070' }} />
                        ) : (
                          <VisibilityOffIcon style={{ color: '#707070' }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...register('confirmPassword', {
                  required: 'Please Enter confirm password',

                  validate: value =>
                    value === password.current ||
                    ' New Password and Confirm Password does not match',
                })}
                className={classes.textField}
              />

              {errors.confirmPassword && (
                <FormHelperText>
                  <Typography variant="body1" className={classes.helperText}>
                    {errors.confirmPassword.message}
                  </Typography>
                </FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} className={classes.buttonContainer}>
              <Button variant="contained" className={classes.buttons}>
                Cancel
              </Button>

              <Button
                variant="contained"
                className={classes.buttons}
                color="primary"
                type="submit">
                {loading ? (
                  <CircularProgress size={25} color="textSecondary" />
                ) : (
                  ' Change Password'
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default ChangePassword;
