import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const MyEditor = props => {
  const editorStyle = {
    minHeight: 100,
    padding: 10,
    overflowY: 'hidden',
    border: '1px solid #e9e9e9',
    ...props.Styles,
  };

  const handleChange = e => {
    props.setEditorState(e);
  };

  return (
    <Editor
      editorState={props.editorState}
      editorStyle={editorStyle}
      onEditorStateChange={handleChange}
    />
  );
};

export default MyEditor;
