import React, { useRef, useEffect } from 'react';
import {
  Dialog,
  Button,
  IconButton,
  Typography,
  TextField,
  Slide,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  makeStyles,
  createStyles,
  MenuItem,
  FormHelperText,
  Grid,
} from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Controller, useForm } from 'react-hook-form';
import { nanoid } from 'nanoid';

import api from '../../api/api';
import { url, isImage } from '../../utils/common';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStylesDialog = makeStyles(theme =>
  createStyles({
    dialogTitleContainer: {
      backgroundColor: theme.palette.ternary.main,
      padding: '0px 15px',
    },
    dialogTitleContainerDiv: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },

    dialogActionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 10,
    },
    dialogActionButton: {
      margin: '5px 13px',
      color: '#FFF',
      padding: '10px 25px',
    },

    dialogTextField: {
      margin: '5px 0',
    },
    uploadFileContainer: {
      border: '1px solid #00000060',
      borderRadius: 4,
      minHeight: 230,
      textAlign: 'center',
    },
    adminInfoContainer: {
      marginTop: 10,
    },
    rowAdminInput: {
      margin: '15px 0px 0px 0px',
    },

    permission: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      //   width: '50%',
    },
    adminInfoRow: {
      display: 'flex',
      backgroundColor: '#f1f1f1',
      minHeight: 120,
      paddingLeft: 24,
    },
    adminInfo: {
      margin: '15px 15px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    divider: {
      borderLeft: '1px solid #000000',
      margin: '0 5px',
    },
    icon: {
      color: '#00000099',
    },
    dialogContent: {
      padding: 0,
    },
    permissionContainer: {
      paddingLeft: 24,
      paddingRight: 24,
    },
    prevAvatar: {
      height: theme.spacing(10),
      width: theme.spacing(10),
      marginTop: 20,
    },
    permissionTitle: {
      width: '30%',
    },
    gridRightContainer: {
      paddingLeft: 10,
    },
    helperText: {
      marginLeft: 10,
      color: 'red',
    },
    imgPreviewContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  })
);

const CreateAdminDialog = ({
  adminDialog,
  setAdminDialog,
  alerts,
  editAdminData,
  authState,
}) => {
  const classes = useStylesDialog();
  const {
    handleSubmit,
    reset,
    control,
    defaultValues,
    formState: { errors },
  } = useForm({
    defaultValues: { ...editAdminData[0] },
  });
  const [profilePhoto, setProfilePhoto] = React.useState(null);
  const browseBtnRef = useRef();
  const [browseErr, setBrowseErr] = React.useState('');

  useEffect(() => {
    editAdminData[0] && setProfilePhoto(editAdminData[0]?.profile_photo);
    reset({
      first_name: editAdminData[0]?.first_name,
      last_name: editAdminData[0]?.last_name,
      email_id: editAdminData[0]?.email_id,
      phone_no: editAdminData[0]?.phone_no,
      alternate_no: editAdminData[0]?.alternate_no,
      company_name: editAdminData[0]?.company_name,
      country: editAdminData[0]?.country,
      city: editAdminData[0]?.city,
      dashboard_read: editAdminData[0]?.dashboard_read ? true : false,
      dashboard_write: editAdminData[0]?.dashboard_write ? true : false,
      dashboard_delete: editAdminData[0]?.dashboard_delete ? true : false,
      requirement_read: editAdminData[0]?.requirement_read ? true : false,
      requirement_write: editAdminData[0]?.requirement_write ? true : false,
      requirement_delete: editAdminData[0]?.requirement_delete ? true : false,
      profile_read: editAdminData[0]?.profile_read ? true : false,
      profile_write: editAdminData[0]?.profile_write ? true : false,
      profile_delete: editAdminData[0]?.profile_delete ? true : false,
      report_read: editAdminData[0]?.report_read ? true : false,
      report_write: editAdminData[0]?.report_write ? true : false,
      report_delete: editAdminData[0]?.report_delete ? true : false,
    });
  }, [editAdminData, reset]);

  const handleFileUpload = e => {
    var formData = new FormData();
    var image = isImage(e.target.files[0]);
    let fileSize = parseFloat(e.target.files[0]?.size / 1024 / 1024).toFixed(2);

    if (!image) {
      alerts[1]({
        error: true,
        message: 'Please select image',
        state: true,
      });
      return;
    }
    if (parseFloat(fileSize) > 15) {
      alerts[1]({
        error: true,
        message: 'Maximum 10Mb of size allowed',
        state: true,
      });
      return;
    }

    formData.append('image', e.target.files[0]);

    api({ path: '/image/upload', data: formData })
      .then(res => setProfilePhoto(res.data.data.imageId))
      .catch(err => console.log('ERR', err));
  };

  const handleClose = () => {
    editAdminData[1](null);
    setAdminDialog(false);
    setProfilePhoto(null);
    reset({ defaultValues });
  };

  const createUpdateAdmin = formData => {
    let data = {};
    editAdminData[0]
      ? (data = {
          ...formData,
          profile_photo: profilePhoto,
          user_account_id: authState[0].user_account_id.toString(),
        })
      : (data = {
          ...formData,
          role: 'Admin',
          profile_photo: profilePhoto,
          password: nanoid(10),
        });
    editAdminData[0]
      ? api({ path: '/admin/updateAdmin', data: data })
          .then(res => {
            if (res.data.hasError) {
              alerts[1]({
                error: true,
                message: 'Update admin data operation faild',
                state: true,
              });
              handleClose();
              return;
            }
            alerts[1]({
              error: false,
              message: res.data?.message,
              state: true,
            });
            handleClose();
          })
          .catch(err => {
            alerts[1]({
              error: true,
              message: 'Updated admin data operation faild',
              state: true,
            });
            handleClose();
          })
      : api({ path: '/admin/signUp', data: data })
          .then(res => {
            if (res.data.hasError) {
              alerts[1]({
                error: true,
                message: 'Updated admin data operation faild',
                state: true,
              });
              handleClose();
              return;
            }
            alerts[1]({
              error: false,
              message: res.data?.message,
              state: true,
            });
            handleClose();
          })
          .catch(err => {
            alerts[1]({
              error: true,
              message: 'Updated admin data operation faild',
              state: true,
            });
            handleClose();
          });
  };

  return (
    <Dialog
      open={adminDialog}
      maxWidth="sm"
      TransitionComponent={Transition}
      fullWidth>
      <DialogTitle className={classes.dialogTitleContainer}>
        <div className={classes.dialogTitleContainerDiv}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton>
              <ArrowBackIcon style={{ color: '#FFF' }} />
            </IconButton>
            <Typography variant="subtitle1" style={{ color: '#FFF' }}>
              {editAdminData[0] ? 'Update Admin' : 'Create Admin'}
            </Typography>
          </div>
          <IconButton
            onClick={() => {
              handleClose();
            }}>
            <CloseIcon style={{ color: '#FFF' }} />
          </IconButton>
        </div>
      </DialogTitle>
      <form onSubmit={handleSubmit(createUpdateAdmin)}>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {profilePhoto ? (
                <div className={classes.imgPreviewContainer}>
                  <img
                    src={`${url}/image/${profilePhoto}`}
                    alt="pic"
                    height={70}
                    width={70}
                  />
                  <IconButton
                    onClick={() => {
                      setProfilePhoto(null);
                    }}>
                    <CloseIcon />
                  </IconButton>
                </div>
              ) : (
                <div
                  className={classes.uploadFileContainer}
                  style={{ textAlign: 'center' }}>
                  <IconButton>
                    <BackupIcon style={{ fontSize: 70 }} />
                  </IconButton>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 'normal' }}
                    align="center">
                    Click here to browse or <br /> drag and drop a file
                  </Typography>
                  <Button
                    style={{ padding: '15px 55px', margin: '10px 5px' }}
                    variant="contained"
                    onClick={() => browseBtnRef?.current?.click()}>
                    <Typography variant="subtitle1">Browse</Typography>
                  </Button>
                  <input
                    type="file"
                    id="file"
                    style={{ display: 'none' }}
                    onChange={e => handleFileUpload(e)}
                    ref={browseBtnRef}
                    accept="image/*"
                  />
                  <Typography style={{ marginBottom: 5 }} variant="body1">
                    File must be an image format. The maximum file-size is 10 MB
                  </Typography>
                </div>
              )}
              {browseErr && (
                <Typography
                  style={{ marginBottom: 5 }}
                  className={classes.helperText}
                  variant="body1">
                  {browseErr}
                </Typography>
              )}
            </Grid>

            <Grid item xs={6}>
              <Controller
                render={({ field }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="First Name"
                    className={classes.rowAdminInput}
                    style={{ marginRight: 5 }}
                    {...field}
                  />
                )}
                name="first_name"
                control={control}
                rules={{ required: 'Please enter first name' }}
              />
              {errors?.first_name && (
                <FormHelperText>
                  <Typography variant="body1" className={classes.helperText}>
                    {errors.first_name?.message}
                  </Typography>
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={6}>
              <Controller
                render={({ field }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Last Name"
                    className={classes.rowAdminInput}
                    style={{ marginLeft: 5 }}
                    {...field}
                  />
                )}
                name="last_name"
                control={control}
                rules={{ required: 'Please enter last name' }}
              />
              {errors?.last_name && (
                <FormHelperText>
                  <Typography variant="body1" className={classes.helperText}>
                    {errors.last_name?.message}
                  </Typography>
                </FormHelperText>
              )}
            </Grid>

            <Grid item xs={6}>
              <Controller
                render={({ field }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Email Address"
                    className={classes.rowAdminInput}
                    style={{ marginRight: 5 }}
                    {...field}
                  />
                )}
                name="email_id"
                control={control}
                rules={{
                  required: 'Please enter email address',
                  pattern: {
                    value: new RegExp('^[A-Za-z0-9+_.-]+@(.+)$'),
                    message: 'Please enter valid email',
                  },
                }}
              />
              {errors?.email_id && (
                <FormHelperText>
                  <Typography variant="body1" className={classes.helperText}>
                    {errors.email_id?.message}
                  </Typography>
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={6}>
              <Controller
                render={({ field }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Phone Number"
                    type="number"
                    className={classes.rowAdminInput}
                    style={{ marginLeft: 5 }}
                    {...field}
                  />
                )}
                name="phone_no"
                control={control}
                rules={{
                  required: 'Please enter phone no',
                  minLength: {
                    value: 10,
                    message: 'Please enter valid phone no',
                  },
                  maxLength: {
                    value: 10,
                    message: 'Please enter valid phone no',
                  },
                }}
              />
              {errors?.phone_no && (
                <FormHelperText>
                  <Typography variant="body1" className={classes.helperText}>
                    {errors.phone_no?.message}
                  </Typography>
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={6}>
              <Controller
                render={({ field }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Alternate Number"
                    className={classes.rowAdminInput}
                    type="number"
                    style={{ marginRight: 5 }}
                    {...field}
                  />
                )}
                name="alternate_no"
                control={control}
                rules={{
                  minLength: {
                    value: 10,
                    message: 'Please enter valid alternate no',
                  },
                  maxLength: {
                    value: 10,
                    message: 'Please enter valid alternate no',
                  },
                }}
              />
              {errors?.alternate_no && (
                <FormHelperText>
                  <Typography variant="body1" className={classes.helperText}>
                    {errors.alternate_no?.message}
                  </Typography>
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={6}>
              <Controller
                render={({ field }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Company Name"
                    className={classes.rowAdminInput}
                    style={{ marginRight: 5 }}
                    {...field}
                  />
                )}
                name="company_name"
                control={control}
                rules={{ required: 'Please enter company name' }}
              />
              {errors?.company_name && (
                <FormHelperText>
                  <Typography variant="body1" className={classes.helperText}>
                    {errors.company_name?.message}
                  </Typography>
                </FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                align="left"
                style={{ marginTop: 8 }}>
                Address and Location{' '}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Controller
                render={({ field }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    className={classes.rowAdminInput}
                    style={{ marginRight: 5, color: '#000' }}
                    {...field}
                    select>
                    <MenuItem value="placeholder">Country/Region</MenuItem>
                    <MenuItem value="india">India</MenuItem>
                    <MenuItem value="usa">USA</MenuItem>
                    <MenuItem value="uk">UK</MenuItem>
                  </TextField>
                )}
                name="country"
                control={control}
                rules={{
                  required: 'Please select country',
                  validate: value =>
                    value === 'placeholder'
                      ? 'Please select Country/Region'
                      : true,
                }}
              />
              {errors?.country && (
                <FormHelperText>
                  <Typography variant="body1" className={classes.helperText}>
                    {errors.country?.message}
                  </Typography>
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={6}>
              <Controller
                render={({ field }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    className={classes.rowAdminInput}
                    style={{ marginLeft: 5 }}
                    {...field}
                    select>
                    <MenuItem value="placeholder">City/District</MenuItem>
                    <MenuItem value="newyork">New York</MenuItem>
                    <MenuItem value="london">London</MenuItem>
                    <MenuItem value="pune">Pune</MenuItem>
                    <MenuItem value="mumbai">Mumbai</MenuItem>
                    <MenuItem value="satara">Satara</MenuItem>
                  </TextField>
                )}
                rules={{
                  required: 'Please select city/district',
                  validate: value =>
                    value === 'placeholder'
                      ? 'Please select city/district'
                      : true,
                }}
                control={control}
                name="city"
              />
              {errors?.city && (
                <FormHelperText>
                  <Typography variant="body1" className={classes.helperText}>
                    {errors.city?.message}
                  </Typography>
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                align="left"
                style={{ marginTop: 8 }}>
                Set Permissions
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.permission}>
                <Typography
                  className={classes.permissionTitle}
                  variant="body1"
                  align="left">
                  Dashboard
                </Typography>
                <div>
                  R
                  <Controller
                    render={({ field }) => (
                      <Checkbox checked={field.value} {...field} />
                    )}
                    control={control}
                    name="dashboard_read"
                  />
                  W
                  <Controller
                    render={({ field }) => (
                      <Checkbox checked={field.value} {...field} />
                    )}
                    control={control}
                    name="dashboard_write"
                  />
                  D
                  <Controller
                    render={({ field }) => (
                      <Checkbox checked={field.value} {...field} />
                    )}
                    control={control}
                    name="dashboard_delete"
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={6} className={classes.gridRightContainer}>
              <div className={classes.permission}>
                <Typography
                  className={classes.permissionTitle}
                  variant="body1"
                  align="left">
                  Requirements
                </Typography>
                <div>
                  R
                  <Controller
                    render={({ field }) => (
                      <Checkbox checked={field.value} {...field} />
                    )}
                    control={control}
                    name="requirement_read"
                  />
                  W
                  <Controller
                    render={({ field }) => (
                      <Checkbox checked={field.value} {...field} />
                    )}
                    control={control}
                    name="requirement_write"
                  />
                  D
                  <Controller
                    render={({ field }) => (
                      <Checkbox checked={field.value} {...field} />
                    )}
                    control={control}
                    name="requirement_delete"
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.permission}>
                <Typography
                  className={classes.permissionTitle}
                  variant="body1"
                  align="left">
                  Profile
                </Typography>
                <div>
                  R
                  <Controller
                    render={({ field }) => (
                      <Checkbox checked={field.value} {...field} />
                    )}
                    control={control}
                    name="profile_read"
                  />
                  W
                  <Controller
                    render={({ field }) => (
                      <Checkbox checked={field.value} {...field} />
                    )}
                    control={control}
                    name="profile_write"
                  />
                  D
                  <Controller
                    render={({ field }) => (
                      <Checkbox checked={field.value} {...field} />
                    )}
                    control={control}
                    name="profile_delete"
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={6} className={classes.gridRightContainer}>
              <div className={classes.permission}>
                <Typography
                  className={classes.permissionTitle}
                  variant="body1"
                  align="left">
                  Reports
                </Typography>
                <div>
                  R
                  <Controller
                    render={({ field }) => (
                      <Checkbox checked={field.value} {...field} />
                    )}
                    control={control}
                    name="report_read"
                  />
                  W
                  <Controller
                    render={({ field }) => (
                      <Checkbox checked={field.value} {...field} />
                    )}
                    control={control}
                    name="report_write"
                  />
                  D
                  <Controller
                    render={({ field }) => (
                      <Checkbox checked={field.value} {...field} />
                    )}
                    control={control}
                    name="report_delete"
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActionContainer}>
          <Button
            variant="contained"
            className={classes.dialogActionButton}
            style={{ color: '#000' }}
            size="large"
            onClick={() => handleClose()}>
            Back
          </Button>
          <Button
            variant="contained"
            className={classes.dialogActionButton}
            color="primary"
            size="large"
            onClick={() =>
              !profilePhoto ? setBrowseErr('Please select profile photo') : true
            }
            type="submit">
            {editAdminData[0] ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateAdminDialog;
