import React, { useContext, useEffect } from 'react';
import {
  makeStyles,
  Typography,
  Grid,
  Container,
  Button,
  Avatar,
  FormControl,
  InputLabel,
  Radio,
  FormControlLabel,
  RadioGroup,
  InputAdornment,
  OutlinedInput,
  FormHelperText,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import api from '../../../api/api';
import SnackBarContext from '../../../Context/SnackBarContext';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  columnOptionContainer: {
    marginLeft: 60,
  },
  columnOption: {
    marginLeft: 15,
    color: '#ffffff91',
  },
  smallIcon: {
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
    backgroundColor: 'transparent',
    marginRight: 5,
  },
  sideBarTitle: {
    marginLeft: 15,
    color: '#fff',
  },

  //Right content classes

  uploadFileContainer: {
    border: '1px solid #00000060',
    borderRadius: 4,
    minHeight: 230,
    textAlign: 'center ',
  },
  imgPreviewContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleContainer: {
    marginTop: 10,
    fontWeight: 600,
  },
  textField: {
    marginTop: 5,
    marginBottom: 5,
  },
  editor: {
    marginTop: 15,
  },
  subtitle: {
    marginTop: 5,
    fontWeight: 'bold',
    opacity: 0.5,
  },
  caption: {
    marginTop: 3,
  },
  createBtn: {
    marginTop: 10,
    minWidth: 150,
  },
  inlineContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  helperText: {
    color: 'red',
    marginLeft: 5,
  },
  bottomButton: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  saveBtn: {
    marginRight: 10,
    padding: '10px 30px',
    height: 22,
    marginTop: 10,
  },
  saveBtnTxt: {
    opacity: 0.5,
    fontWeight: 600,
  },
  smallNumber: {
    height: theme.spacing(2.1),
    width: theme.spacing(2.1),
    backgroundColor: 'transparent',
    marginRight: 5,
    border: '0.5px solid #ffffff91',
    fontSize: 13,
    color: '#ffffff91',
  },
  smallNumberHighlighted: {
    height: theme.spacing(2.1),
    width: theme.spacing(2.1),
    backgroundColor: '#fff',
    marginRight: 5,
    fontSize: 13,
    color: '#000',
    border: '0.5px solid #FFF',
  },
  resendCodeTimer: {
    fontSize: 11,
    right: 6,
    position: 'absolute',
    top: 44,
  },
  // End
}));

const VerificationSidebar = () => {
  const classes = useStyles();
  return (
    <Container>
      <div className={classes.row}>
        <CheckCircleIcon
          style={{ color: '#FFF' }}
          className={classes.smallIcon}
          fontSize="large"
        />
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Sign up
        </Typography>
      </div>

      <div className={classes.row}>
        <CheckCircleIcon
          style={{ color: '#FFF' }}
          className={classes.smallIcon}
          fontSize="large"
        />
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Basic Information
        </Typography>
      </div>

      <div className={classes.row}>
        <CheckCircleIcon
          style={{ color: '#FFF' }}
          className={classes.smallIcon}
          fontSize="large"
        />
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Employment History
        </Typography>
      </div>

      <div className={classes.row}>
        <Avatar className={classes.smallNumberHighlighted}>4</Avatar>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.sideBarTitle}>
          Verification & Finish
        </Typography>
      </div>
    </Container>
  );
};

const VerificationContent = ({ useFormContext, isCodeVerified }) => {
  const classes = useStyles();
  const [resendCode, setResendCode] = React.useState(false);
  const [codeTimer, setCodeTimer] = React.useState(90);
  const timerRef = React.useRef(10);
  const { alerts } = useContext(SnackBarContext);

  const {
    formState: { errors },
    register,
    getValues,
  } = useFormContext();

  const resendVerifyCode = () => {
    let to_email = sessionStorage.getItem('candidateEmail');
    if (to_email)
      api({
        path: `/email/verifyEmail`,
        data: { to_email },
      })
        .then(res => {
          if (res.data.hasError) {
            // setErrorMessage(res.data.message);

            return;
          } else {
            alerts[1]({
              error: true,
              message: 'Verification Code sent to your Email',
              state: true,
            });
          }
        })
        .catch(err => {
          console.log('errr', err);
        });
  };

  const verifyCode = () => {
    let email_id = sessionStorage.getItem('candidateEmail');
    let { code } = getValues();
    if (!code) {
      alerts[1]({
        error: false,
        message: 'Please Enter Verification Code',
        state: true,
      });
      return;
    }
    if (email_id)
      api({
        path: `/candidate/verifyCode`,
        data: { email_id, code: code },
      })
        .then(res => {
          if (res.data.hasError) {
            // setErrorMessage(res.data.message);
            alerts[1]({
              error: true,
              message: res.data.message.toString(),
              state: true,
            });
            return;
          } else {
            alerts[1]({
              error: false,
              message: 'Code Verified',
              state: true,
            });
            isCodeVerified[1](true);
          }
        })
        .catch(err => {
          console.log('errr', err);
        });
  };
  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (timerRef.current > 0) {
        timerRef.current = timerRef.current - 1;
        setCodeTimer(prev => prev - 1);
      } else {
        setResendCode(true);
      }
    }, 1000);
    return function () {
      clearInterval(timerInterval);
    };
  }, []);

  return (
    <Container
      style={{ paddingRight: 5, position: 'relative', minHeight: '70vh' }}>
      <Grid maxWidth="sm" container xs={8} spacing={2}>
        <Grid item xs={12}>
          <div className={classes.leftTitle}>
            <div className={classes.inlineContainer}>
              <Typography variant="h1">Verification & Status</Typography>
            </div>
            <Typography variant="body1" className={classes.subtitle}>
              Enter the code sent to your email ID
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography
            className={classes.titleContainer}
            style={{ marginBottom: 8, margingTop: 20 }}
            variant="subtitle1">
            Confirm your email address
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-password">
              Verification Code
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={'text'}
              error={errors?.code || false}
              {...register('code', { required: true })}
              endAdornment={
                <InputAdornment position="end">
                  {resendCode && (
                    <Typography
                      variant="body1"
                      color="primary"
                      style={{ cursor: 'pointer', opacity: 1 }}
                      onClick={() => {
                        timerRef.current = 90;
                        setCodeTimer(90);
                        setResendCode(false);
                        resendVerifyCode();
                      }}>
                      RESEND CODE
                    </Typography>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ padding: '7px 10px', left: 10 }}
                    onClick={() => verifyCode()}>
                    <Typography variant="body2" style={{ color: '#fff' }}>
                      VERIFY CODE
                    </Typography>
                  </Button>
                </InputAdornment>
              }
              labelWidth={95}
            />
            {!resendCode && (
              <Typography className={classes.resendCodeTimer} color="primary">
                Resend Code in{' '}
                {Math.floor(codeTimer / 60) +
                  ':' +
                  (codeTimer % 60 ? codeTimer % 60 : '00')}
              </Typography>
            )}
            {errors?.code?.type !== undefined &&
              errors?.code?.type === 'required' && (
                <FormHelperText>This is require field</FormHelperText>
              )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography
            className={classes.titleContainer}
            style={{ marginBottom: 8, margingTop: 20 }}
            variant="subtitle1">
            Are you looking for a job?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="div" fullWidth>
            <RadioGroup
              style={{ flexDirection: 'row' }}
              {...register('looking_job', { required: true })}>
              <FormControlLabel
                value="Yes"
                control={<Radio />}
                label="Yes"
                style={{ width: 150 }}
              />
              <FormControlLabel value="No" control={<Radio />} label="No" />
            </RadioGroup>
            {errors?.looking_job?.type !== undefined &&
              errors?.looking_job?.type === 'required' && (
                <FormHelperText>This is require field</FormHelperText>
              )}
          </FormControl>
        </Grid>
      </Grid>
    </Container>
  );
};

export { VerificationContent, VerificationSidebar };
