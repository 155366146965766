import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const isAutheticated = () => {
  if (typeof window == 'undefined') {
    return false;
  }
  if (sessionStorage.getItem('auth')) {
    return sessionStorage.getItem('auth');
  } else {
    return false;
  }
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        isAutheticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
