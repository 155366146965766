import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import LocationOnIcon from '@material-ui/icons/LocationOnOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import BarChartIcon from '@material-ui/icons/BarChart';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';

import { Bar } from 'react-chartjs-2';

import {
  Container,
  Divider,
  InputAdornment,
  Dialog,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  Button,
  IconButton,
  Collapse,
  Paper,
  TableContainer,
  TableRow,
  Table,
  TableCell,
  TableBody,
  Typography,
  TextField,
  Slide,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    tableContainer: {
      marginTop: 20,
      border: '1px solid #0000004D',
      borderRadius: 4,
    },
    titleRow: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: '#f1f1f1',
    },
    requirementTitleCell: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '65%',
    },
    tablecellIconandText: {
      display: 'flex',
      alignItems: 'center',
    },
    tablecell: {
      paddingLeft: 5,
      paddingRight: 0,
    },
    container: {},
    icon: {
      color: '#6f6f6f',
      padding: '0px 5px 0px 15px',
    },
    primaryColorIcon: {
      color: theme.palette.primary.main,
      padding: '0px 5px 0px 15px',
    },

    divider: {
      borderLeft: '1px solid #0000004D',
    },

    formControl: {
      margin: theme.spacing(1),
      minWidth: 150,
    },
    titleDiv: {
      margin: 25,
    },
    expandAllRow: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    rowActionDiv: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: 7,
    },
    rowAction: {
      display: 'flex',
      alignItems: 'center',
      margin: '0px 10px',
    },
    dividerTop: {
      borderTop: '1px solid #e0e0e0',
    },
    filterContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    collapseText: {
      paddingLeft: 10,
      paddingRight: 10,
      marginTop: 3,
      marginBottom: 3,
      fontWeight: 'bold',
      fontSize: 12,
    },
    subtitle1: {
      opacity: 0.5,
      fontWeight: 600,
    },
    expandBtn: {
      fontWeight: 600,
    },
    barchartContainer: {
      backgroundColor: '#f7f7f7',
      marginBottom: 15,
      paddingTop: 15,
    },
    barchart: {
      height: 200,
      width: '77%',
      marginLeft: '3%',
    },
  })
);

const useStylesDialog = makeStyles(theme =>
  createStyles({
    dialogTitleContainer: {
      backgroundColor: theme.palette.ternary.main,
      padding: '5px 24px',
    },
    dialogTitleContainerDiv: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    select: {
      color: '#000',
      marginTop: 25,
    },
    dialogActionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 15,
      marginBottom: 20,
    },
    dialogActionButton: {
      margin: '0px 13px',
    },
  })
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function createData(
  id,
  eventTime,
  title,
  sources,
  noofRecruiters,
  date,
  requirementBy,
  location,
  applicants
) {
  return {
    id,
    eventTime,
    title,
    sources,
    noofRecruiters,
    date,
    requirementBy,
    location,
    applicants,
  };
}

const rows = [
  createData(
    'QHS-596',
    50,
    'Head Sales and Marketing_Evercare_Dhaka',
    20,
    1,
    '23-01-2021',
    'Rohit Sharma',
    'Los Angeles, USA',
    4
  ),
  createData(
    'QHS-596',
    50,
    'Head Sales and Marketing_Evercare_Dhaka',
    4,
    2,
    '23-01-2021',
    'Rohit Sharma',
    'Los Angeles, USA',
    4
  ),
  createData(
    'QHS-596',
    50,
    'Head Sales and Marketing_Evercare_Dhaka',
    22,
    5,
    '23-01-2021',
    'Rohit Sharma',
    'Los Angeles, USA',
    4
  ),
  createData(
    'QHS-596',
    50,
    'Head Sales and Marketing_Evercare_Dhaka',
    240,
    12,
    '23-01-2021',
    'Rohit Sharma',
    'Los Angeles, USA',
    4
  ),
  createData(
    'QHS-596',
    50,
    'Head Sales and Marketing_Evercare_Dhaka',
    30,
    15,
    '23-01-2021',
    'Rohit Sharma',
    'Los Angeles, USA',
    4
  ),
  createData(
    'QHS-596',
    50,
    'Head Sales and Marketing_Evercare_Dhaka',
    20,
    10,
    '23-01-2021',
    'Rohit Sharma',
    'Los Angeles, USA',
    4
  ),
];

const VisibilityDialog = ({ visibilityOpen }) => {
  const classes = useStylesDialog();
  return (
    <Dialog
      open={visibilityOpen[0]}
      maxWidth="sm"
      TransitionComponent={Transition}
      fullWidth>
      <DialogTitle className={classes.dialogTitleContainer}>
        <div className={classes.dialogTitleContainerDiv}>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton>
              <ArrowBackIcon style={{ color: '#FFF' }} fontSize="large" />
            </IconButton>
            <Typography variant="subtitle1" color="textSecondary">
              Set Visibility Settings
            </Typography>
          </span>
          <IconButton onClick={() => visibilityOpen[1](false)}>
            <CloseIcon style={{ color: '#FFF' }} fontSize="large" />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <Select
          value="Placeholder"
          variant="outlined"
          className={classes.select}
          fullWidth>
          <MenuItem value="Placeholder">Visibility Settings</MenuItem>
          <MenuItem value={1}>Visible to me</MenuItem>
          <MenuItem value={2}>Visible to All</MenuItem>
          <MenuItem value={3}>Visible to Selected People</MenuItem>
        </Select>

        <TextField
          variant="outlined"
          placeholder="Recruiter Name:"
          className={classes.select}
          fullWidth
        />
      </DialogContent>
      <DialogActions className={classes.dialogActionContainer}>
        <Button
          variant="contained"
          className={classes.dialogActionButton}
          style={{ color: '#000' }}
          size="large"
          onClick={() => visibilityOpen[1](false)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          className={classes.dialogActionButton}
          color="primary"
          size="large">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const data = {
  labels: ['Prospect', 'Applied Not Matching ', 'Rejected Prospect'],
  datasets: [
    {
      backgroundColor: ['#2d9314', '#dcbd14', '#982215'],
      data: [300, 65, 200],
      hoverBackgroundColor: ['#2d9314', '#dcbd14', '#982215'],
    },
  ],
};

const BarChart = () => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },

    type: 'bar',
    scales: {
      xAxes: [
        {
          maxBarThickness: 30,
          gridLines: {
            display: false,
          },
          ticks: {
            align: 'start',
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };

  return <Bar data={data} width={null} height={null} options={options} />;
};

const Requirements = () => {
  const classes = useStyles();
  const [filterOpen, setFilterOpen] = useState(false);
  const visibilityOpen = useState(false);
  const [expandAll, setExpandAll] = useState(false);

  const Row = ({ row }) => {
    const [open, setOpen] = useState(expandAll ? true : false);
    return (
      <>
        <TableRow hover align="left">
          <TableCell style={{ padding: '0px 5px 70px 5px' }}>
            <Checkbox style={{ verticalAlign: 'top' }} />
          </TableCell>
          <TableCell width="50%" className={classes.tablecell}>
            <span className={classes.requirementTitleCell}>
              <Typography variant="body1">#{row?.id}</Typography>
              <Typography
                variant="body1"
                style={{ display: 'flex', alignItems: 'center' }}>
                <FiberManualRecordIcon
                  className={classes.icon}
                  style={{ fontSize: 14 }}
                />
                {row?.eventTime} MIN AGO
              </Typography>
            </span>
            <Typography variant="subtitle1">{row?.title}</Typography>
            <div style={{ display: 'flex', marginTop: 3 }}>
              <Typography variant="body1" color="primary">
                {row?.sources} Sources
              </Typography>
              <Typography variant="body1" style={{ margin: '0px 5px' }}>
                Created By <span style={{ fontWeight: 'bold' }}>You</span>{' '}
                Visible to
              </Typography>
              <Typography variant="body1" color="primary">
                {row?.noofRecruiters} Recruiters
              </Typography>
            </div>
            <div className={classes.rowActionDiv}>
              <span className={classes.rowAction} style={{ marginLeft: 0 }}>
                <Select
                  fullWidth={true}
                  disableUnderline
                  placeholder="Experiance"
                  defaultValue="Placeholder"
                  IconComponent={ExpandMoreIcon}>
                  <MenuItem value="Placeholder" disabled>
                    Modify
                  </MenuItem>
                  <MenuItem value={1}>Edit</MenuItem>
                </Select>
              </span>
              <span className={classes.divider} />

              <span className={classes.divider} />
              <span className={classes.rowAction}>
                <Select
                  fullWidth={true}
                  disableUnderline
                  placeholder="Experiance"
                  defaultValue="Placeholder"
                  IconComponent={ExpandMoreIcon}>
                  <MenuItem value="Placeholder" disabled>
                    Open
                  </MenuItem>
                  <MenuItem value={1}>A</MenuItem>
                  <MenuItem value={2}>B</MenuItem>
                </Select>
              </span>

              <span className={classes.divider} />
              <span className={classes.rowAction}>
                <ChatBubbleOutlineIcon className={classes.icon} />
                <Typography variant="body1">52</Typography>{' '}
              </span>
              <span className={classes.divider} />
              <span className={classes.rowAction}>
                <BarChartIcon
                  className={open ? classes.primaryColorIcon : classes.icon}
                />
              </span>
            </div>
          </TableCell>

          <TableCell width="15%" className={classes.tablecell}>
            <span className={classes.tablecellIconandText}>
              <PersonOutlineIcon className={classes.icon} />{' '}
              <Typography variant="body1">By {row?.requirementBy}</Typography>
            </span>
          </TableCell>
          <TableCell width="17%" className={classes.tablecell}>
            <span className={classes.tablecellIconandText}>
              <LocationOnIcon className={classes.icon} />
              <Typography variant="body1">{row?.location}</Typography>
            </span>
          </TableCell>
          <TableCell className={classes.tablecell}>
            <div className={classes.tablecellIconandText}>
              <Typography variant="body1">
                {row?.applicants} Applicants
              </Typography>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </div>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell colSpan={5} style={{ padding: 0 }}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <div className={classes.barchartContainer}>
                <div className={classes.barchart}>
                  <BarChart />
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'space-between',
                  backgroundColor: '#f7f7f7',
                  padding: 18,
                }}>
                <div>
                  <Typography variant="subtitle1">
                    Requirement posted as
                  </Typography>
                  <div style={{ display: 'flex', margin: 15 }}>
                    <span>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}>
                        RMS JOBS
                      </Typography>
                    </span>
                    <span style={{ marginLeft: 30 }}>
                      <Typography variant="body1" color="primary">
                        CFO_Temasek_Kolkata
                      </Typography>
                      <span style={{ display: 'flex' }}>
                        <Button className={classes.collapseText}>View</Button>
                        <span className={classes.divider} />
                        <Button className={classes.collapseText}>Remove</Button>
                        <span className={classes.divider} />
                        <Button className={classes.collapseText}>
                          Create Employee Referral
                        </Button>
                        <span className={classes.divider} />
                        <Button className={classes.collapseText}>
                          Edit Job
                        </Button>
                      </span>
                      <Typography
                        variant="body1"
                        className={classes.collapseText}>
                        Posted by socialkratti@gmail.com on 22-03-2021
                      </Typography>
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                  }}>
                  <span style={{ textAlign: 'right' }}>
                    <IconButton>
                      <CloseIcon />
                    </IconButton>
                  </span>
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    400 Applications
                  </Typography>
                </div>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  const expandAllFunc = () => {
    setExpandAll(!expandAll);
  };

  return (
    <Container maxWidth="xl" disableGutters>
      <Grid container>
        <Grid item xs={12}>
          <Paper variant="outlined" className={classes.tableContainer}>
            <div className={classes.titleRow}>
              <div className={classes.titleDiv}>
                <Typography variant="subtitle1">
                  List of Requirements
                </Typography>
                <Typography variant="body1">
                  Lorem ipsum dolor sit, amet consectetur adipisicing
                  elit.Quisquam, nobis.
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Search by title, skills, location"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <SearchIcon style={{ color: '#707070' }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  style={{
                    minWidth: 300,
                    alignSelf: 'center',
                    backgroundColor: '#FFF',
                  }}
                />
                <IconButton onClick={() => setFilterOpen(!filterOpen)}>
                  <FilterListIcon
                    style={{ color: '#6f6f6f', margin: '15px 15px ' }}
                  />
                </IconButton>
              </div>
            </div>
            {filterOpen && (
              <div className={classes.filterContainer}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select
                    fullWidth
                    variant="outlined"
                    defaultValue="Placeholder"
                    IconComponent={ExpandMoreOutlinedIcon}>
                    <MenuItem value="Placeholder">Choose Status</MenuItem>
                    <MenuItem value={1}>A</MenuItem>
                    <MenuItem value={2}>B</MenuItem>
                    <MenuItem value={3}>C</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <Select
                    defaultValue="Placeholder"
                    variant="outlined"
                    IconComponent={ExpandMoreOutlinedIcon}>
                    <MenuItem value="Placeholder">Created By</MenuItem>
                    <MenuItem value={1}>Rohan</MenuItem>
                    <MenuItem value={2}>Ajinkya</MenuItem>
                    <MenuItem value={3}>Shubham</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <Select
                    variant="outlined"
                    value="Placeholder"
                    IconComponent={ExpandMoreOutlinedIcon}>
                    <MenuItem value="Placeholder">Visible to</MenuItem>
                    <MenuItem value={10}>Visible to All</MenuItem>
                    <MenuItem value={20}>Visible to me</MenuItem>
                    <MenuItem value={30}>Visible to Selected People</MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}
            <div>
              <TableContainer className={classes.container}>
                <div className={classes.dividerTop} />
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ paddingLeft: 5 }}>
                        <Checkbox style={{ paddingLeft: 5 }} />
                      </TableCell>
                      <TableCell style={{ paddingLeft: 5 }}>
                        <div className={classes.expandAllRow}>
                          <Typography
                            variant="subtitle1"
                            className={classes.subtitle1}>
                            Search selected requirements
                          </Typography>
                          <Divider
                            orientation="vertical"
                            flexItem
                            style={{ margin: '0px 5px', color: '#000' }}
                          />
                          <Button
                            variant="subtitle1"
                            onClick={() => visibilityOpen[1](true)}
                            className={classes.subtitle1}
                            style={{
                              textTransform: 'capitalize',
                              paddingLeft: 8,
                            }}>
                            Set Visibility
                          </Button>
                        </div>
                      </TableCell>
                      <TableCell colSpan={2} />
                      <TableCell colSpan={3} align="right">
                        <Button
                          className={classes.expandBtn}
                          color="primary"
                          onClick={() => expandAllFunc()}>
                          {expandAll ? 'Collapse All' : 'Expand All'}
                        </Button>
                      </TableCell>
                    </TableRow>
                    {rows.map(row => (
                      <Row row={row} />
                    ))}
                    <TableRow>
                      <TableCell style={{ paddingLeft: 5 }}>
                        <Checkbox style={{ paddingLeft: 5 }} />
                      </TableCell>
                      <TableCell>
                        <div className={classes.expandAllRow}>
                          <Typography
                            variant="subtitle1"
                            className={classes.subtitle1}>
                            Search selected requirements
                          </Typography>
                          <Divider
                            orientation="vertical"
                            flexItem
                            style={{ margin: '0px 5px', color: '#000' }}
                          />
                          <Button
                            variant="subtitle1"
                            onClick={() => visibilityOpen[1](true)}
                            className={classes.subtitle1}
                            style={{
                              textTransform: 'capitalize',
                              paddingLeft: 5,
                            }}>
                            Set Visibility
                          </Button>
                        </div>
                      </TableCell>
                      <TableCell colSpan={2} />
                      <TableCell colSpan={3} align="right"></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <VisibilityDialog visibilityOpen={visibilityOpen} />
    </Container>
  );
};

export default Requirements;
