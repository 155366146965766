import React, { useState, useEffect, useContext } from 'react';

import SearchIcon from '@material-ui/icons/SearchOutlined';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SendIcon from '@material-ui/icons/SendOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import isEmpty from 'lodash/isEmpty';

import {
  Container,
  InputAdornment,
  createStyles,
  makeStyles,
  Button,
  IconButton,
  Paper,
  TableContainer,
  TableRow,
  Table,
  TableCell,
  TableBody,
  Typography,
  TextField,
  TableHead,
  Avatar,
  Grid,
  Checkbox,
  MenuItem,
  TablePagination,
  CircularProgress,
} from '@material-ui/core';

import CreateAdminDialog from './CreateAdminDialog';
import PreviewAdminDialog from './PreviewAdmin';

import api from '../../api/api';
import SnackBarContext from '../../Context/SnackBarContext';
import UserContext from '../../Context/UserContext';
import { url } from '../../utils/common';

const useStyles = makeStyles(theme =>
  createStyles({
    titleRow: {
      minHeight: 130,
      backgroundColor: '#f1f1f1',
    },
    container: {
      marginTop: 25,
    },
    titleDiv: {
      paddingLeft: 25,
    },
    tablecell: {
      paddingLeft: 5,
      paddingRight: 5,
      borderBottom: 'none',
    },
    tableHead: {
      paddingLeft: 5,
      paddingRight: 5,
    },

    divider: {
      borderLeft: '1px solid #0000004D',
      margin: '0 2px',
    },
    icon: {
      color: '#6f6f6f',
    },
    inlineContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    tablePagination: {
      '& .MuiTablePagination-select': {
        backgroundColor: '#FFF',
      },
    },
  })
);

const perTick = per => {
  return per ? (
    <CheckIcon fontSize="small" style={{ color: 'green', marginLeft: 3 }} />
  ) : (
    <ClearIcon fontSize="small" style={{ color: 'red', marginLeft: 3 }} />
  );
};

const ManageAdmin = () => {
  const classes = useStyles();
  const [adminDialog, setAdminDialog] = useState(false);
  const previewAdminDialog = useState(false);
  const [onHover, setOnHover] = useState(0);
  const [deleteAdmins, setDeleteAdmins] = useState([]);
  const [adminData, setAdminData] = useState(null);
  const [sortBy, setSortBy] = useState('ASC');
  const [searchTerm, setSearchTerm] = useState('');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const { alerts } = useContext(SnackBarContext);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = useState(true);
  const editAdminData = useState(null);
  const { authState } = useContext(UserContext);
  const [previewData, setPreviewData] = useState(null);

  useEffect(() => {
    const data = {
      role: 'Admin',
      sort_by: sortBy,
      search_param: searchTerm,
      limit: limit,
      page_no: page,
    };

    if (!adminDialog)
      api({ path: '/admin/getActiveUsers', data: data })
        .then(res => {
          if (res.data.hasError) {
            alerts[1]({
              ...alerts[0],
              message: res.data.message,
              state: true,
            });
            setLoading(false);
            return;
          }
          setLoading(false);
          !isEmpty(res.data?.data) && setAdminData(res.data?.data);
          setTotalRecord(res.data?.totalRecord);
        })
        .catch(err => {
          alerts[1]({
            ...alerts[0],
            message: 'Something went wrong',
            state: true,
          });
          console.log('Get Admin', err);
          setLoading(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, searchTerm, sortBy]);

  const handleEditClick = data => {
    editAdminData[1](data);
    setAdminDialog(true);
  };

  const handleChange = (e, id) => {
    e.target.checked
      ? setDeleteAdmins([...deleteAdmins, id])
      : setDeleteAdmins(deleteAdmins.filter(item => item !== id));
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const deleteAllAdmin = adminData.map(n => n?.key);
      setDeleteAdmins(deleteAllAdmin);
      return;
    }
    setDeleteAdmins([]);
  };

  const handleChangeRowsPerPage = event => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const Row = ({ row }) => {
    return (
      <>
        <TableRow
          hover
          align="left"
          onMouseOver={() => setOnHover(row?.id)}
          onMouseLeave={() => setOnHover(0)}
          key={row?.id}>
          <TableCell className={classes.tablecell} width="1%">
            <Checkbox
              key={Math.random()}
              checked={
                deleteAdmins?.findIndex(item => item === row?.id) !== -1
                  ? true
                  : false
              }
              onChange={e => handleChange(e, row?.id)}
              style={{ paddingLeft: 5, paddingRight: 5 }}
            />
          </TableCell>
          <TableCell className={classes.tablecell}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar src={`${url}/image/${row?.profile_photo}`} />
              <div>
                <Typography variant="body1" style={{ marginLeft: 10 }}>
                  {`${row?.first_name} ${row?.last_name}`}
                </Typography>
                <div style={{ display: 'flex' }}>
                  <Typography variant="body1" style={{ margin: '0px 6px' }}>
                    {row?.phone_no}
                  </Typography>
                  <div className={classes.divider} />
                  <Typography variant="body1">{row?.whatsapp_no}</Typography>
                </div>
              </div>
            </div>
          </TableCell>
          <TableCell className={classes.tablecell}>
            {
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1">R</Typography>{' '}
                {perTick(row?.dashboard_read)}
                <Typography variant="body1">W</Typography>{' '}
                {perTick(row?.dashboard_write)}{' '}
                <Typography variant="body1">D</Typography>
                {perTick(row?.dashboard_delete)}
              </span>
            }
          </TableCell>
          <TableCell className={classes.tablecell}>
            {
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1">R</Typography>{' '}
                {perTick(row?.requirement_read)}
                <Typography variant="body1">W</Typography>{' '}
                {perTick(row?.requirement_write)}{' '}
                <Typography variant="body1">D</Typography>
                {perTick(row?.requirement_delete)}
              </span>
            }
          </TableCell>
          <TableCell className={classes.tablecell}>
            {
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1">R</Typography>{' '}
                {perTick(row?.report_read)}
                <Typography variant="body1">W</Typography>{' '}
                {perTick(row?.report_write)}{' '}
                <Typography variant="body1">D</Typography>
                {perTick(row?.report_delete)}
              </span>
            }
          </TableCell>
          <TableCell className={classes.tablecell}>
            {
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1">R</Typography>{' '}
                {perTick(row?.profile_read)}
                <Typography variant="body1">W</Typography>{' '}
                {perTick(row?.profile_write)}{' '}
                <Typography variant="body1">D</Typography>
                {perTick(row?.profile_delete)}
              </span>
            }
          </TableCell>
          <TableCell className={classes.tablecell}>
            {
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1">R</Typography>{' '}
                {perTick(row?.dashboards?.r)}
                <Typography variant="body1">W</Typography>{' '}
                {perTick(row?.dashboards?.w)}{' '}
                <Typography variant="body1">D</Typography>
                {perTick(row?.dashboards?.d)}
              </span>
            }
          </TableCell>
          <TableCell className={classes.tablecell}>
            {onHover === row?.id ? (
              <div className={classes.hoverIcons}>
                <IconButton>
                  <VisibilityIcon
                    fontSize="small"
                    onClick={() => {
                      setPreviewData(row);
                      previewAdminDialog[1](true);
                    }}
                    className={classes.icon}
                  />
                </IconButton>
                <IconButton>
                  <SendIcon fontSize="small" className={classes.icon} />
                </IconButton>
                <IconButton onClick={() => handleEditClick(row)}>
                  <EditIcon fontSize="small" className={classes.icon} />
                </IconButton>
                <IconButton>
                  <DeleteIcon fontSize="small" className={classes.icon} />
                </IconButton>
              </div>
            ) : (
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1">R</Typography>{' '}
                {perTick(row?.dashboards?.r)}
                <Typography variant="body1">W</Typography>{' '}
                {perTick(row?.dashboards?.w)}{' '}
                <Typography variant="body1">D</Typography>
                {perTick(row?.dashboards?.d)}
              </span>
            )}
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <Container maxWidth="xl" disableGutters>
      <Grid container>
        <Paper elevation={5} style={{ width: '100%' }}>
          <Grid item xs={12}>
            <Grid container alignItems="center" className={classes.titleRow}>
              <Grid item xs={8} className={classes.titleDiv}>
                <Typography variant="subtitle1">Manage Admin</Typography>
                <Typography variant="body1">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Quisquam, nobis.
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                style={{
                  padding: '0px 10px',
                }}>
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right',
                    paddingBottom: 10,
                  }}>
                  <Button color="primary">Import / Export</Button>
                  <Button color="primary" onClick={() => setAdminDialog(true)}>
                    Create
                  </Button>
                </div>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Search here"
                  fullWidth
                  onChange={e => setSearchTerm(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: '#707070' }} />
                      </InputAdornment>
                    ),
                  }}
                  style={{
                    minWidth: 300,
                    alignSelf: 'center',
                    backgroundColor: '#FFF',
                    marginRight: 15,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} container md>
            <Grid item xs={12} md={12}>
              <TableContainer className={classes.container}>
                <Table>
                  <TableHead>
                    <TableRow
                      align="center"
                      style={{ backgroundColor: '#f1f1f1', padding: 0 }}>
                      <TableCell
                        variant="head"
                        className={classes.tableHead}
                        colSpan={3}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          <Checkbox
                            indeterminate={
                              deleteAdmins.length !== 0 &&
                              deleteAdmins.length !== adminData.length
                                ? true
                                : false
                            }
                            onClick={e => handleSelectAllClick(e)}
                          />
                          <DeleteIcon className={classes.icon} />
                        </div>
                      </TableCell>
                      <TableCell
                        className={classes.tableHead}
                        variant="head"
                        colSpan={5}
                        align="justify">
                        <TablePagination
                          component="div"
                          labelRowsPerPage="Show"
                          rowsPerPage={limit}
                          count={totalRecord}
                          page={page}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={e => handleChangeRowsPerPage(e)}
                          className={classes.tablePagination}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="32%" variant="head" colSpan={2}>
                        <div className={classes.inlineContainer}>
                          <Typography
                            variant="subtitle1"
                            style={{ fontWeight: 'normal' }}>
                            SORT BY :
                          </Typography>
                          <TextField
                            InputProps={{ disableUnderline: true }}
                            select
                            value={sortBy}
                            onChange={e =>
                              setSortBy(
                                e.target.value === 'placeholder'
                                  ? 'ASC'
                                  : e.target.value
                              )
                            }
                            style={{ marginLeft: 10, paddingTop: 1 }}>
                            <MenuItem value="placeholder">DEFAULT</MenuItem>
                            <MenuItem value="ASC">Ascending</MenuItem>
                            <MenuItem value="DESC">Descending</MenuItem>
                          </TextField>
                        </div>
                      </TableCell>
                      <TableCell
                        variant="head"
                        width="10%"
                        className={classes.tableHead}>
                        <Typography variant="subtitle1">Dashboard</Typography>
                      </TableCell>

                      <TableCell
                        variant="head"
                        width="10%"
                        className={classes.tableHead}>
                        <Typography variant="subtitle1">
                          Requirements
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        variant="head"
                        width="10%"
                        className={classes.tableHead}>
                        <Typography variant="subtitle1" colSpan={2}>
                          Reports
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        variant="head"
                        width="10%"
                        className={classes.tableHead}>
                        <Typography variant="subtitle1" colSpan={2}>
                          Profile
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        variant="head"
                        width="10%"
                        className={classes.tableHead}>
                        <Typography variant="subtitle1" colSpan={2}>
                          Manage User
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        variant="head"
                        className={classes.tableHead}>
                        <Typography variant="subtitle1" colSpan={2}>
                          Roles & Permission
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableCell align="center" colSpan={8}>
                        <CircularProgress />
                      </TableCell>
                    ) : adminData ? (
                      adminData?.map(row => <Row key={row?.id} row={row} />)
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={9}>
                          <Typography variant="subtitle1">
                            No Data Found
                          </Typography>{' '}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <CreateAdminDialog
        adminDialog={adminDialog}
        setAdminDialog={setAdminDialog}
        alerts={alerts}
        editAdminData={editAdminData}
        authState={authState}
      />
      <PreviewAdminDialog
        previewAdminDialog={previewAdminDialog}
        previewData={previewData}
      />
    </Container>
  );
};

export default ManageAdmin;
