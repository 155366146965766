import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme =>
  createStyles({
    dialogTitle: {
      backgroundColor: theme.palette.ternary.main,
    },
  })
);

const ConfirmationDialog = ({ open, onClick }) => {
  const classes = useStyles();

  const handleClose = () => {
    open[1](false);
  };

  return (
    <Dialog
      open={open[0]}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="confirmation-dialog"
      aria-describedby="confirmation-dialog">
      <DialogTitle id="confirmation-dialog" className={classes.dialogTitle}>
        <Typography variant="subtitle1" color="textSecondary">
          Confirmation Dialog
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Are you sure you want to delete this ?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained">
          Cancel
        </Button>
        <Button onClick={onClick} variant="contained" color="primary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
