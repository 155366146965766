import React, { useState } from 'react';
import {
  InputAdornment,
  Dialog,
  Button,
  IconButton,
  Typography,
  TextField,
  Slide,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  makeStyles,
  createStyles,
  List,
  ListItem,
  CircularProgress,
  FormHelperText,
} from '@material-ui/core';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import last from 'lodash/last';

import api from '../../api/api';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStylesDialog = makeStyles(theme =>
  createStyles({
    dialogTitleContainer: {
      backgroundColor: theme.palette.ternary.main,
      padding: '10px 15px',
    },
    dialogTitleContainerDiv: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },

    dialogActionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 10,
    },
    dialogActionButton: {
      margin: '5px 13px',
      color: '#FFF',
      padding: '10px 25px',
      minWidth: 132,
    },

    dialogTextField: {
      margin: '5px 0',
    },
    listItem: {
      paddingLeft: 0,
      paddingRight: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
    },
    btn: {
      fontWeight: 600,
      marginTop: 15,
    },
    helperText: {
      color: 'red',
      marginLeft: 5,
    },
  })
);

const CreateStatusDialog = ({
  statusDialogOpen,
  setStatusDialogOpen,
  editIconHead = 'profile_status',
  editStatusData,
  setEditStatusData,
  setEditIconHead,
  alerts,
  authState,
}) => {
  const classes = useStylesDialog();
  const [newStatus, setNewStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [headErr, setHeadErr] = useState(null);

  const handleClose = () => {
    if (headErr !== null) {
      alerts[1]({
        error: true,
        message: 'Please resolve errors',
        state: true,
      });
      return;
    }
    setLoading(false);
    setStatusDialogOpen(false);
  };

  const onClickFunc = () => {
    if (headErr !== null) {
      alerts[1]({
        error: true,
        message: 'Please resolve errors',
        state: true,
      });
      return;
    }
    setLoading(true);
    const data = {
      user_account_id: authState[0].user_account_id.toString(),
      status_heading: editIconHead,
      status_head: editStatusData[editIconHead].map((item, index) => {
        return { id: item?.id, value: item?.status_head, position: index };
      }),
    };
    api({ path: '/admin/status/createStatus', data })
      .then(res => {
        if (res.data.hasError) {
          alerts[1]({
            error: true,
            message: res.data.error,
            state: true,
          });
          handleClose();
          return;
        }
        alerts[1]({
          error: false,
          message: res.data.message,
          state: true,
        });
        handleClose();
      })

      .catch(err => {
        alerts[1]({
          error: true,
          message: 'Something went wrong',
          state: true,
        });
        console.log(err);
        handleClose();
      });
  };

  function handleOnDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const items = Array.from(editStatusData[editIconHead]);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setEditStatusData({ ...editStatusData, [editIconHead]: items });
  }

  const addMoreClick = () => {
    if (newStatus === '') {
      setErr('Please enter subhead name');
      return;
    }
    let status = {
      status_head: newStatus,
      id: 0,
    };
    let editStatusDataObject = editStatusData;
    editStatusDataObject[editIconHead]?.splice(
      editStatusDataObject[editIconHead]?.length - 1,
      0,
      status
    );

    setEditStatusData(editStatusDataObject);
    setNewStatus('');
  };

  const editStatusHead = (e, index) => {
    console.log('fdf', e.target.value === '');
    if (e.target.value === '') {
      setHeadErr('This field can not be empty');
      console.log('fdf2', e.target.value === '');
    } else {
      setHeadErr(null);
    }

    let editStatusDataArr = editStatusData[editIconHead];
    editStatusDataArr[index] = {
      ...editStatusDataArr[index],
      status_head: e.target.value,
    };
  };

  const deleteRecord = index => {
    api({ path: '/admin/status/deleteStatus', data: { status_id: index } })
      .then(res => {
        let items = Array.from(editStatusData[editIconHead]);
        items = items.filter(item => item.id !== index);
        setEditStatusData({ ...editStatusData, [editIconHead]: items });
        setHeadErr(null);
        alerts[1]({
          error: false,
          message: 'Delete operation successful',
          state: true,
        });
      })
      .catch(err =>
        alerts[1]({
          error: true,
          message: 'Delete opertion faild',
          state: true,
        })
      );
  };

  return (
    <Dialog
      open={statusDialogOpen}
      maxWidth="sm"
      TransitionComponent={Transition}
      fullWidth>
      <DialogTitle className={classes.dialogTitleContainer}>
        <div className={classes.dialogTitleContainerDiv}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton>
              <ArrowBackIcon style={{ color: '#FFF' }} />
            </IconButton>
            <Typography variant="subtitle1" style={{ color: '#FFF' }}>
              Create Status
            </Typography>
          </div>
          <IconButton onClick={() => handleClose()}>
            <CloseIcon style={{ color: '#FFF' }} />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        <Typography variant="subtitle1" style={{ padding: '10px 0' }}>
          Status Heading
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          defaultValue={editIconHead}
          className={classes.dialogTextField}
          onChange={e => setEditIconHead(e.target.value)}
          select>
          <MenuItem value="profile_status">Profile Status</MenuItem>
          <MenuItem value="requirement_status">Requirement Status</MenuItem>
          <MenuItem value="call_status">Call Status</MenuItem>
        </TextField>

        <Typography variant="subtitle1" style={{ padding: '10px 0' }}>
          Status Heads
        </Typography>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="Status">
            {provided => (
              <List {...provided.droppableProps} ref={provided.innerRef}>
                {editStatusData[editIconHead]?.map((item, index) => {
                  return (
                    <Draggable
                      key={item.id}
                      draggableId={item?.id?.toString()}
                      isDragDisabled={
                        index === 0 ||
                        editIconHead !== 'call_status' ||
                        last(editStatusData[editIconHead])?.id === item?.id
                          ? true
                          : false
                      }
                      index={index}>
                      {provided => (
                        <>
                          {editIconHead !== 'call_status' && index === 0 ? (
                            <ListItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={classes.listItem}
                              key={index}>
                              <TextField
                                defaultValue={item?.status_head}
                                variant="outlined"
                                fullWidth
                                disabled={true}
                              />
                            </ListItem>
                          ) : editIconHead !== 'call_status' &&
                            last(editStatusData[editIconHead])?.id ===
                              item?.id ? (
                            <ListItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={classes.listItem}
                              key={index}>
                              <TextField
                                defaultValue={item?.status_head}
                                variant="outlined"
                                fullWidth
                                disabled={true}
                              />
                            </ListItem>
                          ) : (
                            <ListItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={classes.listItem}
                              key={index}>
                              <TextField
                                defaultValue={item?.status_head}
                                // value={editStatusData[editIconHead][index].status_head}
                                variant="outlined"
                                fullWidth
                                onChange={e => editStatusHead(e, index)}
                                InputProps={{
                                  endAdornment: (
                                    <>
                                      <InputAdornment position="start">
                                        <IconButton
                                          onClick={() =>
                                            deleteRecord(item?.id)
                                          }>
                                          <DeleteIcon
                                            style={{ color: '#707070' }}
                                          />
                                        </IconButton>
                                      </InputAdornment>
                                      <InputAdornment position="start">
                                        <IconButton>
                                          <DragIndicatorIcon
                                            style={{
                                              color: '#707070',
                                            }}
                                          />
                                        </IconButton>
                                      </InputAdornment>
                                    </>
                                  ),
                                }}
                              />

                              <FormHelperText className={classes.helperText}>
                                {headErr}
                              </FormHelperText>
                            </ListItem>
                          )}
                        </>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
        <Typography variant="subtitle1" style={{ padding: '10px 0' }}>
          Create Subhead
        </Typography>
        <TextField
          variant="outlined"
          className={classes.dialogTextField}
          placeholder="Type Subhead"
          fullWidth
          value={newStatus}
          onChange={e => {
            setErr(null);
            setNewStatus(e.target.value);
          }}
        />
        <FormHelperText className={classes.helperText}>{err}</FormHelperText>
        <div>
          <Button
            color="primary"
            className={classes.btn}
            onClick={() => addMoreClick()}>
            <AddOutlinedIcon />
            Add New Subhead
          </Button>
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActionContainer}>
        <Button
          variant="contained"
          className={classes.dialogActionButton}
          style={{ color: '#000' }}
          size="large"
          onClick={() => handleClose()}>
          Cancel
        </Button>
        {loading ? (
          <Button
            variant="contained"
            className={classes.dialogActionButton}
            color="primary"
            size="large">
            <CircularProgress color="textSecondary" size={25} />
          </Button>
        ) : (
          <Button
            variant="contained"
            className={classes.dialogActionButton}
            color="primary"
            size="large"
            onClick={() => {
              onClickFunc();
            }}>
            Save & Update
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CreateStatusDialog;
