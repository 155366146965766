import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Logo from '../../assets/logo/logo.png';
import Button from '@material-ui/core/Button';
// import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  loginButton: {
    borderRadius: 2,
  },
}));

export default function GuestHeader() {
  const classes = useStyles();
  // const history = useHistory();

  // const logOut = () => {
  //   sessionStorage.clear();
  //   history.replace('/');
  // };

  return (
    <div className={classes.grow}>
      <AppBar position="static" color="secondary">
        <Container maxWidth={false}>
          <Toolbar>
            <img src={Logo} alt="logo" />
            <div className={classes.grow} />
            <div>
              <Button
                variant="contained"
                color="primary"
                className={classes.loginButton}>
                LOGIN OR REGISTER
              </Button>
            </div>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}
