import {
  Chip,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import React from 'react';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';

const useStyles = makeStyles(theme => ({
  container: {
    border: '1px solid #00000032',
    padding: 15,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 5,
  },
  chipsDiv: {
    listStyle: 'none',
    display: 'flex',
    flexFlow: 'wrap',
    marginTop: 5,
  },
  chip: {
    margin: theme.spacing(0.5),
    fontSize: 12,
    borderRadius: 4,
    backgroundColor: '#fff',
    border: '1px solid #00000032',
    width: '98%',
  },
}));

function SidebarFilter(props) {
  const classes = useStyles();
  const [chipData, setChipData] = React.useState([
    { key: 0, label: 'Accounting' },
    { key: 1, label: 'UI Designer' },
    { key: 2, label: 'Marketing & Sales' },
    { key: 3, label: 'Sales' },
    { key: 4, label: 'Architecture' },
  ]);
  const handleDelete = chipToDelete => () => {
    setChipData(prev => prev.filter((_, i) => i !== chipToDelete));
  };
  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <Typography variant={'subtitle1'}>Job Titles</Typography>
        <Typography variant="body1">CLEAR</Typography>
      </div>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search within Results"
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SearchRoundedIcon />
            </InputAdornment>
          ),
        }}
      />
      <div className={classes.chipsDiv}>
        {chipData.map((item, i) => {
          return (
            <li key={i} style={{ flex: '1' }}>
              <Chip
                label={item.label}
                onDelete={handleDelete(i)}
                className={classes.chip}
              />
            </li>
          );
        })}
      </div>
    </div>
  );
}

export default SidebarFilter;
