import React from 'react';
import {
  Typography,
  makeStyles,
  Paper,
  Button,
  TextField,
  MenuItem,
  Tab,
  Tabs,
  withStyles,
  Collapse,
  IconButton,
  Avatar,
  Checkbox,
  Select,
} from '@material-ui/core';
import { TabPanel, TabContext } from '@material-ui/lab';
import PropTypes from 'prop-types';
import GetAppIcon from '@material-ui/icons/GetAppOutlined';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import ArrowUpIcon from '@material-ui/icons/KeyboardArrowUpOutlined';
import BackupIcon from '@material-ui/icons/BackupOutlined';
import CloseIcon from '@material-ui/icons/CancelRounded';
import PdfIcon from '@material-ui/icons/PictureAsPdfOutlined';
import ImageIcon from '@material-ui/icons/ImageOutlined';
import ChatBubbleIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';

import person from '../../assets/images/face.jpg';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 500,
  },
  paper: {
    padding: 15,
    marginBottom: 20,
    paddingBottom: 20,
  },
  textField: {
    marginTop: 10,
  },
  commentBtn: {
    backgroundColor: theme.palette.ternary.main,
    color: '#FFF',
    marginTop: 10,
  },
  tabs: {
    marginTop: 10,
  },
  bold: {
    fontWeight: 'bold',
  },
  muiTab: {},
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    marginLeft: 5,
    marginRight: 5,
  },
  downloadLogPerson: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 5,
  },
  divider: {
    height: 30,
    borderLeft: '1px solid #00000060',
    marginRight: 3,
    marginLeft: 3,
  },
  faintText: {
    opacity: 0.8,
  },
  horizontalDivider: {
    borderTop: '1px solid #00000026',
    margin: '10px 0px',
  },
  uploadFileContainer: {
    border: '1px dashed #00000060',
    borderRadius: 4,
    minHeight: 230,
    margin: 10,
    textAlign: 'center',
    padding: 10,
  },
  uploadFileList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  commentTitleRow: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  inlineContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  tabPanel: {
    padding: '10px 0px',
  },
  commentsContainer: {
    maxHeight: 500,
    overflowY: 'auto',
    marginTop: 5,
  },
  selectedReq: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    marginRight: 5,
    marginBottom: 5,
    marginTop: 5,
    minWidth: '40%',
  },
  selectedReqContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: 20,
  },
}));

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
})(Tabs);

const AntTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 80,
    marginRight: theme.spacing(1),
    '&$selected': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = index => {
  return {
    id: `comment-tab-${index}`,
    'aria-controls': `comment-tabpanel-${index}`,
  };
};

const downloadLog = [
  {
    name: 'Larry Bass',
    date: 'Feb 11,2021',
    time: '04:30',
  },
  {
    name: 'Flora Gilbert',
    date: 'Feb 11,2021',
    time: '04:30',
  },
  {
    name: 'Anna Padilla',
    date: 'Feb 11,2021',
    time: '04:30',
  },
  {
    name: 'Steven McBridge',
    date: 'Feb 11,2021',
    time: '04:30',
  },
  {
    name: 'Leilla Simpson',
    date: 'Feb 11,2021',
    time: '04:30',
  },
];

const RightSideBar = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [downloadLogOpen, setDownloadLogOpen] = React.useState(true);
  const [uploadFileOpen, setUploadFileOpen] = React.useState(true);
  const [uploadFiles, setUploadFiles] = React.useState([]);
  const [referOpen, setReferOpen] = React.useState();
  const [requirements, setRequirements] = React.useState([]);

  var regex = new RegExp('[^.]+$');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getFileType = fileName => {
    let extension = fileName.match(regex);

    switch (extension[0]) {
      case 'pdf':
        return <PdfIcon />;
      case 'png':
        return <ImageIcon />;
      case 'jpg':
        return <ImageIcon />;
      default:
        break;
    }
  };

  const removeFile = index => {
    var files = uploadFiles;
    files.splice(index, 1);
    setUploadFiles([...files]);
  };

  const removeComment = index => {
    var requirementsArr = requirements;
    requirementsArr.splice(index, 1);
    setRequirements([...requirementsArr]);
  };

  const Comments = ({ requirementSpe = false }) => {
    const comments = [
      {
        name: 'Rahul Sahu',
        date: 'Feb 24, 2021',
        time: '04:30PM',
        id: '#1245789',
      },
      {
        name: 'Rahul Sahu',
        date: 'Feb 24, 2021',
        time: '04:30PM',
        id: '#1245790',
      },
      {
        name: 'Rahul Sahu',
        date: 'Feb 24, 2021',
        time: '04:30PM',
        id: '#1245791',
      },
    ];

    return (
      <div className={classes.commentsContainer}>
        {requirementSpe && (
          <Select
            variant="outlined"
            value={['placeholder']}
            style={{ width: '90%' }}
            multiple>
            <MenuItem value="placeholder">Choose Requirement</MenuItem>
            {comments.map(comment => (
              <MenuItem value={comment.id}>
                <Checkbox
                  value={comment.id}
                  checked={requirements?.includes(comment.id)}
                  onChange={e =>
                    setRequirements([...requirements, e.target.value])
                  }
                />
                {comment.id}
              </MenuItem>
            ))}
          </Select>
        )}
        <div className={classes.selectedReqContainer}>
          {requirements.map((requirement, index) => (
            <Paper variant="outlined" className={classes.selectedReq}>
              {requirement}{' '}
              <CloseIcon
                onClick={() => removeComment(index)}
                style={{ marginLeft: 15, color: '#000', opacity: 0.3 }}
              />
            </Paper>
          ))}
        </div>

        {comments.map(comment => (
          <div style={{ marginTop: 10 }}>
            <div className={classes.commentTitleRow}>
              <div className={classes.inlineContainer}>
                <ChatBubbleIcon
                  fontSize="small"
                  style={{ marginRight: 5, marginBottom: 10 }}
                />
                <div>
                  <Typography variant="body1" className={classes.bold}>
                    {comment.name}
                  </Typography>
                  <Typography>{comment.id}</Typography>
                </div>
              </div>
              <Typography variant="body1">
                {comment.date}
                <span className={classes.divider} /> {comment.time}
              </Typography>
            </div>
            <Typography
              variant="body1"
              style={{ textAlign: 'justify', marginTop: 5 }}>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis in
              nam architecto molestias ipsa iste placeat officia possimus
            </Typography>
          </div>
        ))}
      </div>
    );
  };

  const CommentTabs = () => {
    return (
      <TabContext value={value}>
        <div>
          <AntTabs value={value} onChange={handleChange}>
            <AntTab label="All" {...a11yProps(0)} />
            <AntTab label="General" {...a11yProps(1)} />
            <AntTab label="Req. Specific" {...a11yProps(2)} />
          </AntTabs>

          <TabPanel classes={{ root: classes.tabPanel }} value={0} index={0}>
            <Comments />
          </TabPanel>
          <TabPanel classes={{ root: classes.tabPanel }} value={1} index={1}>
            <Comments />
          </TabPanel>
          <TabPanel classes={{ root: classes.tabPanel }} value={2} index={2}>
            <Comments requirementSpe={true} />
          </TabPanel>
        </div>
      </TabContext>
    );
  };

  return (
    <div>
      <Paper variant="outlined" className={classes.paper}>
        <Typography variant="subtitle1">Add Comments</Typography>
        <TextField
          defaultValue="placeholder"
          variant="outlined"
          className={classes.textField}
          fullWidth
          select>
          <MenuItem value="placeholder">This Requirement</MenuItem>
        </TextField>
        <TextField
          variant="outlined"
          className={classes.textField}
          placeholder="Type Comments Here"
          InputProps={{
            style: {
              height: 100,
              flexDirection: 'column',
            },
          }}
          fullWidth
          multiline
        />
        <Button variant="contained" className={classes.commentBtn} fullWidth>
          Comment
        </Button>
        <CommentTabs />
      </Paper>
      {/* NOTE: Download Log */}
      <Paper variant="outlined" className={classes.paper}>
        <div className={classes.titleRow}>
          <Typography variant="subtitle1">Download Log</Typography>
          <div>
            <IconButton>
              <GetAppIcon />
            </IconButton>
            <IconButton onClick={() => setDownloadLogOpen(prev => !prev)}>
              {' '}
              {downloadLogOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </IconButton>
          </div>
        </div>
        <Collapse in={downloadLogOpen} unmountOnExit>
          {downloadLog.map(item => (
            <>
              <div className={classes.downloadLogPerson}>
                <Avatar src={person} alt="person" />
                <div style={{ marginLeft: 10 }}>
                  <Typography className={classes.bold} variant="body1">
                    {item.name}
                  </Typography>
                  <Typography className={classes.faintText} variant="body1">
                    {item.date} <span className={classes.divider} /> {item.time}
                  </Typography>
                </div>
              </div>
              <div className={classes.horizontalDivider} />
            </>
          ))}
        </Collapse>
      </Paper>

      {/* NOTE:Upload File */}

      <Paper variant="outlined" className={classes.paper}>
        <div className={classes.titleRow}>
          <Typography variant="subtitle1">Upload File</Typography>

          <IconButton onClick={() => setUploadFileOpen(prev => !prev)}>
            {' '}
            {uploadFileOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </IconButton>
        </div>
        <Collapse in={uploadFileOpen} unmountOnExit>
          <div className={classes.uploadFileContainer}>
            <IconButton>
              <BackupIcon style={{ fontSize: 70 }} />
            </IconButton>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: 'normal' }}
              align="center">
              Click here to browse or <br /> drag and drop a file
            </Typography>
            <Button
              style={{ padding: '15px 55px', margin: '10px 5px' }}
              variant="contained">
              <label for="file">
                <Typography variant="subtitle1">Browse</Typography>
              </label>
            </Button>
            <input
              type="file"
              id="file"
              onChange={e =>
                setUploadFiles([...uploadFiles, e.target.files[0].name])
              }
              style={{ display: 'none' }}
            />
            <Typography style={{ marginBottom: 5 }} variant="body1">
              File can be an image, doc,audio or video format The maximum
              file-size is 10MB
            </Typography>
          </div>
          <div className={classes.horizontalDivider} />

          <div>
            {uploadFiles.length !== 0 &&
              uploadFiles?.map((file, index) => (
                <div className={classes.uploadFileList}>
                  <div className={classes.uploadFileList}>
                    {getFileType(file)}
                    <Typography
                      variant="body1"
                      className={classes.bold}
                      color="primary"
                      style={{ marginLeft: 5 }}>
                      {file}
                    </Typography>
                  </div>
                  <IconButton onClick={() => removeFile(index)}>
                    <CloseIcon fontSize="small" />
                  </IconButton>{' '}
                </div>
              ))}
          </div>
        </Collapse>
      </Paper>

      {/* NOTE: Refernce Check Form */}
      <Paper variant="outlined" className={classes.paper}>
        <div className={classes.titleRow}>
          <Typography variant="subtitle1">Reference Check Form</Typography>

          <IconButton onClick={() => setReferOpen(prev => !prev)}>
            {' '}
            {referOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </IconButton>
        </div>
        <Collapse in={referOpen} unmountOnExit>
          <div className={classes.horizontalDivider} />

          <div>
            {uploadFiles.length !== 0 &&
              uploadFiles?.map((file, index) => (
                <div className={classes.uploadFileList}>
                  <div className={classes.uploadFileList}>
                    {getFileType(file)}
                    <Typography
                      variant="body1"
                      className={classes.bold}
                      color="primary"
                      style={{ marginLeft: 5 }}>
                      {file}
                    </Typography>
                  </div>
                  <IconButton onClick={() => removeFile(index)}>
                    <CloseIcon fontSize="small" />
                  </IconButton>{' '}
                </div>
              ))}
          </div>
        </Collapse>
      </Paper>
    </div>
  );
};

export default RightSideBar;
