import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#E45D33',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#141D3D',
      contrastText: '#FFF',
    },
    ternary: {
      main: '#1A1427',
      contrastText: '#fff',
    },
    text: {
      primary: '#000000CC',
      secondary: '#FFFFFF',
    },
  },

  typography: {
    h1: {
      fontSize: 34,
      fontWeight: 'bold',
    },

    subtitle1: {
      fontSize: 14,
      color: '#000000',
      opacity: 0.8,
      fontWeight: 'bold',
    },

    body1: {
      fontSize: 12,
      color: '#000000',
      opacity: 0.8,
    },

    body2: {
      fontSize: 13,
      color: '#000000',
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&:hover': {
          border: 'none',
        },
        color: 'black',
        height: 40,
        borderRadius: 4,
      },
      input: {
        '&::placeholder': {
          fontSize: 12,
          color: '#000000',
          opacity: 0.6,
          fontWeight: 'bold',
        },
      },
    },
    MuiFormHelperText: {
      root: {
        color: 'crimson',
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: 12,
        color: '#00000099',
        fontWeight: 'bold',
      },
    },

    MuiSelect: {
      root: {
        fontSize: 12,
        color: '#00000099',
        fontWeight: 'bold',
      },
    },
    MuiCheckbox: {
      root: {
        color: '#00000099',
      },
    },
    MuiRadio: {
      root: {
        color: '#9893a0',
      },
    },
    MuiChip: {
      deletable: {
        backgroundColor: '#fff !important',
      },
      deleteIcon: {
        height: 18,
        width: 18,
      },
    },
  },
});

export default theme;
