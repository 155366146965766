import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function SnackBar({ alerts }) {
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    alerts[1]({ ...alerts, state: false });
  };

  return (
    <div>
      {alerts[0]?.error ? (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={alerts[0].state}
          autoHideDuration={4000}
          onClose={handleClose}
          className={classes.root}>
          <Alert onClose={handleClose} severity="error">
            {alerts[0]?.message}
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={alerts[0].state}
          autoHideDuration={6000}
          onClose={handleClose}
          className={classes.root}>
          <Alert onClose={handleClose} severity="success">
            {alerts[0]?.message}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
