import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import ChangePassword from './ChangePassword';
import UpdateLogo from './UpdateLogo';
import DeactivateAccount from './DeactivateAccount';
import TermsAndPolicy from './TermsAndPolicy';
import DashboardTab from '../shared/DashboardTab';
import standinggirl from '../../assets/images/standinggirl.png';
const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && (
        <div p={3}>
          <Typography>{children}</Typography>
        </div>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = index => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  paper: {
    maxWidth: 250,
    marginTop: 18,
    marginLeft: 15,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    padding: '15px 0px',
  },
  img: {
    marginTop: 130,
    maxWidth: 250,
  },
  tabName: {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    textTransform: 'none',
    '& .MuiTab-wrapper': {
      alignItems: 'flex-start',
    },
  },
  tabIndicator: {
    padding: 1,
  },
}));

const Settings = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    newValue !== 0 && setValue(newValue);
  };

  return (
    <>
      <DashboardTab tab={false} />
      <div className={classes.root}>
        <div>
          <Paper className={classes.paper}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              className={classes.tabs}
              textColor="primary"
              indicatorColor="primary"
              TabIndicatorProps={{ className: classes.tabIndicator }}>
              <Tab
                className={classes.tabName}
                label="Settings"
                {...a11yProps(2)}
              />
              <Tab
                className={classes.tabName}
                label="Change Password"
                {...a11yProps(2)}
              />
              <Tab
                className={classes.tabName}
                label="Update Logo"
                {...a11yProps(2)}
              />
              <Tab
                className={classes.tabName}
                label="Deactive Account"
                {...a11yProps(3)}
              />
              <Tab
                className={classes.tabName}
                label="Terms & Policy"
                {...a11yProps(4)}
              />
            </Tabs>
          </Paper>
          <img src={standinggirl} className={classes.img} alt="people" />
        </div>

        <TabPanel value={value} index={1}>
          <ChangePassword />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <UpdateLogo />
        </TabPanel>

        <TabPanel value={value} index={3}>
          <DeactivateAccount />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <TermsAndPolicy />
        </TabPanel>
      </div>
    </>
  );
};

export default Settings;
