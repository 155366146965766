import React, { useContext } from 'react';
import {
  Container,
  Paper,
  Typography,
  Grid,
  Avatar,
  makeStyles,
  Button,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router';

import api from '../../api/api';
import UserContext from '../../Context/UserContext';
import SnackBarContext from '../../Context/SnackBarContext';

const useStyles = makeStyles(theme => ({
  deactivateAccContainer: {
    padding: '20px 50px',
    marginTop: 18,
  },
  textLabel: {
    marginTop: 3,
  },
}));

const DeactivateAccount = () => {
  const classes = useStyles();
  const { authState } = useContext(UserContext);
  const { alerts } = useContext(SnackBarContext);
  const history = useHistory();
  console.log(alerts);

  const logOut = () => {
    sessionStorage.clear();
    history.replace('/');
  };

  const deactivateAccount = () => {
    const data = {
      user_account_id: authState[0].user_account_id,
    };
    api({ path: '/admin/setting/deactiveAccount', data: data })
      .then(res => {
        if (res.data.hasError) {
          alerts[0][1]({
            ...alerts[0],
            state: true,
            message: res.data.message,
          });
        }
        alerts[1]({
          error: false,
          state: true,
          message: res.data.message,
        });
        logOut();
      })
      .catch(err => console.log(err));
  };

  return (
    <Container>
      <Paper className={classes.deactivateAccContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ArrowBackIcon />
              <Typography variant="subtitle1" style={{ marginLeft: 10 }}>
                Deactivate Account
              </Typography>
            </div>
            <Typography
              variant="body1"
              style={{ marginLeft: 35, marginTop: 5 }}>
              Find out how you can deactivate your account
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar>R</Avatar>
              <div style={{ marginLeft: 15 }}>
                <Typography variant="subtitle1">Rohit Sharma</Typography>
                <Typography variant="body1" className={classes.textLabel}>
                  rohitsharma@gmail.com
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              This will deactivate your account
            </Typography>
            <Typography variant="body1" className={classes.textLabel}>
              You're about to start the process of deactivating your edorer
              account{' '}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              What else you should know
            </Typography>
            <Typography variant="body1" className={classes.textLabel}>
              You can restore your account if it was accidentally or wrongfully
              deactivated for up to 30 days after deactivation
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ textAlign: 'center' }}
            className={classes.textLabel}>
            <Button
              color="primary"
              style={{ textTransfrom: 'uppearcase', fontWeight: 600 }}
              onClick={() => deactivateAccount()}>
              Deactivate This Account
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default DeactivateAccount;
