import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/PersonRounded';
import WorkIcon from '@material-ui/icons/WorkRounded';
import SmileIcon from '@material-ui/icons/EmojiEmotionsRounded';
import ChatIcon from '@material-ui/icons/ChatRounded';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import LocationOnIcon from '@material-ui/icons/LocationOnOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDownOutlined';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Notifications from './Notifications';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import ExpandMoreOutlined from '@material-ui/icons/ExpandMoreOutlined';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    leftContainer: {
      width: '85%',
    },
    dashboardContainer: {
      display: 'flex',
      justifyContent: 'space-between',

      minHeight: 80,
    },
    dashBoardBoxRoot: {
      border: '1px solid #00000066',
      width: '23%',
      padding: '10px 5px',
      borderRadius: 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
    },
    tableContainer: {
      padding: '15px 10px',
      marginTop: 20,
      border: '1px solid #0000004D',
      borderRadius: 4,
    },
    titleRow: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 10,
    },
    requirementTitleCell: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '50%',
    },
    tablecellIconandText: {
      display: 'flex',
      alignItems: 'center',
    },
    tablecell: {
      paddingLeft: 5,
      paddingRight: 5,
    },
    container: {
      marginTop: 25,
    },
    rightContainer: {
      padding: 10,
      width: '30%',
    },
    icon: {
      color: '#6f6f6f',
      padding: '0px 5px 0px 15px',
    },
    expandViewContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: '#f7f7f7',
      marginTop: 10,
    },
    expandLeftContainer: {
      width: '20%',
      margin: 15,
      marginLeft: 0,
    },
    expandRightContainer: {
      width: '100%',
    },
    expandViewTextContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 10,
    },
    expandContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    divider: {
      borderLeft: '1px solid #0000004D',
    },
    expandComment: {
      padding: '10px 0',
      marginLeft: 15,
    },
    dividerBottom: {
      borderBottom: '1px solid #00000033',
    },
    filterContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    filterIcon: {
      color: '#6f6f6f',
      margin: '5px 15px ',
    },
    btn: {
      marginLeft: 10,
      color: theme.palette.text.secondary,
      fontWeight: 'bold',
      fontSize: 12,
    },
    marginClass: {
      marginLeft: 5,
      marginRight: 5,
    },
  })
);

function createData(
  id,
  eventTime,
  title,
  noOfPeopleApplied,
  date,
  requirementBy,
  location,
  applicants
) {
  return {
    id,
    eventTime,
    title,
    noOfPeopleApplied,
    date,
    requirementBy,
    location,
    applicants,
  };
}

const rows = [
  createData(
    'QHS-596',
    50,
    'Head Sales and Marketing_Evercare_Dhaka',
    240,
    '01-23-2021',
    'Rohit Sharma',
    'Los Angeles, USA',
    4
  ),
  createData(
    'QHS-596',
    50,
    'Head Sales and Marketing_Evercare_Dhaka',
    240,
    '01-23-2021',
    'Rohit Sharma',
    'Los Angeles, USA',
    4
  ),
  createData(
    'QHS-596',
    50,
    'Head Sales and Marketing_Evercare_Dhaka',
    240,
    '01-23-2021',
    'Rohit Sharma',
    'Los Angeles, USA',
    4
  ),
  createData(
    'QHS-596',
    50,
    'Head Sales and Marketing_Evercare_Dhaka',
    240,
    '01-23-2021',
    'Rohit Sharma',
    'Los Angeles, USA',
    4
  ),
  createData(
    'QHS-596',
    50,
    'Head Sales and Marketing_Evercare_Dhaka',
    240,
    '01-23-2021',
    'Rohit Sharma',
    'Los Angeles, USA',
    4
  ),
  createData(
    'QHS-596',
    50,
    'Head Sales and Marketing_Evercare_Dhaka',
    240,
    '01-23-2021',
    'Rohit Sharma',
    'Los Angeles, USA',
    4
  ),
];

const DashBoardBox = ({ title, count, icon }) => {
  const classes = useStyles();

  const dashboardBoxData = item => {
    switch (item) {
      case 'openRequirement':
        return (
          <Avatar style={{ backgroundColor: '#edf2ff' }}>
            <WorkIcon style={{ color: '#4a7bf8' }} />
          </Avatar>
        );

      case 'newApplications':
        return (
          <Avatar style={{ backgroundColor: '#fdefeb' }}>
            <PersonIcon style={{ color: '#e46036' }} />
          </Avatar>
        );

      case 'shortListApplication':
        return (
          <Avatar style={{ backgroundColor: '#fef7e6' }}>
            <SmileIcon style={{ color: '#f2ae04' }} />
          </Avatar>
        );

      case 'applicationInInterview':
        return (
          <Avatar style={{ backgroundColor: '#e7f8f7' }}>
            <ChatIcon style={{ color: '#0eb9a9' }} />
          </Avatar>
        );

      default:
        return;
    }
  };

  return (
    <Paper variant="outlined" className={classes.dashBoardBoxRoot}>
      <div style={{ paddingLeft: 3 }}>{dashboardBoxData(icon)}</div>
      <div style={{ paddingLeft: 5 }}>
        <Typography variant="subtitle1">{count}</Typography>
        <Typography
          variant="body1"
          style={{ opacity: 0.7, fontWeight: 'bold' }}>
          {title}
        </Typography>
      </div>
    </Paper>
  );
};

const ActiveRequirementsList = () => {
  const classes = useStyles();
  const [filterOpen, setFilterOpen] = useState(false);

  const Row = ({ row }) => {
    const [open, setOpen] = useState(false);
    return (
      <>
        <TableRow hover align="left">
          <TableCell width="35%" className={classes.tablecell}>
            <span className={classes.requirementTitleCell}>
              <Typography variant="body1">#{row?.id}</Typography>
              <FiberManualRecordIcon
                className={classes.icon}
                style={{ fontSize: 14, paddingRight: 0 }}
              />
              <Typography variant="body1">{row?.eventTime} MIN AGO</Typography>
            </span>
            <Typography variant="subtitle1">{row?.title}</Typography>
            <Typography variant="body1">
              {row?.noOfPeopleApplied} Candidates Applied
            </Typography>
          </TableCell>
          <TableCell width="13%" className={classes.tablecell}>
            <span className={classes.tablecellIconandText}>
              <CalendarTodayIcon className={classes.icon} />

              <Typography variant="body1">{row?.date}</Typography>
            </span>
          </TableCell>
          <TableCell width="17%" className={classes.tablecell}>
            <span className={classes.tablecellIconandText}>
              <PersonOutlineIcon className={classes.icon} />{' '}
              <Typography variant="body1">by {row?.requirementBy}</Typography>
            </span>
          </TableCell>
          <TableCell width="16%" className={classes.tablecell}>
            <span className={classes.tablecellIconandText}>
              <LocationOnIcon className={classes.icon} />
              <Typography variant="body1">{row?.location}</Typography>
            </span>
          </TableCell>
          <TableCell width="10%" className={classes.tablecell}>
            <span className={classes.tablecellIconandText}>
              <Typography
                variant="body1"
                style={{ fontWeight: 'bold', opacity: 0.9 }}>
                {row?.applicants} Applicants
              </Typography>
            </span>
          </TableCell>
          <TableCell width="1%" className={classes.tablecell}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell
            scope="row"
            colSpan={6}
            style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <div className={classes.expandContainer}>
                <div className={classes.expandLeftContainer}>
                  <Typography variant="subtitle1">Stages</Typography>

                  <div className={classes.expandViewTextContainer}>
                    <Typography variant="subtitle1" color="primary">
                      Prospect
                    </Typography>
                    <Typography variant="subtitle1" color="primary">
                      220
                    </Typography>
                  </div>
                  <div className={classes.expandViewTextContainer}>
                    <Typography variant="subtitle1" color="primary">
                      Rejected
                    </Typography>
                    <Typography variant="subtitle1" color="primary">
                      20
                    </Typography>
                  </div>
                </div>
                <div className={classes.divider} />
                <div className={classes.expandRightContainer}>
                  <Typography variant="subtitle1" style={{ margin: 15 }}>
                    Activities
                  </Typography>
                  <div className={classes.dividerBottom} />
                  <div className={classes.expandComment}>
                    <Typography variant="body1">52 min ago</Typography>
                    <Typography variant="body1" style={{ display: 'flex' }}>
                      Comment Added to profile of{' '}
                      <Typography
                        variant="body1"
                        className={classes.marginClass}
                        color="primary">
                        {' '}
                        Arvind Pahwa
                      </Typography>{' '}
                      by Upasona@qwazent.com
                    </Typography>
                  </div>
                  <div className={classes.dividerBottom} />
                  <div className={classes.expandComment}>
                    <Typography variant="body1">52 min ago</Typography>
                    <Typography variant="body1" style={{ display: 'flex' }}>
                      <Typography
                        variant="body1"
                        className={classes.marginClass}
                        color="primary">
                        {' '}
                        Arvind Pahwa
                      </Typography>{' '}
                      has been{' '}
                      <Typography
                        variant="body1"
                        className={classes.marginClass}
                        style={{
                          color: '#000',
                          fontWeight: 'bold',
                          opacity: 0.8,
                        }}>
                        Rejected
                      </Typography>{' '}
                      at{' '}
                      <Typography
                        variant="body1"
                        className={classes.marginClass}
                        style={{
                          color: '#000',
                          fontWeight: 'bold',
                          opacity: 0.8,
                        }}>
                        Prospect
                      </Typography>{' '}
                      by upsona@qwazent.com
                    </Typography>
                    <span style={{ display: 'flex', marginTop: 10 }}>
                      <Button
                        className={classes.btn}
                        style={{
                          backgroundColor: '#299c14',
                          fontWeight: 'bold',
                        }}>
                        <FiberManualRecordIcon
                          style={{ fontSize: 12, marginRight: 3 }}
                        />
                        Prospect
                      </Button>
                      <Button
                        className={classes.btn}
                        style={{
                          backgroundColor: '#9c1f14',
                          fontWeight: 'bold',
                        }}>
                        <FiberManualRecordIcon
                          style={{ fontSize: 12, marginRight: 3 }}
                        />
                        Rejected
                      </Button>
                    </span>
                  </div>
                  <div className={classes.dividerBottom} />
                  <div className={classes.expandComment}>
                    <Typography variant="body1">52 min ago</Typography>
                    <Typography variant="body1" style={{ display: 'flex' }}>
                      <Typography
                        variant="body1"
                        className={classes.marginClass}
                        color="primary">
                        {' '}
                        Arvind Pahwa
                      </Typography>
                      has been{' '}
                      <Typography
                        variant="body1"
                        className={classes.marginClass}
                        style={{
                          color: '#000',
                          fontWeight: 'bold',
                          opacity: 0.8,
                        }}>
                        Rejected
                      </Typography>{' '}
                      at{' '}
                      <Typography
                        variant="body1"
                        className={classes.marginClass}
                        style={{
                          color: '#000',
                          fontWeight: 'bold',
                          opacity: 0.8,
                        }}>
                        Prospect
                      </Typography>{' '}
                      by upsona@qwazent.com
                    </Typography>
                    <span style={{ display: 'flex', marginTop: 10 }}>
                      <Button
                        className={classes.btn}
                        style={{ backgroundColor: '#299c14' }}>
                        <FiberManualRecordIcon
                          style={{ fontSize: 12, marginRight: 3 }}
                        />{' '}
                        Prospect
                      </Button>
                      <Button
                        className={classes.btn}
                        style={{ backgroundColor: '#9c1f14' }}>
                        <FiberManualRecordIcon
                          style={{ fontSize: 12, marginRight: 3 }}
                        />{' '}
                        Rejected
                      </Button>
                    </span>
                  </div>
                </div>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <Container maxWidth="xl" disableGutters>
      <Grid container>
        <Grid item xs={12} md={9}>
          <div className={classes.dashboardContainer}>
            <DashBoardBox
              title="Open Requirements"
              count="06"
              icon="openRequirement"
            />
            <DashBoardBox
              title="New Applications"
              count="45"
              icon="newApplications"
            />
            <DashBoardBox
              title="Shortlisted Applications"
              count="35"
              icon="shortListApplication"
            />
            <DashBoardBox
              title="Application In Interview"
              count="85"
              icon="applicationInInterview"
            />
          </div>
          <Paper variant="outlined" className={classes.tableContainer}>
            <div className={classes.titleRow}>
              <div>
                <Typography variant="subtitle1">Active Requirements</Typography>
                <Typography variant="body1">by me</Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Search by title, skills, location"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <SearchIcon style={{ color: '#707070' }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  style={{ minWidth: 300, alignSelf: 'center' }}
                />
                <IconButton onClick={() => setFilterOpen(!filterOpen)}>
                  <FilterListIcon className={classes.filterIcon} />
                </IconButton>
              </div>
            </div>
            {filterOpen && (
              <div className={classes.filterContainer}>
                <TextField
                  variant="outlined"
                  placeholder="Search Skill Keyword"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: '#707070' }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select
                    fullWidth={true}
                    variant="outlined"
                    placeholder="Experiance"
                    defaultValue="Placeholder"
                    IconComponent={ExpandMoreOutlined}>
                    <MenuItem value="Placeholder" disabled>
                      Experiance
                    </MenuItem>
                    <MenuItem value={1}>1+ years</MenuItem>
                    <MenuItem value={2}>2+ years</MenuItem>
                    <MenuItem value={3}>3+ years</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <Select
                    defaultValue="Placeholder"
                    variant="outlined"
                    IconComponent={ExpandMoreOutlined}>
                    <MenuItem value="Placeholder">Location</MenuItem>
                    <MenuItem value={10}>Pune</MenuItem>
                    <MenuItem value={20}>Mumbai</MenuItem>
                    <MenuItem value={30}>Delhi</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <Select
                    defaultValue="Placeholder"
                    variant="outlined"
                    IconComponent={ExpandMoreOutlined}>
                    <MenuItem value="Placeholder">Education</MenuItem>
                    <MenuItem value={10}>Engineering</MenuItem>
                    <MenuItem value={20}>Msc</MenuItem>
                    <MenuItem value={30}>Bsc</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                  <Select
                    defaultValue="Placeholder"
                    variant="outlined"
                    IconComponent={ExpandMoreOutlined}>
                    <MenuItem value="Placeholder">Job Type</MenuItem>
                    <MenuItem value={1}>Part Time</MenuItem>
                    <MenuItem value={2}>Full Time</MenuItem>
                  </Select>
                </FormControl>
              </div>
            )}
            <div className={classes.table}>
              <TableContainer className={classes.container}>
                <Table>
                  <TableBody>
                    {rows.map(row => (
                      <Row row={row} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3} className={classes.rightContainer}>
          <Notifications />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ActiveRequirementsList;
