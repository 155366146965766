import React from 'react';
import {
  Button,
  DialogActions,
  Typography,
  IconButton,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  createStyles,
  Divider,
  Paper,
  Avatar,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';
import WorkOutLineIcon from '@material-ui/icons/WorkOutline';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme =>
  createStyles({
    dialogTitleContainer: {
      backgroundColor: theme.palette.ternary.main,
      padding: '0px 15px',
    },
    dialogTitleContainerDiv: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },

    dialogActionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 10,
    },
    dialogActionButton: {
      margin: '5px 13px',
      color: '#FFF',
      padding: '10px 25px',
    },
    dialogActionBlueButton: {
      margin: '5px 13px',
      color: '#FFF',
      padding: '10px 25px',
      backgroundColor: theme.palette.ternary.main,
    },

    divider: {
      borderBottom: '1px solid #FFFFFF66',
    },
    dialogTitleAction: {
      backgroundColor: theme.palette.ternary.main,
    },
    actionContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: 10,
      float: 'right',
    },
    action: {
      display: 'flex',
      alignItems: 'center',
      padding: '0px 20px',
    },
    verticalDivider: {
      borderLeft: '1px solid #FFFFFF60',
      margin: '3px 5px',
      minHeight: 15,
      height: '100%',
    },
    icon: {
      color: '#FFFFFFCC',
      padding: '0px 5px',
    },
    locationContainer: {
      display: 'flex',
      marginTop: 5,
      marginBottom: 15,
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'space-between',
      padding: '10px 20px',
    },
    subtitleRow: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      marginTop: 5,
      padding: '0px 20px',
    },
    topContainer: {
      backgroundColor: '#f3f3f3',
    },
    dialogContent: {
      padding: '0px 0px 8px 0px',
    },
    jobHighlight: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      marginTop: 10,
    },
    jobHightlightTitle: {
      color: '#bfbfbf',
    },
    container: {
      padding: '10px 20px',
    },
    infoContainer: {
      marginTop: 15,
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 10,
      marginBottom: 10,
    },
    rowTitle: {
      width: '40%',
    },
    keywords: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: 10,
    },
    keyword: {
      padding: 10,
      border: '1px solid grey',
      marginRight: 10,
    },
    subtitle: {
      marginTop: 10,
      marginBottom: 5,
    },
    contactDetails: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  })
);

const JobPreview = ({ open, handleEditPdf }) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open[0]}
      maxWidth="md"
      TransitionComponent={Transition}
      fullWidth>
      <DialogTitle className={classes.dialogTitleContainer}>
        <div className={classes.dialogTitleContainerDiv}>
          <div>
            <Typography variant="subtitle1" style={{ color: '#FFF' }}>
              Job Preview
            </Typography>
          </div>
          <IconButton onClick={() => open[1](false)}>
            <CloseIcon style={{ color: '#FFF' }} fontSize="medium" />
          </IconButton>
        </div>
      </DialogTitle>
      <div className={classes.dialogTitleAction}>
        <Divider variant="fullWidth" classes={{ root: classes.divider }} />
        <div className={classes.actionContainer}>
          <span className={classes.action}>
            <EditOutlinedIcon color="textSecondary" className={classes.icon} />
            <Typography variant="body1" color="textSecondary">
              Edit
            </Typography>
          </span>
          <div className={classes.verticalDivider} />
          <span className={classes.action}>
            <PlayForWorkIcon className={classes.icon} />
            <Typography variant="body1" color="textSecondary">
              PDF EXPORT
            </Typography>
          </span>
          <div className={classes.verticalDivider} />
          <span className={classes.action}>
            <WorkOutLineIcon color="primary" style={{ padding: '0px 5px' }} />
            <Typography variant="body1" color="primary">
              POST JOB
            </Typography>
          </span>
        </div>
      </div>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.topContainer}>
          <div className={classes.titleContainer}>
            <div>
              <Typography variant="h1" style={{ fontWeight: 'normal' }}>
                UI & UX Designer, Product Designer
              </Typography>
            </div>
            <div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="contained" color="primary" size="large">
                  Apply Now
                </Button>
              </div>
              <Typography
                variant="subtitle1"
                color="primary"
                style={{ marginTop: 10 }}>
                SEND ME JOB LIKE THIS
              </Typography>
            </div>
          </div>
          <div className={classes.subtitleRow}>
            <div className={classes.locationContainer}>
              <Typography variant="body1">Google.inc</Typography>
              <div
                className={classes.verticalDivider}
                style={{ borderColor: '#00000060' }}
              />
              <Typography variant="body1">Mumbai India</Typography>
            </div>
            <div style={{ display: 'flex' }}>
              <Typography variant="body1">Posted 1 week ago - </Typography>
              <Typography
                variant="body1"
                style={{ fontWeight: 'bold', paddingLeft: 2 }}>
                12 Applicants
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.container}>
          <div className={classes.jobHighlight}>
            <div>
              <span>
                <Typography
                  variant="subtitle1"
                  className={classes.jobHightlightTitle}>
                  EXPERIANCE
                </Typography>
              </span>
              <span>
                <Typography variant="subtitle1">3-8 years</Typography>
              </span>
            </div>
            <div>
              <span>
                <Typography
                  variant="subtitle1"
                  className={classes.jobHightlightTitle}>
                  SALARY
                </Typography>
              </span>
              <span>
                <Typography variant="subtitle1">₹ 3.25 - 4.75 L.P.A</Typography>
              </span>
            </div>
            <div>
              <span>
                <Typography
                  variant="subtitle1"
                  className={classes.jobHightlightTitle}>
                  EMPLOYMENT
                </Typography>
              </span>
              <span>
                <Typography variant="subtitle1">Full Time</Typography>
              </span>
            </div>
            <div>
              <span>
                <Typography
                  variant="subtitle1"
                  className={classes.jobHightlightTitle}>
                  OPENINGS
                </Typography>
              </span>
              <span>
                <Typography variant="subtitle1">05</Typography>
              </span>
            </div>
          </div>
          <div className={classes.jobHighlight} style={{ width: '47%' }}>
            <div>
              {' '}
              <span>
                <Typography
                  variant="subtitle1"
                  className={classes.jobHightlightTitle}>
                  OPENINGS
                </Typography>
              </span>
              <span>
                <Typography variant="subtitle1">05</Typography>
              </span>
            </div>
            <div>
              {' '}
              <span>
                <Typography
                  variant="subtitle1"
                  className={classes.jobHightlightTitle}>
                  OPENINGS
                </Typography>
              </span>
              <span>
                <Typography variant="subtitle1">05</Typography>
              </span>
            </div>
          </div>
          <div className={classes.infoContainer}>
            <Typography variant="subtitle1" className={classes.subtitle}>
              Job Description
            </Typography>
            <Typography variant="body1" style={{ textAlign: 'justify' }}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima,
              deserunt! Similique quasi aspernatur libero! Illum necessitatibus
              voluptates modi esse non laudantium iste, voluptatibus veniam aut
              ullam ad enim sit consequatur? Lorem ipsum dolor sit amet,
              consectetur adipisicing elit. Eum, quo! Lorem ipsum dolor sit
              amet, consectetur adipisicing elit. Cupiditate qui eos quos hic
              quo voluptatum blanditiis, dolorem amet culpa commodi nulla sint
              dolore perspiciatis totam numquam fuga magnam excepturi corporis?
            </Typography>
          </div>
          <div className={classes.infoContainer}>
            <Typography variant="subtitle1" className={classes.subtitle}>
              Desired Candidate Profile
            </Typography>
            <Typography variant="body1" style={{ textAlign: 'justify' }}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima,
              deserunt! Similique quasi aspernatur libero! Illum necessitatibus
              voluptates modi esse non laudantium iste, voluptatibus veniam aut
              ullam ad enim sit consequatur? Lorem ipsum dolor sit amet,
            </Typography>
          </div>
          <div className={classes.infoContainer}>
            <Typography variant="subtitle1" className={classes.subtitle}>
              Key Performance Indicators
            </Typography>
            <Typography variant="body1" style={{ textAlign: 'justify' }}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima,
              deserunt! Similique quasi aspernatur libero! Illum necessitatibus
              voluptates modi esse non laudantium iste, voluptatibus veniam aut
              ullam ad enim sit consequatur? Lorem ipsum dolor sit amet,
              consectetur adipisicing elit. Eum, quo! Lorem ipsum dolor sit
              amet, consectetur adipisicing elit. Cupiditate qui eos quos hic
              quo voluptatum blanditiis, dolorem amet culpa commodi nulla sint
              dolore perspiciatis totam numquam fuga magnam excepturi corporis?
            </Typography>
          </div>
          <div className={classes.row}>
            <div className={classes.rowTitle}>industry Type</div>
            <div>IT-Software / Software Services</div>
          </div>
          <div className={classes.row}>
            <div className={classes.rowTitle}>Functional Area</div>
            <div>Design, creative, User Experiance</div>
          </div>
          <Typography variant="subtitle1" className={classes.subtitle}>
            Education
          </Typography>
          <div className={classes.row}>
            <div className={classes.rowTitle}>UG</div>
            <div>Graduation Not Required</div>
          </div>
          <div className={classes.row}>
            <div className={classes.rowTitle}>PG</div>
            <div>Graduation Not Required</div>
          </div>
          <div className={classes.row}>
            <div className={classes.rowTitle}>Doctorate</div>
            <div>Graduation Not Required</div>
          </div>

          <Typography variant="subtitle1" className={classes.subtitle}>
            Keywords
          </Typography>
          <div className={classes.keywords}>
            <Paper elevation={0} className={classes.keyword}>
              Figma
            </Paper>
            <Paper elevation={0} className={classes.keyword}>
              User Interface designing
            </Paper>
            <Paper elevation={0} className={classes.keyword}>
              UX
            </Paper>
            <Paper elevation={0} className={classes.keyword}>
              Photoshop
            </Paper>
            <Paper elevation={0} className={classes.keyword}>
              User Experiance Design
            </Paper>
          </div>
          <Typography variant="subtitle1" className={classes.subtitle}>
            Question
          </Typography>
          <Typography variant="body1" style={{ marginTop: 10 }}>
            What is best online solutions for marketing business?
          </Typography>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '50%' }}>
              <Typography variant="subtitle1" className={classes.subtitle}>
                Reference Code
              </Typography>
              <div style={{ display: 'flex' }}>
                <Paper elevation={0} className={classes.keyword}>
                  #1245564
                </Paper>
                <Paper elevation={0} className={classes.keyword}>
                  #789456
                </Paper>
                <Paper elevation={0} className={classes.keyword}>
                  #557459
                </Paper>
              </div>
            </div>
            <div>
              <Typography variant="subtitle1" className={classes.subtitle}>
                Hiring For
              </Typography>
              <div style={{ display: 'flex' }}>
                <Paper elevation={0} className={classes.keyword}>
                  Mr. Amit Sharma
                </Paper>
              </div>
            </div>
          </div>
          <Typography variant="subtitle1" className={classes.subtitle}>
            Contact Person
          </Typography>
          <div className={classes.contactDetails}>
            <div style={{ display: 'flex' }}>
              <Avatar />
              <div style={{ marginLeft: 10 }}>
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                  Mr. Ravi Shastri
                </Typography>
                <Typography variant="body1" style={{ marginTop: 5 }}>
                  +91 7014568792
                </Typography>
              </div>
            </div>
            <div>
              <Button
                color="primary"
                fontSize="large"
                style={{ fontWeight: 'bold' }}>
                SEND MESSAGE{' '}
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActionContainer}>
        <Button
          variant="contained"
          className={classes.dialogActionBlueButton}
          size="large"
          onClick={() => open[1](false)}>
          Export PDF
        </Button>
        <div>
          <Button
            variant="contained"
            className={classes.dialogActionBlueButton}
            size="large"
            onClick={() => handleEditPdf()}>
            Edit PDF
          </Button>
          <Button
            variant="contained"
            className={classes.dialogActionButton}
            color="primary"
            size="large">
            POST JOB
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default JobPreview;
