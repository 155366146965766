import React from 'react';
import {
  Grid,
  Container,
  makeStyles,
  Typography,
  Button,
  Paper,
  ButtonBase,
} from '@material-ui/core';

import { useForm, FormProvider } from 'react-hook-form';

import { StepOneSidebar, StepOneContent } from './SubPageWidgets/StepOne';
import Logo from '../../assets/logo/logo.png';

const useStyles = makeStyles(theme => ({
  leftContainer: {
    backgroundColor: theme.palette.secondary.main,
    paddingLeft: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  leftContent: {
    marginTop: 30,
  },

  rightContainer: {
    marginTop: 25,
    marginLeft: 25,
  },
  navigationActionContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    marginTop: 15,
  },
  navigationBottomContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    margin: '0px 0px 20px 55px',
  },
  sidebarBottomContent: {
    margin: '50px 15px',
    textAlign: 'justify',
  },
  saveBtn: {
    marginRight: 10,
    padding: 10,
  },
  saveBtnTxt: {
    opacity: 0.5,
    fontWeight: 600,
  },
  exportButton: {
    fontWeight: 600,
    marginRight: 25,
  },
  finishBtn: {
    fontWeight: 600,
    padding: '7px 30px',
  },
  inlineContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const ReferenceCheckForm = () => {
  const classes = useStyles();
  const methods = useForm({});

  const Navigation = () => {
    return (
      <Grid container>
        <Grid item xs={7}></Grid>
        <Grid item xs={5}>
          <div className={classes.navigationActionContainer}>
            <Button className={classes.exportButton}>EXPORT PDF</Button>
            <Paper className={classes.saveBtn}>
              <ButtonBase>
                <Typography variant="body1" className={classes.saveBtnTxt}>
                  SAVE & ADD MORE
                </Typography>
              </ButtonBase>
            </Paper>
            <Button
              variant="contained"
              className={classes.finishBtn}
              color="primary">
              FINISH
            </Button>
          </div>
        </Grid>
      </Grid>
    );
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Grid container direction="row" justify="space-between">
        <Grid item xs={3} className={classes.leftContainer}>
          <div>
            <img src={Logo} alt="logo" />
            <div className={classes.leftContent}>
              <StepOneSidebar />
            </div>
          </div>
          <div className={classes.sidebarBottomContent}>
            <Typography
              variant="h1"
              color="textSecondary"
              style={{ fontWeight: 'normal', color: '#FFF' }}>
              Lorem ipsum has been the industry's standard.
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat
              necessitatibus laudantium aspernatur! Saepe, labore nam!
            </Typography>
          </div>
        </Grid>
        <Grid item xs={9}>
          <div>
            <FormProvider {...methods}>
              <form>
                <Grid container>
                  <Grid item xs={12}>
                    <div className={classes.navigation}>
                      <Navigation />
                      <div className={classes.rightContainer}>
                        <StepOneContent />
                      </div>
                    </div>
                  </Grid>
                  <Grid xs={7}>
                    <div className={classes.navigationBottomContainer}>
                      <Button className={classes.exportButton}>
                        Export PDF
                      </Button>
                      <div className={classes.inlineContainer}>
                        <Paper
                          style={{ marginRight: 15 }}
                          className={classes.saveBtn}>
                          <ButtonBase>
                            <Typography
                              variant="body1"
                              className={classes.saveBtnTxt}>
                              SAVE & ADD MORE
                            </Typography>
                          </ButtonBase>
                        </Paper>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.finishBtn}>
                          Finish
                        </Button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ReferenceCheckForm;
