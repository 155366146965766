import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';

import People from '../../assets/images/right-section-people-signin.png';
import api from '../../api/api';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      height: '100vh',
      maxWidth: '100vw',
    },
    peopleImg: {
      width: '70%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    rightContainer: {
      backgroundColor: theme.palette.secondary.main,
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    h1Text: {
      paddingTop: 40,
    },
    subtitle1Text: {
      padding: 40,
      paddingTop: 20,
    },
    leftContainer: {
      width: '100%',
      height: '100vh',
    },
    formField: {
      marginTop: 25,
    },
    formContainer: {
      width: '80%',
    },
    footer: {
      position: 'absolute',
      bottom: 30,
    },
    subtitle1: {
      opacity: 0.4,
    },
    helperText: {
      color: 'red',
      marginLeft: 5,
    },
    alertErrorRoot: {
      marginTop: 20,
      marginBottom: 20,
    },
    link: {
      color: theme.palette.text.primary,
      textDecoration: 'none',
    },
    agreeError: {
      position: 'relative',
      top: '-18px',
      left: 7,
    },
  })
);

const SignUp = () => {
  const classes = useStyles();
  const history = useHistory();
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();
  const [visiblePassword, setVisibilePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = formfields => {
    setErrorMessage('');
    const { email, password, confirmPassword } = formfields;
    if (password !== confirmPassword) {
      setErrorMessage('Password and Confirm Password should be same');
      return;
    }
    setLoading(true);
    const credentials = { email_id: email, password: password };
    api({ path: '/candidate/signupCandidateStep1st', data: credentials })
      .then(res => {
        console.log('response', res);
        if (res.data.hasError) {
          setErrorMessage(res.data.message);
          setLoading(false);
          return;
        }
        setLoading(false);
        sessionStorage.setItem('candidateId', res?.data?.data?.candidateId);
        sessionStorage.setItem('candidateEmail', email);
        history.push('/onBoarding');
      })
      .catch(err => {
        console.log('errr', err);

        setErrorMessage('Something went wrong');
        setLoading(false);
      });
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} md={5}>
          <Grid
            item
            md={12}
            container
            xs
            direction="column"
            justify="space-around"
            alignItems="center"
            className={classes.leftContainer}>
            <div className={classes.formContainer}>
              <div>
                <Typography variant="h1">Let's Get Started</Typography>
                <Typography variant="subtitle1" className={classes.subtitle1}>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                </Typography>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                  fullWidth
                  variant="outlined"
                  error={errors['email'] || false}
                  name="email"
                  placeholder="Email Address"
                  className={classes.formField}
                  type="email"
                  {...register('email', {
                    required: true,
                    pattern: new RegExp('^[A-Za-z0-9+_.-]+@(.+)$'),
                  })}
                />
                {errors && errors['email']?.type === 'required' ? (
                  <FormHelperText>
                    <Typography variant="body1" className={classes.helperText}>
                      Please enter your email address
                    </Typography>
                  </FormHelperText>
                ) : (
                  errors['email']?.type === 'pattern' && (
                    <FormHelperText>
                      <Typography
                        variant="body1"
                        className={classes.helperText}>
                        Please enter valid email
                      </Typography>
                    </FormHelperText>
                  )
                )}

                <TextField
                  fullWidth
                  variant="outlined"
                  name="password"
                  type={visiblePassword ? 'text' : 'password'}
                  className={classes.formField}
                  error={errors['password'] || false}
                  placeholder="Password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          onClick={() => setVisibilePassword(!visiblePassword)}>
                          {visiblePassword ? (
                            <VisibilityIcon style={{ color: '#707070' }} />
                          ) : (
                            <VisibilityOffIcon style={{ color: '#707070' }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...register('password', { required: true })}
                />
                {errors && errors['password']?.type === 'required' && (
                  <FormHelperText>
                    <Typography className={classes.helperText} variant="body1">
                      Please enter your password
                    </Typography>
                  </FormHelperText>
                )}
                <TextField
                  fullWidth
                  variant="outlined"
                  className={classes.formField}
                  name="confirmPassword"
                  error={errors['confirmPassword'] || false}
                  type={visiblePassword ? 'text' : 'password'}
                  placeholder="Confirm Password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          onClick={() => setVisibilePassword(!visiblePassword)}>
                          {visiblePassword ? (
                            <VisibilityIcon style={{ color: '#707070' }} />
                          ) : (
                            <VisibilityOffIcon style={{ color: '#707070' }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...register('confirmPassword', { required: true })}
                />
                {errors && errors['confirmPassword']?.type === 'required' && (
                  <FormHelperText>
                    <Typography className={classes.helperText} variant="body1">
                      Please enter your confirm password
                    </Typography>
                  </FormHelperText>
                )}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '10px 0px',
                  }}>
                  <input
                    type="checkbox"
                    {...register('iAgree', { required: true })}
                    name="iAgree"
                    color="primary"
                    size="small"
                  />
                  <Typography style={{ marginRight: 4 }}>
                    I agree to all the
                  </Typography>
                  <Typography color="primary">
                    terms, & privacy policy
                  </Typography>
                </div>
                {errors && errors['iAgree']?.type === 'required' && (
                  <FormHelperText className={classes.agreeError}>
                    <Typography className={classes.helperText} variant="body1">
                      Please agree to terms & privacy policy
                    </Typography>
                  </FormHelperText>
                )}

                <Alert
                  variant="filled"
                  icon={<ReportProblemOutlinedIcon />}
                  severity="error"
                  classes={{ root: classes.alertErrorRoot }}
                  style={{ display: errorMessage ? 'flex' : 'none' }}>
                  {errorMessage}
                </Alert>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  //   className={classes.formField}
                  style={{ padding: 10 }}
                  type="submit">
                  {loading ? (
                    <CircularProgress size={25} color="textSecondary" />
                  ) : (
                    'Create Account'
                  )}
                </Button>
              </form>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '15px 0px',
                }}>
                <Typography style={{ marginRight: 4 }}>
                  Already have an account?
                </Typography>
                <Typography
                  color="primary"
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push('/signin')}>
                  Log In
                </Typography>
              </div>
              <div className={classes.footer}>
                <Typography variant="subtitle2">
                  © 2021 RMS. All right Reserved{' '}
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} md={7}>
          <div className={classes.rightContainer}>
            <img src={People} alt="people" className={classes.peopleImg} />
            <div>
              <Typography
                variant="h1"
                align="center"
                color="textSecondary"
                className={classes.h1Text}>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Explicabo, dolores.
              </Typography>
              <Typography
                variant="subtitle1"
                align="center"
                className={classes.subtitle1Text}
                color="textSecondary">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia
                nisi corporis sed? Quos ut ea officia rem, corporis natus quod
                unde at? Nulla, ullam molestias.
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SignUp;
