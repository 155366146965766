import React, { useContext, useState, useEffect } from 'react';
import {
  IconButton,
  Typography,
  TextField,
  Button,
  makeStyles,
  Container,
  Grid,
  MenuItem,
  Paper,
  FormHelperText,
} from '@material-ui/core';
import BackupIcon from '@material-ui/icons/BackupOutlined';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { useForm, Controller } from 'react-hook-form';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import SnackBarContext from '../../Context/SnackBarContext';
import api from '../../api/api';
import { url } from '../../utils/common';
import UserContext from '../../Context/UserContext';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 20,
  },
  uploadFileContainer: {
    border: '1px solid #00000060',
    borderRadius: 4,
    minHeight: 230,
    margin: 10,
  },
  buttonContainer: {
    marginTop: 40,
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttons: {
    padding: '12px 20px',
  },
  previewImg: {
    marginLeft: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  helperText: {
    color: 'red',
    marginLeft: 5,
  },
}));

const PersonalInfo = ({ generalDetailsref, addressandLocationref }) => {
  const classes = useStyles();
  const { alerts } = useContext(SnackBarContext);
  const { authState } = useContext(UserContext);
  const [logo, setLogo] = useState(null);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const data = { user_account_id: authState[0].user_account_id?.toString() };
    api({ path: '/admin/getSingleUser', data: data }).then(res => {
      if (!res.data.hasError) {
        reset({
          firstName: res.data?.data[0]?.first_name,
          lastName: res.data?.data[0]?.last_name,
          email: res.data?.data[0]?.email_id,
          phoneNo: res.data?.data[0]?.phone_no,
          alternateNo: res.data?.data[0]?.alternate_no,
          whatsappNo: res.data?.data[0]?.whatsapp_no,
          country: res.data?.data[0].country,
          city: res.data?.data[0].city,
          companyName: res.data?.data[0]?.company_name,
        });
        setLogo(res.data?.data[0]?.profile_photo);
      }
    });
  }, [authState, reset]);

  const handleFileUpload = (e, logoName) => {
    var formData = new FormData();
    formData.append('image', e.target.files[0]);
    api({ path: '/image/upload', data: formData })
      .then(res => {
        if (res.data.hasError) {
          alerts[1]({
            ...alerts[0],

            message: res.data.message,
            state: true,
          });
        }
        alerts[1]({
          error: false,
          message: res.data.message,
          state: true,
        });
        setLogo(res.data.data.imageId);
      })
      .catch(err => console.log('ERR', err));
  };

  const onSubmit = formData => {
    const data = {
      user_account_id: authState[0].user_account_id,
      first_name: formData.firstName,
      last_name: formData.lastName,
      email_id: formData.email,
      profile_photo: logo,
      phone_no: formData.phoneNo,
      alternate_no: formData.alternateNo,
      whatsapp_no: formData.whatsappNo,
      company_name: formData.companyName,
      country: formData.country,
      city: formData.city,
    };
    api({ path: '/admin/updateAdmin', data: data })
      .then(res => {
        if (res.data.hasError) {
          alerts[1]({ error: true, message: res.data.message, state: true });
        }
        alerts[1]({ error: false, message: res.data.message, state: true });
      })
      .catch(err => console.log(err));
  };

  return (
    <Container maxWidth="md">
      <Paper className={classes.container} elevation={3}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                Personal Info
              </Typography>
              <Typography variant="body1">
                Please fill your general information & add your profile photo
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              {logo ? (
                <div className={classes.previewImg}>
                  <img
                    src={`${url}/image/${logo}`}
                    alt="monogramdark logo"
                    height={150}
                    width={200}
                  />
                  <IconButton onClick={() => setLogo(null)}>
                    <CloseIcon className={classes.closeIcon} />
                  </IconButton>
                </div>
              ) : (
                <div className={classes.uploadFileContainer}>
                  <IconButton>
                    <BackupIcon style={{ fontSize: 70 }} />
                  </IconButton>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 'normal' }}
                    align="center">
                    Click here to browse or <br /> drag and drop a file
                  </Typography>
                  <Button
                    style={{ padding: '15px 55px', margin: '10px 5px' }}
                    variant="contained">
                    <label htmlFor="file">
                      <Typography variant="subtitle1">Browse</Typography>
                    </label>
                  </Button>
                  <input
                    type="file"
                    id="file"
                    onChange={e => handleFileUpload(e)}
                    style={{ display: 'none' }}
                  />
                  <Typography style={{ marginBottom: 5 }} variant="body1">
                    File must be an image format. The maximum file-size is 10 MB{' '}
                  </Typography>{' '}
                </div>
              )}
            </Grid>

            <Grid item xs={12} md={6} ref={generalDetailsref}>
              <Controller
                control={control}
                rules={{ required: 'First name is required' }}
                name="firstName"
                render={({ field }) => (
                  <TextField
                    placeholder="First Name"
                    variant="outlined"
                    fullWidth
                    {...field}
                  />
                )}
              />
              {errors.firstName && (
                <FormHelperText>
                  <Typography variant="body1" className={classes.helperText}>
                    {errors.firstName.message}
                  </Typography>
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                rules={{ required: 'Last name is required' }}
                name="lastName"
                render={({ field }) => (
                  <TextField
                    placeholder="Last Name"
                    variant="outlined"
                    fullWidth
                    {...field}
                  />
                )}
              />
              {errors.lastName && (
                <FormHelperText>
                  <Typography variant="body1" className={classes.helperText}>
                    {errors.lastName.message}
                  </Typography>
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                rules={{ required: 'Email is required' }}
                name="email"
                render={({ field }) => (
                  <TextField
                    placeholder="Email Address"
                    variant="outlined"
                    fullWidth
                    {...field}
                  />
                )}
              />
              {errors.email && (
                <FormHelperText>
                  <Typography variant="body1" className={classes.helperText}>
                    {errors.email.message}
                  </Typography>
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                rules={{ required: 'Phone no is required' }}
                name="phoneNo"
                render={({ field }) => (
                  <TextField
                    placeholder="Phone Number"
                    type="number"
                    variant="outlined"
                    fullWidth
                    {...field}
                  />
                )}
              />
              {errors.phoneNo && (
                <FormHelperText>
                  <Typography variant="body1" className={classes.helperText}>
                    {errors.phoneNo.message}
                  </Typography>
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                rules={{ required: 'Alternate no is required' }}
                name="alternateNo"
                render={({ field }) => (
                  <TextField
                    placeholder="Alternate Number"
                    type="number"
                    variant="outlined"
                    fullWidth
                    {...field}
                  />
                )}
              />
              {errors.alternateNo && (
                <FormHelperText>
                  <Typography variant="body1" className={classes.helperText}>
                    {errors.alternateNo.message}
                  </Typography>
                </FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                rules={{ required: 'Whatsapp no is required' }}
                name="whatsappNo"
                render={({ field }) => (
                  <PhoneInput
                    country={'in'}
                    variant="outlined"
                    fullWidth
                    placeholder="Whatsapp Number"
                    inputStyle={{
                      minHeight: 45,
                      minWidth: '100%',
                    }}
                    {...field}
                  />
                )}
              />
              {errors.whatsappNo && (
                <FormHelperText>
                  <Typography variant="body1" className={classes.helperText}>
                    {errors.whatsappNo.message}
                  </Typography>
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                control={control}
                rules={{ required: 'Company Name is required' }}
                name="companyName"
                render={({ field }) => (
                  <TextField
                    placeholder="Company Name"
                    variant="outlined"
                    fullWidth
                    {...field}
                  />
                )}
              />
              {errors.companyName && (
                <FormHelperText>
                  <Typography variant="body1" className={classes.helperText}>
                    {errors.companyName.message}
                  </Typography>
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} ref={addressandLocationref}>
              <Typography variant="h1" align="left" style={{ marginTop: 8 }}>
                Address and Location
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                render={({ field }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    className={classes.rowAdminInput}
                    style={{ marginRight: 5, color: '#000' }}
                    select
                    {...field}>
                    <MenuItem value="Placeholder">Country</MenuItem>
                    <MenuItem value="India">India</MenuItem>
                    <MenuItem value="USA">USA</MenuItem>
                    <MenuItem value="UK">UK</MenuItem>
                  </TextField>
                )}
                control={control}
                rules={{ required: 'Country is required' }}
                name="country"
                defaultValue="Placeholder"
              />
              {errors.country && (
                <FormHelperText>
                  <Typography variant="body1" className={classes.helperText}>
                    {errors.country.message}
                  </Typography>
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                rules={{ required: 'City is required' }}
                name="city"
                defaultValue="Placeholder"
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    className={classes.rowAdminInput}
                    style={{ marginRight: 5 }}
                    select>
                    <MenuItem value="Placeholder">City/District</MenuItem>
                    <MenuItem value="Pune">Pune</MenuItem>
                    <MenuItem value="Mumbai">Mumbai</MenuItem>
                    <MenuItem value="Delhi">Delhi</MenuItem>
                  </TextField>
                )}
              />
              {errors.city && (
                <FormHelperText>
                  <Typography variant="body1" className={classes.helperText}>
                    {errors.city.message}
                  </Typography>
                </FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} className={classes.buttonContainer}>
              <Button variant="contained" className={classes.buttons}>
                Cancel
              </Button>

              <Button
                variant="contained"
                className={classes.buttons}
                color="primary"
                type="submit">
                Change Password
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default PersonalInfo;
