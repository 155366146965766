import React from 'react';
import {
  makeStyles,
  Typography,
  TextField,
  Grid,
  Container,
  Button,
  TableCell,
  TableBody,
  Table,
  TableRow,
  TableHead,
  TableContainer,
  Paper,
  Popper,
  ButtonBase,
  Avatar,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DoneIcon from '@material-ui/icons/DoneOutline';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import Editor from '../../shared/Editor';
import Image from '../../../assets/images/requirementimage.png';
import Person from '../../../assets/images/face.jpg';

const useStyles = makeStyles(theme => ({
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  columnOptionContainer: {
    marginLeft: 60,
  },
  columnOption: {
    marginTop: 15,
  },
  smallIcon: {
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
    backgroundColor: 'transparent',
    marginRight: 5,
  },
  sideBarTitle: {
    marginLeft: 15,
  },

  //Right content classes

  titleContainer: {
    marginTop: 10,
    fontWeight: 600,
  },
  textField: {
    marginTop: 10,
    marginBottom: 10,
  },
  editor: {
    marginTop: 15,
  },
  subtitle: {
    marginTop: 5,
    fontWeight: 'bold',
    opacity: 0.5,
  },
  caption: {
    marginTop: 3,
  },
  autoComplete: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] ': {
      paddingTop: 3,
    },
  },
  createBtn: {
    marginTop: 10,
    minWidth: 150,
  },
  inlineContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  //   NOTE: AutoComplete Classes

  autoCompleteOption: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  optionTitle: {
    fontWeight: 600,
  },

  // End
}));

const formalRef = [
  {
    name: 'Gabriel Pires',
    email: 'uguse@wazefov.gov',
    mobile: '+91 7014XXXXXX',
  },
  {
    name: 'Mayke Schuurs',
    email: 'nodefa@wazefov.gov',
    mobile: '+91 7014XXXXXX',
  },
  {
    name: 'Stephanus Huggins',
    email: 'kopale@wazefov.gov',
    mobile: '+91 7014XXXXXX',
  },
];

const StepOneSidebar = () => {
  const classes = useStyles();
  return (
    <Container>
      <div className={classes.titleRow}>
        <CheckCircleIcon
          style={{ color: '#FFF' }}
          className={classes.smallIcon}
          fontSize="large"
        />
        <Typography
          variant="subtitle1"
          color="textSecondary"
          className={classes.sideBarTitle}>
          Reference Check Form
        </Typography>
      </div>
      <div className={classes.columnOptionContainer}>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Candidate Info
        </Typography>

        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Formal Reference
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Discreet Reference
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Summary
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Candidate Reference Ratings
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Strengths
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Development Area
        </Typography>
      </div>
    </Container>
  );
};

const StepOneContent = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [editTitle, setEditTitle] = React.useState(false);
  const [title, setTitle] = React.useState();

  const Ref = () => {
    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'Ref' : undefined;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <ButtonBase disableRipple aria-describedby={id} onClick={handleClick}>
            <span>Ref 1</span>
            <KeyboardArrowDownIcon />
          </ButtonBase>
        </div>
        <div>
          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            placement="bottom-start"
            style={{ minHeight: '50%' }}>
            <Typography variant="subtitle1" style={{ color: '#FFFF' }}>
              Hello
            </Typography>
            {/* <div className={classes.header}>
            Apply labels to this pull request
          </div>
          <Autocomplete
            open
            onClose={handleClose}
            multiple
            classes={{
              paper: classes.paper,
              option: classes.option,
              popperDisablePortal: classes.popperDisablePortal,
            }}
            value={pendingValue}
            onChange={(event, newValue) => {
              setPendingValue(newValue);
            }}
            disableCloseOnSelect
            disablePortal
            renderTags={() => null}
            noOptionsText="No labels"
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <DoneIcon
                  className={classes.iconSelected}
                  style={{ visibility: selected ? "visible" : "hidden" }}
                />
                <span
                  className={classes.color}
                  style={{ backgroundColor: option.color }}
                />
                <div className={classes.text}>
                  {option.name}
                  <br />
                  {option.description}
                </div>
                <CloseIcon
                  className={classes.close}
                  style={{ visibility: selected ? "visible" : "hidden" }}
                />
              </React.Fragment>
            )}
            options={[...labels].sort((a, b) => {
              // Display the selected labels first.
              let ai = value.indexOf(a);
              ai = ai === -1 ? value.length + labels.indexOf(a) : ai;
              let bi = value.indexOf(b);
              bi = bi === -1 ? value.length + labels.indexOf(b) : bi;
              return ai - bi;
            })}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <InputBase
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                autoFocus
                className={classes.inputBase}
              />
            )}
          /> */}
          </Popper>
        </div>
      </React.Fragment>
    );
  };

  const AutoCompleteOption = ({ option }) => {
    return (
      <div className={classes.autoCompleteOption}>
        <div className={classes.inlineContainer}>
          <Avatar src={Person} alt="Person" />
          <Typography
            variant="body1"
            className={classes.optionTitle}
            style={{ marginLeft: 5 }}>
            {option.name}
          </Typography>
        </div>
        <Typography variant="body1" className={classes.optionTitle}>
          {option.email}
        </Typography>
        <Typography variant="body1" className={classes.optionTitle}>
          {option.mobile}
        </Typography>
      </div>
    );
  };

  return (
    <Container style={{ paddingRight: 5, position: 'relative' }}>
      <Grid container>
        <Grid item xs={7} md container>
          <Grid item xs={12}>
            <div className={classes.leftTitle}>
              {editTitle ? (
                <div className={classes.inlineContainer}>
                  <TextField
                    fullWidth
                    onChange={e => setTitle(e.target.value)}
                  />
                  <DoneIcon
                    style={{ marginLeft: 10 }}
                    onClick={() => setEditTitle(false)}
                  />
                </div>
              ) : (
                <div className={classes.inlineContainer}>
                  <Typography
                    variant="h1"
                    onDoubleClick={() => setEditTitle(true)}>
                    {title ? title : 'Reference Check Form'}
                  </Typography>
                  <EditOutlinedIcon
                    style={{ marginLeft: 10 }}
                    onClick={() => setEditTitle(true)}
                  />
                </div>
              )}
              <Typography variant="body1" className={classes.subtitle}>
                Before you looking for job, we need to know some information to
                get to know you.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Candidate Name"
              className={classes.textField}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Current Employer"
              className={classes.textField}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Current Destination"
              className={classes.textField}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Current Location"
              className={classes.textField}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className={classes.titleContainer}>
              Formal Reference
            </Typography>
            <Autocomplete
              options={formalRef}
              getOptionLabel={option => `${option.name},${option.email}`}
              renderOption={option => <AutoCompleteOption option={option} />}
              renderInput={params => (
                <TextField
                  id="first-name"
                  variant="outlined"
                  label="Type Candidate Name, Email"
                  className={classes.textField}
                  value=""
                  fullWidth
                  {...params}
                />
              )}
              className={classes.autoComplete}
            />

            <Button
              variant="contained"
              color="primary"
              className={classes.createBtn}>
              CREATE
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className={classes.titleContainer}>
              Discreet Reference
            </Typography>
            <Autocomplete
              options={formalRef}
              getOptionLabel={option => option.name}
              renderInput={params => (
                <TextField
                  id="first-name"
                  variant="outlined"
                  placeholder="Type Candidate Name, Email"
                  className={classes.textField}
                  value=""
                  fullWidth
                  {...params}
                />
              )}
              className={classes.autoComplete}
            />

            <Button
              variant="contained"
              color="primary"
              className={classes.createBtn}
              size="large">
              CREATE
            </Button>
          </Grid>
          <Grid item xs={12}>
            <div>
              <Typography
                variant="subtitle1"
                className={classes.titleContainer}>
                {' '}
                Summary
              </Typography>

              <div className={classes.editor}>
                <Editor />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <Typography
                variant="subtitle1"
                className={classes.titleContainer}>
                Candidate Reference Ratings
              </Typography>

              <div>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Attributes</TableCell>
                        <TableCell align="left">
                          <Ref />
                        </TableCell>
                        <TableCell align="left">Ref 2</TableCell>
                        <TableCell align="left">Ref 3</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left"></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 15 }}>
                  ADD MORE
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <Typography
                variant="subtitle1"
                className={classes.titleContainer}>
                {' '}
                Strengths
              </Typography>

              <div className={classes.editor}>
                <Editor />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ marginBottom: 40 }}>
              <Typography
                variant="subtitle1"
                className={classes.titleContainer}>
                {' '}
                Development Area
              </Typography>

              <div className={classes.editor}>
                <Editor placeholder="Type Here" />
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <div>
            <img
              src={Image}
              alt="right"
              height="18%"
              width="40%"
              style={{ position: 'absolute', right: 0, bottom: -60 }}
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export { StepOneContent, StepOneSidebar };
