import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';

import {
  FormControl,
  Typography,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
// import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  mr_15: {
    marginRight: 15,
  },
  loginButton: {
    borderRadius: 2,
  },
  titleDiv: {
    display: 'flex',
    minWidth: 180,
    color: '#00000',
  },
}));

export default function GuestHeader() {
  const classes = useStyles();
  // const history = useHistory();

  // const logOut = () => {
  //   sessionStorage.clear();
  //   history.replace('/');
  // };

  return (
    <div className={classes.grow}>
      <AppBar
        position="static"
        style={{
          backgroundColor: '#fff',
          boxShadow: '-2px 10px 5px -12px rgba(0,0,0,0.75)',
        }}>
        <Container maxWidth={'lg'}>
          <Toolbar>
            <div className={classes.titleDiv}>
              <LocalMallOutlinedIcon />
              <Typography variant="subtitle1">5000+ Job Available</Typography>
            </div>
            <FormControl variant="outlined" fullWidth className={classes.mr_15}>
              <InputLabel>Filter by City</InputLabel>
              <Select label="Country/Region" fullWidth>
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" fullWidth className={classes.mr_15}>
              <InputLabel>Filter by Category</InputLabel>
              <Select label="Country/Region" fullWidth>
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" fullWidth className={classes.mr_15}>
              <InputLabel>Filter by Type</InputLabel>
              <Select label="Country/Region" fullWidth>
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" fullWidth className={classes.mr_15}>
              <InputLabel>Salary Range</InputLabel>
              <Select label="Country/Region" fullWidth>
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>{' '}
            <FormControl variant="outlined" fullWidth className={classes.mr_15}>
              <InputLabel>More Filters</InputLabel>
              <Select label="Country/Region" fullWidth>
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}
