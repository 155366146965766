import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory } from 'react-router';

import Header from '../shared/Header';
import ActiveRequirementsList from '../Dashboard/ActiveRequirementsList';
import Requirements from '../Requirements/Requirements';
import EditProfile from '../EditProfile/EditProfile';

//System Management Components
import ManageCompany from '../SystemManagement/ManageCompany';
import ManageSkills from '../SystemManagement/ManageSkills';
import ManageAttribute from '../SystemManagement/ManageAttribute';
import ProfileSource from '../SystemManagement/ProfileSource';
import ManageStatus from '../SystemManagement/ManageStatus';
//Manage Users Components
import ManageAdmin from '../UserManagement/ManageAdmin';
import ManageRecruiters from '../UserManagement/ManageRecruiters';
import ManageCandidates from '../UserManagement/ManageCandidates';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme =>
  createStyles({
    dashboardTabpanel: {
      '& > .MuiBox-root': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    tab: {
      color: '#a6a6a6',
      fontWeight: 600,
      textTransform: 'none',
    },
    container: {
      paddingRight: 0,
    },
    manageusrItem: {
      minWidth: 120,
      textAlign: 'left',
    },
    systemManagementItem: {
      minWidth: 160,
      textAlign: 'left',
    },
    manageUserPopover: {
      marginLeft: 30,
      marginTop: 10,
    },
    systemMangrPopover: {
      marginTop: 10,
    },
  })
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `dashboard-${index}`,
    'aria-controls': `dashboard-${index}`,
  };
}

const routeIndexMap = {
  dashboard: { index: 0, path: 0 },
  requirements: { index: 1, path: 1 },
  profiles: { index: 2, path: 2 },
  'user-management': { index: 3, path: 3 },
  'roles-and-permission': { index: 4, path: 4 },
  'system-management': { index: 5, path: 5 },
  help: { index: 6, path: 6 },
  Admin: { index: 3, path: 3 },
  Candidate: { index: 6, path: 3 },
  Recruiter: { index: 7, path: 3 },
  Company: { index: 5, path: 5 },
  Skills: { index: 9, path: 5 },
  Status: { index: 10, path: 5 },
  Attributes: { index: 11, path: 5 },
  ProfileSource: { index: 12, path: 5 },
};

export default function DashboardTab({ tab = true }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [companyanchorE1, setCompanyanchorE1] = React.useState(null);
  const [requirementanchorE1, setRequirementanchorE1] = React.useState(null);
  const history = useHistory();
  const index = routeIndexMap[useParams().path]?.index ?? -1;
  const pathIndex = routeIndexMap[useParams().path]?.path ?? -1;

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setCompanyanchorE1(null);
    setRequirementanchorE1(null);
  };

  const open = Boolean(anchorEl);
  const companyOpen = Boolean(companyanchorE1);
  const requirementOpen = Boolean(requirementanchorE1);

  const User = selectedOption => {
    switch (selectedOption) {
      case 'Admin':
        return <ManageAdmin />;
      case 'Candidate':
        return <ManageCandidates />;
      case 'Recruiter':
        return <ManageRecruiters />;
      default:
        return <ManageAdmin />;
    }
  };

  const SystemManagement = sysSelectedOption => {
    switch (sysSelectedOption) {
      case 'Company':
        return <ManageCompany />;
      case 'Skills':
        return <ManageSkills />;
      case 'Status':
        return <ManageStatus />;
      case 'Attributes':
        return <ManageAttribute />;
      case 'ProfileSource':
        return <ProfileSource />;
      default:
        return <ManageCompany />;
    }
  };

  const onPopoverClick = option => {
    history.push(`/dashboard/${option}`);
  };

  const onSysManPopoverClick = option => {
    history.push(`/dashboard/${option}`);
  };

  const ManageuserPopover = ({ open }) => {
    return (
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        className={classes.manageUserPopover}>
        <List component="nav">
          <ListItem
            button
            onClick={() => onPopoverClick('Admin')}
            className={classes.manageusrItem}>
            <ListItemText>
              <Typography variant="subtitle1">Admin</Typography>
            </ListItemText>
          </ListItem>
          <ListItem
            button
            onClick={() => onPopoverClick('Recruiter')}
            className={classes.manageusrItem}>
            <ListItemText>
              <Typography variant="subtitle1">Recruiter</Typography>
            </ListItemText>
          </ListItem>
          <ListItem
            button
            onClick={() => onPopoverClick('Candidate')}
            className={classes.manageusrItem}>
            <ListItemText>
              {' '}
              <Typography variant="subtitle1">Candidate</Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Popover>
    );
  };

  const SystemManagementPopover = ({ open }) => {
    return (
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={companyanchorE1}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={classes.systemMangrPopover}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        <List component="nav">
          <ListItem
            button
            onClick={() => {
              onSysManPopoverClick('Company');
            }}
            className={classes.systemManagementItem}>
            <ListItemText>
              <Typography variant="subtitle1">Company</Typography>
            </ListItemText>
          </ListItem>
          <ListItem
            button
            className={classes.systemManagementItem}
            onClick={() => onSysManPopoverClick('Skills')}>
            <ListItemText>
              <Typography variant="subtitle1">Skills</Typography>
            </ListItemText>
          </ListItem>
          <ListItem
            button
            className={classes.systemManagementItem}
            onClick={() => onSysManPopoverClick('Status')}>
            <ListItemText>
              {' '}
              <Typography variant="subtitle1">Status</Typography>
            </ListItemText>
          </ListItem>
          <ListItem
            button
            className={classes.systemManagementItem}
            onClick={() => onSysManPopoverClick('Attributes')}>
            <ListItemText>
              {' '}
              <Typography variant="subtitle1">Attributes</Typography>
            </ListItemText>
          </ListItem>
          <ListItem
            button
            className={classes.systemManagementItem}
            onClick={() => onSysManPopoverClick('ProfileSource')}>
            <ListItemText>
              <Typography variant="subtitle1">Profile Source</Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Popover>
    );
  };

  const RequirementPopover = ({ open }) => {
    return (
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={requirementanchorE1}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        <List component="nav">
          <ListItem button>
            <ListItemText onClick={() => history.push('/createrequirement')}>
              <Typography variant="subtitle1">Create Requirement</Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Popover>
    );
  };

  return (
    <>
      <Header />
      <Paper>
        <Container maxWidth="xl" className={classes.container}>
          <Tabs value={pathIndex} textColor="primary" indicatorColor="primary">
            <Tab
              className={classes.tab}
              onClick={() => history.push('/dashboard/dashboard')}
              label="Dashboard"
              {...a11yProps(0)}
            />
            <Tooltip
              onMouseEnter={e => setRequirementanchorE1(e.currentTarget)}
              onMouseLeave={handlePopoverClose}
              title="">
              <Tab
                className={classes.tab}
                label="Requirements"
                {...a11yProps(1)}
                onClick={() => history.push('/dashboard/requirements')}
              />
            </Tooltip>
            <Tab
              className={classes.tab}
              onClick={() => history.push('/dashboard/profiles')}
              label="Profiles"
              {...a11yProps(2)}
            />
            <Tooltip
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              title="">
              <Tab
                className={classes.tab}
                label="Users Management"
                {...a11yProps(3)}
                onClick={() => history.push('/dashboard/user-management')}
              />
            </Tooltip>
            <Tooltip
              onMouseEnter={e => setCompanyanchorE1(e.currentTarget)}
              onMouseLeave={handlePopoverClose}
              title="">
              <Tab
                className={classes.tab}
                label="System Management"
                {...a11yProps(5)}
                onClick={() => history.push('/dashboard/system-management')}
              />
            </Tooltip>
          </Tabs>
        </Container>
      </Paper>
      <Container maxWidth="xl">
        <TabPanel value={index} index={0} className={classes.dashboardTabpanel}>
          <ActiveRequirementsList />
        </TabPanel>
        <TabPanel value={index} index={1}>
          <Requirements />
        </TabPanel>
        <TabPanel value={index} index={2}>
          <EditProfile />
        </TabPanel>
        <TabPanel value={index} index={3}>
          {User('Admin')}
        </TabPanel>
        <TabPanel value={index} index={6}>
          {User('Candidate')}
        </TabPanel>
        <TabPanel value={index} index={7}>
          {User('Recruiter')}
        </TabPanel>

        <TabPanel value={index} index={5}>
          {SystemManagement('Company')}
        </TabPanel>
        <TabPanel value={index} index={9}>
          {SystemManagement('Skills')}
        </TabPanel>
        <TabPanel value={index} index={10}>
          {SystemManagement('Status')}
        </TabPanel>
        <TabPanel value={index} index={11}>
          {SystemManagement('Attributes')}
        </TabPanel>
        <TabPanel value={index} index={12}>
          {SystemManagement('ProfileSource')}
        </TabPanel>
      </Container>
      <SystemManagementPopover open={companyOpen} />
      <ManageuserPopover open={open} />
      <RequirementPopover open={requirementOpen} />
    </>
  );
}
