import axios from 'axios';

const api = async ({ method = 'POST', path, data = {} }) => {
  const basePath = 'https://medlent.herokuapp.com';
  var config = {
    method,
    headers: {
      Authorization: sessionStorage.getItem('auth'),
    },
    url: `${basePath}${path}`,
    data: data,
  };

  return axios(config);
};

export default api;
