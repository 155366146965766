import React, { useState, useContext, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/CloseOutlined';

import api from '../../api/api';
import { url, isImage } from '../../utils/common';
import UserContext from '../../Context/UserContext';
import SnackBarContext from '../../Context/SnackBarContext';

const useStyles = makeStyles(theme => ({
  container: {
    margin: '15px  15px 0px',
  },
  titleRow: {
    padding: '15px 50px',
  },
  textField: {
    padding: ' 0px 0px 5px 10px',
  },
  buttonContainer: {
    marginTop: 40,
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttons: {
    padding: '12px 20px',
  },
  textFieldLabel: {
    '& > .MuiGrid-root': {
      padding: 0,
    },
    marginLeft: 10,
    fontWeight: 'bold',
  },
  textFieldBodyTitle: {
    marginLeft: 20,
    fontWeight: 'normal',
  },
  previewImg: {
    marginLeft: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  closeIcon: {
    color: '#7a7a7a',
  },
}));

const UpdateLogo = () => {
  const classes = useStyles();
  const [logo, setLogo] = useState({});
  const { authState } = useContext(UserContext);
  const { alerts } = useContext(SnackBarContext);

  const handleFileUpload = (e, logoName) => {
    var image = isImage(e.target.files[0]);
    let fileSize = parseFloat(e.target.files[0]?.size / 1024 / 1024).toFixed(2);

    if (!image) {
      alerts[1]({
        error: true,
        message: 'Please select image',
        state: true,
      });

      return;
    }
    if (parseFloat(fileSize) > 15) {
      alerts[1]({
        error: true,
        message: 'Maximum 10Mb of size allowed',
        state: true,
      });
      return;
    }

    var formData = new FormData();
    formData.append('image', e.target.files[0]);
    api({ path: '/image/upload', data: formData })
      .then(res => {
        console.log('res', res);
        if (res.data.hasError) {
          alerts[1]({
            error: true,
            message: res.data.message,
            state: true,
          });
        }
        alerts[1]({
          error: false,
          message: res.data.message,
          state: true,
        });
        setLogo({
          ...logo,
          [logoName]: res.data.data.imageId,
        });
      })
      .catch(err => console.log('ERR', err));
  };

  useEffect(() => {
    api({ path: '/admin/setting/getSiteSetting' }).then(res => {
      console.log(res.data.data[0].monogram_dark_logo);
      setLogo({
        monogram_dark_logo: res.data?.data[0]?.monogram_dark_logo,
        monogrma_light_logo: res.data?.data[0]?.monogrma_light_logo,
        full_dark_logo: res.data?.data[0]?.full_dark_logo,
        full_light_logo: res.data?.data[0]?.full_light_logo,
        template_logo: res.data?.data[0]?.template_logo,
      });
    });
  }, []);

  const updateLogo = () => {
    if (Object.keys(logo).length === 0) {
      alerts[1]({
        error: true,
        message: 'Please select atleast one logo',
        state: true,
      });
      return;
    }
    const data = {
      logo: {
        admin_id: authState[0].admin_id,
        user_account_id: authState[0].user_account_id,
        ...logo,
      },
    };
    api({ path: '/admin/setting/updateLogo', data: data })
      .then(res => {
        console.log('dvdfd', res);
        if (res.data.hasError) {
          alerts[1]({
            error: true,
            message: res.data.message,
            state: true,
          });
          return;
        }
        alerts[1]({
          error: false,
          message: res.data.message,
          state: true,
        });
      })
      .catch(err => {
        alerts[1]({
          error: true,
          message: 'Error occured while updating a logo',
          state: true,
        });
      });
  };

  return (
    <Container className={classes.container} maxWidth="md">
      <Paper className={classes.titleRow}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Company Logo</Typography>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <Typography variant="body1" style={{ fontWeight: 'normal' }}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.textFieldLabel}>
            <Typography variant="subtitle1">Monogram logo</Typography>
          </Grid>
          <Grid item xs={6}>
            {logo?.monogram_dark_logo ? (
              <div className={classes.previewImg}>
                <img
                  src={`${url}/image/${logo?.monogram_dark_logo}`}
                  alt="monogramdark logo"
                  height={50}
                  width={100}
                />
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={() => setLogo({ ...logo, monogram_dark_logo: '' })}
                />
              </div>
            ) : (
              <>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="darkLogo"
                  placeholder="Dark Logo"
                  className={classes.textField}
                  type="file"
                  onChange={e => handleFileUpload(e, 'monogram_dark_logo')}
                />
                <Typography
                  variant="body1"
                  className={classes.textFieldBodyTitle}>
                  Logo size 43px × 34px
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={6}>
            {logo?.monogrma_light_logo ? (
              <div className={classes.previewImg}>
                <img
                  src={`${url}/image/${logo?.monogrma_light_logo}`}
                  alt="monogramlight logo"
                  height={50}
                  width={100}
                />
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={e => setLogo({ ...logo, monogrma_light_logo: '' })}
                />
              </div>
            ) : (
              <>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="lightLogo"
                  placeholder="Light Logo"
                  className={classes.textField}
                  type="file"
                  onChange={e => handleFileUpload(e, 'monogrma_light_logo')}
                />
                <Typography
                  variant="body1"
                  className={classes.textFieldBodyTitle}>
                  Logo size 43px × 34px
                </Typography>
              </>
            )}
          </Grid>

          <Grid item xs={12} className={classes.textFieldLabel}>
            <Typography variant="subtitle1">Full logo</Typography>
          </Grid>
          <Grid item xs={6}>
            {logo?.full_dark_logo ? (
              <div className={classes.previewImg}>
                <img
                  src={`${url}/image/${logo?.full_dark_logo}`}
                  alt="fulldark logo"
                  height={100}
                  width={150}
                />
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={() => setLogo({ ...logo, full_dark_logo: '' })}
                />
              </div>
            ) : (
              <>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="darkLogo"
                  placeholder="Dark Logo"
                  className={classes.textField}
                  type="file"
                  onChange={e => handleFileUpload(e, 'full_dark_logo')}
                />
                <Typography
                  variant="body1"
                  className={classes.textFieldBodyTitle}>
                  Logo size 224px × 103px
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={6}>
            {logo?.full_light_logo ? (
              <div className={classes.previewImg}>
                <img
                  src={`${url}/image/${logo?.full_light_logo}`}
                  alt="fulllight logo"
                  height={100}
                  width={150}
                />
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={() => setLogo({ ...logo, full_light_logo: '' })}
                />
              </div>
            ) : (
              <>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="lightLogo"
                  placeholder="Light Logo"
                  className={classes.textField}
                  type="file"
                  onChange={e => handleFileUpload(e, 'full_light_logo')}
                />
                <Typography
                  variant="body1"
                  className={classes.textFieldBodyTitle}>
                  Logo size 224px × 103px
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={12} className={classes.textFieldLabel}>
            <Typography variant="subtitle1">Template logo</Typography>
          </Grid>
          <Grid item xs={6}>
            {logo?.template_logo ? (
              <div className={classes.previewImg}>
                <img
                  src={`${url}/image/${logo?.template_logo}`}
                  alt="template logo"
                  height={80}
                  width={130}
                />
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={() => setLogo({ ...logo, template_logo: '' })}
                />
              </div>
            ) : (
              <>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="templateLogo"
                  placeholder="Logo"
                  className={classes.textField}
                  type="file"
                  onChange={e => handleFileUpload(e, 'template_logo')}
                />
                <Typography
                  variant="body1"
                  className={classes.textFieldBodyTitle}>
                  Logo size 130px × 60px
                </Typography>
              </>
            )}
          </Grid>

          <Grid item xs={12} className={classes.buttonContainer}>
            <Button variant="contained" className={classes.buttons}>
              Cancel
            </Button>

            <Button
              variant="contained"
              className={classes.buttons}
              color="primary"
              onClick={() => updateLogo()}>
              SAVE & UPDATE
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default UpdateLogo;
