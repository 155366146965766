import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import jwt_decode from 'jwt-decode';

import theme from './components/shared/theme';
import PrivateRoute from './components/shared/PrivateRoute';
import UserContext from './Context/UserContext';
import SnackBarContext from './Context/SnackBarContext';
import SignIn from './components/SignIn/SignIn';
import ForgetPassword from './components/SignIn/ForgetPassword';
import ForgetPasswordMailSent from './components/SignIn/ForgetPasswordMailSent';
import ResetPassword from './components/SignIn/ResetPassword';
import Dashboard from './components/shared/DashboardTab';
import Settings from './components/Settings/Settings';
import CreateRequirement from './components/CreateRequirement/CreateRequirement';
import SnackBar from './components/shared/SnackBar';

//  Candidate
import CandidateProfile from './components/CandidateProfile/CandidateProfile';
import ReferenceCheckForm from "./components/ReferenceCheckForm/ReferenceCheckForm";
import OnBoarding from './components/OnBoarding/OnBoarding';
import SignUp from './components/SignUp/SignUp';
import JobList from './components/SharedScreens/JobList';

const App = () => {
  const authState = useState({});
  const alerts = useState({ state: false, message: '', error: true });

  useEffect(() => {
    const auth = sessionStorage.getItem('auth');
    var decodedData;
    if (auth) {
      decodedData = jwt_decode(auth);
      authState[1](decodedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserContext.Provider value={{ authState }}>
      <SnackBarContext.Provider value={{ alerts }}>
        <ThemeProvider theme={theme}>
          <Router>
            <Switch>
              <Route exact path="/">
                <Redirect to="/dashboard/dashboard" />
              </Route>
              <Route exact path="/dashboard">
                <Redirect to="/dashboard/dashboard" />
              </Route>
              <Route exact path="/recruiter_dashboard">
                <Redirect to="/recruiter_dashboard/dashboard" />
              </Route>
              <Route exact path="/signin" component={SignIn} />
              <Route exact path="/signup" component={SignUp} />
              <Route exact path="/forgotpassword" component={ForgetPassword} />
              <Route
                exact
                path="/forgetpasswordmailsent"
                component={ForgetPasswordMailSent}
              />
              <Route
                exact
                path="/resetpassword/:resetId"
                component={ResetPassword}
              />

              <PrivateRoute
                exact
                path="/dashboard/:path"
                component={Dashboard}
              />
              <PrivateRoute exact path="/settings" component={Settings} />
              <PrivateRoute
                exact
                path="/createrequirement"
                component={CreateRequirement}
              />
              <PrivateRoute
                exact
                path="/candidateprofile"
                component={CandidateProfile}
              />
              <Route exact path="/onBoarding" component={OnBoarding} />
              <Route exact path="/joblist" component={JobList} />

              <Route exact path="/referencecheckform" component={ReferenceCheckForm} />
              <PrivateRoute
                exact
                path="/recruiter_dashboard/:path"
                component={Dashboard}
              />
            </Switch>
          </Router>
          <SnackBar alerts={alerts} />
        </ThemeProvider>
      </SnackBarContext.Provider>
    </UserContext.Provider>
  );
};

export default App;
