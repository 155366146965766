import {
  Avatar,
  Button,
  Chip,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '30px 25px',
    backgroundColor: '#bfbfbf3d',
  },
  titleDiv: { maxWidth: 550 },
  applyNowDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  smallDetailsDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 30px',
  },
  smallTitle: {
    fontWeight: '600',
    marginBottom: 10,
  },
  smallDetailsDiv2: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 30px',
    borderBottom: '1px solid #0000000f',
  },
  smallDetaildivSubContainer: {
    width: '100%',
  },
  descContainer: {
    padding: '10px 40px 10px 30px',
  },
  lineHeight_25: {
    lineHeight: '25px',
  },
  tableTypeDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '55%',
  },
  chipsDiv: {
    listStyle: 'none',
    display: 'flex',
    flexFlow: 'wrap',
    marginTop: 5,
  },
  chip: {
    margin: theme.spacing(0.5),
    fontSize: 12,
    borderRadius: 4,
    backgroundColor: '#fff',
    border: '1px solid #00000032',
    width: '98%',
  },
  contactPerson: {
    display: 'flex',
    padding: 10,
  },
}));

function JobDetailsCard({
  position,
  company,
  city,
  country,
  yearsExp,
  salary,
  employmentType,
  vacancies,
  reportingTo,
  reportees,
  jobDetails,
  desiredCandidateProfile,
  keyPerformancesIndicatores,
  industry,
  functionalArea,
  keywords = [],
  referenceCode = [],
  hiringFor,
  doctorate,
  postGraduation,
  graduation,
  createOn,
  contactPMobile,
  contactPName,
}) {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.titleContainer}>
        <div className={classes.titleDiv}>
          <Typography variant="h1" style={{ marginBottom: 15 }}>
            {position}
          </Typography>
          <Typography variant="body1">
            {company} | {city} {country}
          </Typography>
        </div>
        <div className={classes.applyNowDiv}>
          <Button
            variant="contained"
            color="primary"
            style={{ maxWidth: 125, marginBottom: 8 }}>
            APPLY NOW
          </Button>
          <Typography variant="body1">
            Posted {createOn} -{' '}
            <Typography variant="span">12 Applicants</Typography>
          </Typography>
        </div>
      </div>
      <div className={classes.smallDetailsDiv}>
        <div className={classes.smallDetaildivSubContainer}>
          <Typography variant="body1" className={classes.smallTitle}>
            EXPERIENCE
          </Typography>
          <Typography variant="body2">{yearsExp}</Typography>
        </div>
        <div className={classes.smallDetaildivSubContainer}>
          <Typography variant="body1" className={classes.smallTitle}>
            SALARY
          </Typography>
          <Typography variant="body2">₹ {salary}</Typography>
        </div>
        <div className={classes.smallDetaildivSubContainer}>
          <Typography variant="body1" className={classes.smallTitle}>
            EMPLOYMENT
          </Typography>
          <Typography variant="body2">{employmentType}</Typography>
        </div>
        <div className={classes.smallDetaildivSubContainer}>
          <Typography variant="body1" className={classes.smallTitle}>
            OPENINGS
          </Typography>
          <Typography variant="body2">{vacancies}</Typography>
        </div>
      </div>
      <div className={classes.smallDetailsDiv2}>
        <div className={classes.smallDetaildivSubContainer}>
          <Typography variant="body1" className={classes.smallTitle}>
            REPORTING TO
          </Typography>
          <Typography variant="body2">{reportingTo}</Typography>
        </div>
        <div className={classes.smallDetaildivSubContainer}>
          <Typography variant="body1" className={classes.smallTitle}>
            REPORTEES/TEAM
          </Typography>
          <Typography variant="body2">{reportees}</Typography>
        </div>
        <div className={classes.smallDetaildivSubContainer}>
          <Typography> </Typography>
        </div>
        <div className={classes.smallDetaildivSubContainer}>
          <Typography> </Typography>
        </div>
      </div>
      <div className={classes.descContainer}>
        <Typography variant="subtitle1" className={classes.lineHeight_25}>
          Job Description
        </Typography>
        <Typography>{jobDetails}</Typography>
      </div>
      <div className={classes.descContainer}>
        <Typography variant="subtitle1" className={classes.lineHeight_25}>
          Desired Candidate Profile
        </Typography>
        <Typography>{desiredCandidateProfile}</Typography>
      </div>
      <div className={classes.descContainer}>
        <Typography variant="subtitle1" className={classes.lineHeight_25}>
          Key Performance Indicators
        </Typography>
        <Typography>{keyPerformancesIndicatores}</Typography>
      </div>
      <div className={classes.descContainer}>
        <div className={classes.tableTypeDiv}>
          <Typography>Industry Type</Typography>
          <Typography variant="body2" className={classes.lineHeight_25}>
            {industry}
          </Typography>
        </div>
        <div className={classes.tableTypeDiv}>
          <Typography>Functional Area</Typography>
          <Typography variant="body2" className={classes.lineHeight_25}>
            {functionalArea}
          </Typography>
        </div>
      </div>
      <div className={classes.descContainer}>
        <div>
          <Typography variant="subtitle1" className={classes.lineHeight_25}>
            Education
          </Typography>
          <div>
            <div className={classes.tableTypeDiv}>
              <Typography className={classes.lineHeight_25}>UG</Typography>
              <Typography className={classes.lineHeight_25} variant="body2">
                {graduation ? 'Garduation Required' : 'Garduation Not Required'}
              </Typography>
            </div>
            <div className={classes.tableTypeDiv}>
              <Typography className={classes.lineHeight_25}>UG</Typography>
              <Typography className={classes.lineHeight_25} variant="body2">
                {postGraduation
                  ? 'Post Garduation Required'
                  : 'Post Garduation Not Required'}
              </Typography>
            </div>
            <div className={classes.tableTypeDiv}>
              <Typography className={classes.lineHeight_25}>
                Doctorate
              </Typography>
              <Typography className={classes.lineHeight_25} variant="body2">
                {doctorate
                  ? 'Doctorate Not Required'
                  : 'Doctorate Not Required'}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.descContainer}>
        <Typography variant="subtitle1" className={classes.lineHeight_25}>
          Keywords
        </Typography>
        <div className={classes.chipsDiv}>
          {keywords?.map((item, i) => {
            return (
              <li key={i}>
                <Chip label={item} className={classes.chip} />
              </li>
            );
          })}
        </div>
      </div>
      <div
        className={classes.descContainer}
        style={{ display: 'flex', borderBottom: '1px solid #0000000f' }}>
        <div>
          <Typography variant="subtitle1" className={classes.lineHeight_25}>
            Reference Code
          </Typography>
          <div className={classes.chipsDiv}>
            {referenceCode?.map((item, i) => {
              return (
                <li key={i}>
                  <Chip label={item} className={classes.chip} />
                </li>
              );
            })}
          </div>
        </div>
        <div style={{ padding: '6px 40px' }}>
          <Typography variant="subtitle1" className={classes.lineHeight_25}>
            Hiring For
          </Typography>
          <Chip label={hiringFor} className={classes.chip} />
        </div>
      </div>
      <div className={classes.descContainer}>
        <Typography variant="subtitle1" className={classes.lineHeight_25}>
          Contact Person
        </Typography>
        <div className={classes.contactPerson}>
          <Avatar
            alt="profile"
            className={classes.largeAvatar}
            style={{ marginRight: 10 }}
          />
          <div>
            <Typography variant="body2" className={classes.lineHeight_25}>
              {contactPName}
            </Typography>{' '}
            <Typography variant="body1">{contactPMobile}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobDetailsCard;
