import React, { useState } from 'react';
import {
  Button,
  Typography,
  IconButton,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import ArrowBackIcon from '@material-ui/icons/ArrowBackOutlined';
import CloseIcon from '@material-ui/icons/Close';
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  dialogTitleContainer: {
    backgroundColor: theme.palette.ternary.main,
    padding: '0px 15px',
  },
  dialogTitleContainerDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: '#FFF',
    marginRight: 15,
  },
  dialogContent: {
    textAlign: 'center',
    marginTop: 10,
  },
  rating: {
    marginTop: 15,
  },
  rateBtn: {
    margin: '20px 0px',
    textAlign: 'center',
    minWidth: 200,
    fontWeight: 600,
  },
}));

const WriteReview = ({ open }) => {
  const classes = useStyles();
  const [rating, setRating] = useState(0);
  return (
    <Dialog
      open={open[0]}
      maxWidth="sm"
      TransitionComponent={Transition}
      fullWidth>
      <DialogTitle className={classes.dialogTitleContainer}>
        <div className={classes.dialogTitleContainerDiv}>
          <div className={classes.dialogTitle}>
            <ArrowBackIcon className={classes.icon} />
            <Typography variant="subtitle1" style={{ color: '#FFF' }}>
              Write a Review
            </Typography>
          </div>
          <IconButton onClick={() => open[1](false)}>
            <CloseIcon style={{ color: '#FFF' }} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className={classes.dialogContent}>
          <Typography variant="subtitle1">
            Your Opinion matter to us!
          </Typography>
          <Typography variant="body1">
            How was the candidate performed
          </Typography>
          <Rating
            name="simple-controlled"
            value={rating}
            icon={<StarOutlinedIcon style={{ fontSize: 30 }} />}
            onChange={(event, value) => {
              setRating(value);
            }}
            className={{ root: classes.rating }}
          />
        </div>
        <div style={{ textAlign: 'center' }}>
          <Button
            className={classes.rateBtn}
            size="large"
            color="primary"
            variant="contained">
            RATE NOW
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default WriteReview;
