import React, { useState } from 'react';
import {
  Container,
  Grid,
  Typography,
  Paper,
  makeStyles,
  Button,
  Breadcrumbs,
  Link,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PersonIcon from '@material-ui/icons/PersonOutlineOutlined';
import WorkIcon from '@material-ui/icons/WorkOutlineOutlined';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import Header from '../shared/Header';
import LeftSideBar from './LeftSideBar';
import RightSideBar from './RightSideBar';
import MiddleContent from './MiddleContent';
import ExportPdf from './ExportPdf';
import WriteReview from './WriteReview';

const useStyles = makeStyles(theme => ({
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  topActions: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
  },
  paper: {
    marginTop: 20,
    padding: 15,
    marginBottom: 5,
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
  },
  icon: {
    marginRight: 5,
  },
  horizontalDivider: {
    borderTop: '1px solid #00000026',
    margin: '10px 0px',
  },
  btn: {
    fontWeight: 600,
  },
  breadcrumbsContainer: {
    padding: 15,
  },
  breadcrumbs: {
    marginLeft: 15,
  },
  breadcrumbTitle: {
    fontWeight: 'normal',
  },
  helpBtn: {
    position: 'absolute',
    right: 55,
    top: 0,
    marginTop: theme.spacing(10),
  },
  separator: {
    '& .MuiBreadcrumbs-separator': {
      color: '#000',
    },
  },
}));

const CandidateProfile = () => {
  const classes = useStyles();
  const exportPdfOpen = useState(false);
  const writeReviewOpen = useState(true);

  return (
    <div>
      <Header />
      {/* NOTE: Breadcrumb */}
      <Paper className={classes.breadcrumbsContainer}>
        <Container maxWidth={false} className={classes.breadcrumbs}>
          <Breadcrumbs className={classes.separator} aria-label="breadcrumb">
            <Link href="/">
              <Typography
                className={classes.breadcrumbTitle}
                variant="subtitle1">
                Home
              </Typography>
            </Link>
            <Link href="/">
              <Typography
                className={classes.breadcrumbTitle}
                variant="subtitle1">
                Manage User
              </Typography>
            </Link>
            <Link href="/" aria-current="page">
              <Typography
                className={classes.breadcrumbTitle}
                variant="subtitle1"
                color="primary">
                Profile
              </Typography>
            </Link>
            <Link className={classes.helpBtn}>
              <Typography
                className={classes.breadcrumbTitle}
                variant="subtitle1">
                Help
              </Typography>
            </Link>
          </Breadcrumbs>
        </Container>
      </Paper>
      <Container maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper variant="outlined" className={classes.paper}>
              <div className={classes.actionContainer}>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: 'normal' }}
                  color="primary">
                  Applications for UI Designer
                </Typography>
                <NavigateNextIcon className={classes.icon} />
                <Typography variant="subtitle1">Rahul Kumar Sahu</Typography>
              </div>
              <div className={classes.horizontalDivider} />
              <div className={classes.topActions}>
                <div className={classes.action}>
                  <Button
                    startIcon={<PersonIcon className={classes.icon} />}
                    className={classes.btn}>
                    Download CV
                  </Button>
                </div>
                <div className={classes.action}>
                  <Button
                    startIcon={<WorkIcon className={classes.icon} />}
                    endIcon={<ArrowDropDownIcon className={classes.icon} />}
                    className={classes.btn}>
                    Assign to Req.
                  </Button>
                </div>
                <div className={classes.action}>
                  <Button
                    startIcon={
                      <PictureAsPdfOutlinedIcon className={classes.icon} />
                    }
                    className={classes.btn}
                    onClick={() => exportPdfOpen[1](true)}>
                    Export PDF
                  </Button>
                </div>
                <div className={classes.action}>
                  <Button
                    startIcon={<FolderIcon className={classes.icon} />}
                    className={classes.btn}>
                    Ask for documents
                  </Button>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <LeftSideBar />
          </Grid>
          <Grid item xs={6}>
            <MiddleContent />
          </Grid>
          <Grid item xs={3}>
            <RightSideBar />
          </Grid>
        </Grid>
      </Container>
      <ExportPdf open={exportPdfOpen} />
      <WriteReview open={writeReviewOpen} />
    </div>
  );
};

export default CandidateProfile;
