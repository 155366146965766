import React, { useState, useEffect, useContext } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import isEmpty from 'lodash/isEmpty';
import {
  Container,
  InputAdornment,
  Dialog,
  Checkbox,
  Button,
  IconButton,
  MenuItem,
  Paper,
  TableContainer,
  TableRow,
  Table,
  TableCell,
  TableBody,
  Typography,
  TextField,
  Slide,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableHead,
  Switch,
  TablePagination,
  FormHelperText,
  CircularProgress,
} from '@material-ui/core';

import api from '../../api/api';
import UserContext from '../../Context/UserContext';
import SnackBarContext from '../../Context/SnackBarContext';
import ConfirmationDialogs from './ConfirmationDialog';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    leftContainer: {
      width: '100%',
    },

    tableContainer: {
      marginTop: 20,
      border: '1px solid #0000004D',
      borderRadius: 4,
    },
    titleRow: {
      minHeight: 130,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    tablecell: {
      borderBottom: 0,
    },
    tableHead: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    container: {
      marginTop: 25,
    },
    titleDiv: {
      paddingLeft: 25,
    },
    icon: {
      color: '#6f6f6f',
      padding: '0px 5px 0px 15px',
    },
    actionBtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginRight: 5,
    },
    inlineContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    tablePagination: {
      '& .MuiTablePagination-select': {
        backgroundColor: '#FFF',
      },
    },
    iconBtn: {
      padding: 5,
    },
  })
);

const useStylesDialog = makeStyles(theme =>
  createStyles({
    dialogTitleContainer: {
      backgroundColor: theme.palette.ternary.main,
      padding: '10px 15px',
    },
    dialogTitleContainerDiv: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },

    dialogActionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 10,
    },
    dialogActionButton: {
      margin: '5px 13px',
      color: '#FFF',
      padding: '10px 25px',
    },

    uploadFileContainer: {
      border: '1px solid #00000060',
      borderRadius: 4,
      minHeight: 230,
    },
    companyInfoContainer: {
      marginTop: 10,
    },
    rowCompanyInput: {
      margin: '15px 0px',
    },
    helperText: {
      color: 'red',
      marginLeft: 5,
    },
  })
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateProfileSourceDialog = ({
  profileSourceOpen,
  setProfileSourceOpen,
  authState,
  alerts,
  editProfileSource,
}) => {
  const classes = useStylesDialog();
  const [createProfileSouceData, setCreateProfileSourceData] = useState({
    visibility: 0,
    profileSourceName: '',
  });
  const [error, setError] = useState();

  const handleCreateProfileSource = () => {
    if (createProfileSouceData?.profileSourceName === '') {
      setError('Please enter profile source.');
      return;
    }
    createProfileSource(createProfileSouceData);
    setError();
  };

  useEffect(() => {
    editProfileSource[0]
      ? setCreateProfileSourceData({
          profileSourceName: editProfileSource[0]?.profile_source_name,
          visibility: editProfileSource[0]?.status === 'active' ? 1 : 0,
          id: editProfileSource[0]?.id,
        })
      : setCreateProfileSourceData({
          visibility: 0,
          profileSourceName: '',
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileSourceOpen]);

  const createProfileSource = data => {
    const sourceData = {
      admin_id: authState[0].admin_id,
      user_account_id: authState[0].user_account_id,
      profile_source_name: data?.profileSourceName,
      visibility: data?.visibility,
      profileId: data?.id.toString(),
    };

    editProfileSource[0]
      ? api({
          path: '/admin/profile-source/editProfileSource',
          data: sourceData,
        })
          .then(res => {
            if (res.data.hasError) {
              alerts[1]({
                error: true,
                message: res.data.message,
                state: true,
              });
            }
            alerts[1]({
              error: false,
              message: res.data.message,
              state: true,
            });
            handleClose();
          })
          .catch(err => console.log(err), handleClose())
      : api({
          path: '/admin/profile-source/createProfileSource',
          data: sourceData,
        })
          .then(res => {
            if (res.data.hasError) {
              alerts[1]({
                error: true,
                message: res.data.message,
                state: true,
              });
            }
            alerts[1]({
              error: false,
              message: res.data.message,
              state: true,
            });
            handleClose();
          })
          .catch(err => {
            alerts[1]({
              error: true,
              message: err?.data?.message,
              state: true,
            });
            handleClose();
          });
  };

  const handleClose = () => {
    setProfileSourceOpen(false);
    editProfileSource[1](null);
  };

  return (
    <Dialog
      open={profileSourceOpen}
      maxWidth="sm"
      TransitionComponent={Transition}
      fullWidth>
      <DialogTitle className={classes.dialogTitleContainer}>
        <div className={classes.dialogTitleContainerDiv}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton>
              <ArrowBackIcon style={{ color: '#FFF' }} />
            </IconButton>
            <Typography variant="subtitle1" style={{ color: '#FFF' }}>
              {editProfileSource[0]
                ? 'Update Profile Source'
                : 'Create Profile Source'}
            </Typography>
          </div>
          <IconButton onClick={() => handleClose()}>
            <CloseIcon style={{ color: '#FFF' }} />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        <Typography variant="body1" style={{ padding: '10px 0' }}>
          Profile Source
        </Typography>
        <TextField
          value={createProfileSouceData?.profileSourceName}
          onChange={e => {
            setError();
            setCreateProfileSourceData({
              ...createProfileSouceData,
              profileSourceName: e.target.value,
            });
          }}
          variant="outlined"
          className={classes.select}
          placeholder="Type Here"
          fullWidth
        />
        <FormHelperText>
          <Typography variant="body1" className={classes.helperText}>
            {error}
          </Typography>
        </FormHelperText>
        <span style={{ display: 'flex' }}>
          <Typography variant="body1" style={{ padding: '10px 0' }}>
            Show
          </Typography>
          <Switch
            name="checkedA"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            checked={createProfileSouceData?.visibility ? true : false}
            onChange={e => {
              e.target.checked
                ? setCreateProfileSourceData({
                    ...createProfileSouceData,
                    visibility: 1,
                  })
                : setCreateProfileSourceData({
                    ...createProfileSouceData,
                    visibility: 0,
                  });
            }}
            value={createProfileSouceData?.visibility}
          />
        </span>
      </DialogContent>
      <DialogActions className={classes.dialogActionContainer}>
        <Button
          variant="contained"
          className={classes.dialogActionButton}
          style={{ color: '#000' }}
          size="large"
          onClick={() => handleClose()}>
          Cancel
        </Button>
        <Button
          variant="contained"
          className={classes.dialogActionButton}
          color="primary"
          size="large"
          onClick={() => handleCreateProfileSource()}>
          {editProfileSource[0] ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const LoadingLine = () => {
  return (
    <div>
      <CircularProgress />
    </div>
  );
};

const ProfileSource = () => {
  const classes = useStyles();
  const [profileSourceOpen, setProfileSourceOpen] = useState(false);
  const [profileSourceData, setProfileSourceData] = useState(null);
  const { authState } = useContext(UserContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNo, setPageNo] = useState(0);
  const [deleteProfileSource, setDeleteProfileSource] = useState([]);
  const deleteDialog = useState(false);
  const { alerts } = useContext(SnackBarContext);
  const [totalRecord, setTotalRecord] = useState(0);
  const editProfileSource = useState(null);
  const [onHover, setOnHover] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const data = {
      sort_by: 'ASC',
      search: searchTerm,
      user_account_id: authState[0].user_account_id,
      page_no: pageNo,
      limit: pageLimit,
    };
    if (!profileSourceOpen)
      api({
        path: '/admin/profile-source/listProfileSource',
        data: data,
      })
        .then(res => {
          if (res.data.hasError) {
            alerts[1]({ error: true, message: res.data.message, state: true });
            setLoading(false);
            return;
          }
          !isEmpty(res.data.data) && setProfileSourceData(res.data.data);
          setTotalRecord(res.data.totalRecord);
          setLoading(false);
        })
        .catch(err => {
          alerts[1]({
            error: true,
            message: 'Something went wrong',
            state: true,
          });
          setLoading(false);
        });
  }, [profileSourceOpen, searchTerm, authState, pageNo, pageLimit, alerts]);

  const removeProfileSource = () => {
    const data = { profie_src_ids: deleteProfileSource };
    api({
      path: '/admin/profile-source/deleteProfileSource',
      data: data,
    })
      .then(res => {
        deleteDialog[1](false);
        if (res.data.hasError) {
          alerts[1]({ error: true, message: res.data.message, state: true });
        }
        alerts[1]({ error: false, message: res.data.message, state: true });
        setProfileSourceData(
          profileSourceData?.filter(
            item => deleteProfileSource.indexOf(item.id) < 0
          )
        );
        setDeleteProfileSource([]);
      })
      .catch(err => console.log('ERR', err));
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const deleteAllProfileSource = profileSourceData?.map(n => n.id);
      setDeleteProfileSource(deleteAllProfileSource);
      return;
    }
    setDeleteProfileSource([]);
  };

  const handleChange = (e, id) => {
    e.target.checked
      ? setDeleteProfileSource([...deleteProfileSource, id])
      : setDeleteProfileSource(deleteProfileSource.filter(item => item !== id));
  };

  const Row = ({ row }) => {
    return (
      <>
        <TableRow
          hover
          align="left"
          style={{ height: 55 }}
          onMouseOver={() => setOnHover(row?.id)}
          onMouseLeave={() => setOnHover(0)}
          key={row?.id}>
          <TableCell width="3%" style={{ borderBottom: 0 }}>
            <Checkbox
              key={Math.random()}
              checked={
                deleteProfileSource?.findIndex(item => item === row?.id) !== -1
                  ? true
                  : false
              }
              onChange={e => handleChange(e, row?.id)}
            />
          </TableCell>
          <TableCell className={classes.tablecell} width="50%">
            <Typography variant="body1" style={{ marginLeft: 10 }}>
              {row?.profile_source_name}
            </Typography>
          </TableCell>
          <TableCell className={classes.tablecell} colSpan={2}>
            {onHover === row?.id && (
              <div className={classes.actionBtn}>
                <IconButton
                  className={classes.iconBtn}
                  onClick={() => handleEditProfileSource(row)}>
                  <EditIcon />
                </IconButton>
                <IconButton
                  className={classes.iconBtn}
                  onClick={() => {
                    deleteDialog[1](true);
                  }}>
                  <DeleteOutlineIcon />
                </IconButton>
              </div>
            )}
          </TableCell>
        </TableRow>
      </>
    );
  };

  const handleChangeRowsPerPage = event => {
    setPageLimit(parseInt(event.target.value, 10));
    setPageNo(0);
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const handleEditProfileSource = data => {
    editProfileSource[1](data);
    setProfileSourceOpen(true);
  };

  return (
    <Container maxWidth="xl" disableGutters>
      <div className={classes.root}>
        <div className={classes.leftContainer}>
          <Paper elevation={5} className={classes.tableContainer}>
            <div className={classes.titleRow}>
              <div className={classes.titleDiv}>
                <Typography variant="subtitle1">Profile Source</Typography>
                <Typography variant="body1">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Quisquam, nobis.
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  padding: '0px 10px',
                }}>
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right',
                    paddingBottom: 10,
                  }}>
                  <Button
                    color="primary"
                    onClick={() => setProfileSourceOpen(true)}
                    style={{ fontWeight: 600 }}>
                    Create
                  </Button>
                </div>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Search here"
                  fullWidth
                  onInput={e => setSearchTerm(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: '#707070' }} />
                      </InputAdornment>
                    ),
                  }}
                  style={{
                    minWidth: 300,
                    alignSelf: 'center',
                    backgroundColor: '#FFF',
                    marginRight: 15,
                  }}
                />
              </div>
            </div>

            <div className={classes.table}>
              <TableContainer className={classes.container}>
                <Table>
                  <TableHead>
                    <TableRow
                      align="center"
                      style={{ backgroundColor: '#f1f1f1' }}>
                      <TableCell
                        variant="head"
                        className={classes.tableHead}
                        colSpan={3}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          <Checkbox
                            indeterminate={
                              deleteProfileSource.length !== 0 &&
                              deleteProfileSource.length !==
                                profileSourceData?.length
                                ? true
                                : false
                            }
                            onChange={handleSelectAllClick}
                          />
                          <IconButton
                            edge="start"
                            onClick={() => deleteDialog[1](true)}>
                            <DeleteOutlineIcon className={classes.icon} />
                          </IconButton>
                        </div>
                      </TableCell>
                      <TableCell
                        variant="head"
                        className={classes.tableHead}
                        colSpan={4}
                        align="justify">
                        <TablePagination
                          labelRowsPerPage="Show"
                          className={classes.tablePagination}
                          component="div"
                          count={totalRecord}
                          rowsPerPage={pageLimit}
                          page={pageNo}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={e => handleChangeRowsPerPage(e)}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="40%" variant="head" colSpan={7}>
                        <div
                          className={classes.inlineContainer}
                          style={{ marginLeft: 10 }}>
                          <Typography
                            variant="subtitle1"
                            style={{ fontWeight: 'normal' }}>
                            SORT BY :
                          </Typography>
                          <TextField
                            defaultValue="placeholder"
                            InputProps={{ disableUnderline: true }}
                            select
                            style={{ marginLeft: 10, paddingTop: 1 }}>
                            <MenuItem value="placeholder">DEFAULT</MenuItem>
                            <MenuItem value="A">Ascending</MenuItem>
                            <MenuItem value="B">Descending</MenuItem>
                          </TextField>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableCell align="center" colSpan={7}>
                        <LoadingLine />
                      </TableCell>
                    ) : profileSourceData ? (
                      profileSourceData?.map(row => (
                        <Row key={row?.id} row={row} />
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={5}>
                          <Typography variant="subtitle1">
                            No Data Found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Paper>
        </div>
      </div>
      <CreateProfileSourceDialog
        profileSourceOpen={profileSourceOpen}
        setProfileSourceOpen={setProfileSourceOpen}
        editProfileSource={editProfileSource}
        authState={authState}
        alerts={alerts}
      />
      <ConfirmationDialogs open={deleteDialog} onClick={removeProfileSource} />
    </Container>
  );
};

export default ProfileSource;
