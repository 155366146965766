import {
  Button,
  Container,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import GuestHeader from '../shared/GuestHeader';
import JobDetailCard from '../shared/JobDetailCard';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import SidebarFilter from '../shared/SidebarFilter';
import JobDetailsCard from '../shared/JobDetailsCard';
import FilterHeader from '../shared/FilterHeader';
import clsx from 'clsx';
import api from '../../api/api';
import { formatDistance } from 'date-fns';

const useStyles = makeStyles(theme => ({
  gridLeftContainer: {
    marginTop: 40,
    border: '1px solid #00000029',
    borderRadius: 4,
  },
  titleCard: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '25px 10px',
    borderBottom: '1px solid #00000029',
  },
  applyNowDiv: {
    display: 'flex',
    marginBottom: 15,
    alignItems: 'center',
  },
  //right Side

  preferedLocationDiv: {
    border: '1px solid #00000032',
    padding: 15,
  },
}));

function JobList(props) {
  const classes = useStyles();
  const [jobListData, setJobListData] = React.useState([]);
  const [detailView, setDetailView] = React.useState(false);
  const [detailViewData, setDetailViewData] = React.useState({});

  useEffect(() => {
    api({
      path: `/candidate/getJobListForGuest`,
      data: {
        limit: '10',
        page_no: '0',
        search_param: '',
        jobTitle: [],
        jobSkill: [],
        location: '',
      },
    })
      .then(res => {
        console.log('response', res);
        if (res.data.hasError) {
          // setErrorMessage(res.data.message);
          return;
        } else {
          setJobListData(res.data.data);
        }
      })
      .catch(err => {
        console.log('errr', err);
      });
    // setJobListData(demoData);
  }, []);

  return (
    <div>
      <GuestHeader />
      {detailView && <FilterHeader />}
      <Container>
        <Grid container spacing={detailView ? 0 : 4}>
          <Grid
            item
            xs={detailView ? 4 : 9}
            className={clsx(classes.gridLeftContainer, 'no_Scrollbar')}
            style={
              detailView
                ? {
                    padding: '0px',
                    marginTop: 0,
                    maxHeight: '100vh',
                    overflow: 'scroll',
                  }
                : { padding: '16px !important' }
            }>
            {detailView ? (
              <div className={classes.titleCard} style={{ paddingLeft: 20 }}>
                <div>
                  <Typography variant="subtitle1">UI & UX Designer</Typography>
                  <Typography variant="body1">1235 results</Typography>
                </div>
              </div>
            ) : (
              <div className={classes.titleCard}>
                <div>
                  <Typography variant="h5">5000+ Job Available</Typography>
                  <Typography variant="body1">
                    Lorem ipsum demo content here available
                  </Typography>
                </div>
                <div>
                  <div className={classes.applyNowDiv}>
                    <Typography variant="body1" style={{ marginRight: 10 }}>
                      You can select upto 5 jobs to apply
                    </Typography>
                    <Button variant="contained" color="primary">
                      APPLY NOW
                    </Button>
                  </div>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search within Results"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <SearchRoundedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
            )}
            {jobListData?.map((item, i) => {
              return (
                <JobDetailCard
                  key={i}
                  position={item.job_title}
                  company={item.company}
                  years={`${
                    item.work_expriences_min ? item.work_expriences_min : 0
                  }-${
                    item.work_expriences_max ? item.work_expriences_max : 0
                  } years`}
                  salary={
                    item.annual_ctc_visibility === 1 ||
                    item.annual_ctc_visibility === '1'
                      ? item.annual_ctc_max
                        ? item.annual_ctc_max
                        : 'Not Disclosed'
                      : 'Not Disclosed'
                  }
                  location={item.city}
                  skills={item.keywords ? item.keywords.split(',') : []}
                  desc={item.job_details}
                  createOn={
                    item.created_at
                      ? formatDistance(new Date(item.created_at), new Date(), {
                          addSuffix: true,
                        })
                      : ''
                  }
                  OnClickApply={() => {
                    console.log('Apply click');
                    // setDetailView(true);
                  }}
                  OnDivClick={() => {
                    console.log('div click');
                    setDetailView(prev => !prev);
                    setDetailViewData(item);
                  }}
                  detailView={detailView}
                />
              );
            })}
          </Grid>
          <Grid
            item
            xs={detailView ? 8 : 3}
            className={clsx(classes.gridRightContainer, 'no_Scrollbar')}
            style={
              detailView
                ? {
                    border: '1px solid #0000000f',
                    borderRadius: 4,
                    marginTop: 0,
                    maxHeight: '100vh',
                    overflow: 'scroll',
                  }
                : { marginTop: 25 }
            }>
            {!detailView ? (
              <>
                {[0, 1, 2].map(item => (
                  <SidebarFilter />
                ))}
                <div className={classes.preferedLocationDiv}>
                  <Typography variant={'subtitle1'}>Job Titles</Typography>
                  <br />
                  <Typography variant={'body2'} color="primary">
                    + Add Your Preferred Destinations
                  </Typography>
                </div>
              </>
            ) : (
              <JobDetailsCard
                position={detailViewData.job_title}
                company={detailViewData.company}
                yearsExp={`${
                  detailViewData.work_expriences_min
                    ? detailViewData.work_expriences_min
                    : 0
                }-${
                  detailViewData.work_expriences_max
                    ? detailViewData.work_expriences_max
                    : 0
                } years`}
                salary={
                  detailViewData.annual_ctc_visibility === 1 ||
                  detailViewData.annual_ctc_visibility === '1'
                    ? `${
                        detailViewData.annual_ctc_min
                          ? detailViewData.annual_ctc_min
                          : 'Not Disclosed'
                      }-${
                        detailViewData.annual_ctc_max
                          ? detailViewData.annual_ctc_max
                          : 'Not Disclosed'
                      } L.P.A`
                    : 'Not Disclosed'
                }
                city={detailViewData.city}
                keywords={
                  detailViewData.keywords
                    ? detailViewData.keywords.split(',')
                    : []
                }
                jobDetails={detailViewData.job_details}
                country={detailViewData.country}
                vacancies={detailViewData.no_of_vacancies}
                employmentType={detailViewData.employment_type}
                reportingTo={detailViewData.reporting_to}
                reportees={detailViewData.reportees}
                desiredCandidateProfile={
                  detailViewData.desired_candidate_profile
                }
                keyPerformancesIndicatores={
                  detailViewData.key_performances_indicatores
                }
                industry={detailViewData.industry}
                functionalArea={detailViewData.functional_area}
                referenceCode={
                  detailViewData.reference_code
                    ? detailViewData.reference_code.split(',')
                    : []
                }
                hiringFor={detailViewData.hiring_for}
                doctorate={detailViewData.doctorate}
                graduation={detailViewData.graduation}
                postGraduation={detailViewData.postGraduation}
                createOn={
                  detailViewData.created_at
                    ? formatDistance(
                        new Date(detailViewData.created_at),
                        new Date(),
                        { addSuffix: true }
                      )
                    : ''
                }
                contactPName={`${
                  detailViewData.first_name ? detailViewData.first_name : ''
                } ${detailViewData.last_name ? detailViewData.last_name : ''}`}
                contactPMobile={detailViewData.contact_details_to_jobseekers}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default JobList;
