import React, { useState, useEffect, useContext } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';

import {
  Container,
  InputAdornment,
  Dialog,
  Button,
  IconButton,
  Paper,
  TableContainer,
  TableRow,
  Table,
  TableCell,
  TableBody,
  Typography,
  TextField,
  Slide,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableHead,
  FormHelperText,
  CircularProgress,
} from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';

import api from '../../api/api';
import UserContext from '../../Context/UserContext';
import SnackBarContext from '../../Context/SnackBarContext';
import ConfirmationDialogs from './ConfirmationDialog';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    leftContainer: {
      width: '100%',
    },

    tableContainer: {
      marginTop: 20,
      border: '1px solid #0000004D',
      borderRadius: 4,
    },
    titleRow: {
      minHeight: 130,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    tablecell: {
      paddingLeft: 0,
      borderBottom: 0,
    },
    tableHead: {
      padding: 0,
    },
    container: {
      marginTop: 25,
    },
    titleDiv: {
      paddingLeft: 40,
    },
    icon: {
      color: '#6f6f6f',
      padding: '0px 5px 0px 15px',
    },
    actionBtn: {
      display: 'flex',
      alignItems: 'center',
      marginRight: 5,
    },
    iconBtn: {
      padding: 2,
    },
  })
);

const useStylesDialog = makeStyles(theme =>
  createStyles({
    dialogTitleContainer: {
      backgroundColor: theme.palette.ternary.main,
      padding: '10px 15px',
    },
    dialogTitleContainerDiv: {
      display: 'flex',
      alignItems: 'space-between',
      justifyContent: 'space-between',
      width: '100%',
    },

    dialogActionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 10,
    },
    dialogActionButton: {
      margin: '5px 13px',
      color: '#FFF',
      padding: '10px 25px',
    },

    select: {
      marginTop: 10,
    },
    helperText: {
      color: 'red',
      marginLeft: 5,
    },
  })
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateSkillDialog = ({
  attributeDialogOpen,
  onClickFunc,
  error,
  setError,
  editAttribute,
  handleClose,
}) => {
  const classes = useStylesDialog();
  const [attributeName, setAttributeName] = useState('');

  useEffect(() => {
    setAttributeName(editAttribute?.attribute_name);
  }, [editAttribute, attributeDialogOpen]);

  return (
    <Dialog
      open={attributeDialogOpen}
      maxWidth="sm"
      TransitionComponent={Transition}
      fullWidth>
      <DialogTitle className={classes.dialogTitleContainer}>
        <div className={classes.dialogTitleContainerDiv}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton>
              <ArrowBackIcon style={{ color: '#FFF' }} fontSize="medium" />
            </IconButton>
            <Typography variant="subtitle1" style={{ color: '#FFF' }}>
              {editAttribute ? 'Update Attributes' : 'Create Attributes'}
            </Typography>
          </div>

          <IconButton onClick={() => handleClose()}>
            <CloseIcon style={{ color: '#FFF' }} fontSize="medium" />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">Attribute Name</Typography>
        <TextField
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          variant="outlined"
          className={classes.select}
          onChange={e => {
            setAttributeName(e.target.value);
            setError('');
          }}
          placeholder="Type Here"
          fullWidth
          value={attributeName}
        />
        {error && (
          <FormHelperText>
            <Typography variant="body1" className={classes.helperText}>
              {error}
            </Typography>
          </FormHelperText>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActionContainer}>
        <Button
          variant="contained"
          className={classes.dialogActionButton}
          style={{ color: '#000' }}
          size="large"
          onClick={() => handleClose()}>
          Cancel
        </Button>

        <Button
          variant="contained"
          className={classes.dialogActionButton}
          color="primary"
          size="large"
          onClick={() =>
            editAttribute
              ? onClickFunc(attributeName, editAttribute?.id)
              : onClickFunc(attributeName)
          }>
          {editAttribute ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ManageAttribute = () => {
  const classes = useStyles();
  const [attributeDialogOpen, setAttributeDialogOpen] = useState(false);
  const [attributeData, setAttributeData] = useState(null);
  const { authState } = useContext(UserContext);
  const { alerts } = useContext(SnackBarContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState('');
  const [onHover, setOnHover] = useState(0);
  const [editAttribute, setEditAttribute] = useState(null);
  const [deleteAttribute, setDeleteAttribute] = useState(null);
  const deleteDialog = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const data = { sort_by: 'ASC', search: searchTerm };
    if (!attributeDialogOpen)
      api({ path: '/admin/attribute/getAttribute', data: data })
        .then(res => {
          !isEmpty(res.data.data) && setAttributeData(res.data.data);
          setLoading(false);
        })
        .catch(err => {
          alerts[1]({
            error: true,
            message: 'Something went wrong',
            state: true,
          });
          setLoading(false);
        });
  }, [attributeDialogOpen, searchTerm, alerts]);

  const handleClose = () => {
    setAttributeDialogOpen(false);
    setEditAttribute(null);
    setError('');
  };

  const createAttribute = (attributeName, id = undefined) => {
    if (!attributeName || attributeName === '') {
      setError('Please enter attribute name');
      return;
    }

    const attributeData = {
      admin_id: authState[0].admin_id?.toString(),
      user_account_id: authState[0].user_account_id?.toString(),
      attribute_name: attributeName,
      attribute_id: id,
    };

    id
      ? api({
          path: '/admin/attribute/editAttribute',
          data: attributeData,
        }).then(res => {
          if (res.data.hasError) {
            alerts[1]({ error: true, message: res.data.message, state: true });
          }
          handleClose();
          alerts[1]({ error: false, message: res.data.message, state: true });
        })
      : api({
          path: '/admin/attribute/createAttribute',
          data: attributeData,
        }).then(res => {
          if (res.data.hasError) {
            alerts[1]({ error: true, message: res.data.message, state: true });
          }
          handleClose();
          alerts[1]({ error: false, message: res.data.message, state: true });
        });
  };

  const removeAttribute = () => {
    const data = { attrbute_ids: deleteAttribute };

    api({
      path: '/admin/attribute/deleteAttribute',
      data: data,
    })
      .then(res => {
        if (res.data.hasError) {
          alerts[1]({ error: true, message: res.data.message, state: true });
        }
        deleteDialog[1](false);
        alerts[1]({ error: false, message: res.data.message, state: true });
        setAttributeData(
          attributeData.filter(item => deleteAttribute.indexOf(item.id) < 0)
        );
      })
      .catch(err => console.log('ERR', err));
  };

  const Row = ({ row }) => {
    return (
      <>
        <TableRow
          hover
          align="left"
          style={{ height: 60 }}
          onMouseOver={() => setOnHover(row?.id)}
          onMouseLeave={() => setOnHover(0)}
          key={row?.id}>
          <TableCell align="left" style={{ borderBottom: 0 }}></TableCell>
          <TableCell align="left" className={classes.tablecell}>
            <Typography variant="body1">{row?.attribute_name}</Typography>
          </TableCell>
          <TableCell align="left" className={classes.tablecell}>
            <Typography variant="body1" style={{ marginLeft: 5 }}>
              {row?.id}
            </Typography>
          </TableCell>
          <TableCell style={{ borderBottom: 0 }}>
            {onHover === row?.id && (
              <div className={classes.actionBtn}>
                <IconButton
                  className={classes.iconBtn}
                  onClick={() => handleEditAttribute(row)}>
                  <EditIcon />
                </IconButton>
                <IconButton
                  className={classes.iconBtn}
                  onClick={e => {
                    setDeleteAttribute([row?.id]);
                    deleteDialog[1](true);
                  }}>
                  <DeleteOutlineIcon />
                </IconButton>
              </div>
            )}
          </TableCell>
        </TableRow>
      </>
    );
  };

  const handleEditAttribute = data => {
    setEditAttribute(data);
    setAttributeDialogOpen(true);
  };

  const LoadingLine = () => {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  };

  return (
    <Container maxWidth="xl" disableGutters>
      <div className={classes.root}>
        <div className={classes.leftContainer}>
          <Paper elevation={5} className={classes.tableContainer}>
            <div className={classes.titleRow}>
              <div className={classes.titleDiv}>
                <Typography variant="subtitle1">Attribute</Typography>
                <Typography variant="body1">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Quisquam, nobis.
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  padding: '0px 10px',
                }}>
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right',
                    paddingBottom: 10,
                  }}>
                  <Button
                    color="primary"
                    onClick={() => setAttributeDialogOpen(true)}
                    style={{ fontWeight: 600 }}>
                    Create
                  </Button>
                </div>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Search here"
                  fullWidth
                  onInput={e => {
                    setSearchTerm(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <SearchIcon style={{ color: '#707070' }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  style={{
                    minWidth: 300,
                    alignSelf: 'center',
                    backgroundColor: '#FFF',
                    marginRight: 15,
                  }}
                />
              </div>
            </div>

            <div className={classes.table}>
              <TableContainer className={classes.container}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell width="3%" />
                      <TableCell
                        width="40%"
                        variant="head"
                        className={classes.tableHead}
                        align="left">
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: 'bold' }}>
                          Attributes
                        </Typography>
                      </TableCell>
                      <TableCell
                        width="40%"
                        variant="head"
                        className={classes.tableHead}
                        align="left">
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: 'bold' }}>
                          Index
                        </Typography>
                      </TableCell>
                      <TableCell width="10%" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableCell align="center" colspan={7}>
                        <LoadingLine />
                      </TableCell>
                    ) : attributeData ? (
                      attributeData?.map(row => <Row key={row?.id} row={row} />)
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={5}>
                          <Typography variant="subtitle1">
                            No Data Found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Paper>
        </div>
      </div>
      <CreateSkillDialog
        attributeDialogOpen={attributeDialogOpen}
        setAttributeDialogOpen={setAttributeDialogOpen}
        onClickFunc={createAttribute}
        error={error}
        setError={setError}
        editAttribute={editAttribute}
        handleClose={handleClose}
      />
      <ConfirmationDialogs open={deleteDialog} onClick={removeAttribute} />
    </Container>
  );
};

export default ManageAttribute;
