import React, { useState } from 'react';
import {
  makeStyles,
  Typography,
  Switch,
  TextField,
  Grid,
  Container,
  withStyles,
  Paper,
  Collapse,
  IconButton,
  InputAdornment,
  Radio,
  FormControlLabel,
  FormControl,
  RadioGroup,
  MenuItem,
  Checkbox,
  Button,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUpOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import BackupIcon from '@material-ui/icons/BackupOutlined';

import Editor from '../../shared/Editor';
import Image from '../../../assets/images/requirementimage.png';

const useStyles = makeStyles(theme => ({
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  columnOptionContainer: {
    marginLeft: 60,
  },
  columnOption: {
    marginTop: 15,
  },
  smallIcon: {
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
    backgroundColor: 'transparent',
    marginRight: 5,
  },
  sideBarTitle: {
    marginLeft: 15,
  },

  // NOTE:Right content classes

  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
  },
  editor: {
    marginTop: 15,
  },
  subtitle: {
    marginTop: 5,
    fontWeight: 'bold',
  },
  caption: {
    marginTop: 3,
  },
  autoComplete: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] ': {
      paddingTop: 3,
    },
  },
  paper: {
    margin: '10px 0px',
  },
  targetPoolTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#f3f3f3',
    padding: '5px 15px',
    marginBottom: 10,
  },
  formControlLabel: {
    marginRight: 6,
  },
  collapseOption: {
    padding: '15px 15px',
  },
  inlineFieldContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  uploadFileContainer: {
    border: '1px dashed #00000060',
    borderRadius: 4,
    minHeight: 230,
    margin: 10,
  },
}));

const AntSwitch = withStyles(theme => ({
  root: {
    width: 29,
    height: 18,
    padding: 2,
    display: 'flex',
    margin: '0px 10px',
    borderRadius: 5,
  },
  switchBase: {
    padding: 3.5,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const keywords = [
  { title: 'Communication Skills' },
  { title: 'Computer Skills' },
  { title: 'People Skills' },
  { title: 'Leadership Skills' },
  { title: 'Organizational Skills' },
];

const profileSources = [
  { title: 'Linked In' },
  { title: 'Google' },
  { title: 'Shine' },
  { title: 'Naukri Portal' },
];

const StepThreeSidebar = () => {
  const classes = useStyles();

  return (
    <Container>
      <div className={classes.titleRow}>
        <CheckCircleIcon
          style={{ color: '#FFF' }}
          className={classes.smallIcon}
          fontSize="large"
        />
        <Typography
          variant="subtitle1"
          color="textSecondary"
          className={classes.sideBarTitle}>
          Search Strategy
        </Typography>
      </div>
      <div className={classes.columnOptionContainer}>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Brief
        </Typography>

        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Role Dynamics
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Required Candidate Skills
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Target Pool
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Conclusion
        </Typography>
      </div>
    </Container>
  );
};

const StepThreeContent = ({ Controller, useFormContext }) => {
  const { control } = useFormContext();
  const classes = useStyles();
  const [basicDetailsOpen, setBasicDetailsOpen] = useState(false);
  const [searchCandidate, setSearchCandidate] = useState(false);
  const [employementDetails, setEmployementDetails] = useState(false);
  const [additionalFilter, setAdditionalFilter] = useState(false);
  const [affirmativeActions, setAffirmativeActions] = useState(false);
  const [displayOptions, setDisplayOptions] = useState(false);
  return (
    <Container style={{ paddingRight: 5, position: 'relative' }}>
      <Grid container>
        <Grid item xs={6} md container>
          <Grid item xs={12}>
            <div className={classes.leftTitle}>
              <Typography variant="h1">Search Strategy</Typography>
              <Typography variant="body1" className={classes.subtitle}>
                Lorem ipsum dolor sit amet.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <div className={classes.titleContainer}>
                <Typography variant="subtitle1">Brief</Typography>
                <AntSwitch />
                <Typography variant="body1">Add to Job Post</Typography>
              </div>
              <Typography variant="body1" className={classes.caption}>
                Mention all job Roles & responsibilities here
              </Typography>
              <div className={classes.editor}>
                <Editor />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <div className={classes.titleContainer}>
                <Typography variant="subtitle1">Role Dynamics</Typography>
                <AntSwitch />
                <Typography variant="body1">Add to Job Post</Typography>
              </div>
              <Typography variant="body1" className={classes.caption}>
                Lorem ipsum dolor sit.
              </Typography>
              <div className={classes.editor}>
                <Editor />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <div className={classes.titleContainer}>
                <Typography variant="subtitle1">
                  Required candidate skills
                </Typography>
                <AntSwitch />
                <Typography variant="body1">Add to Job Post</Typography>
              </div>
              <Typography variant="body1" className={classes.caption}>
                Lorem ipsum dolor sit amet.
              </Typography>
              <Controller
                control={control}
                name="firstName"
                render={({ field }) => (
                  <Controller
                    control={control}
                    name="firstName"
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        multiple
                        options={keywords}
                        getOptionLabel={option => option.title}
                        renderInput={params => (
                          <TextField
                            id="first-name"
                            variant="outlined"
                            placeholder="Type here"
                            margin="normal"
                            value=""
                            fullWidth
                            {...params}
                          />
                        )}
                        className={classes.autoComplete}
                      />
                    )}
                  />
                )}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div>
              <div className={classes.titleContainer}>
                <Typography variant="subtitle1">Target Pool</Typography>
                <AntSwitch />
                <Typography variant="body1">Add to Job Post</Typography>
              </div>
              <Typography variant="body1" className={classes.caption}>
                Lorem ipsum dolor sit amet.
              </Typography>
              <Paper className={classes.paper}>
                {/* NOTE:Basic Details */}
                <div>
                  <div className={classes.targetPoolTitleContainer}>
                    <Typography variant="subtitle1">Basic Details</Typography>
                    <IconButton
                      onClick={() => setBasicDetailsOpen(!basicDetailsOpen)}>
                      {basicDetailsOpen ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </div>
                  <Collapse
                    in={basicDetailsOpen}
                    className={classes.collapseOption}
                    timeout="auto"
                    unmountOnExit>
                    <TextField
                      id="first-name"
                      variant="outlined"
                      placeholder="Type keywords"
                      margin="normal"
                      value=""
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <Typography variant="subtitle1" color="primary">
                                Expand
                              </Typography>
                            </IconButton>
                            <IconButton>
                              <KeyboardArrowDownIcon color="primary" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="position"
                        name="position"
                        defaultValue="entireResume"
                        style={{
                          marginTop: 3,
                        }}
                        className={classes.inlineFieldContainer}>
                        <div style={{ marginRight: 4 }}>
                          <Typography variant="body1">Search in</Typography>
                        </div>
                        <FormControlLabel
                          value="entireResume"
                          control={<Radio color="primary" />}
                          label="Entire Resume"
                          classes={{ root: classes.formControlLabel }}
                        />
                        <FormControlLabel
                          value="start"
                          control={<Radio color="primary" />}
                          label="Resume Title & Key Skills"
                          classes={{ root: classes.formControlLabel }}
                        />
                        <FormControlLabel
                          value="bottom"
                          control={<Radio color="primary" />}
                          label="Resume Synopsis"
                          classes={{ root: classes.formControlLabel }}
                        />
                        <FormControlLabel
                          value="end"
                          control={<Radio color="primary" />}
                          label="Resume Title"
                          classes={{ root: classes.formControlLabel }}
                        />
                      </RadioGroup>
                    </FormControl>
                    <Typography variant="subtitle1">
                      Total Experiance in Years
                    </Typography>
                    <div style={{ display: 'flex' }}>
                      <Controller
                        control={control}
                        name="firstName"
                        render={({ field }) => (
                          <TextField
                            id="first-name"
                            variant="outlined"
                            placeholder="Minimum Experiance"
                            margin="normal"
                            fullWidth
                            style={{ marginRight: 5 }}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="firstName"
                        render={({ field }) => (
                          <TextField
                            id="first-name"
                            variant="outlined"
                            placeholder="Maximum Experiance"
                            margin="normal"
                            fullWidth
                            style={{ marginLeft: 5 }}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <Typography variant="subtitle1">Annual Salary</Typography>
                    <div style={{ display: 'flex' }}>
                      <Controller
                        control={control}
                        name="firstName"
                        render={({ field }) => (
                          <TextField
                            id="first-name"
                            variant="outlined"
                            placeholder="Minimum Salary"
                            margin="normal"
                            fullWidth
                            defaultValue="rupees"
                            style={{ marginRight: 5, maxWidth: 50 }}
                            {...field}
                            select>
                            <MenuItem value="rupees">₹</MenuItem>
                            <MenuItem value="dollars">$</MenuItem>
                          </TextField>
                        )}
                      />
                      <Controller
                        control={control}
                        name="firstName"
                        render={({ field }) => (
                          <TextField
                            id="first-name"
                            variant="outlined"
                            placeholder="Minimum Salary"
                            margin="normal"
                            fullWidth
                            style={{ marginRight: 5 }}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="firstName"
                        render={({ field }) => (
                          <TextField
                            id="first-name"
                            variant="outlined"
                            placeholder="Maximum Salary"
                            margin="normal"
                            fullWidth
                            style={{ marginLeft: 5 }}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={
                          <CheckBoxIcon fontSize="small" color="primary" />
                        }
                      />
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}>
                        Include profiles who entered zero salary or have not
                        mentioned their CTC
                      </Typography>
                    </div>
                    <Typography variant="subtitle1" style={{ marginTop: 3 }}>
                      Candidate Current Locations:
                    </Typography>
                    <Controller
                      control={control}
                      name="firstName"
                      render={({ field }) => (
                        <TextField
                          id="first-name"
                          variant="outlined"
                          placeholder="Type here"
                          margin="normal"
                          fullWidth
                          style={{ marginLeft: 5 }}
                          {...field}
                        />
                      )}
                    />
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: 'bold',
                        marginLeft: 10,
                        paddingBottom: 15,
                      }}>
                      Specify candidate's preffered location's
                    </Typography>
                  </Collapse>
                </div>

                {/* NOTE: Search Candidates in */}
                <div>
                  <div className={classes.targetPoolTitleContainer}>
                    <Typography variant="subtitle1">
                      Search Candidates In
                    </Typography>
                    <IconButton
                      onClick={() => setSearchCandidate(!searchCandidate)}>
                      {searchCandidate ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </div>
                  <Collapse
                    in={searchCandidate}
                    className={classes.collapseOption}
                    timeout="auto"
                    unmountOnExit>
                    <Typography variant="subtitle1">Requirements</Typography>
                    <TextField
                      id="first-name"
                      variant="outlined"
                      placeholder="Type here"
                      margin="normal"
                      value=""
                      fullWidth
                    />
                    <Typography variant="subtitle1">Status</Typography>
                    <TextField
                      id="first-name"
                      variant="outlined"
                      placeholder="Type here"
                      margin="normal"
                      value=""
                      fullWidth
                    />
                    <Typography variant="subtitle1">Profile Sources</Typography>
                    <Autocomplete
                      multiple
                      options={profileSources}
                      getOptionLabel={option => option.title}
                      renderInput={params => (
                        <TextField
                          id="first-name"
                          variant="outlined"
                          placeholder="Type here"
                          margin="normal"
                          value=""
                          fullWidth
                          {...params}
                        />
                      )}
                      className={classes.autoComplete}
                    />
                    <Typography variant="subtitle1">Comments</Typography>
                    <TextField
                      id="first-name"
                      variant="outlined"
                      placeholder="Type here"
                      margin="normal"
                      value=""
                      fullWidth
                    />
                  </Collapse>
                </div>

                {/* NOTE:Employment Details */}

                <div>
                  <div className={classes.targetPoolTitleContainer}>
                    <Typography variant="subtitle1">
                      Employment Details
                    </Typography>
                    <IconButton
                      onClick={() =>
                        setEmployementDetails(!employementDetails)
                      }>
                      {employementDetails ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </div>
                  <Collapse
                    in={employementDetails}
                    className={classes.collapseOption}
                    timeout="auto"
                    unmountOnExit>
                    <Typography variant="subtitle1">
                      Fucntional Area and Roles
                    </Typography>
                    <div className={classes.inlineFieldContainer}>
                      <TextField
                        id="first-name"
                        variant="outlined"
                        margin="normal"
                        value="Placeholder"
                        fullWidth
                        select
                        style={{ marginRight: 5 }}>
                        <MenuItem value="Placeholder">
                          Choose Functional Area
                        </MenuItem>
                        <MenuItem value="a">A</MenuItem>
                        <MenuItem value="b">B</MenuItem>
                      </TextField>
                      <TextField
                        placeholder="Choose Role"
                        variant="outlined"
                        fullWidth
                        style={{ marginLeft: 5, marginTop: 5 }}
                      />
                    </div>
                    <Typography variant="subtitle1">Industry Type</Typography>
                    <TextField
                      id="first-name"
                      variant="outlined"
                      placeholder="Type here"
                      margin="normal"
                      value=""
                      fullWidth
                    />
                    <Typography variant="subtitle1">Employers:</Typography>
                    <div className={classes.inlineFieldContainer}>
                      <TextField
                        variant="outlined"
                        placeholder="Type here"
                        fullWidth
                      />
                      <TextField
                        variant="outlined"
                        defaultValue="placeholder"
                        select
                        fullWidth
                        style={{ marginLeft: 5 }}>
                        <MenuItem value="placeholder">
                          Current Employer
                        </MenuItem>
                        <MenuItem value="google">Google</MenuItem>
                        <MenuItem value="micro">Microsoft</MenuItem>

                        <MenuItem value="uber">Uber</MenuItem>
                      </TextField>
                      <TextField
                        variant="outlined"
                        fullWidth
                        defaultValue="placeholder"
                        style={{ marginLeft: 5 }}
                        select>
                        <MenuItem value="placeholder">All Words</MenuItem>
                        <MenuItem value="google">A</MenuItem>
                        <MenuItem value="micro">B</MenuItem>

                        <MenuItem value="uber">C</MenuItem>
                      </TextField>
                    </div>

                    <Typography variant="subtitle1">
                      Exclude Employers:
                    </Typography>
                    <div className={classes.inlineFieldContainer}>
                      <TextField
                        variant="outlined"
                        placeholder="Type here"
                        fullWidth
                      />
                      <TextField
                        variant="outlined"
                        defaultValue="placeholder"
                        select
                        fullWidth
                        style={{ marginLeft: 5 }}>
                        <MenuItem value="placeholder">
                          Current Employer
                        </MenuItem>
                        <MenuItem value="google">Google</MenuItem>
                        <MenuItem value="micro">Microsoft</MenuItem>

                        <MenuItem value="uber">Uber</MenuItem>
                      </TextField>
                      <TextField
                        variant="outlined"
                        fullWidth
                        defaultValue="placeholder"
                        style={{ marginLeft: 5 }}
                        select>
                        <MenuItem value="placeholder">All Words</MenuItem>
                        <MenuItem value="google">A</MenuItem>
                        <MenuItem value="micro">B</MenuItem>

                        <MenuItem value="uber">C</MenuItem>
                      </TextField>
                    </div>
                    <Typography variant="subtitle1">Designation:</Typography>
                    <div className={classes.inlineFieldContainer}>
                      <TextField
                        variant="outlined"
                        placeholder="Type here"
                        fullWidth
                      />
                      <TextField
                        variant="outlined"
                        defaultValue="placeholder"
                        select
                        fullWidth
                        style={{ marginLeft: 5 }}>
                        <MenuItem value="placeholder">
                          Current Employer
                        </MenuItem>
                        <MenuItem value="google">Google</MenuItem>
                        <MenuItem value="micro">Microsoft</MenuItem>

                        <MenuItem value="uber">Uber</MenuItem>
                      </TextField>
                      <TextField
                        variant="outlined"
                        fullWidth
                        defaultValue="placeholder"
                        style={{ marginLeft: 5 }}
                        select>
                        <MenuItem value="placeholder">All Words</MenuItem>
                        <MenuItem value="google">A</MenuItem>
                        <MenuItem value="micro">B</MenuItem>

                        <MenuItem value="uber">C</MenuItem>
                      </TextField>
                    </div>

                    <Typography variant="subtitle1">Notice Period</Typography>
                    <TextField
                      id="first-name"
                      variant="outlined"
                      placeholder="Choose Functional Area"
                      margin="normal"
                      defaultValue="Placehoder"
                      fullWidth
                      select>
                      <MenuItem value="Placehoder">Select In Months</MenuItem>
                      <MenuItem value={1}>1 Month</MenuItem>
                      <MenuItem value={2}>2 Month</MenuItem>
                      <MenuItem value={3}>3 Month</MenuItem>
                      <MenuItem value={4}>4 Month</MenuItem>
                      <MenuItem value={5}>5 Month</MenuItem>
                      <MenuItem value={6}>6 Month</MenuItem>
                    </TextField>
                  </Collapse>
                </div>

                {/* TODO: Educational Details */}

                {/* NOTE:Additional Filters */}
                <div>
                  <div className={classes.targetPoolTitleContainer}>
                    <Typography variant="subtitle1">
                      Additional Filters
                    </Typography>
                    <IconButton
                      onClick={() => setAdditionalFilter(!additionalFilter)}>
                      {additionalFilter ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </div>
                  <Collapse
                    in={additionalFilter}
                    className={classes.collapseOption}
                    timeout="auto"
                    unmountOnExit>
                    <Typography variant="subtitle1">
                      Resume Processed By:
                    </Typography>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="position"
                        name="position"
                        defaultValue="entireResume"
                        style={{
                          marginTop: 3,
                        }}
                        className={classes.inlineFieldContainer}>
                        <FormControlLabel
                          value="entireResume"
                          control={<Radio color="primary" />}
                          label="Anyone"
                        />
                        <FormControlLabel
                          value="start"
                          control={<Radio color="primary" />}
                          label="Me"
                        />
                        <FormControlLabel
                          value="bottom"
                          control={<Radio color="primary" />}
                          label="Specific"
                        />
                      </RadioGroup>
                    </FormControl>

                    <Typography variant="subtitle1">Star Rating</Typography>
                    <div className={classes.inlineFieldContainer}>
                      <TextField
                        id="first-name"
                        variant="outlined"
                        placeholder="From"
                        margin="normal"
                        value=""
                        fullWidth
                        style={{ marginRight: 5 }}
                      />
                      <TextField
                        id="first-name"
                        variant="outlined"
                        placeholder="To"
                        margin="normal"
                        value=""
                        fullWidth
                        style={{ marginLeft: 5 }}
                      />
                    </div>
                    <Typography variant="subtitle1">Candidate Age:</Typography>
                    <div className={classes.inlineFieldContainer}>
                      <TextField
                        id="first-name"
                        variant="outlined"
                        placeholder="From"
                        margin="normal"
                        value=""
                        fullWidth
                        style={{ marginRight: 5 }}
                      />
                      <TextField
                        id="first-name"
                        variant="outlined"
                        placeholder="To"
                        margin="normal"
                        value=""
                        fullWidth
                        style={{ marginLeft: 5 }}
                      />
                    </div>
                    <Typography variant="subtitle1">
                      Work Status For:
                    </Typography>
                    <div className={classes.inlineFieldContainer}>
                      <TextField
                        id="first-name"
                        variant="outlined"
                        defaultValue="Placeholder"
                        margin="normal"
                        fullWidth
                        style={{ marginRight: 5 }}
                        select>
                        <MenuItem value="Placeholder">Countries</MenuItem>
                        <MenuItem value="in">India</MenuItem>
                        <MenuItem value="us">USA</MenuItem>
                      </TextField>
                      <TextField
                        id="first-name"
                        variant="outlined"
                        placeholder="Enter Visa Type"
                        margin="normal"
                        value=""
                        fullWidth
                        style={{ marginLeft: 5 }}
                      />
                    </div>
                  </Collapse>
                </div>
                {/* NOTE:Affirmative Actions */}
                <div>
                  <div className={classes.targetPoolTitleContainer}>
                    <Typography variant="subtitle1">
                      Affirmative Actions
                    </Typography>
                    <IconButton
                      onClick={() =>
                        setAffirmativeActions(!affirmativeActions)
                      }>
                      {affirmativeActions ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </div>
                  <Collapse
                    in={affirmativeActions}
                    className={classes.collapseOption}
                    timeout="auto"
                    unmountOnExit>
                    <TextField
                      variant="outlined"
                      defaultValue="placeholder"
                      fullWidth
                      select>
                      <MenuItem value="placeholder">Select from list</MenuItem>
                      <MenuItem value="A">A</MenuItem>
                      <MenuItem value="B">B</MenuItem>
                      <MenuItem value="C">C</MenuItem>
                    </TextField>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 5,
                      }}>
                      <Checkbox />{' '}
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}>
                        Search women candidates only
                      </Typography>
                    </div>
                  </Collapse>
                </div>

                {/* NOTE:Display Options */}

                <div>
                  <div className={classes.targetPoolTitleContainer}>
                    <Typography variant="subtitle1">Display Options</Typography>
                    <IconButton
                      onClick={() => setDisplayOptions(!displayOptions)}>
                      {displayOptions ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </div>
                  <Collapse
                    in={displayOptions}
                    className={classes.collapseOption}
                    timeout="auto"
                    unmountOnExit>
                    <FormControl component="fieldset">
                      <Typography variant="subtitle1">
                        Show Candidates Seeking:
                      </Typography>
                      <RadioGroup
                        row
                        aria-label="position"
                        name="position"
                        defaultValue="entireResume"
                        style={{
                          marginTop: 3,
                        }}
                        className={classes.inlineFieldContainer}>
                        <FormControlLabel
                          value="entireResume"
                          control={<Radio color="primary" />}
                          label="Any Job"
                          classes={{ root: classes.formControlLabel }}
                        />
                        <FormControlLabel
                          value="start"
                          control={<Radio color="primary" />}
                          label="Permanent Job"
                          classes={{ root: classes.formControlLabel }}
                        />
                        <FormControlLabel
                          value="bottom"
                          control={<Radio color="primary" />}
                          label="Temporary/Contract Job"
                          classes={{ root: classes.formControlLabel }}
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl component="fieldset">
                      <Typography variant="subtitle1">
                        Employment Type:
                      </Typography>
                      <RadioGroup
                        row
                        aria-label="position"
                        name="position"
                        defaultValue="entireResume"
                        style={{
                          marginTop: 3,
                        }}
                        className={classes.inlineFieldContainer}>
                        <FormControlLabel
                          value="entireResume"
                          control={<Radio color="primary" />}
                          label="Any"
                          classes={{ root: classes.formControlLabel }}
                        />
                        <FormControlLabel
                          value="start"
                          control={<Radio color="primary" />}
                          label="Full Time"
                          classes={{ root: classes.formControlLabel }}
                        />
                        <FormControlLabel
                          value="bottom"
                          control={<Radio color="primary" />}
                          label="Part Time"
                          classes={{ root: classes.formControlLabel }}
                        />
                      </RadioGroup>
                    </FormControl>
                    <Typography variant="subtitle1">
                      Show candidates only with
                    </Typography>
                    <div style={{ display: 'flex' }}>
                      <span className={classes.inlineFieldContainer}>
                        <Checkbox />
                        <Typography>Verified e-mail id</Typography>
                      </span>
                      <span className={classes.inlineFieldContainer}>
                        <Checkbox />
                        <Typography>Attached Resume</Typography>
                      </span>
                    </div>

                    <Typography variant="subtitle1">Sort By:</Typography>
                    <TextField
                      variant="outlined"
                      defaultValue="Relevance"
                      fullWidth
                      select>
                      <MenuItem value="Relevance">Relevance</MenuItem>
                      <MenuItem value="Latest">Latest</MenuItem>
                    </TextField>
                  </Collapse>
                </div>
              </Paper>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.titleContainer}>
              <Typography variant="subtitle1">Conclusion</Typography>
              <AntSwitch />
              <Typography variant="body1">Add to Job Post</Typography>
            </div>
            <Typography variant="body1">Lorem ipsum dolor sit amet.</Typography>
            <div className={classes.editor}>
              <Editor />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ textAlign: 'center' }}
            className={classes.uploadFileContainer}>
            <IconButton>
              <BackupIcon style={{ fontSize: 70 }} />
            </IconButton>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: 'normal' }}
              align="center">
              Click here to browse or <br /> drag and drop a file
            </Typography>
            <Button
              style={{ padding: '15px 55px', margin: '10px 5px' }}
              variant="contained">
              <label for="file">
                <Typography variant="subtitle1">Browse</Typography>
              </label>
            </Button>
            <input type="file" id="file" style={{ display: 'none' }} />
            <Typography style={{ marginBottom: 5 }} variant="body1">
              File Can be an image, doc, audio or video format. The maximum
              file-size is 10MB
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <div>
            <img
              src={Image}
              alt="right"
              height="18%"
              width="42%"
              style={{ position: 'absolute', right: 0, bottom: -56 }}
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export { StepThreeSidebar, StepThreeContent };
