import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Switch,
  Typography,
  IconButton,
  Slide,
  Grid,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import BackupIcon from '@material-ui/icons/Backup';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStylesDialog = makeStyles(theme =>
  createStyles({
    dialogTitleContainer: {
      backgroundColor: theme.palette.ternary.main,
      padding: '10px 15px',
    },
    dialogTitleContainerDiv: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },

    dialogActionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 10,
    },
    dialogActionButton: {
      margin: '5px 13px',
      color: '#FFF',
      padding: '10px 25px',
    },

    uploadFileContainer: {
      border: '1px solid #00000060',
      borderRadius: 4,
      minHeight: 230,
    },

    switchContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '5px 0px',
      padding: '0px 5px',
    },
  })
);

const ImportCandidatesDialog = ({ importDialogOpen }) => {
  const classes = useStylesDialog();
  return (
    <Dialog
      open={importDialogOpen[0]}
      maxWidth="sm"
      TransitionComponent={Transition}
      fullWidth>
      <DialogTitle className={classes.dialogTitleContainer}>
        <div className={classes.dialogTitleContainerDiv}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton>
              <ArrowBackIcon style={{ color: '#FFF' }} />
            </IconButton>
            <Typography variant="subtitle1" style={{ color: '#FFF' }}>
              Create Candidate
            </Typography>
          </div>
          <IconButton onClick={() => importDialogOpen[1](false)}>
            <CloseIcon style={{ color: '#FFF' }} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} className={classes.uploadFileContainer}>
            <div style={{ textAlign: 'center' }}>
              <IconButton>
                <BackupIcon style={{ fontSize: 70 }} />
              </IconButton>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: 'normal' }}
                align="center">
                Click here to browse or <br /> drag and drop a file
              </Typography>
              <Button
                style={{ padding: '15px 55px', margin: '10px 5px' }}
                variant="contained">
                <label for="file">
                  <Typography variant="subtitle1">Browse</Typography>
                </label>
              </Button>
              <input type="file" id="file" style={{ display: 'none' }} />
              <Typography style={{ marginBottom: 5 }} variant="body1">
                File must be an image format. The maximum file-size is 10 MB{' '}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} container sm>
            <Grid item xs={12} sm={12} style={{ marginTop: 15 }}>
              <Typography variant="subtitle1">Export Users</Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.switchContainer}>
              <Typography variant="body1">Name</Typography>
              <Switch />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.switchContainer}>
              <Typography variant="body1">Dashboard</Typography>
              <Switch />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.switchContainer}>
              <Typography variant="body1">Email</Typography>
              <Switch />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.switchContainer}>
              <Typography variant="body1">Requirements</Typography>
              <Switch />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.switchContainer}>
              <Typography variant="body1">Contact No</Typography>
              <Switch />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.switchContainer}>
              <Typography variant="body1">Profile</Typography>
              <Switch />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.switchContainer}>
              <Typography variant="body1">Company Name</Typography>
              <Switch />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.switchContainer}>
              <Typography variant="body1">Reports</Typography>
              <Switch />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.switchContainer}>
              <Typography variant="body1">Country</Typography>
              <Switch />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.switchContainer}>
              <Typography variant="body1">Manage User</Typography>
              <Switch />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.switchContainer}>
              <Typography variant="body1">City/State</Typography>
              <Switch />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.switchContainer}>
              <Typography variant="body1">Roles & Permissions</Typography>
              <Switch />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.switchContainer}>
              <Typography variant="body1">Company Logo</Typography>
              <Switch />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.switchContainer}>
              <Typography variant="body1">Employment Type</Typography>
              <Switch />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.switchContainer}>
              <Typography variant="body1">Recent Job Title</Typography>
              <Switch />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.switchContainer}>
              <Typography variant="body1">Most Recent Company</Typography>
              <Switch />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.switchContainer}>
              <Typography variant="body1">Working from year</Typography>
              <Switch />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.switchContainer}>
              <Typography variant="body1">Working from month</Typography>
              <Switch />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.switchContainer}>
              <Typography variant="body1">General Comments</Typography>
              <Switch />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.switchContainer}>
              <Typography variant="body1">
                Requirements Specific Comments
              </Typography>
              <Switch />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.switchContainer}>
              <Typography variant="body1">Summary</Typography>
              <Switch />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActionContainer}>
        <Button
          variant="contained"
          className={classes.dialogActionButton}
          style={{ color: '#000' }}
          size="large"
          onClick={() => importDialogOpen[1](false)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          className={classes.dialogActionButton}
          color="primary"
          size="large"
          onClick={() => importDialogOpen[1](true)}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportCandidatesDialog;
