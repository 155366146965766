import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Face from '../../assets/images/face.jpg';
import PeopleImg from '../../assets/images/people-on-dashboard.png';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  notificationContainer: {
    padding: 15,
    minHeight: 300,
  },
  notificationTitleRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  notificationRowContainer: {
    display: 'flex',
    padding: '15px 5px 5px',
  },
  btn: {
    marginTop: 5,
    textAlign: 'center',
  },
}));

const Notifications = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Paper className={classes.notificationContainer} variant="outlined">
        <div className={classes.notificationTitleRow}>
          <Typography variant="subtitle1">Notifications</Typography>
          <MoreHorizIcon style={{ color: '#8d8d8d' }} />
        </div>
        <div className={classes.notificationRowContainer}>
          <Avatar src={Face} alt="face" />
          <Typography variant="body1" style={{ marginLeft: 7 }}>
            {' '}
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde, non!{' '}
          </Typography>
        </div>
        <div className={classes.notificationRowContainer}>
          <Avatar src={Face} alt="face" />
          <Typography variant="body1" style={{ marginLeft: 7 }}>
            {' '}
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde, non!{' '}
          </Typography>
        </div>
        <div className={classes.notificationRowContainer}>
          <Avatar src={Face} alt="face" />
          <Typography variant="body1" style={{ marginLeft: 7 }}>
            {' '}
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde, non!{' '}
          </Typography>
        </div>
        <div className={classes.notificationRowContainer}>
          <Avatar src={Face} alt="face" />
          <Typography variant="body1" style={{ marginLeft: 7 }}>
            {' '}
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde, non!{' '}
          </Typography>
        </div>
        <div className={classes.btn}>
          {' '}
          <Button color="primary" style={{ textTransform: 'none' }}>
            See all activities
          </Button>
        </div>
      </Paper>
      <img src={PeopleImg} alt="people" width="100%" />
    </div>
  );
};

export default Notifications;
