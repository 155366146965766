import React, { useContext } from 'react';
import {
  makeStyles,
  Typography,
  TextField,
  Grid,
  Container,
  Button,
  Avatar,
  IconButton,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import BackupIcon from '@material-ui/icons/BackupOutlined';
import api from '../../../api/api';
import SnackBarContext from '../../../Context/SnackBarContext';
import { ReactComponent as WhatsAppSVG } from '../../../assets/logo/whatsapp.svg';
const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  columnOptionContainer: {
    marginLeft: 60,
  },
  columnOption: {
    marginLeft: 15,
    color: '#ffffff91',
  },
  smallIcon: {
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
    backgroundColor: 'transparent',
    marginRight: 5,
  },
  sideBarTitle: {
    marginLeft: 15,
    color: '#fff',
  },

  //Right content classes

  uploadFileContainer: {
    border: '1px solid #00000060',
    borderRadius: 4,
    minHeight: 230,
    textAlign: 'center ',
  },
  imgPreviewContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleContainer: {
    marginTop: 10,
    fontWeight: 600,
  },
  textField: {
    marginTop: 5,
    marginBottom: 5,
  },
  editor: {
    marginTop: 15,
  },
  subtitle: {
    marginTop: 5,
    fontWeight: 'bold',
    opacity: 0.5,
  },
  caption: {
    marginTop: 3,
  },
  createBtn: {
    marginTop: 10,
    minWidth: 150,
  },
  inlineContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  helperText: {
    color: 'red',
    marginLeft: 5,
  },
  bottomButton: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  saveBtn: {
    marginRight: 10,
    padding: '10px 30px',
    height: 22,
    marginTop: 10,
  },
  saveBtnTxt: {
    opacity: 0.5,
    fontWeight: 600,
  },
  smallNumber: {
    height: theme.spacing(2.1),
    width: theme.spacing(2.1),
    backgroundColor: 'transparent',
    marginRight: 5,
    border: '0.5px solid #ffffff91',
    fontSize: 13,
    color: '#ffffff91',
  },
  smallNumberHighlighted: {
    height: theme.spacing(2.1),
    width: theme.spacing(2.1),
    backgroundColor: '#fff',
    marginRight: 5,
    fontSize: 13,
    color: '#000',
    border: '0.5px solid #FFF',
  },

  // End
}));

const BasicInfoSidebar = () => {
  const classes = useStyles();
  return (
    <Container>
      <div className={classes.row}>
        <CheckCircleIcon
          style={{ color: '#FFF' }}
          className={classes.smallIcon}
          fontSize="large"
        />
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Sign up
        </Typography>
      </div>

      <div className={classes.row}>
        <Avatar className={classes.smallNumberHighlighted}>2</Avatar>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.sideBarTitle}>
          Basic Information
        </Typography>
      </div>

      <div className={classes.row}>
        <Avatar className={classes.smallNumber}>3</Avatar>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Employment History
        </Typography>
      </div>

      <div className={classes.row}>
        <Avatar className={classes.smallNumber}>4</Avatar>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Verification & Finish
        </Typography>
      </div>
    </Container>
  );
};

const BasicInfoContent = ({ Controller, useFormContext }) => {
  const {
    formState: { errors },
    register,
    control,
    setValue,
    getValues,
  } = useFormContext();

  const { alerts } = useContext(SnackBarContext);
  const classes = useStyles();
  const [dialogPic, setDialogPic] = React.useState(null);
  const values = React.useState(getValues());

  const browseBtnRef = React.useRef();

  const handleFileUpload = e => {
    var formData = new FormData();
    formData.append('image', e.target.files[0]);
    api({ path: '/image/upload', data: formData })
      .then(res => {
        if (res.data.hasError) {
          alerts[1]({
            ...alerts[0],

            message: res.data.message,
            state: true,
          });
          setValue('profile_photo', '');
          return;
        }
        alerts[1]({
          error: false,
          message: res.data.message,
          state: true,
        });
        setValue('profile_photo', res?.data?.data?.imageId);
        setDialogPic(URL.createObjectURL(e.target.files[0]));
      })
      .catch(err => console.log('ERR', err));
  };

  return (
    <Container style={{ paddingRight: 5, position: 'relative' }}>
      <Grid maxWidth="sm" container xs={8} spacing={2}>
        <Grid item xs={12}>
          <div className={classes.leftTitle}>
            <div className={classes.inlineContainer}>
              <Typography variant="h1">Basic Information</Typography>
            </div>
            <Typography variant="body1" className={classes.subtitle}>
              Before you looking for job, we need to know some information to
              get to know you.
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography
            className={classes.titleContainer}
            style={{ marginBottom: 8, margingTop: 20 }}
            variant="subtitle1">
            Personal Information
          </Typography>
          {dialogPic ? (
            <div className={classes.imgPreviewContainer}>
              <img src={dialogPic} alt="pic" height={70} width={70} />
              <IconButton
                onClick={() => {
                  setDialogPic(null);
                }}>
                <CloseIcon />
              </IconButton>
            </div>
          ) : (
            <>
              <div
                className={classes.uploadFileContainer}
                style={{ textAlign: 'center' }}>
                <IconButton>
                  <BackupIcon style={{ fontSize: 70 }} />
                </IconButton>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: 'normal' }}
                  align="center">
                  Click here to browse or <br /> drag and drop a file
                </Typography>
                <Button
                  style={{ padding: '15px 55px', margin: '10px 5px' }}
                  variant="contained"
                  onClick={() => browseBtnRef?.current?.click()}>
                  <Typography variant="subtitle1">Browse</Typography>
                </Button>
                <Controller
                  control={control}
                  name="profile_photo"
                  render={({ field: { onChange } }) => (
                    <input
                      type="file"
                      id="profile_photo"
                      style={{ display: 'none' }}
                      onChange={e => {
                        onChange(e);
                        handleFileUpload(e);
                      }}
                      ref={browseBtnRef}
                      // {...register('profile_photo', { required: true })}
                    />
                  )}
                  rules={{ required: true }}></Controller>
                <Typography style={{ marginBottom: 5 }} variant="body1">
                  File must be an image format. The maximum file-size is 10 MB
                </Typography>
              </div>
              {errors?.profile_photo?.type !== undefined &&
                errors?.profile_photo?.type === 're  quired' && (
                  <FormHelperText style={{ marginLeft: 12 }}>
                    This is require field
                  </FormHelperText>
                )}
            </>
          )}
        </Grid>

        <Grid item xs={6}>
          <TextField
            variant="outlined"
            label="First Name"
            defaultValue={values[0].first_name || ''}
            error={errors?.first_name || false}
            className={classes.textField}
            fullWidth
            {...register('first_name', { required: true })}
            helperText={
              errors?.first_name?.type !== undefined &&
              errors?.first_name?.type === 'required' &&
              'This is require field'
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            label="Last Name"
            defaultValue={values[0].last_name || ''}
            error={errors?.last_name || false}
            className={classes.textField}
            fullWidth
            {...register('last_name', { required: true })}
            helperText={
              errors?.last_name?.type !== undefined &&
              errors?.last_name?.type === 'required' &&
              'This is require field'
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            label="Phone Number"
            type={'number'}
            defaultValue={values[0].phone_no || ''}
            error={errors?.phone_no || false}
            className={classes.textField}
            fullWidth
            {...register('phone_no', { required: true })}
            helperText={
              errors?.phone_no?.type !== undefined &&
              errors?.phone_no?.type === 'required' &&
              'This is require field'
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            label="Alternative Number"
            className={classes.textField}
            type={'number'}
            {...register('alternate_no', { required: true })}
            defaultValue={values[0].alternate_no || ''}
            fullWidth
            error={errors?.alternate_no || false}
            helperText={
              errors?.alternate_no?.type !== undefined &&
              errors?.alternate_no?.type === 'required' &&
              'This is require field'
            }
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            {/* <InputLabel htmlFor="outlined-adornment-whatsApp">
              WhatsApp Number
            </InputLabel> */}
            <OutlinedInput
              id="outlined-adornment-whatsApp"
              type={'number'}
              className={classes.textField}
              placeholder="WhatsApp Number"
              defaultValue={values[0].whatsapp_no || ''}
              error={errors?.whatsapp_no || false}
              {...register('whatsapp_no', { required: true })}
              endAdornment={
                <InputAdornment position="start">
                  <WhatsAppSVG />
                </InputAdornment>
              }
              startAdornment={
                <InputAdornment position="start">
                  <Typography>+91 |</Typography>
                </InputAdornment>
              }
              // labelWidth={95}
            />
            {errors?.whatsapp_no?.type !== undefined &&
              errors?.whatsapp_no?.type === 'required' && (
                <FormHelperText>This is require field</FormHelperText>
              )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.titleContainer}>
            Address and Location
          </Typography>
        </Grid>

        <Grid item xs={6} style={{ marginBottom: 20 }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Country/Region</InputLabel>
            <Select
              label="Country/Region"
              fullWidth
              defaultValue={values[0].country || ''}
              error={errors?.country || false}
              {...register('country', { required: true })}>
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
            {errors?.country?.type !== undefined &&
              errors?.country?.type === 'required' && (
                <FormHelperText>This is require field</FormHelperText>
              )}
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>City/District</InputLabel>
            <Select
              // value={age}
              // onChange={handleChange}
              error={errors?.city || false}
              label="City/District"
              fullWidth
              defaultValue={values[0].city || ''}
              {...register('city', { required: true })}>
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
            {errors?.city?.type !== undefined &&
              errors?.city?.type === 'required' && (
                <FormHelperText>This is require field</FormHelperText>
              )}
          </FormControl>
        </Grid>
      </Grid>
    </Container>
  );
};

export { BasicInfoContent, BasicInfoSidebar };
