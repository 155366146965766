import React from 'react';
import {
  Dialog,
  createStyles,
  makeStyles,
  IconButton,
  Typography,
  Slide,
  DialogTitle,
  DialogContent,
  Avatar,
  Checkbox,
  Grid,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import { url } from '../../utils/common';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStylesDialog = makeStyles(theme =>
  createStyles({
    dialogTitleContainer: {
      backgroundColor: theme.palette.ternary.main,
      padding: '0px 15px',
    },
    dialogTitleContainerDiv: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },

    permission: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '50%',
    },
    adminInfoRow: {
      display: 'flex',
      backgroundColor: '#f1f1f1',
      minHeight: 120,
      paddingLeft: 24,
    },
    adminInfo: {
      margin: '15px 15px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    divider: {
      borderLeft: '1px solid #000000',
      margin: '0 5px',
    },
    icon: {
      color: theme.palette.text.primary,
    },
    dialogContent: {
      padding: 0,
    },
    permissionContainer: {
      paddingLeft: 24,
      paddingRight: 24,
    },
    prevAvatar: {
      height: theme.spacing(10),
      width: theme.spacing(10),
      marginTop: 20,
    },
    hoverIcons: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '80%',
    },
    permissionTitle: {
      width: '30%',
    },
  })
);

const PreviewAdminDialog = ({ previewAdminDialog, previewData }) => {
  const classes = useStylesDialog();

  return (
    <Dialog
      open={previewAdminDialog[0]}
      maxWidth="sm"
      TransitionComponent={Transition}
      fullWidth>
      <DialogTitle className={classes.dialogTitleContainer}>
        <div className={classes.dialogTitleContainerDiv}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton>
              <ArrowBackIcon style={{ color: '#FFF' }} />
            </IconButton>
            <Typography variant="subtitle1" style={{ color: '#FFF' }}>
              Preview
            </Typography>
          </div>
          <IconButton onClick={() => previewAdminDialog[1](false)}>
            <CloseIcon style={{ color: '#FFF' }} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.adminInfoRow}>
          <Avatar
            src={`${url}/image/${previewData?.profile_photo}`}
            className={classes.prevAvatar}
          />
          <div className={classes.adminInfo}>
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              {`${previewData?.first_name} ${previewData?.last_name}`}
            </Typography>
            <div style={{ display: 'flex' }}>
              <Typography variant="body1">{previewData?.email_id}</Typography>
              <div className={classes.divider} />
              <Typography variant="body1">{previewData?.phone_no}</Typography>
            </div>
            <Typography variant="body1">{previewData?.company_name}</Typography>
            <Typography variant="body1">
              {`${previewData?.city} ${previewData?.country}`}
            </Typography>
          </div>
        </div>
        <div className={classes.permissionContainer}>
          <Typography
            variant="body1"
            style={{ marginTop: 15, fontWeight: 'bold' }}>
            Permissions{' '}
          </Typography>
          <Grid container>
            <Grid item xs={6} className={classes.permission}>
              <Typography
                variant="body1"
                align="left"
                className={classes.permissionTitle}>
                Dashboard
              </Typography>
              <div>
                R
                <Checkbox
                  checked={previewData?.dashboard_read ? true : false}
                />
                W
                <Checkbox
                  checked={previewData?.dashboard_write ? true : false}
                />
                D
                <Checkbox
                  checked={previewData?.dashboard_delete ? true : false}
                />
              </div>
            </Grid>
            <Grid item xs={6} className={classes.permission}>
              <Typography
                variant="body1"
                align="left"
                className={classes.permissionTitle}>
                Requirements
              </Typography>
              <div>
                R
                <Checkbox
                  checked={previewData?.requirement_read ? true : false}
                />
                W
                <Checkbox
                  checked={previewData?.requirement_write ? true : false}
                />
                D
                <Checkbox
                  checked={previewData?.requirement_delete ? true : false}
                />
              </div>
            </Grid>

            <Grid item xs={6} className={classes.permission}>
              <Typography
                variant="body1"
                align="left"
                className={classes.permissionTitle}>
                Profile
              </Typography>
              <div>
                R<Checkbox checked={previewData?.profile_read ? true : false} />
                W
                <Checkbox checked={previewData?.profile_write ? true : false} />
                D
                <Checkbox
                  checked={previewData?.profile_delete ? true : false}
                />
              </div>
            </Grid>
            <Grid item xs={6} className={classes.permission}>
              <Typography
                variant="body1"
                align="left"
                className={classes.permissionTitle}>
                Reports
              </Typography>
              <div>
                R<Checkbox checked={previewData?.report_read ? true : false} />
                W<Checkbox checked={previewData?.report_write ? true : false} />
                D
                <Checkbox checked={previewData?.report_delete ? true : false} />
              </div>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PreviewAdminDialog;
