import React, { useState } from 'react';
import {
  Button,
  DialogActions,
  Typography,
  IconButton,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  createStyles,
  TextField,
  MenuItem,
  Avatar,
  Collapse,
  withStyles,
  Switch,
  Grid,
  Container,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBackOutlined';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUpOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDownOutlined';

import google from '../../../assets/logo/google.png';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme =>
  createStyles({
    dialogTitleContainer: {
      backgroundColor: theme.palette.ternary.main,
      padding: '0px 15px',
    },
    dialogTitleContainerDiv: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },

    dialogActionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 10,
    },
    dialogActionButton: {
      margin: '5px 13px',
      color: '#FFF',
      padding: '10px 25px',
    },
    dialogActionBlueButton: {
      margin: '5px 13px',
      color: '#FFF',
      padding: '10px 25px',
      backgroundColor: theme.palette.ternary.main,
    },

    icon: {
      color: '#FFFFFFCC',
      padding: '0px 5px',
    },
    dialogTitle: {
      display: 'flex',
      alignItems: 'center',
    },
    comapanyinfo: {
      display: 'flex',
      marginTop: 15,
    },
    dialogTopContainer: {
      margin: '20px 18px',
    },
    collapseTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: '#f3f3f3',
      padding: '5px 15px',
      marginBottom: 10,
    },
    jobDetailsRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '15px 0px',
    },
    dialogContent: {
      padding: '0px 0px 8px 0px',
    },
    gridItemLeft: {
      paddingLeft: 15,
    },
    gridItemRight: {
      paddingRight: 15,
    },
  })
);

const AntSwitch = withStyles(theme => ({
  root: {
    width: 29,
    height: 18,
    padding: 2,
    display: 'flex',
    margin: '0px 10px',
    borderRadius: 5,
  },
  switchBase: {
    padding: 3.5,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#299c14',
        borderColor: '#299c14',
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const ExportDialog = ({ open }) => {
  const classes = useStyles();
  const [jobDetails, setJobDetails] = useState(false);
  const [postingDetails, setPostingDetails] = useState(false);
  const [searchStrategy, setSearchStrategy] = useState(false);

  return (
    <Dialog
      open={open[0]}
      maxWidth="sm"
      TransitionComponent={Transition}
      fullWidth>
      <DialogTitle className={classes.dialogTitleContainer}>
        <div className={classes.dialogTitleContainerDiv}>
          <div className={classes.dialogTitle}>
            <ArrowBackIcon className={classes.icon} />
            <Typography variant="subtitle1" style={{ color: '#FFF' }}>
              Back to Preview
            </Typography>
          </div>
          <IconButton onClick={() => open[1](false)}>
            <CloseIcon style={{ color: '#FFF' }} />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Container disableGutters>
          <Grid container>
            <Grid item xs={12} className={classes.dialogTopContainer}>
              <TextField
                variant="outlined"
                defaultValue="google"
                fullWidth
                select>
                <MenuItem value="placeholder">Choose Company</MenuItem>
                <MenuItem value="google">Google</MenuItem>
                <MenuItem value="microsoft">Microsoft</MenuItem>
              </TextField>
              <div className={classes.comapanyinfo}>
                <Avatar src={google} alt="google" />
                <div style={{ marginLeft: 10 }}>
                  <Typography variant="subtitle1">Google.Inc</Typography>
                  <Typography variant="body1">
                    Akshaya Nagar 1st Block 1st Cross, Rammurthy nagar,Banglore
                    - 560016
                  </Typography>
                </div>
              </div>
              <TextField
                variant="outlined"
                placeholder="Enter Title here"
                fullWidth
                style={{ marginTop: 14 }}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.collapseTitleContainer}>
                <Typography variant="subtitle1">Job Details</Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <AntSwitch />
                  <Typography variant="body1">Select All</Typography>
                  <IconButton onClick={() => setJobDetails(!jobDetails)}>
                    {jobDetails ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </div>
              </div>
              <Collapse
                in={jobDetails}
                className={classes.collapseOption}
                timeout="auto"
                unmountOnExit>
                <Grid container>
                  <Grid item xs={6} className={classes.gridItemLeft}>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">Job Title</Typography>
                      <AntSwitch />
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">Reporting To</Typography>
                      <AntSwitch />
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">Reportees/ Team</Typography>
                      <AntSwitch />
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">Employment Type</Typography>
                      <AntSwitch />
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">Job Details</Typography>
                      <AntSwitch />
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">Key Result Areas</Typography>
                      <AntSwitch />
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">
                        Key Performance Indicators
                      </Typography>
                      <AntSwitch />
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">
                        Desired Candidate Profile
                      </Typography>
                      <AntSwitch />
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">Annual CTC</Typography>
                      <AntSwitch />
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    className={classes.gridItemRight}
                    style={{
                      borderLeft: '1px dashed #e2e2e2',
                      paddingLeft: 10,
                    }}>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">
                        Other Salary Details
                      </Typography>
                      <AntSwitch />
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">Work Experience</Typography>
                      <AntSwitch />
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">Keywords</Typography>
                      <AntSwitch />
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">Job Located</Typography>
                      <AntSwitch />
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">Hiring For</Typography>
                      <AntSwitch />
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">
                        Visibility Settings
                      </Typography>
                      <AntSwitch />
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">Recruiter Name</Typography>
                      <AntSwitch />
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">No. of Vacancies</Typography>
                      <AntSwitch />
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">Reference Code</Typography>
                      <AntSwitch />
                    </div>
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.collapseTitleContainer}>
                <Typography variant="subtitle1">Posting Details</Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <AntSwitch />
                  <Typography variant="body1">Select All</Typography>
                  <IconButton
                    onClick={() => setPostingDetails(!postingDetails)}>
                    {postingDetails ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </div>
              </div>
              <Collapse
                in={postingDetails}
                className={classes.collapseOption}
                timeout="auto"
                unmountOnExit>
                <Grid container>
                  <Grid item xs={6} className={classes.gridItemLeft}>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">Graduation</Typography>
                      <AntSwitch />
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">Post Graduation</Typography>
                      <AntSwitch />
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">Doctorate</Typography>
                      <AntSwitch />
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">Industry</Typography>
                      <AntSwitch />
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    className={classes.gridItemRight}
                    style={{
                      borderLeft: '1px dashed #e2e2e2',
                      paddingLeft: 10,
                    }}>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">Functional Area</Typography>
                      <AntSwitch />
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">
                        Have any question for the candidate?
                      </Typography>
                      <AntSwitch />
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">
                        Show contact details to jobseekers
                      </Typography>
                      <AntSwitch />
                    </div>
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.collapseTitleContainer}>
                <Typography variant="subtitle1">Search Strategy</Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <AntSwitch />
                  <Typography variant="body1">Select All</Typography>
                  <IconButton
                    onClick={() => setSearchStrategy(!searchStrategy)}>
                    {searchStrategy ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </div>
              </div>
              <Collapse
                in={searchStrategy}
                className={classes.collapseOption}
                timeout="auto"
                unmountOnExit>
                <Grid container>
                  <Grid item xs={6} className={classes.gridItemLeft}>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">Brief</Typography>
                      <AntSwitch />
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">Role Dynamics</Typography>
                      <AntSwitch />
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">
                        Required candidate skills
                      </Typography>
                      <AntSwitch />
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    className={classes.gridItemRight}
                    style={{
                      borderLeft: '1px dashed #e2e2e2',
                      paddingLeft: 10,
                    }}>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">Target Pool</Typography>
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">Conclusion</Typography>
                      <AntSwitch />
                    </div>
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          </Grid>
        </Container>
      </DialogContent>
      <DialogActions className={classes.dialogActionContainer}>
        <Button
          variant="contained"
          className={classes.dialogActionBlueButton}
          onClick={() => open[1](false)}>
          BACK TO EDIT
        </Button>

        <Button
          variant="contained"
          className={classes.dialogActionButton}
          color="primary">
          Export PDF
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportDialog;
