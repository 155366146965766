import React, { useEffect, useState, useContext } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  makeStyles,
  Button,
} from '@material-ui/core';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';

import Editor from '../shared/Editor';
import api from '../../api/api';
import UserContext from '../../Context/UserContext';
import SnackBarContext from '../../Context/SnackBarContext';

const useStyles = makeStyles(theme => ({
  titleRow: {
    padding: '15px 15px 0px  30px',
  },
  privacyPolicyTitle: {
    padding: '15px 15px 15px  35px',
  },
  buttons: {
    padding: '12px 20px',
  },
  buttonContainer: {
    margin: '15px 50px 15px 30px',
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '90%',
  },
  editor: {
    minHeight: 500,
    margin: '10px 27px',
    borderRadius: 4,
    maxWidth: '90%',
    overflowY: 'hidden',
  },
}));

const TermsAndPolicy = () => {
  const classes = useStyles();
  const editorStyle = {
    minHeight: 500,
  };
  const { alerts } = useContext(SnackBarContext);
  const { authState } = useContext(UserContext);
  const [termsandconditions, setTermsandconditions] = useState(() =>
    EditorState.createEmpty()
  );
  const [privacyPolicy, setPrivacyPolicy] = useState(() =>
    EditorState.createEmpty()
  );

  const submitTermsAndConditions = () => {
    const content = convertToRaw(termsandconditions.getCurrentContent());
    const data = {
      user_account_id: authState[0].user_account_id.toString(),
      admin_id: authState[0].admin_id,
      terms_and_conditions: JSON.stringify(content),
    };
    api({
      path: '/admin/setting/saveTermsCondition',
      data: data,
    })
      .then(res => {
        if (res.data.hasError) {
          alerts[1]({
            ...alerts[0],

            message: res.data.message,
            state: true,
          });
        }
        alerts[1]({
          error: false,
          message: res.data.message,
          state: true,
        });
      })
      .catch(err =>
        alerts[1]({
          ...alerts[0],

          message: err,
          state: true,
        })
      );
  };

  const submitPrivacyPolicy = () => {
    const content = convertToRaw(privacyPolicy.getCurrentContent());
    const data = {
      user_account_id: authState[0].user_account_id.toString(),
      admin_id: authState[0].admin_id,
      privacy_policy: JSON.stringify(content),
    };
    api({
      path: '/admin/setting/saveprivacyPolicy',
      data: data,
    })
      .then(res => {
        if (res.data.hasError) {
          alerts[1]({
            ...alerts[0],

            message: res.data.message,
            state: true,
          });
        }
        alerts[1]({
          error: false,
          message: res.data.message,
          state: true,
        });
      })
      .catch(err => console.log(err));
  };

  const getTermsandConditions = () => {
    api({ path: '/admin/setting/getSiteSetting' }).then(res => {
      var contentState = JSON.parse(res.data.data[0].terms_and_conditions);

      contentState = convertFromRaw(contentState);

      setTermsandconditions(EditorState.createWithContent(contentState));
    });
  };

  const getPrivacyPolicy = () => {
    api({ path: '/admin/setting/getSiteSetting' }).then(res => {
      var contentState = JSON.parse(res.data.data[0].privacy_policy);

      contentState = convertFromRaw(contentState);

      setPrivacyPolicy(EditorState.createWithContent(contentState));
    });
  };

  useEffect(() => {
    getTermsandConditions();
    getPrivacyPolicy();
  }, []);

  return (
    <Container style={{ overflow: 'hidden', maxWidth: '80vw' }}>
      <Paper style={{ marginTop: 25 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.titleRow}>
              <Typography variant="subtitle1">Terms & Conditions</Typography>
              <Typography variant="body1" style={{ marginLeft: 5 }}>
                Lorem ipsum dolor sit amet.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.editor}>
              <Editor
                Styles={editorStyle}
                editorState={termsandconditions}
                setEditorState={setTermsandconditions}
              />
            </div>
          </Grid>

          <Grid item xs={11} className={classes.buttonContainer}>
            <Button variant="contained" className={classes.buttons}>
              Cancel
            </Button>

            <Button
              variant="contained"
              className={classes.buttons}
              color="primary"
              onClick={submitTermsAndConditions}>
              Save & Update
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper style={{ marginTop: 25 }}>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.privacyPolicyTitle}>
              <Typography variant="subtitle1">Privacy Policy</Typography>
              <Typography variant="body1">
                Lorem ipsum dolor sit amet.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.editor}>
            <Editor
              Styles={editorStyle}
              editorState={privacyPolicy}
              setEditorState={setPrivacyPolicy}
            />
          </Grid>

          <Grid item xs={12} className={classes.buttonContainer}>
            <Button variant="contained" className={classes.buttons}>
              Cancel
            </Button>

            <Button
              variant="contained"
              className={classes.buttons}
              color="primary"
              onClick={() => submitPrivacyPolicy()}>
              Save & Update
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default TermsAndPolicy;
