import React, { useContext } from 'react';
import {
  makeStyles,
  Typography,
  TextField,
  Grid,
  Container,
  Button,
  ButtonBase,
  Avatar,
  IconButton,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Paper,
  FormHelperText,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import api from '../../../api/api';
import SnackBarContext from '../../../Context/SnackBarContext';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  columnOptionContainer: {
    marginLeft: 60,
  },
  columnOption: {
    marginLeft: 15,
    color: '#ffffff91',
  },
  smallIcon: {
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
    backgroundColor: 'transparent',
    marginRight: 5,
  },
  sideBarTitle: {
    marginLeft: 15,
    color: '#fff',
  },

  //Right content classes

  uploadFileContainer: {
    border: '1px solid #00000033',
    borderRadius: 4,
    height: 32,
    display: 'flex',
    justifyContent: 'space-between',
    padding: 3,
    paddingLeft: 12,
    color: '#000000b0',
  },
  detailsCard: {
    border: '1px solid #00000033',
    padding: 18,
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: 4,
    marginBottom: 10,
  },
  imgPreviewContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleContainer: {
    marginTop: 10,
    fontWeight: 600,
  },
  textField: {
    marginTop: 5,
    marginBottom: 5,
  },
  editor: {
    marginTop: 15,
  },
  subtitle: {
    marginTop: 5,
    fontWeight: 'bold',
    opacity: 0.5,
  },
  caption: {
    marginTop: 3,
  },
  createBtn: {
    marginTop: 10,
    minWidth: 150,
  },
  inlineContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  helperText: {
    color: 'red',
    marginLeft: 5,
  },
  bottomButton: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  saveBtn: {
    marginTop: 10,
    marginRight: 20,
    padding: '8px 35px',
  },
  cancelBtn: {
    marginRight: 10,
    padding: '10px 30px',
    height: 22,
    marginTop: 10,
  },
  cancelBtnTxt: {
    opacity: 0.5,
    fontWeight: 600,
  },
  smallNumber: {
    height: theme.spacing(2.1),
    width: theme.spacing(2.1),
    backgroundColor: 'transparent',
    marginRight: 5,
    border: '0.5px solid #ffffff91',
    fontSize: 13,
    color: '#ffffff91',
  },
  smallNumberHighlighted: {
    height: theme.spacing(2.1),
    width: theme.spacing(2.1),
    backgroundColor: '#fff',
    marginRight: 5,
    fontSize: 13,
    color: '#000',
    border: '0.5px solid #FFF',
  },
  buttons: {
    display: 'flex',
    paddingBottom: '15px !important',
    borderBottom: '1px dashed #0c0c0c29',
    paddingLeft: '0px !important',
    marginLeft: 10,
  },
  addMore: {
    paddingBottom: '0px !important',
    paddingTop: '12px !important',
    cursor: 'pointer',
  },
  // End
}));

const EmpHistorySidebar = () => {
  const classes = useStyles();
  return (
    <Container>
      <div className={classes.row}>
        <CheckCircleIcon
          style={{ color: '#FFF' }}
          className={classes.smallIcon}
          fontSize="large"
        />
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Sign up
        </Typography>
      </div>

      <div className={classes.row}>
        <CheckCircleIcon
          style={{ color: '#FFF' }}
          className={classes.smallIcon}
          fontSize="large"
        />
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Basic Information
        </Typography>
      </div>

      <div className={classes.row}>
        <Avatar className={classes.smallNumberHighlighted}>3</Avatar>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.sideBarTitle}>
          Employment History
        </Typography>
      </div>

      <div className={classes.row}>
        <Avatar className={classes.smallNumber}>4</Avatar>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Verification & Finish
        </Typography>
      </div>
    </Container>
  );
};

const EmpHistoryContent = ({ savedDetails }) => {
  const classes = useStyles();
  const [dialogPicUri, setDialogPicUri] = React.useState(null);
  const [dialogPic, setDialogPic] = React.useState(null);
  const [recentJobTitle, setRecentJobTitle] = React.useState('');
  const [employmentType, setEmploymentType] = React.useState('');
  const [recentCompany, setRecentCompany] = React.useState('');
  const [workingFromYear, setWorkingFromYear] = React.useState('');
  const [workingToMonth, setWorkingToMonth] = React.useState('');
  const { alerts } = useContext(SnackBarContext);
  const [errors, setError] = React.useState({});

  const [saved, setSaved] = React.useState(false);

  const browseBtnRef = React.useRef();

  const handleFileUpload = (e, logoName) => {
    var formData = new FormData();
    formData.append('image', e.target.files[0]);
    api({ path: '/image/upload', data: formData })
      .then(res => {
        if (res.data.hasError) {
          alerts[1]({
            ...alerts[0],

            message: res.data.message,
            state: true,
          });
          setDialogPicUri(null);
          return;
        }
        alerts[1]({
          error: false,
          message: res.data.message,
          state: true,
        });
        setDialogPicUri(res?.data?.data?.imageId);
        setDialogPic(
          `https://medlent.herokuapp.com/image/${res?.data?.data?.imageId}`
        );
      })
      .catch(err => console.log('ERR', err));
  };

  const handleSave = () => {
    if (!dialogPic || !dialogPicUri)
      setError(prev => ({ ...prev, dialogPic: !prev['dialogPic'] }));
    if (!recentJobTitle)
      setError(prev => ({
        ...prev,
        recentJobTitle: !prev['recentJobTitle'],
      }));
    if (!employmentType)
      setError(prev => ({
        ...prev,
        employmentType: !prev['employmentType'],
      }));
    if (!recentCompany)
      setError(prev => ({
        ...prev,
        recentCompany: !prev['recentCompany'],
      }));
    if (!workingFromYear)
      setError(prev => ({
        ...prev,
        workingFromYear: !prev['workingFromYear'],
      }));
    if (!workingToMonth)
      setError(prev => ({
        ...prev,
        workingToMonth: !prev['workingToMonth'],
      }));
    if (
      !workingToMonth ||
      !workingFromYear ||
      !recentCompany ||
      !employmentType ||
      !recentJobTitle ||
      !dialogPic
    )
      return;
    setSaved(true);

    savedDetails[1](prev => [
      ...prev,
      {
        dialogPic: dialogPicUri,
        recentJobTitle,
        employmentType,
        recentCompany,
        workingFromYear,
        workingToMonth,
      },
    ]);
    setDialogPic(null);
    setRecentJobTitle('');
    setEmploymentType('');
    setRecentCompany('');
    setWorkingFromYear('');
    setWorkingToMonth('');
  };

  return (
    <Container style={{ paddingRight: 5, position: 'relative' }}>
      <Grid maxWidth="sm" container xs={8} spacing={2}>
        <Grid item xs={12}>
          <div className={classes.leftTitle}>
            <div className={classes.inlineContainer}>
              <Typography variant="h1">Employment History</Typography>
            </div>
            <Typography variant="body1" className={classes.subtitle}>
              Before you looking for job, we need to know some information to
              get to know you.
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12}>
          {savedDetails[0].length !== 0 && (
            <Typography
              className={classes.titleContainer}
              style={{ marginBottom: 8, margingTop: 20 }}
              variant="subtitle1">
              Employment Details
            </Typography>
          )}
          {savedDetails[0].map(item => {
            return (
              <Grid item xs={12} className={classes.detailsCard}>
                <div>
                  <Typography variant="body2" style={{ lineHeight: '21px' }}>
                    {item.recentJobTitle} at {item.recentCompany}
                  </Typography>

                  <Typography variant="body1" style={{ lineHeight: '15px' }}>
                    {item.recentCompany} | {item.employmentType}
                  </Typography>

                  <Typography variant="body1" style={{ lineHeight: '25px' }}>
                    {item.workingFromYear} to {item.workingToMonth} (8 months)
                  </Typography>
                </div>
                <div>
                  <IconButton>
                    <EditOutlinedIcon variant="body2" />
                  </IconButton>

                  <IconButton>
                    <DeleteOutlineOutlinedIcon variant="body2" />
                  </IconButton>
                </div>
              </Grid>
            );
          })}
        </Grid>
        {!saved ? (
          <>
            <Grid item xs={12}>
              {savedDetails[0].length === 0 && (
                <Typography
                  className={classes.titleContainer}
                  style={{ marginBottom: 8, margingTop: 20 }}
                  variant="subtitle1">
                  Employment Details
                </Typography>
              )}
              {dialogPic ? (
                <div className={classes.imgPreviewContainer}>
                  <img src={dialogPic} alt="pic" height={70} width={70} />
                  <IconButton
                    onClick={() => {
                      setDialogPic(null);
                    }}>
                    <CloseIcon />
                  </IconButton>
                </div>
              ) : (
                <div
                  className={classes.uploadFileContainer}
                  style={{ textAlign: 'center' }}>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 'normal', alignSelf: 'center' }}
                    align="center">
                    Upload Company Logo
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => browseBtnRef?.current?.click()}>
                    <Typography
                      variant="subtitle1"
                      style={{ color: '#fff', opacity: '1' }}>
                      UPLOAD LOGO
                    </Typography>
                  </Button>
                  <input
                    type="file"
                    id="file"
                    value={dialogPic}
                    style={{ display: 'none' }}
                    onChange={e => {
                      if (errors?.dialogPic) {
                        setError(prev => ({ ...prev, dialogPic: false }));
                      }
                      handleFileUpload(e);
                    }}
                    ref={browseBtnRef}
                  />
                </div>
              )}
              {errors?.dialogPic && (
                <FormHelperText style={{ marginLeft: 10 }}>
                  This is require field
                </FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Most Recent Job Title"
                className={classes.textField}
                fullWidth
                value={recentJobTitle}
                error={errors?.recentJobTitle || false}
                onChange={e => {
                  if (errors?.recentJobTitle) {
                    setError(prev => ({ ...prev, recentJobTitle: false }));
                  }
                  setRecentJobTitle(e.target.value);
                }}
                helperText={errors?.recentJobTitle && 'This is require field'}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                fullWidth
                error={errors?.employmentType || false}>
                <InputLabel>Employment Type</InputLabel>
                <Select
                  value={employmentType}
                  onChange={e => {
                    if (errors?.employmentType) {
                      setError(prev => ({ ...prev, employmentType: false }));
                    }
                    setEmploymentType(e.target.value);
                  }}
                  label="Employment Type"
                  fullWidth>
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={'Full Time Employee'}>
                    Full Time Employee
                  </MenuItem>
                  <MenuItem value={'Part Time Employee'}>
                    Part Time Employee
                  </MenuItem>
                </Select>
                {errors?.employmentType && (
                  <FormHelperText>This is require field</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Most Recent Company"
                className={classes.textField}
                fullWidth
                value={recentCompany}
                error={errors?.recentCompany || false}
                onChange={e => {
                  if (errors?.recentCompany) {
                    setError(prev => ({ ...prev, recentCompany: false }));
                  }
                  setRecentCompany(e.target.value);
                }}
                helperText={errors?.recentCompany && 'This is require field'}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                label="Working From Year"
                className={classes.textField}
                fullWidth
                error={errors?.workingFromYear || false}
                value={workingFromYear}
                onChange={e => {
                  if (errors?.workingFromYear) {
                    setError(prev => ({ ...prev, workingFromYear: false }));
                  }
                  setWorkingFromYear(e.target.value);
                }}
                helperText={errors?.workingFromYear && 'This is require field'}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                label="Working To month"
                className={classes.textField}
                fullWidth
                error={errors?.workingToMonth || false}
                value={workingToMonth}
                onChange={e => {
                  if (errors?.workingToMonth) {
                    setError(prev => ({ ...prev, workingToMonth: false }));
                  }
                  setWorkingToMonth(e.target.value);
                }}
                helperText={errors?.workingToMonth && 'This is require field'}
              />
            </Grid>
            <Grid item xs={12} className={classes.buttons}>
              <Button
                variant="contained"
                color="primary"
                className={classes.saveBtn}
                onClick={handleSave}>
                SAVE
              </Button>
              <Paper style={{ marginRight: 15 }} className={classes.cancelBtn}>
                <ButtonBase>
                  <Typography variant="body1" className={classes.cancelBtnTxt}>
                    CANCEL
                  </Typography>
                </ButtonBase>
              </Paper>
            </Grid>
          </>
        ) : null}
        <Grid item xs={12} className={classes.addMore}>
          <Typography
            variant="body1"
            color="primary"
            onClick={() => setSaved(false)}>
            + ADD MORE
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export { EmpHistoryContent, EmpHistorySidebar };
