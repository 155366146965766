import React from 'react';
import {
  makeStyles,
  Typography,
  Avatar,
  Switch,
  TextField,
  Grid,
  Container,
  withStyles,
  Button,
  MenuItem,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddIcon from '@material-ui/icons/Add';

import Editor from '../../shared/Editor';
import Image from '../../../assets/images/requirementimage.png';

const useStyles = makeStyles(theme => ({
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  columnOptionContainer: {
    marginLeft: 60,
  },
  columnOption: {
    marginTop: 15,
  },
  smallIcon: {
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
    backgroundColor: 'transparent',
    marginRight: 5,
  },
  sideBarTitle: {
    marginLeft: 15,
  },

  //Right content classes

  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
  },
  editor: {
    marginTop: 15,
  },
  subtitle: {
    marginTop: 5,
    fontWeight: 'bold',
  },
  caption: {
    marginTop: 3,
  },
  autoComplete: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] ': {
      paddingTop: 3,
    },
  },
}));

const AntSwitch = withStyles(theme => ({
  root: {
    width: 29,
    height: 18,
    padding: 2,
    display: 'flex',
    margin: '0px 10px',
    borderRadius: 5,
  },
  switchBase: {
    padding: 3.5,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const keywords = [
  { title: 'Communication Skills' },
  { title: 'Computer Skills' },
  { title: 'People Skills' },
  { title: 'Leadership Skills' },
  { title: 'Organizational Skills' },
];

const StepOneSidebar = () => {
  const classes = useStyles();
  return (
    <Container>
      <div className={classes.titleRow}>
        <CheckCircleIcon
          style={{ color: '#FFF' }}
          className={classes.smallIcon}
          fontSize="large"
        />
        <Typography
          variant="subtitle1"
          color="textSecondary"
          className={classes.sideBarTitle}>
          Job Details
        </Typography>
      </div>
      <div className={classes.columnOptionContainer}>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Requirement Title
        </Typography>

        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Job Title
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Reporting To
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Reportees / Team
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Employment Type
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Job Details
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Key Result Areas
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Key Performance Indicators
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Desired Candidate Profile
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Annual CTC
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Other Salary Details
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Work Experiance
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Keywords
        </Typography>

        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Job Located
        </Typography>
      </div>
      <span
        style={{ display: 'flex', alignItems: 'center' }}
        className={classes.titleRow}>
        <Avatar
          className={classes.smallIcon}
          style={{ border: '0.5px solid #FFF' }}>
          2
        </Avatar>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          className={classes.sideBarTitle}>
          Additional Details
        </Typography>
      </span>
      <div className={classes.columnOptionContainer}>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Hiring For
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Visibility Settings
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Recruiter Name
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          No. of Vacancies
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Reference Code
        </Typography>
      </div>
    </Container>
  );
};

const StepOneContent = ({ Controller, useFormContext }) => {
  const { control } = useFormContext();
  const classes = useStyles();

  return (
    <Container style={{ paddingRight: 5, position: 'relative' }}>
      <Grid container>
        <Grid item xs={6} md container>
          <Grid item xs={12}>
            <div className={classes.leftTitle}>
              <Typography variant="h1">Job Details</Typography>
              <Typography variant="body1" className={classes.subtitle}>
                Lorem ipsum dolor sit amet.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <div className={classes.titleContainer}>
                <Typography variant="subtitle1">Requirement Title</Typography>
                <AntSwitch />
                <Typography variant="body1">Add to Job Post</Typography>
              </div>
              <Typography variant="body1" className={classes.caption}>
                Let's make it memorable
              </Typography>
              <Controller
                control={control}
                name="firstName"
                render={({ field }) => (
                  <TextField
                    id="first-name"
                    variant="outlined"
                    placeholder="Type Here"
                    margin="normal"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <div className={classes.titleContainer}>
                <Typography variant="subtitle1">Job Title</Typography>
                <AntSwitch />
                <Typography variant="body1">Add to Job Post</Typography>
              </div>
              <Typography variant="body1" className={classes.caption}>
                Let's make it memorable
              </Typography>
              <Controller
                control={control}
                name="firstName"
                render={({ field }) => (
                  <TextField
                    id="first-name"
                    variant="outlined"
                    placeholder="Use complete words Eg: Type senior Manager,not Sr. Mgr"
                    margin="normal"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <div className={classes.titleContainer}>
                <Typography variant="subtitle1">Reporting To</Typography>
                <AntSwitch />
                <Typography variant="body1">Add to Job Post</Typography>
              </div>
              <Typography variant="body1" className={classes.caption}>
                Let's make it memorable
              </Typography>
              <Controller
                control={control}
                name="firstName"
                render={({ field }) => (
                  <TextField
                    id="first-name"
                    variant="outlined"
                    placeholder="Type Here"
                    margin="normal"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <div className={classes.titleContainer}>
                <Typography variant="subtitle1">Reportees/Team</Typography>
                <AntSwitch />
                <Typography variant="body1">Add to Job Post</Typography>
              </div>
              <Controller
                control={control}
                name="firstName"
                render={({ field }) => (
                  <TextField
                    id="first-name"
                    variant="outlined"
                    placeholder="Type Here"
                    margin="normal"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <div className={classes.titleContainer}>
                <Typography variant="subtitle1">Employment Type</Typography>
                <AntSwitch />
                <Typography variant="body1">Add to Job Post</Typography>
              </div>
              <Typography variant="body1" className={classes.caption}>
                Let's make it memorable
              </Typography>
              <Controller
                control={control}
                name="firstName"
                render={({ field }) => (
                  <TextField
                    id="first-name"
                    variant="outlined"
                    placeholder="Type Here"
                    margin="normal"
                    fullWidth
                    select
                    defaultValue="Placeholder"
                    {...field}>
                    <MenuItem value="Placeholder">
                      Select Employment Type
                    </MenuItem>
                    <MenuItem value="per">Permanent</MenuItem>
                    <MenuItem value="temp">Temporary</MenuItem>
                  </TextField>
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <div className={classes.titleContainer}>
                <Typography variant="subtitle1">Job Details</Typography>
                <AntSwitch />
                <Typography variant="body1">Add to Job Post</Typography>
              </div>
              <Typography variant="body1" className={classes.caption}>
                Mention all job roles & responsibilities here
              </Typography>
              <div className={classes.editor}>
                <Editor />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <div className={classes.titleContainer}>
                <Typography variant="subtitle1"> Key Result Areas</Typography>
                <AntSwitch />
                <Typography variant="body1">Add to Job Post</Typography>
              </div>
              <Typography variant="body1" className={classes.caption}>
                Lorem ipsum dolor sit amet.
              </Typography>
              <div className={classes.editor}>
                <Editor />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <div className={classes.titleContainer}>
                <Typography variant="subtitle1">
                  Key Performance Indicators
                </Typography>
                <AntSwitch />
                <Typography variant="body1">Add to Job Post</Typography>
              </div>
              <Typography variant="body1" className={classes.caption}>
                Lorem ipsum dolor sit.
              </Typography>
              <div className={classes.editor}>
                <Editor />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <div className={classes.titleContainer}>
                <Typography variant="subtitle1">
                  Desired candidate profile
                </Typography>
                <AntSwitch />
                <Typography variant="body1">Add to Job Post</Typography>
              </div>
              <Typography variant="body1" className={classes.caption}>
                Lorem ipsum dolor sit.
              </Typography>
              <div className={classes.editor}>
                <Editor />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <div className={classes.titleContainer}>
                <Typography variant="subtitle1">Annual CTC</Typography>
                <AntSwitch />
                <Typography variant="body1">Add to Job Post</Typography>
              </div>
              <Typography variant="body1" className={classes.caption}>
                Lorem ipsum dolor sit.
              </Typography>
              <div>
                <div style={{ display: 'flex' }}>
                  <Controller
                    control={control}
                    name="firstName"
                    render={({ field }) => (
                      <TextField
                        id="first-name"
                        variant="outlined"
                        placeholder="Minimum Salary"
                        margin="normal"
                        fullWidth
                        defaultValue="rupees"
                        style={{ marginRight: 5, maxWidth: 50 }}
                        {...field}
                        select>
                        <MenuItem value="rupees">₹</MenuItem>
                        <MenuItem value="dollars">$</MenuItem>
                      </TextField>
                    )}
                  />
                  <Controller
                    control={control}
                    name="firstName"
                    render={({ field }) => (
                      <TextField
                        id="first-name"
                        variant="outlined"
                        placeholder="Minimum Salary"
                        margin="normal"
                        fullWidth
                        style={{ marginRight: 5 }}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="firstName"
                    render={({ field }) => (
                      <TextField
                        id="first-name"
                        variant="outlined"
                        placeholder="Maximum Salary"
                        margin="normal"
                        fullWidth
                        style={{ marginLeft: 5 }}
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <div className={classes.titleContainer}>
                <Typography variant="subtitle1">
                  Other Salary Details
                </Typography>
                <AntSwitch />
                <Typography variant="body1">Add to Job Post</Typography>
              </div>
              <Typography variant="body1" className={classes.caption}>
                Lorem ipsum dolor sit.
              </Typography>
              <div className={classes.editor}>
                <Editor />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <div className={classes.titleContainer}>
                <Typography variant="subtitle1">Work Experiance</Typography>
                <AntSwitch />
                <Typography variant="body1">Add to Job Post</Typography>
              </div>
              <Typography variant="body1" className={classes.caption}>
                Minimum Years of Experiance Required
              </Typography>
              <div style={{ display: 'flex' }}>
                <Controller
                  control={control}
                  name="firstName"
                  render={({ field }) => (
                    <TextField
                      id="first-name"
                      variant="outlined"
                      placeholder="Minimum Experiance"
                      margin="normal"
                      fullWidth
                      style={{ marginRight: 5 }}
                      {...field}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="firstName"
                  render={({ field }) => (
                    <TextField
                      id="first-name"
                      variant="outlined"
                      placeholder="Maximum Experiance"
                      margin="normal"
                      fullWidth
                      style={{ marginLeft: 5 }}
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <div className={classes.titleContainer}>
                <Typography variant="subtitle1">Keywords</Typography>
                <AntSwitch />
                <Typography variant="body1">Add to Job Post</Typography>
              </div>
              <Typography variant="body1" className={classes.caption}>
                Choose upto 5 skills
              </Typography>
              <Controller
                control={control}
                name="firstName"
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={keywords}
                    getOptionLabel={option => option.title}
                    renderInput={params => (
                      <TextField
                        id="first-name"
                        variant="outlined"
                        placeholder="Use complete words like Customer Executive not Cust. Exec."
                        margin="normal"
                        value=""
                        fullWidth
                        {...params}
                      />
                    )}
                    className={classes.autoComplete}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <div className={classes.titleContainer}>
                <Typography variant="subtitle1">Job Located</Typography>
                <AntSwitch />
                <Typography variant="body1">Add to Job Post</Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <Typography variant="body1" className={classes.caption}>
                  Enter City,State,Country
                </Typography>
                <Button color="primary">
                  <AddIcon />
                  Add More
                </Button>
              </div>
              <div style={{ display: 'flex' }}>
                <Controller
                  control={control}
                  name="firstName"
                  render={({ field }) => (
                    <TextField
                      id="first-name"
                      variant="outlined"
                      placeholder="Type Here"
                      margin="normal"
                      fullWidth
                      select
                      defaultValue="Placeholder"
                      style={{ marginRight: 5 }}
                      {...field}>
                      <MenuItem value="Placeholder">Country</MenuItem>
                      <MenuItem value="per">India</MenuItem>
                      <MenuItem value="temp">USA</MenuItem>
                    </TextField>
                  )}
                />
                <Controller
                  control={control}
                  name="firstName"
                  render={({ field }) => (
                    <TextField
                      id="first-name"
                      variant="outlined"
                      placeholder="Type Here"
                      margin="normal"
                      fullWidth
                      select
                      defaultValue="Placeholder"
                      style={{ marginLeft: 5, marginRight: 5 }}
                      {...field}>
                      <MenuItem value="Placeholder">Country</MenuItem>
                      <MenuItem value="per">India</MenuItem>
                      <MenuItem value="temp">USA</MenuItem>
                    </TextField>
                  )}
                />
                <Controller
                  control={control}
                  name="firstName"
                  render={({ field }) => (
                    <TextField
                      id="first-name"
                      variant="outlined"
                      placeholder="Type Here"
                      margin="normal"
                      fullWidth
                      select
                      defaultValue="Placeholder"
                      {...field}
                      style={{ marginLeft: 5 }}>
                      <MenuItem value="Placeholder">State</MenuItem>
                      <MenuItem value="per">Maharashtra</MenuItem>
                      <MenuItem value="temp">Delhi</MenuItem>
                    </TextField>
                  )}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ marginTop: 15 }}>
              <Typography variant="h1">Additional Details</Typography>
              <Typography variant="body1" className={classes.subtitle}>
                Lorem ipsum dolor sit amet.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <div className={classes.titleContainer}>
                <Typography variant="subtitle1">Hiring For</Typography>
                <AntSwitch />
                <Typography variant="body1">Add to Job Post</Typography>
              </div>
              <Typography variant="body1" className={classes.caption}>
                Mention this who you want to show this.
              </Typography>
              <Controller
                control={control}
                name="firstName"
                render={({ field }) => (
                  <TextField
                    id="first-name"
                    variant="outlined"
                    placeholder="No Employee Selected"
                    margin="normal"
                    fullWidth
                    {...field}
                  />
                )}
              />
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <AntSwitch />
                <Typography variant="body1">
                  Show this to job seekers
                </Typography>
              </span>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <div className={classes.titleContainer}>
                <Typography variant="subtitle1">Employment Type</Typography>
                <AntSwitch />
                <Typography variant="body1">Add to Job Post</Typography>
              </div>
              <Typography variant="body1" className={classes.caption}>
                Let's make it memorable
              </Typography>
              <Controller
                control={control}
                name="firstName"
                render={({ field }) => (
                  <TextField
                    id="first-name"
                    variant="outlined"
                    placeholder="Type Here"
                    margin="normal"
                    fullWidth
                    select
                    defaultValue="Placeholder"
                    {...field}>
                    <MenuItem value="Placeholder">Choose Visibility</MenuItem>
                    <MenuItem value="per">Visible to All</MenuItem>
                    <MenuItem value="temp">Visible to Me</MenuItem>
                  </TextField>
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <div className={classes.titleContainer}>
                <Typography variant="subtitle1">Recruiter Name</Typography>
                <AntSwitch />
                <Typography variant="body1">Add to Job Post</Typography>
              </div>
              <Typography variant="body1">Lorem ipsum dolor sit.</Typography>
              <Controller
                control={control}
                name="firstName"
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={keywords}
                    getOptionLabel={option => option.title}
                    renderInput={params => (
                      <TextField
                        id="first-name"
                        variant="outlined"
                        placeholder="Type here"
                        margin="normal"
                        fullWidth
                        {...field}
                      />
                    )}
                    className={classes.autoComplete}
                  />
                )}
              />

              <span style={{ display: 'flex', alignItems: 'center' }}>
                <AntSwitch />
                <Typography variant="body1">
                  Notify user about Assignment of Requirements
                </Typography>
              </span>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  marginTop: 5,
                }}>
                <div className={classes.titleContainer}>
                  <Typography variant="subtitle1">No. of Vacancies</Typography>
                  <AntSwitch />
                  <Typography variant="body1">Add to Job Post</Typography>
                </div>

                <Button color="primary">
                  <AddIcon />
                  Add More
                </Button>
              </div>

              <Controller
                control={control}
                name="firstName"
                render={({ field }) => (
                  <TextField
                    id="first-name"
                    variant="outlined"
                    placeholder="Use complete words like Customer Executive not Cust. Exec."
                    margin="normal"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <div className={classes.titleContainer}>
                <Typography variant="subtitle1">Reference Code</Typography>
                <AntSwitch />
                <Typography variant="body1">Add to Job Post</Typography>
              </div>
              <Typography variant="body1" className={classes.caption}>
                Any reference code you want to generate{' '}
              </Typography>
              <Controller
                control={control}
                name="firstName"
                render={({ field }) => (
                  <TextField
                    id="first-name"
                    variant="outlined"
                    placeholder="Enter Reference Code"
                    margin="normal"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <div>
            <img
              src={Image}
              alt="right"
              height="11%"
              width="40%"
              style={{ position: 'absolute', right: 0, bottom: -56 }}
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export { StepOneContent, StepOneSidebar };
