import React, { useState, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Alert from '@material-ui/lab/Alert';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

import People from '../../assets/images/right-section-people-forgetpasswordmailsent.png';
import api from '../../api/api';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      height: '100vh',
      maxWidth: '100vw',
    },
    peopleImg: {
      width: '50%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    rightContainer: {
      backgroundColor: theme.palette.secondary.main,
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    h1Text: {
      paddingTop: 40,
    },
    subtitle1Text: {
      padding: 50,
      paddingTop: 20,
    },
    leftContainer: {
      width: '100%',
      height: '100vh',
    },
    formField: {
      marginTop: 25,
    },
    formContainer: {
      width: '80%',
    },
    footer: {
      position: 'absolute',
      bottom: 30,
    },
    helperText: {
      color: 'red',
      marginLeft: 5,
    },
    alertErrorRoot: {
      marginTop: 5,
    },
  })
);

const SuccessPopup = ({ popUp, onClick }) => {
  return (
    <Dialog open={popUp[0]} maxWidth="xs">
      <DialogContent>
        <DialogContentText>
          <Typography variant="subtitle1">
            Password reset successfully. Now you can go back to login into your
            account
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClick}>
          Login
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ResetPassword = () => {
  const success = useState(false);
  const classes = useStyles();
  const history = useHistory();
  let { resetId } = useParams();
  const [error, setError] = useState('');
  const [visiblePassword, setVisibilePassword] = useState(false);
  const [visibleConfirmPassword, setVisibileConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const password = useRef({});
  password.current = watch('password');

  const gotoLogin = () => {
    success[1](false);
    history.replace('/signin');
  };

  const onSubmit = formData => {
    setLoading(true);
    const data = { uuid: resetId, password: formData.password };
    api({ path: '/admin/resetPassword', data: data })
      .then(res => {
        reset();
        setLoading(false);
        if (res.data.hasError) {
          setError(res.data?.message);
          return;
        }
        success[1](true);
      })
      .catch(err => setLoading(false));
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} md={5}>
          <Grid
            item
            md={12}
            container
            xs
            direction="column"
            justify="space-around"
            alignItems="center"
            className={classes.leftContainer}>
            <div className={classes.formContainer}>
              <div>
                <Typography variant="h1">Reset Password</Typography>
                <Typography variant="subtitle2">
                  Please create your new password
                </Typography>
              </div>

              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className={classes.formField}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="password"
                      type={visiblePassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              onClick={() =>
                                setVisibilePassword(!visiblePassword)
                              }>
                              {visiblePassword ? (
                                <VisibilityIcon style={{ color: '#707070' }} />
                              ) : (
                                <VisibilityOffIcon
                                  style={{ color: '#707070' }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      {...register('password', {
                        required: 'Please Enter a New Password',
                      })}
                      placeholder="Enter New Password"
                    />
                    {errors.password && (
                      <FormHelperText>
                        <Typography
                          variant="body1"
                          className={classes.helperText}>
                          {errors.password.message}
                        </Typography>
                      </FormHelperText>
                    )}
                  </div>
                  <div className={classes.formField}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      type={visibleConfirmPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              onClick={() =>
                                setVisibileConfirmPassword(
                                  !visibleConfirmPassword
                                )
                              }>
                              {visibleConfirmPassword ? (
                                <VisibilityIcon style={{ color: '#707070' }} />
                              ) : (
                                <VisibilityOffIcon
                                  style={{ color: '#707070' }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      name="confirmPassword"
                      {...register('confirmPassword', {
                        required: 'Please confirm new password',
                        validate: value =>
                          value === password.current ||
                          ' New Password and Confirm Password does not match',
                      })}
                      placeholder="Confirm New Password"
                    />
                    {errors.confirmPassword && (
                      <FormHelperText>
                        <Typography
                          variant="body1"
                          className={classes.helperText}>
                          {errors.confirmPassword.message}
                        </Typography>
                      </FormHelperText>
                    )}
                  </div>
                  <Alert
                    variant="filled"
                    icon={<ReportProblemOutlinedIcon />}
                    severity="error"
                    classes={{ root: classes.alertErrorRoot }}
                    style={{ display: error ? 'flex' : 'none' }}>
                    {error}
                  </Alert>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.formField}
                    style={{ padding: 10 }}
                    type="submit">
                    {loading ? (
                      <CircularProgress size={25} color="textSecondary" />
                    ) : (
                      ' Save New Password'
                    )}
                  </Button>
                </div>
              </form>
              <div className={classes.footer}>
                <Typography variant="subtitle2">
                  © 2021 RMS. All right Reserved{' '}
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} md={7}>
          <div className={classes.rightContainer}>
            <img src={People} alt="people" className={classes.peopleImg} />
            <div>
              <Typography
                variant="h1"
                align="center"
                color="textSecondary"
                className={classes.h1Text}>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Explicabo, dolores.
              </Typography>
              <Typography
                variant="subtitle1"
                align="center"
                color="textSecondary"
                className={classes.subtitle1Text}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia
                nisi corporis sed? Quos ut ea officia rem, corporis natus quod
                unde at? Nulla, ullam molestias.
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
      <SuccessPopup popUp={success} onClick={gotoLogin} />
    </div>
  );
};

export default ResetPassword;
