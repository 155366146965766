import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import People from '../../assets/images/right-section-people-forgetpasswordmailsent.png';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      height: '100vh',
      maxWidth: '100vw',
    },
    peopleImg: {
      width: '45%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    rightContainer: {
      backgroundColor: theme.palette.secondary.main,
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    h1Text: {
      paddingTop: 40,
    },
    subtitle1Text: {
      padding: 40,
      paddingTop: 20,
    },
    leftContainer: {
      width: '100%',
      height: '100vh',
    },
    formField: {
      marginTop: 25,
    },
    formContainer: {
      width: '80%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
    },
    footer: {
      position: 'absolute',
      bottom: 30,
    },
  })
);

const ForgetPasswordMailSent = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} md={5}>
          <Grid
            item
            md={12}
            container
            xs
            direction="column"
            alignItems="center"
            className={classes.leftContainer}>
            <div className={classes.formContainer}>
              <div>
                <Typography variant="h1">Check your email.</Typography>
                <Typography
                  variant="subtitle1"
                  style={{ fontWeight: 'normal' }}>
                  We have sent password recovery instructions on your mail.
                </Typography>
              </div>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.formField}
                  style={{ padding: 10 }}
                  onClick={() => history.push('/signin')}>
                  Back To Login
                </Button>
              </div>
              <div className={classes.footer}>
                <Typography variant="subtitle2">
                  © 2021 RMS. All right Reserved{' '}
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} md={7}>
          <div className={classes.rightContainer}>
            <img src={People} alt="people" className={classes.peopleImg} />
            <div>
              <Typography
                variant="h1"
                align="center"
                color="textSecondary"
                className={classes.h1Text}>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Explicabo, dolores.
              </Typography>
              <Typography
                variant="subtitle1"
                align="center"
                color="textSecondary"
                className={classes.subtitle1Text}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia
                nisi corporis sed? Quos ut ea officia rem, corporis natus quod
                unde at? Nulla, ullam molestias.
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ForgetPasswordMailSent;
