import {
  Avatar,
  ButtonBase,
  Checkbox,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import React from 'react';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import UpdateOutlinedIcon from '@material-ui/icons/UpdateOutlined';

const useStyles = makeStyles(theme => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  cardContainer: {
    display: 'flex',
    borderBottom: '1px solid #00000029',
    padding: '30px 0px',
    // marginTop: 20,
    // width: '100%',
  },
  checkDiv: {
    paddingTop: 10,
  },
  imageDiv: {
    margin: '0px 4px',
  },
  textDiv: {
    paddingLeft: 10,
    width: '100%',
  },
  yearDiv: {
    display: 'flex',
    alignItems: 'center',
    padding: '6px 0px',
  },
  skillDiv: {
    display: 'flex',
    padding: '0px 0px 6px',
  },
  mR_15: {
    marginRight: 15,
  },
  fS_16: {
    fontSize: 16,
    marginRight: 2,
  },
  saveBtn: {
    marginRight: '20px !important',
    padding: '10px 30px',
  },
  saveBtnTxt: {
    opacity: 0.5,
    fontWeight: 600,
  },
  applyButtonDiv: {
    display: 'flex',
    alignItems: 'center',
    margin: '20px 0px 10px',
  },
  detailViewCreatedOn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
}));

function JobDetailCard({
  position,
  company,
  years,
  salary,
  location,
  skills = [],
  desc,
  createOn,
  OnClickApply,
  detailView,
  OnDivClick,
}) {
  const classes = useStyles();

  return (
    <div
      className={classes.cardContainer}
      //   style={detailView ? { borderRight: '3px solid #E45D33' } : {}}
    >
      {!detailView && (
        <div className={classes.checkDiv}>
          <Checkbox
            inputProps={{ 'aria-label': 'primary checkbox' }}
            size="small"
          />
        </div>
      )}
      <div
        className={classes.imageDiv}
        style={detailView ? { paddingLeft: 10 } : {}}>
        <Avatar
          alt="Remy Sharp"
          src="/static/images/avatar/1.jpg"
          className={classes.large}
        />
      </div>
      <div className={classes.textDiv}>
        <div style={{ width: '100%' }} onClick={OnDivClick}>
          <div className={classes.detailViewCreatedOn}>
            <Typography variant="subtitle1">{position}</Typography>
            {detailView && (
              <Typography variant="body1" style={{ marginRight: 15 }}>
                {createOn}
              </Typography>
            )}
          </div>
          <Typography variant="body1">{company}</Typography>

          <div className={classes.yearDiv}>
            <LocalMallOutlinedIcon variant="body1" className={classes.fS_16} />
            <Typography variant="body1" className={classes.mR_15}>
              {years}
            </Typography>
            {/* <Typography variant="body1">$</Typography> */}
            <Typography variant="body1" className={classes.mR_15}>
              ₹ {salary}
            </Typography>
            <LocationOnOutlinedIcon variant="body1" className={classes.fS_16} />
            <Typography variant="body1">{location}</Typography>
          </div>
          {!detailView && (
            <>
              <div className={classes.skillDiv}>
                {skills &&
                  skills?.map((item, i) => {
                    return (
                      <>
                        {i === 0 ? (
                          <Typography
                            variant="body1"
                            style={{ marginRight: 5 }}>
                            {item}
                          </Typography>
                        ) : (
                          <Typography
                            variant="body1"
                            style={{ marginRight: 5 }}>
                            {' '}
                            &#8226; {item}
                          </Typography>
                        )}
                      </>
                    );
                  })}
              </div>
              <Typography variant="body1">{desc}</Typography>
            </>
          )}
        </div>
        {!detailView && (
          <div className={classes.applyButtonDiv}>
            <Paper style={{ marginRight: 15 }} className={classes.saveBtn}>
              <ButtonBase>
                <Typography
                  variant="body1"
                  className={classes.saveBtnTxt}
                  onClick={OnClickApply}>
                  APPLY NOW
                </Typography>
              </ButtonBase>
            </Paper>
            <UpdateOutlinedIcon className={classes.fS_16} />
            <Typography variant="body1">{createOn}</Typography>
          </div>
        )}
      </div>
    </div>
  );
}

export default JobDetailCard;
