import React from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  Paper,
  Button,
  Avatar,
  TextField,
  MenuItem,
} from '@material-ui/core';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DraftsIcon from '@material-ui/icons/DraftsOutlined';
import PhoneIcon from '@material-ui/icons/PhoneOutlined';

import Personimg from '../../assets/images/face.jpg';

const useStyles = makeStyles(theme => ({
  inlineContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  paper: {
    marginTop: 20,
    padding: 15,
    marginBottom: 20,
    paddingBottom: 30,
  },

  profileSidebar: {
    borderRadius: 4,
    position: 'relative',
  },
  profileUpContainer: {
    minHeight: 130,
    backgroundColor: '#141d3d',
  },
  applybtnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  applyBtn: {
    '&:hover': {
      backgroundColor: '#299c14',
    },
    backgroundColor: '#299c14',
    color: '#FFF',
    borderRadius: '4px 0px 0px 4px',
    fontSize: 12,
    textTransform: 'uppercase',
    padding: '4px 5px',
    marginTop: 15,
    fontWeight: 'bold',
  },
  rejectBtn: {
    padding: '4px 5px',
    marginTop: 15,
    fontSize: 12,
    borderRadius: '0px 4px 4px 0px',
    marginRight: 15,
    fontWeight: 'bold',
  },
  largeAvatar: {
    height: theme.spacing(10),
    width: theme.spacing(10),
    position: 'absolute',
    left: 15,
    top: 100,
  },
  profileSideDown: {
    marginTop: 60,
  },
  divider: {
    borderLeft: `1.4px solid ${theme.palette.primary.main}`,
    height: 15,
    paddingLeft: 5,
    paddingRight: 5,
  },
  bold: {
    fontWeight: 'bold',
    marginLeft: 5,
  },
  editBtn: {
    padding: '6px 0px',
  },
  horizontalDivider: {
    borderBottom: '1px solid #0000004D',
    margin: '5px 0px',
  },
  contactDetails: {
    margin: '20px 15px',
  },
  personalInfo: {
    padding: 15,
  },
  keySkills: {
    marginLeft: 15,
    paddingBottom: 30,
  },
  keyContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  key: {
    padding: 10,
    marginRight: 5,
    marginBottom: 5,
  },
}));

const keySkills = [
  'Figma',
  'User Interface designing',
  'UX',
  'Photoshop',
  'User Experience Design',
];

const SideBar = () => {
  const classes = useStyles();

  return (
    <div>
      <Paper className={classes.profileSidebar}>
        <div className={classes.profileUpContainer}>
          <div className={classes.applybtnContainer}>
            <Button
              variant="contained"
              className={classes.applyBtn}
              endIcon={<ArrowDropDownIcon style={{ paddingRight: 3 }} />}>
              Applied - Matching
            </Button>
            <Button className={classes.rejectBtn} variant="contained">
              Reject
            </Button>
          </div>
        </div>
        <Avatar src={Personimg} alt="profile" className={classes.largeAvatar} />
        <div className={classes.profileSideDown}>
          <div style={{ marginLeft: 15 }}>
            <Typography variant="subtitle1">Rahul Sahu</Typography>
            <Typography variant="body1">
              UI & UX Designer & Front-end Developer
            </Typography>
          </div>
          <div className={classes.inlineContainer}>
            <Button className={classes.editBtn} color="primary">
              Edit
            </Button>
            <div className={classes.divider} />
            <Button className={classes.editBtn} color="primary">
              View Resume
            </Button>
          </div>
        </div>
        <div className={classes.horizontalDivider} />
        <div className={classes.contactDetails}>
          <div className={classes.inlineContainer}>
            <DraftsIcon color="primary" />
            <Typography style={{ marginLeft: 5 }} variant="body1">
              rahulrms@rms.com
            </Typography>
          </div>
          <div className={classes.inlineContainer} style={{ marginTop: 5 }}>
            <PhoneIcon color="primary" />
            <Typography style={{ marginLeft: 5 }} variant="body1">
              +91 7014183541
            </Typography>
          </div>
          <div style={{ marginTop: 10 }}>
            <Typography variant="body1" style={{ marginBottom: 5 }}>
              Call Status
            </Typography>
            <Paper elevation={5} style={{ width: 130 }}>
              <TextField
                variant="outlined"
                margin="none"
                defaultValue="placeholder"
                select>
                <MenuItem value="placeholder">Set Call Status</MenuItem>
                <MenuItem value="Available">Called</MenuItem>
                <MenuItem value="Offline">Messaged</MenuItem>
                <MenuItem value="Offline">Call Not Picked</MenuItem>
                <MenuItem value="Offline">Not Reachable</MenuItem>
                <MenuItem value="Offline">Send Whatsapp Message</MenuItem>
              </TextField>
            </Paper>
          </div>
        </div>
        <div className={classes.horizontalDivider} />
        <div className={classes.personalInfo}>
          <Typography variant="subtitle1" style={{ marginBottom: 10 }}>
            Personal Information
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography variant="body1">Email</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Hardenrahul@gmail.com</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Contact No.</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">+91 7014183712</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">DOB</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Feb 23 1992</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Marrital Status</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Married</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Gender</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Male</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Current Location</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Jaipur, Rajastan, India</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Employment Status</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Remote Work</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Category</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">General</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Physically Challenged</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">No</Typography>
            </Grid>
          </Grid>
        </div>
        <div className={classes.horizontalDivider} />
        <div className={classes.keySkills}>
          <Typography variant="subtitle1" style={{ marginBottom: 10 }}>
            Key Skills
          </Typography>
          <div className={classes.keyContainer}>
            {keySkills.map(key => (
              <Paper component="span" className={classes.key}>
                {key}
              </Paper>
            ))}
          </div>
        </div>
      </Paper>
      <Paper className={classes.paper}>
        <form>
          <Typography variant="subtitle1" style={{ marginBottom: 10 }}>
            Summary
          </Typography>
          <TextField
            variant="outlined"
            InputProps={{
              style: {
                height: 150,
                flexDirection: 'column',
              },
            }}
            placeholder="Type Here"
            fullWidth
            multiline
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.bold}
            fullWidth
            style={{ marginTop: 15 }}>
            Submit
          </Button>
        </form>
      </Paper>
    </div>
  );
};

export default SideBar;
