import React from 'react';
import {
  Paper,
  Grid,
  makeStyles,
  Typography,
  Avatar,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

import dribble from '../../assets/logo/dribbble.png';
import behance from '../../assets/logo/behance.png';
import google from '../../assets/logo/google.png';

const useStyles = makeStyles(theme => ({
  root: {},
  paper: {
    padding: '20px 20px 30px 20px',
  },
  heading: {
    marginBottom: 10,
  },
  detailTitle: {
    color: '#b8b8b8',
    marginBottom: 5,
  },
  detailValue: {
    fontWeight: 'bold',
  },
  workExp: {
    marginTop: 5,
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    height: theme.spacing(8),
    width: theme.spacing(8),
  },
  divider: {
    borderBottom: '1px solid #0000001A',
    margin: '15px 10px 15px 70px',
  },
  educationContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  education: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: '40%',
    padding: 10,
    marginRight: 10,
    marginTop: 10,
  },
  tableCell: {
    paddingLeft: 0,
  },
  checkBox: {
    color: '#299c14',
  },
  body1: {
    opacity: 0.8,
    marginTop: 3,
    fontWeight: 600,
  },
  inlineContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  fullDivider: {
    borderBottom: '1px solid #0000001A',
    margin: '15px 0px',
  },
  verifyIcon: {
    marginLeft: 5,
  },
}));

const educationalDetails = [
  { degree: 'Bachelor of Commerce', college: 'University of Rajastan' },
  { degree: 'Higher Senior Secondary', college: 'Raj Shree Public School' },
  { degree: 'Senior Secondary School', college: 'Shared Public School' },
];

const itSkills = [
  {
    skillName: 'SASS',
    version: '3.2',
    lastUsed: '10 Years',
    exp: '10 Years 5 Months',
  },
  {
    skillName: 'JQUERY',
    version: '3.2',
    lastUsed: '5 Years',
    exp: '10 Years 5 Months',
  },
  {
    skillName: 'CSS',
    version: '3.2',
    lastUsed: '2 Years',
    exp: '10 Years 5 Months',
  },
];

const languageKnown = [
  { name: 'Hindi', proficiency: 'Proficient', r: 1, w: 1, s: 1 },
  { name: 'English', proficiency: 'Beginner', r: 1 },
];

const projects = [
  {
    title: 'Economic Tours and Travels',
    funcArea: 'Front-end Development',
    skills: 'MySQL,HTML, Javascript,XHTML',
  },
  {
    title: 'Veena Music Mobile App & Desktop Website',
    funcArea: 'Creative UI & UX Designer',
    skills: 'MySQL,HTML, Javascript,XHTML',
    description:
      'To assist team to gather information about genes involved in causing cancer from articles and other sources. Helping team to create and publish material on a website',
  },
];

const MiddleContent = () => {
  const classes = useStyles();

  return (
    <div>
      {/* NOTE:Candidate Details */}
      <Paper className={classes.paper} variant="outlined">
        <Typography variant="subtitle1" className={classes.heading}>
          Candidate Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.detailTitle}>
              Experiance
            </Typography>
            <div className={classes.inlineContainer}>
              <Typography variant="body1" className={classes.detailValue}>
                8 Years
              </Typography>
              <VerifiedUserIcon
                className={classes.verifyIcon}
                color="primary"
                fontSize="small"
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.detailTitle}>
              Salary
            </Typography>
            <Typography variant="body1" className={classes.detailValue}>
              ₹ 4.75 L.P.A
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.detailTitle}>
              AGE
            </Typography>
            <Typography variant="body1" className={classes.detailValue}>
              25 Years
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.detailTitle}>
              ROLE
            </Typography>
            <Typography variant="body1" className={classes.detailValue}>
              Graphic Designer
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.detailTitle}>
              INDUSTRY
            </Typography>
            <div className={classes.inlineContainer}>
              <Typography variant="body1" className={classes.detailValue}>
                IT Software
              </Typography>
              <VerifiedUserIcon
                className={classes.verifyIcon}
                color="primary"
                fontSize="small"
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.detailTitle}>
              FUNCTIONAL AREA
            </Typography>
            <Typography variant="body1" className={classes.detailValue}>
              Creative, Design
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.detailTitle}>
              CURRENT DESIGNATION
            </Typography>
            <Typography variant="body1" className={classes.detailValue}>
              Creative Designer
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.detailTitle}>
              HIGHEST DEGREE
            </Typography>
            <Typography variant="body1" className={classes.detailValue}>
              Post Graduation
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.detailTitle}>
              NOTICE PERIOD
            </Typography>
            <div className={classes.inlineContainer}>
              <Typography variant="body1" className={classes.detailValue}>
                45 Days
              </Typography>
              <VerifiedUserIcon
                className={classes.verifyIcon}
                color="primary"
                fontSize="small"
              />
            </div>
          </Grid>
        </Grid>
      </Paper>
      {/* NOTE:Work Experiance */}
      <Paper
        className={classes.paper}
        variant="outlined"
        style={{ marginTop: 15 }}>
        <Typography variant="subtitle1" className={classes.heading}>
          Work Experience
        </Typography>
        <div className={classes.workExp}>
          <Avatar src={behance} alt="company" className={classes.avatar} />
          <div style={{ marginLeft: 10 }}>
            <Typography variant="subtitle1">UI Designer</Typography>
            <Typography variant="body1">Behance</Typography>
            <Typography variant="body1" style={{ marginTop: 5 }}>
              Jun 2013 to Feb 2014(8 Months)
            </Typography>
          </div>
        </div>
        <div className={classes.divider} />
        <div className={classes.workExp}>
          <Avatar src={dribble} alt="company" className={classes.avatar} />
          <div style={{ marginLeft: 10 }}>
            <Typography variant="subtitle1">Creative Designer</Typography>
            <Typography variant="body1">Dribble</Typography>
            <Typography variant="body1" style={{ marginTop: 5 }}>
              Jun 2010 to Feb 2013(8 Months)
            </Typography>
          </div>
        </div>
        <div className={classes.divider} />
        <div className={classes.workExp}>
          <Avatar src={google} alt="company" className={classes.avatar} />
          <div style={{ marginLeft: 10 }}>
            <Typography variant="subtitle1">Graphic Designer</Typography>
            <Typography variant="body1">Dribble</Typography>
            <Typography variant="body1" style={{ marginTop: 5 }}>
              Jun 2010 to Feb 2013(8 Months)
            </Typography>
          </div>
        </div>
      </Paper>
      {/* NOTE:Education Qualification */}
      <Paper
        className={classes.paper}
        variant="outlined"
        style={{ marginTop: 15 }}>
        <Typography variant="subtitle1" className={classes.heading}>
          Education and Qualification
        </Typography>
        <div className={classes.educationContainer}>
          {educationalDetails.map(education => (
            <Paper variant="outlined" className={classes.education}>
              <div>
                <Typography variant="subtitle1">{education.degree}</Typography>
                <Typography variant="body1">{education.college}</Typography>
              </div>
              <Avatar src={dribble} alt="edu" />
            </Paper>
          ))}
        </div>
      </Paper>
      {/* NOTE:IT SKILLS */}
      <Paper
        className={classes.paper}
        variant="outlined"
        style={{ marginTop: 15 }}>
        <Typography variant="subtitle1" className={classes.heading}>
          IT Skills
        </Typography>
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell} align="left">
                  <Typography variant="body1" className={classes.bold}>
                    SKILL NAME
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                  <Typography variant="body1" className={classes.bold}>
                    VERSION
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                  <Typography variant="body1" className={classes.bold}>
                    LAST USED
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                  <Typography variant="body1" className={classes.bold}>
                    EXPERIENCE
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {itSkills.map(row => (
                <TableRow key={row.name}>
                  <TableCell
                    className={classes.tableCell}
                    component="th"
                    align="left"
                    scope="row">
                    {row.skillName}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="left">
                    {row.version}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="left">
                    {row.lastUsed}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="left">
                    {row.exp}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* NOTE:Language Known */}
      <Paper
        className={classes.paper}
        variant="outlined"
        style={{ marginTop: 15 }}>
        <Typography variant="subtitle1" className={classes.heading}>
          Language Known
        </Typography>
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell} align="left">
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    LANGUAGE
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    PROFICIENCY
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    READ
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    WRITE
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    SPEAK
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {languageKnown.map(row => (
                <TableRow key={row.name}>
                  <TableCell
                    className={classes.tableCell}
                    component="th"
                    align="left"
                    scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="left">
                    {row.proficiency}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="left">
                    {row.r && <CheckBoxIcon className={classes.checkBox} />}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="left">
                    {row.w && <CheckBoxIcon className={classes.checkBox} />}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="left">
                    {row.s && <CheckBoxIcon className={classes.checkBox} />}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* NOTE: Projects */}
      <Paper
        className={classes.paper}
        variant="outlined"
        style={{ marginTop: 15 }}>
        <Typography variant="subtitle1" className={classes.heading}>
          Projects
        </Typography>
        {projects.map((project, index) => (
          <>
            <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
              {project.title}
            </Typography>
            <Typography variant="body1" className={classes.body1}>
              {project.funcArea}
            </Typography>
            <div style={{ marginTop: 5 }}>
              <Typography
                variant="body1"
                className={classes.body1}
                component="span">
                Skill Used:
              </Typography>
              <Typography component="span" className={classes.bold}>
                {project.skills}
              </Typography>
            </div>
            {project.description && (
              <div style={{ marginTop: 5 }}>
                <Typography
                  variant="body1"
                  component="span"
                  className={classes.body1}>
                  Role Description:
                </Typography>

                <Typography
                  variant="body1"
                  component="span"
                  className={classes.bold}>
                  {project.description}
                </Typography>
              </div>
            )}
            {projects.length !== index + 1 && (
              <div className={classes.fullDivider} />
            )}
          </>
        ))}
      </Paper>
    </div>
  );
};

export default MiddleContent;
