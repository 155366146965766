import React, { useState, useEffect, useContext } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/EditOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import {
  Container,
  Button,
  Paper,
  TableContainer,
  TableRow,
  Table,
  TableCell,
  TableBody,
  Typography,
  TableHead,
  Grid,
  IconButton,
  CircularProgress,
} from '@material-ui/core';

import CreateStatusDialog from './CreateStatusDialog';
import UserContext from '../../Context/UserContext';
import SnackBarContext from '../../Context/SnackBarContext';
import api from '../../api/api';

const useStyles = makeStyles(theme =>
  createStyles({
    titleRow: {
      minHeight: 100,
    },
    container: {
      // marginTop: 25,
    },
    titleDiv: {
      paddingLeft: 25,
    },
    table: {
      borderRight: '1px solid #00000033',
      borderTop: '1px solid #00000033',
    },
    tableCell: {
      borderBottom: 'none',
    },
    tableHead: {
      '&:hover': {
        borderBottom: `1px solid #00000033`,
      },
      borderBottom: `1px solid #00000033`,
    },
    btn: {
      textTransform: 'capitalize',
    },
  })
);

const ManageStatus = () => {
  const classes = useStyles();
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [editIconHead, setEditIconHead] = useState('');
  const [statusData, setStatusData] = useState(null);
  const { authState } = useContext(UserContext);
  const { alerts } = useContext(SnackBarContext);
  const [editStatusData, setEditStatusData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!statusDialogOpen) {
      api({ path: '/admin/status/getStatus' }).then(res => {
        if (res.data.hasError) {
          setLoading(false);
          return;
        }
        setLoading(false);
        res?.data && setStatusData(res.data);
      });
    }
  }, [statusDialogOpen]);

  const StatusRow = ({ row, index, totalRecords, headName = '' }) => {
    return (
      <>
        <TableRow hover align="left">
          <TableCell className={classes.tableCell}>
            <Typography variant="body1">{row?.status_head}</Typography>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Typography variant="body1">{index}</Typography>
          </TableCell>
          {headName !== 'call_status' && (
            <TableCell className={classes.tableCell}>
              {index === 0 && (
                <Button
                  variant="contained"
                  className={classes.btn}
                  size="small"
                  color="primary">
                  <FiberManualRecordIcon
                    style={{ fontSize: 12, marginRight: 3 }}
                  />
                  Started
                </Button>
              )}
              {index === totalRecords - 1 && (
                <Button
                  variant="contained"
                  className={classes.btn}
                  size="small"
                  color="primary">
                  <FiberManualRecordIcon
                    style={{ fontSize: 12, marginRight: 3 }}
                  />
                  End
                </Button>
              )}
            </TableCell>
          )}
          <TableCell className={classes.tableCell} />
        </TableRow>
      </>
    );
  };

  const handleEditIconClick = headName => {
    setStatusDialogOpen(true);
    setEditIconHead(headName);
    setEditStatusData(statusData);
  };

  return (
    <Container maxWidth="xl" disableGutters>
      <Grid container>
        <Paper variant="outlined" style={{ width: '100%' }}>
          <Grid item xs={12}>
            <Grid container alignItems="center" className={classes.titleRow}>
              <Grid item xs={8} className={classes.titleDiv}>
                <Typography variant="subtitle1">Status</Typography>
                <Typography variant="body1">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Quisquam, nobis.
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                style={{
                  padding: '0px 10px',
                }}></Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} container md>
            <Grid item xs={12} md={4} className={classes.table}>
              <TableContainer className={classes.container}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHead}>
                        <Typography variant="subtitle1">
                          Profile Status
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: 'normal' }}>
                          Index
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: 'normal' }}>
                          Status
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableHead}>
                        <IconButton
                          onClick={() => handleEditIconClick('profile_status')}>
                          <EditIcon style={{ color: '#7d7d7d' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableCell align="center" colspan={7}>
                        <CircularProgress />
                      </TableCell>
                    ) : statusData?.profile_status ? (
                      statusData?.profile_status?.map((row, index) => (
                        <StatusRow
                          row={row}
                          index={index}
                          headName="profile_status"
                          totalRecords={statusData?.profile_status?.length}
                        />
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={7}>
                          <Typography variant="subtitle1">
                            No Data Found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={4} className={classes.table}>
              <TableContainer className={classes.container}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ height: '100%' }}>
                        <Typography variant="subtitle1">
                          Requirement Status
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: 'normal' }}>
                          Index
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: 'normal' }}>
                          Status
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() =>
                            handleEditIconClick('requirement_status')
                          }>
                          <EditIcon style={{ color: '#7d7d7d' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableCell align="center" colspan={7}>
                        <CircularProgress />
                      </TableCell>
                    ) : statusData?.requirement_status ? (
                      statusData?.requirement_status?.map((row, index) => (
                        <StatusRow
                          row={row}
                          index={index}
                          totalRecords={statusData?.requirement_status?.length}
                          headName="requirement_status"
                        />
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={7}>
                          <Typography variant="subtitle1">
                            No Data Found
                          </Typography>{' '}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={4}>
              <TableContainer className={classes.container}>
                <Table className={classes.table}>
                  <TableHead>
                    {' '}
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle1">Call Status</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: 'normal' }}>
                          Index
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <IconButton
                          onClick={() => handleEditIconClick('call_status')}>
                          <EditIcon style={{ color: '#7d7d7d' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableCell align="center" colspan={7}>
                        <CircularProgress />
                      </TableCell>
                    ) : statusData?.call_status ? (
                      statusData?.call_status?.map((row, index) => (
                        <StatusRow
                          row={row}
                          index={index}
                          totalRecords={statusData?.call_status?.length}
                          headName="call_status"
                        />
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={7}>
                          <Typography variant="subtitle1">
                            No Data Found
                          </Typography>{' '}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <CreateStatusDialog
        editIconHead={editIconHead}
        statusDialogOpen={statusDialogOpen}
        setStatusDialogOpen={setStatusDialogOpen}
        editStatusData={editStatusData}
        setEditStatusData={setEditStatusData}
        setEditIconHead={setEditIconHead}
        alerts={alerts}
        authState={authState}
      />
    </Container>
  );
};

export default ManageStatus;
