import React from 'react';
import {
  Grid,
  Container,
  makeStyles,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  useForm,
  Controller,
  FormProvider,
  useFormContext,
} from 'react-hook-form';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import clsx from 'clsx';

import { StepOneSidebar, StepOneContent } from './SubPageWidgets/StepOne';
import { StepTwoSidebar, StepTwoContent } from './SubPageWidgets/StepTwo';
import { StepThreeSidebar, StepThreeContent } from './SubPageWidgets/StepThree';
import Logo from '../../assets/logo/logo.png';
import JobPreview from './SubPageWidgets/JobPreview';
import ExportDialog from './SubPageWidgets/ExportDialog';

const useStepIconStyles = makeStyles({
  root: {
    border: '1px solid #949494',
    zIndex: 1,
    color: '#fff',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: '#3ca429',
  },
  completed: {
    backgroundColor: '#3ca429',
  },
});

const useStyles = makeStyles(theme => ({
  leftContainer: {
    backgroundColor: theme.palette.secondary.main,
    paddingLeft: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  leftContent: {
    marginTop: 30,
  },

  rightContainer: {
    marginTop: 25,
    marginLeft: 25,
  },
  navigationActionContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  navigationBottomContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    margin: '0px 0px 20px 55px',
  },
  sidebarBottomContent: {
    margin: '50px 15px',
    textAlign: 'justify',
  },
  backButton: {
    fontWeight: 'bold',
    marginRight: 10,
  },
}));

function getSteps() {
  return ['Job Details', 'Posting Details', 'Search Strategy'];
}

const CreateRequirement = () => {
  const classes = useStyles();
  const methods = useForm({});
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const jobPreview = React.useState(false);
  const exportDialog = React.useState(false);

  const handleEditPdf = () => {
    jobPreview[1](false);
    exportDialog[1](true);
  };

  const handleNext = () => {
    steps.length > activeStep + 1 &&
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    activeStep === steps.length - 1 && jobPreview[1](true);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const StepIcon = props => {
    const classes = useStepIconStyles();
    const { active, completed, icon } = props;

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}>
        {completed || icon === activeStep + 1 ? (
          <CheckCircleOutlinedIcon />
        ) : (
          <RadioButtonUncheckedIcon />
        )}
      </div>
    );
  };

  StepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node,
  };

  const Navigation = () => {
    return (
      <Grid container>
        <Grid item xs={8}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel StepIconComponent={StepIcon}>
                  <Typography variant="subtitle1">{label}</Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={4}>
          {activeStep === steps.length ? (
            <div>
              <Button
                onClick={handleReset}
                color="primary"
                variant="contained"
                style={{ marginTop: 20 }}>
                Reset
              </Button>
            </div>
          ) : (
            <div className={classes.navigationActionContainer}>
              <div>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.backButton}>
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}>
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next Step'}
                </Button>
              </div>
            </div>
          )}
        </Grid>
      </Grid>
    );
  };

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return (
          <StepOneContent
            Controller={Controller}
            useFormContext={useFormContext}
          />
        );
      case 1:
        return (
          <StepTwoContent
            Controller={Controller}
            useFormContext={useFormContext}
          />
        );
      case 2:
        return (
          <StepThreeContent
            Controller={Controller}
            useFormContext={useFormContext}
          />
        );
      default:
        return 'Unknown stepIndex';
    }
  };

  const getStepSidebar = stepIndex => {
    switch (stepIndex) {
      case 0:
        return <StepOneSidebar />;
      case 1:
        return <StepTwoSidebar />;
      case 2:
        return <StepThreeSidebar />;
      default:
        return 'Unknown stepIndex';
    }
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Grid container direction="row" justify="space-between">
        <Grid item xs={3} className={classes.leftContainer}>
          <div>
            <img src={Logo} alt="logo" />
            <div className={classes.leftContent}>
              {getStepSidebar(activeStep)}
            </div>
          </div>
          <div className={classes.sidebarBottomContent}>
            <Typography
              variant="h1"
              color="textSecondary"
              style={{ fontWeight: 'normal', color: '#FFF' }}>
              Lorem ipsum has been the industry's standard.
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat
              necessitatibus laudantium aspernatur! Saepe, labore nam!
            </Typography>
          </div>
        </Grid>
        <Grid item xs={9}>
          <div>
            <FormProvider {...methods}>
              <form>
                <Grid container>
                  <Grid item xs={12}>
                    <div className={classes.navigation}>
                      <Navigation />
                      <div className={classes.rightContainer}>
                        {getStepContent(activeStep)}
                      </div>
                    </div>
                  </Grid>
                  <Grid xs={7}>
                    <div className={classes.navigationBottomContainer}>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.backButton}>
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}>
                        {activeStep === steps.length - 1
                          ? 'Finish'
                          : 'Next Step'}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </div>
        </Grid>
      </Grid>
      <JobPreview open={jobPreview} handleEditPdf={handleEditPdf} />
      <ExportDialog open={exportDialog} />
    </Container>
  );
};

export default CreateRequirement;
