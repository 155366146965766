import React, { useContext } from 'react';
import {
  Grid,
  Container,
  makeStyles,
  Typography,
  Button,
  Paper,
  ButtonBase,
  CircularProgress,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import {
  useForm,
  Controller,
  FormProvider,
  useFormContext,
} from 'react-hook-form';
import { BasicInfoSidebar, BasicInfoContent } from './Steps/BasicInformation';

import Logo from '../../assets/logo/logo.png';
import {
  EmpHistoryContent,
  EmpHistorySidebar,
} from './Steps/EmploymentHistory';
import { VerificationContent, VerificationSidebar } from './Steps/Verification';
import api from '../../api/api';
import SnackBarContext from '../../Context/SnackBarContext';

const useStyles = makeStyles(theme => ({
  leftContainer: {
    backgroundColor: theme.palette.secondary.main,
    paddingLeft: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100vh',
  },
  leftContent: {
    marginTop: 30,
  },

  rightContainer: {
    marginTop: 25,
    marginLeft: 25,
  },
  navigationActionContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    marginTop: 15,
    justifyContent: 'flex-end',
    marginRight: '10%',
  },
  navigationBottomContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    margin: '0px 0px 20px 55px',
  },
  sidebarBottomContent: {
    margin: '50px 15px',
    textAlign: 'justify',
  },
  saveBtn: {
    marginRight: 10,
    padding: '10px 30px',
  },
  saveBtnTxt: {
    opacity: 0.5,
    fontWeight: 600,
  },
  exportButton: {
    fontWeight: 600,
    marginRight: 25,
  },
  finishBtn: {
    fontWeight: 600,
    padding: '7px 30px',
  },
  inlineContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  bottomButton: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
  },
}));

const OnBoarding = () => {
  const classes = useStyles();
  const methods = useForm({});
  const savedDetails = React.useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const isCodeVerified = React.useState(false);
  const { alerts } = useContext(SnackBarContext);
  const history = useHistory();

  const steps = [
    'Basic Information',
    'Employment History',
    'Verification Email',
  ];

  const handleNext = () => {
    steps.length > activeStep + 1 &&
      setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const Navigation = () => {
    return (
      <Grid container>
        <Grid item xs={7}></Grid>
        <Grid item xs={5}>
          <div className={classes.navigationActionContainer}>
            <Paper className={classes.saveBtn}>
              <ButtonBase>
                <Typography
                  variant="body1"
                  className={classes.saveBtnTxt}
                  onClick={() => {
                    sessionStorage.clear();
                    history.replace('/');
                  }}>
                  LOG OUT
                </Typography>
              </ButtonBase>
            </Paper>
          </div>
        </Grid>
      </Grid>
    );
  };

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return (
          <BasicInfoContent
            Controller={Controller}
            useFormContext={useFormContext}
          />
        );
      case 1:
        return (
          <EmpHistoryContent
            // Controller={Controller}
            useFormContext={useFormContext}
            savedDetails={savedDetails}
          />
        );
      case 2:
        return (
          <VerificationContent
            isCodeVerified={isCodeVerified}
            useFormContext={useFormContext}
          />
        );
      default:
        return 'Unknown stepIndex';
    }
  };

  const getStepSidebar = stepIndex => {
    switch (stepIndex) {
      case 0:
        return <BasicInfoSidebar />;
      case 1:
        return <EmpHistorySidebar />;
      case 2:
        return <VerificationSidebar />;
      default:
        return 'Unknown stepIndex';
    }
  };

  const onSubmit = data => {
    setLoading(true);
    if (sessionStorage.getItem('candidateId')) {
      if (activeStep === 0 || activeStep === 2) {
        data = {
          ...data,
          candidateId: sessionStorage.getItem('candidateId'),
        };
      } else if (activeStep === 1) {
        if (savedDetails[0].length === 0) {
          setLoading(false);
          alerts[1]({
            error: true,
            message: 'Please Add Employment History',
            state: true,
          });
          return;
        }
        let temp = {
          candidateId: sessionStorage.getItem('candidateId'),
          role: [],
          employment_type: [],
          company: [],
          working_from: [],
          working_to: [],
        };
        savedDetails[0].forEach(item => {
          temp.role.push(item.recentJobTitle);
          temp.employment_type.push(item.employmentType);
          temp.company.push(item.recentCompany);
          temp.working_from.push(item.workingFromYear);
          temp.working_to.push(item.workingToMonth);
        });
        data = temp;
        let to_email = sessionStorage.getItem('candidateEmail');
        if (to_email)
          api({
            path: `/email/verifyEmail`,
            data: { to_email },
          })
            .then(res => {
              if (res.data.hasError) {
                // setErrorMessage(res.data.message);
                return;
              } else {
                alerts[1]({
                  error: false,
                  message: 'Verification Code sent to your Email',
                  state: true,
                });
              }
            })
            .catch(err => {
              console.log('errr', err);
            });
      }
      if (activeStep === steps.length - 1) {
        if (!isCodeVerified[0]) {
          alerts[1]({
            error: true,
            message: 'Please Verify the code',
            state: true,
          });
          return;
        }
      }

      api({
        path: `/candidate/${
          activeStep === 0
            ? 'signupCandidateStep2nd'
            : activeStep === 1
            ? 'signupCandidateStep3rd'
            : activeStep === 2 && 'signupCandidateFinish'
        }`,
        data: data,
      })
        .then(res => {
          console.log('response', res);
          if (res.data.hasError) {
            // setErrorMessage(res.data.message);
            setLoading(false);
            return;
          }
          setLoading(false);
          handleNext();
        })
        .catch(err => {
          console.log('errr', err);

          // setErrorMessage('Something went wrong');
          setLoading(false);
        });
    }
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Grid container direction="row" justify="space-between">
        <Grid item xs={3} className={classes.leftContainer}>
          <div>
            <img src={Logo} alt="logo" />
            <div className={classes.leftContent}>
              {getStepSidebar(activeStep)}
            </div>
          </div>
          <div className={classes.sidebarBottomContent}>
            <Typography
              variant="h1"
              color="textSecondary"
              style={{ fontWeight: 'normal', color: '#FFF' }}>
              Lorem ipsum has been the industry's standard.
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat
              necessitatibus laudantium aspernatur! Saepe, labore nam!
            </Typography>
          </div>
        </Grid>
        <Grid item xs={9}>
          <div>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container>
                  <Grid item xs={12}>
                    <div className={classes.navigation}>
                      <Navigation />
                      <div className={classes.rightContainer}>
                        {getStepContent(activeStep)}
                      </div>
                    </div>
                  </Grid>
                  <Grid xs={8}>
                    <div className={classes.navigationBottomContainer}>
                      <Grid item xs={12} className={classes.bottomButton}>
                        <Paper
                          style={{ marginRight: 15 }}
                          className={classes.saveBtn}>
                          <ButtonBase>
                            <Typography
                              variant="body1"
                              onClick={handleBack}
                              className={classes.saveBtnTxt}>
                              PREVIOUS STEP
                            </Typography>
                          </ButtonBase>
                        </Paper>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.finishBtn}
                          type="submit"
                          // onClick={handleNext}
                        >
                          {loading ? (
                            <CircularProgress size={12} color="textSecondary" />
                          ) : activeStep === steps.length - 1 ? (
                            'Finish'
                          ) : (
                            'Next Step'
                          )}
                        </Button>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default OnBoarding;
