import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import PersonalInfo from './PersonalInfo';
import standinggirl from '../../assets/images/standinggirl.png';

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && (
        <div p={3}>
          <Typography>{children}</Typography>
        </div>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = index => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#fcfcfc',
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    maxWidth: 250,
  },
  img: {
    marginTop: 230,
    maxWidth: 250,
  },
  tabName: {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    textTransform: 'none',
    '& .MuiTab-wrapper': {
      alignItems: 'flex-start',
    },
  },
  tabBtn: {
    justifyContent: 'flex-start',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    textTransform: 'none',
    paddingLeft: 12,
  },
  paper: {
    // position: "absolute",
    // minWidth: "30%",
  },
}));

const EditProfile = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(1);
  const generalDetailsref = useRef(null);
  const addressandLocationref = useRef(null);

  const handleChange = (event, newValue) => {
    newValue !== 0 && setValue(newValue);
  };

  const handleOnClick = (event, dest) => {
    if (dest === 'generalDetails' && generalDetailsref.current) {
      generalDetailsref.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
    if (dest === 'addressandLocation' && addressandLocationref.current) {
      addressandLocationref.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className={classes.root}>
      <div>
        <Paper className={classes.paper}>
          <Tabs
            orientation="vertical"
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className={classes.tabs}
            textColor="primary"
            indicatorColor="primary">
            <Tab
              className={classes.tabName}
              label="Edit Profile"
              {...a11yProps(0)}
              disableRipple
            />
            <Tab
              className={classes.tabName}
              label="Profile Photo"
              {...a11yProps(1)}
            />
            <Button
              className={classes.tabBtn}
              onClick={e => handleOnClick(e, 'generalDetails')}>
              General Details
            </Button>
            <Button
              className={classes.tabBtn}
              onClick={e => handleOnClick(e, 'addressandLocation')}>
              Address & Location
            </Button>
          </Tabs>
        </Paper>
        <img src={standinggirl} className={classes.img} alt="people" />
      </div>
      <div>
        <TabPanel value={value} index={0}></TabPanel>
        <TabPanel value={value} index={1}>
          <PersonalInfo
            generalDetailsref={generalDetailsref}
            addressandLocationref={addressandLocationref}
          />
        </TabPanel>
        <TabPanel value={value} index={2}></TabPanel>
        <TabPanel value={value} index={3}>
          Item Four
        </TabPanel>
        <TabPanel value={value} index={4}>
          Item Five
        </TabPanel>
        <TabPanel value={value} index={5}>
          Item Six
        </TabPanel>
        <TabPanel value={value} index={6}>
          Item Seven
        </TabPanel>
      </div>
    </div>
  );
};

export default EditProfile;
