import React, { useState, useEffect, useContext } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SendIcon from '@material-ui/icons/SendOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';

import {
  Container,
  InputAdornment,
  Dialog,
  Button,
  IconButton,
  Paper,
  TableContainer,
  TableRow,
  Table,
  TableCell,
  TableBody,
  Typography,
  TextField,
  Slide,
  DialogTitle,
  DialogContent,
  TableHead,
  Avatar,
  Grid,
  Checkbox,
  MenuItem,
  TablePagination,
} from '@material-ui/core';

import person from '../../assets/images/face.jpg';
import api from '../../api/api';
import SnackBarContext from '../../Context/SnackBarContext';
import CreateRecruiter from './CreateRecruiter';
import { url } from '../../utils/common';
import UserContext from '../../Context/UserContext';

const useStyles = makeStyles(theme =>
  createStyles({
    titleRow: {
      minHeight: 130,
      backgroundColor: '#f1f1f1',
    },
    container: {
      maxHeight: 500,
      marginTop: 25,
    },
    titleDiv: {
      paddingLeft: 25,
    },
    tablecell: {
      paddingLeft: 5,
      paddingRight: 5,
      borderBottom: 'none',
      minHeight: 58,
    },
    divider: {
      borderLeft: '1px solid #0000004D',
      margin: '0 2px',
    },
    icon: {
      color: '#6f6f6f',
    },
    inlineContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    tablePagination: {
      '& .MuiTablePagination-select': {
        backgroundColor: '#FFF',
      },
    },
    tableHead: {
      paddingLeft: 5,
      paddingRight: 5,
    },
  })
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStylesDialog = makeStyles(theme =>
  createStyles({
    dialogTitleContainer: {
      backgroundColor: theme.palette.ternary.main,
      padding: '0px 15px',
    },
    dialogTitleContainerDiv: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },

    permission: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '50%',
    },
    adminInfoRow: {
      display: 'flex',
      backgroundColor: '#f1f1f1',
      minHeight: 120,
      paddingLeft: 24,
    },
    adminInfo: {
      margin: '15px 15px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    divider: {
      borderLeft: '1px solid #000000',
      margin: '0 5px',
    },
    inlineContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    dialogContent: {
      padding: 0,
    },
    avatar: {
      height: theme.spacing(10),
      width: theme.spacing(10),
      marginTop: 20,
    },
    permissionContainer: {
      paddingLeft: 24,
      paddingRight: 24,
    },
  })
);

const PreviewRecruiterDialog = ({ previewRecruiterDialog }) => {
  const classes = useStylesDialog();
  return (
    <Dialog
      open={previewRecruiterDialog[0]}
      maxWidth="sm"
      TransitionComponent={Transition}
      fullWidth>
      <DialogTitle className={classes.dialogTitleContainer}>
        <div className={classes.dialogTitleContainerDiv}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton>
              <ArrowBackIcon style={{ color: '#FFF' }} />
            </IconButton>
            <Typography variant="subtitle1" style={{ color: '#FFF' }}>
              Preview
            </Typography>
          </div>
          <IconButton onClick={() => previewRecruiterDialog[1](false)}>
            <CloseIcon style={{ color: '#FFF' }} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.adminInfoRow}>
          <Avatar src={person} alt="person" className={classes.avatar} />
          <div className={classes.adminInfo}>
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
              Rohan Sharma
            </Typography>
            <div style={{ display: 'flex' }}>
              <Typography variant="body1">rohansharma@gmail.com</Typography>
              <div className={classes.divider} />
              <Typography variant="body1"> +91 7014183712</Typography>
            </div>
            <Typography variant="body1">Google</Typography>
            <Typography variant="body1">
              Jaipur,Rajastan, India 302016
            </Typography>
          </div>
        </div>
        <div className={classes.permissionContainer}>
          <Typography variant="subtitle1" style={{ marginTop: 15 }}>
            Permissions{' '}
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className={classes.permission}>
              <Typography variant="body1" align="left">
                Dashboard
              </Typography>
              <div>
                R<Checkbox />
                W<Checkbox />
                D<Checkbox />
              </div>
            </div>
            <div className={classes.permission}>
              <Typography variant="body1" align="left">
                Requirements
              </Typography>
              <div>
                R<Checkbox />
                W<Checkbox />
                D <Checkbox />
              </div>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div className={classes.permission}>
              <Typography variant="body1" align="left">
                Profile
              </Typography>
              <div>
                R<Checkbox />
                W<Checkbox />
                D<Checkbox />
              </div>
            </div>
            <div className={classes.permission}>
              <Typography variant="body1" align="left">
                Reports
              </Typography>
              <div>
                R<Checkbox />
                W<Checkbox />
                D<Checkbox />
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const perTick = per => {
  return per ? (
    <CheckIcon fontSize="small" style={{ color: 'green', marginLeft: 3 }} />
  ) : (
    <ClearIcon fontSize="small" style={{ color: 'red', marginLeft: 3 }} />
  );
};

const ManageRecruiters = () => {
  const classes = useStyles();
  const [recruiterDialog, setRecruiterDialog] = useState(false);
  const previewRecruiterDialog = useState(false);
  const [onHover, setOnHover] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const { alerts } = useContext(SnackBarContext);
  const [totalRecord, setTotalRecord] = useState(0);
  const [sortBy, setSortBy] = useState('ASC');
  const [recruiterData, setRecruiterData] = useState(null);
  const editRecruiterData = useState(null);
  const { authState } = useContext(UserContext);

  useEffect(() => {
    const data = {
      role: 'Recruiter',
      sort_by: sortBy,
      search_param: searchTerm,
      limit: limit,
      page_no: page,
    };
    api({ path: '/admin/getActiveUsers', data: data })
      .then(res => {
        if (res.data.hasError) {
          alerts[1]({
            ...alerts[0],
            message: res.data.message,
            state: true,
          });
          return;
        }
        setRecruiterData(res.data?.data);
        setTotalRecord(res.data?.totalRecord);
      })
      .catch(err =>
        alerts[1]({
          ...alerts[0],
          message: 'Something went wrong',
          state: true,
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, searchTerm, sortBy, recruiterDialog]);

  const TableCellOnHover = ({ data }) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '60%',
        }}>
        <IconButton>
          <VisibilityIcon
            fontSize="small"
            onClick={() => previewRecruiterDialog[1](true)}
            className={classes.icon}
          />
        </IconButton>
        <IconButton>
          <SendIcon fontSize="small" className={classes.icon} />
        </IconButton>
        <IconButton onClick={() => handleEditClick(data)}>
          <EditIcon fontSize="small" className={classes.icon} />
        </IconButton>
        <IconButton>
          <DeleteIcon fontSize="small" className={classes.icon} />
        </IconButton>
      </div>
    );
  };

  const Row = ({ row }) => {
    return (
      <>
        <TableRow
          hover
          align="left"
          onMouseOver={() => setOnHover(row?.id)}
          onMouseLeave={() => setOnHover(0)}
          key={row?.id}>
          <TableCell className={classes.tablecell} width="1%">
            <Checkbox style={{ paddingLeft: 5, paddingRight: 5 }} />
          </TableCell>
          <TableCell className={classes.tablecell}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar src={`${url}/image/${row?.profile_photo}`} />
              <div>
                <Typography variant="body1" style={{ marginLeft: 10 }}>
                  {`${row?.first_name} ${row?.last_name}`}
                </Typography>
                <div style={{ display: 'flex' }}>
                  <Typography
                    variant="body1"
                    style={{ margin: '0px 3px 0px 10px' }}>
                    {row?.email_id}
                  </Typography>
                  <div className={classes.divider} />
                  <Typography variant="body1">{row?.phone_no}</Typography>
                </div>
              </div>
            </div>
          </TableCell>
          <TableCell className={classes.tablecell} variant="body1">
            {
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1">R</Typography>{' '}
                {perTick(row?.dashboard_read)}{' '}
                <Typography variant="body1"> W</Typography>
                {perTick(row?.dashboard_write)}{' '}
                <Typography variant="body1"> D</Typography>
                {perTick(row?.dashboard_delete)}
              </span>
            }
          </TableCell>
          <TableCell className={classes.tablecell} variant="body1">
            {
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1">R</Typography>{' '}
                {perTick(row?.requirement_read)}
                <Typography variant="body1">W</Typography>{' '}
                {perTick(row?.requirement_write)}{' '}
                <Typography variant="body1">D</Typography>
                {perTick(row?.requirement_delete)}
              </span>
            }
          </TableCell>
          <TableCell className={classes.tablecell} variant="body1">
            {
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1">R</Typography>{' '}
                {perTick(row?.report_read)}
                <Typography variant="body1">W</Typography>{' '}
                {perTick(row?.report_write)}{' '}
                <Typography variant="body1">D</Typography>
                {perTick(row?.report_delete)}
              </span>
            }
          </TableCell>
          <TableCell className={classes.tablecell} variant="body1">
            {
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1">R</Typography>{' '}
                {perTick(row?.profile_read)}
                <Typography variant="body1">W</Typography>{' '}
                {perTick(row?.profile_write)}{' '}
                <Typography variant="body1">D</Typography>
                {perTick(row?.profile_delete)}
              </span>
            }
          </TableCell>
          <TableCell className={classes.tablecell} variant="body1">
            {
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1">R</Typography>{' '}
                {perTick(row?.dashboards?.r)}
                <Typography variant="body1">W</Typography>{' '}
                {perTick(row?.dashboards?.w)}{' '}
                <Typography variant="body1">D</Typography>
                {perTick(row?.dashboards?.d)}
              </span>
            }
          </TableCell>
          <TableCell className={classes.tablecell} variant="body1">
            {onHover === row?.id ? (
              <TableCellOnHover data={row} />
            ) : (
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1">R</Typography>{' '}
                {perTick(row?.dashboards?.r)}
                <Typography variant="body1">W</Typography>{' '}
                {perTick(row?.dashboards?.w)}{' '}
                <Typography variant="body1">D</Typography>
                {perTick(row?.dashboards?.d)}
              </span>
            )}
          </TableCell>
        </TableRow>
      </>
    );
  };

  const handleEditClick = data => {
    console.log('DATA', editRecruiterData[0]);
    editRecruiterData[1](data);
    setRecruiterDialog(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container maxWidth="xl" disableGutters>
      <Grid container>
        <Paper variant="outlined" style={{ width: '100%' }}>
          <Grid item xs={12}>
            <Grid container alignItems="center" className={classes.titleRow}>
              <Grid item xs={8} className={classes.titleDiv}>
                <Typography variant="subtitle1">Manage Recruiters</Typography>
                <Typography variant="body1">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Quisquam, nobis.
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                style={{
                  padding: '0px 10px',
                }}>
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right',
                    paddingBottom: 10,
                  }}>
                  <Button color="primary">Import / Export</Button>
                  <Button
                    color="primary"
                    onClick={() => setRecruiterDialog(true)}>
                    Create
                  </Button>
                </div>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Search here"
                  fullWidth
                  onChange={e => setSearchTerm(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: '#707070' }} />
                      </InputAdornment>
                    ),
                  }}
                  style={{
                    minWidth: 300,
                    alignSelf: 'center',
                    backgroundColor: '#FFF',
                    marginRight: 15,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} container md>
            <Grid item xs={12} md={12}>
              <TableContainer className={classes.container}>
                <Table>
                  <TableHead>
                    <TableRow
                      align="center"
                      style={{ backgroundColor: '#f1f1f1', padding: 0 }}>
                      <TableCell
                        variant="head"
                        className={classes.tablecell}
                        colspan={3}
                        style={{ padding: '5px 0px' }}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          <Checkbox />
                          <DeleteOutlineIcon className={classes.icon} />
                        </div>
                      </TableCell>
                      <TableCell
                        variant="head"
                        style={{ padding: '5px 0px' }}
                        colspan={5}
                        align="justify">
                        <TablePagination
                          component="div"
                          labelRowsPerPage="Show"
                          rowsPerPage={limit}
                          count={totalRecord}
                          page={page}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={e => handleChangeRowsPerPage(e)}
                          className={classes.tablePagination}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="32%" variant="head" colSpan={2}>
                        <div className={classes.inlineContainer}>
                          <Typography
                            variant="subtitle1"
                            style={{ fontWeight: 'normal' }}>
                            SORT BY :
                          </Typography>
                          <TextField
                            defaultValue="placeholder"
                            InputProps={{ disableUnderline: true }}
                            select
                            onChange={e =>
                              setSortBy(
                                e.target.value === 'placeholder'
                                  ? 'ASC'
                                  : e.target.value
                              )
                            }
                            style={{ marginLeft: 10, paddingTop: 1 }}>
                            <MenuItem value="placeholder">DEFAULT</MenuItem>
                            <MenuItem value="A">Ascending</MenuItem>
                            <MenuItem value="B">Descending</MenuItem>
                          </TextField>
                        </div>
                      </TableCell>
                      <TableCell
                        width="10%"
                        variant="head"
                        className={classes.tableHead}>
                        <Typography variant="subtitle1">Dashboard</Typography>
                      </TableCell>

                      <TableCell
                        width="10%"
                        variant="head"
                        className={classes.tableHead}>
                        <Typography variant="subtitle1">
                          Requirements
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        variant="head"
                        width="10%"
                        className={classes.tableHead}>
                        <Typography variant="subtitle1">Reports</Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        variant="head"
                        width="10%"
                        className={classes.tableHead}>
                        <Typography variant="subtitle1">Profile</Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        variant="head"
                        className={classes.tableHead}>
                        <Typography variant="subtitle1">Manage User</Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        variant="head"
                        width="15%"
                        className={classes.tableHead}>
                        <Typography variant="subtitle1">
                          Roles & Permission
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {recruiterData?.map(row => (
                      <Row row={row} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <CreateRecruiter
        recruiterDialog={recruiterDialog}
        setRecruiterDialog={setRecruiterDialog}
        alerts={alerts}
        editRecruiterData={editRecruiterData}
        authState={authState}
      />
      <PreviewRecruiterDialog previewRecruiterDialog={previewRecruiterDialog} />
    </Container>
  );
};

export default ManageRecruiters;
