import React, { useState, useEffect, useContext } from 'react';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import isEmpty from 'lodash/isEmpty';

import {
  Container,
  InputAdornment,
  createStyles,
  makeStyles,
  Button,
  IconButton,
  Paper,
  TableContainer,
  TableRow,
  Table,
  TableCell,
  TableBody,
  Typography,
  TextField,
  TableHead,
  Avatar,
  TablePagination,
  MenuItem,
  Checkbox,
  CircularProgress,
} from '@material-ui/core';

import api from '../../api/api';
import CreateCompanyDialog from './CreateCompanyDialog';
import UserContext from '../../Context/UserContext';
import SnackBarContext from '../../Context/SnackBarContext';
import ConfirmationDialogs from './ConfirmationDialog';
import { url } from '../../utils/common';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    leftContainer: {
      width: '100%',
    },

    tableContainer: {
      marginTop: 20,
      border: '1px solid #0000004D',
      borderRadius: 4,
    },
    titleRow: {
      minHeight: 130,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    tablecell: {
      padding: 0,
      borderBottom: 0,
    },
    tableHead: {
      padding: 0,
    },
    container: {
      marginTop: 25,
    },
    titleDiv: {
      paddingLeft: 25,
    },
    icon: {
      color: '#6f6f6f',
      padding: '0px 5px 0px 15px',
    },
    actionBtn: {
      display: 'flex',
      alignItems: 'center',
      marginRight: 5,
    },
    inlineContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    tablePagination: {
      '& .MuiTablePagination-select': {
        backgroundColor: '#FFF',
      },
    },
  })
);

const LoadingLine = () => {
  return (
    <div>
      <CircularProgress />
    </div>
  );
};

const ManageCompany = () => {
  const classes = useStyles();
  const [companyData, setCompanyData] = useState(null);
  const [companyDialogOpen, setCompanyDialogOpen] = useState(false);
  const { authState } = useContext(UserContext);
  const editCompany = useState(null);
  const [deleteCompanys, setDeleteCompanys] = useState([]);
  const deleteDialog = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [sort, setSort] = useState('ASC');
  const [pageNo, setPageNo] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const { alerts } = useContext(SnackBarContext);
  const [onHover, setOnHover] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    var data = {};
    if (!companyDialogOpen)
      data = {
        sort_by: sort,
        search: searchTerm,
        page_no: pageNo,
        limit: pageLimit,
      };
    api({ path: '/admin/company/getAllCompany', data: data })
      .then(res => {
        if (res.data.hasError) {
          alerts[1]({
            ...alerts[0],
            message: 'Company data fetch error',
            state: true,
          });
        }
        !isEmpty(res.data.data) && setCompanyData(res.data.data);
        setTotalRecord(res.data.totalRecord);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }, [companyDialogOpen, sort, searchTerm, pageNo, pageLimit, alerts]);

  const handleEditCompany = data => {
    editCompany[1](data);
    setCompanyDialogOpen(true);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const deleteAllCompany = companyData.map(n => n.id);
      setDeleteCompanys(deleteAllCompany);
      return;
    }
    setDeleteCompanys([]);
  };

  const removeCompanys = () => {
    const data = { company_ids: deleteCompanys };
    api({
      path: '/admin/company/deleteMultipleCompany',
      data: data,
    })
      .then(res => {
        if (res.data.hasError) {
          alerts[1]({ error: true, message: res.data.message, state: true });
        }
        deleteDialog[1](false);
        setCompanyData(
          companyData.filter(item => deleteCompanys.indexOf(item.id) < 0)
        );
        alerts[1]({ error: false, message: res.data.message, state: true });
      })
      .catch(err => console.log('ERR', err));
  };

  const handleChange = (e, id) => {
    e.target.checked
      ? setDeleteCompanys([...deleteCompanys, id])
      : setDeleteCompanys(deleteCompanys.filter(item => item !== id));
  };

  const handleChangeRowsPerPage = event => {
    setPageLimit(parseInt(event.target.value));
    setPageNo(0);
  };

  const handleChangePage = (event, newPage) => {
    setPageNo(newPage);
  };

  const Row = ({ row }) => {
    return (
      <>
        <TableRow
          hover
          align="start"
          onMouseOver={() => setOnHover(row?.id)}
          onMouseLeave={() => setOnHover(0)}
          key={row?.id}>
          <TableCell width="3%" style={{ borderBottom: 0 }}>
            <Checkbox
              key={Math.random()}
              checked={
                deleteCompanys.findIndex(item => item === row?.id) !== -1
                  ? true
                  : false
              }
              onChange={e => handleChange(e, row?.id)}
            />
          </TableCell>

          <TableCell className={classes.tablecell}>
            <div className={classes.inlineContainer}>
              <Avatar src={`${url}/image/${row?.company_logo}`} />
              <Typography variant="body1" style={{ marginLeft: 15 }}>
                {row?.company_name}
              </Typography>
            </div>
          </TableCell>
          <TableCell className={classes.tablecell}>
            <Typography variant="body1">{row?.email_id}</Typography>
          </TableCell>
          <TableCell className={classes.tablecell}>
            <Typography variant="body1">{row?.contact_no}</Typography>
          </TableCell>
          <TableCell className={classes.tablecell}>
            <Typography variant="body1">{row?.employeer_name}</Typography>
          </TableCell>
          <TableCell className={classes.tablecell}>
            <Typography variant="body1">{`${row?.city},${row?.country}`}</Typography>
          </TableCell>
          <TableCell className={classes.tablecell}>
            {onHover === row?.id && (
              <div className={classes.actionBtn}>
                <IconButton onClick={() => handleEditCompany(row)}>
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setDeleteCompanys([row?.id]);
                    deleteDialog[1](true);
                  }}>
                  <DeleteOutlineIcon />
                </IconButton>
              </div>
            )}
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <Container maxWidth="xl" disableGutters>
      <div className={classes.root}>
        <div className={classes.leftContainer}>
          <Paper elevation={5} className={classes.tableContainer}>
            <div className={classes.titleRow}>
              <div className={classes.titleDiv}>
                <Typography variant="subtitle1">Manage Company</Typography>
                <Typography variant="body1">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Quisquam, nobis.
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  padding: '0px 10px',
                }}>
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right',
                    paddingBottom: 10,
                  }}>
                  <Button
                    color="primary"
                    onClick={() => setCompanyDialogOpen(true)}
                    style={{ fontWeight: 600 }}>
                    Create
                  </Button>
                </div>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Search here"
                  fullWidth
                  onInput={e => setSearchTerm(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: '#707070' }} />
                      </InputAdornment>
                    ),
                  }}
                  style={{
                    minWidth: 300,
                    alignSelf: 'center',
                    backgroundColor: '#FFF',
                    marginRight: 15,
                  }}
                />
              </div>
            </div>

            <div className={classes.table}>
              <TableContainer className={classes.container}>
                <Table>
                  <TableHead>
                    <TableRow
                      align="center"
                      style={{ backgroundColor: '#f1f1f1' }}>
                      <TableCell
                        variant="head"
                        className={classes.tableHead}
                        colSpan={3}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: 16,
                          }}>
                          <Checkbox
                            indeterminate={
                              deleteCompanys.length !== 0 &&
                              deleteCompanys.length !== companyData.length
                                ? true
                                : false
                            }
                            onChange={handleSelectAllClick}
                          />
                          <IconButton
                            edge="start"
                            onClick={() => removeCompanys()}>
                            <DeleteOutlineIcon className={classes.icon} />{' '}
                          </IconButton>
                        </div>
                      </TableCell>
                      <TableCell
                        variant="head"
                        className={classes.tableHead}
                        colSpan={4}
                        align="justify">
                        <TablePagination
                          component="div"
                          labelRowsPerPage="Show"
                          count={totalRecord}
                          rowsPerPage={pageLimit}
                          page={pageNo}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={e => handleChangeRowsPerPage(e)}
                          className={classes.tablePagination}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="35%" variant="head" colSpan={2}>
                        <div
                          className={classes.inlineContainer}
                          style={{ marginLeft: 15 }}>
                          <Typography
                            variant="subtitle1"
                            style={{ fontWeight: 'normal' }}>
                            SORT BY :
                          </Typography>
                          <TextField
                            defaultValue="placeholder"
                            InputProps={{ disableUnderline: true }}
                            select
                            onChange={e => setSort(e.target.value)}
                            style={{ marginLeft: 10, paddingTop: 1 }}>
                            <MenuItem value="placeholder">DEFAULT</MenuItem>
                            <MenuItem value="ASC">Ascending</MenuItem>
                            <MenuItem value="DESC">Descending</MenuItem>
                          </TextField>
                        </div>
                      </TableCell>
                      <TableCell
                        width="13%"
                        variant="head"
                        className={classes.tableHead}>
                        <Typography variant="subtitle1">
                          Email Address
                        </Typography>
                      </TableCell>
                      <TableCell
                        width="13%"
                        variant="head"
                        className={classes.tableHead}>
                        <Typography variant="subtitle1">Contact no</Typography>
                      </TableCell>
                      <TableCell
                        width="13%"
                        variant="head"
                        className={classes.tableHead}>
                        <Typography variant="subtitle1">
                          Employer Name
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        width="15%"
                        variant="head"
                        className={classes.tableHead}>
                        <Typography width="15%" variant="subtitle1">
                          Comapany Address
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableHead} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableCell align="center" colspan={7}>
                        <LoadingLine />
                      </TableCell>
                    ) : companyData ? (
                      companyData?.map(row => <Row key={row?.id} row={row} />)
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={7}>
                          <Typography variant="subtitle1">
                            No Data Found
                          </Typography>{' '}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Paper>
        </div>
      </div>

      <CreateCompanyDialog
        companyDialogOpen={companyDialogOpen}
        setCompanyDialogOpen={setCompanyDialogOpen}
        authState={authState}
        editCompanyData={editCompany}
        alerts={alerts}
      />
      <ConfirmationDialogs open={deleteDialog} onClick={removeCompanys} />
    </Container>
  );
};

export default ManageCompany;
