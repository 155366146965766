import React, { useState, useEffect, useRef } from 'react';
import BackupIcon from '@material-ui/icons/BackupOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import {
  Dialog,
  Button,
  IconButton,
  Typography,
  TextField,
  Slide,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  createStyles,
  makeStyles,
  FormHelperText,
  Grid,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';

import api from '../../api/api';
import { url, isImage } from '../../utils/common';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStylesDialog = makeStyles(theme =>
  createStyles({
    dialogTitleContainer: {
      backgroundColor: theme.palette.ternary.main,
      padding: '10px 15px',
    },
    dialogTitleContainerDiv: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },

    dialogActionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 10,
    },
    dialogActionButton: {
      margin: '5px 13px',
      color: '#FFF',
      padding: '10px 25px',
    },

    uploadFileContainer: {
      border: '1px solid #00000060',
      borderRadius: 4,
      minHeight: 230,
      textAlign: 'center ',
    },
    companyInfoContainer: {
      marginTop: 10,
    },
    rowCompanyInput: {
      margin: '0px 0px',
    },
    inlineContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    helperText: {
      color: 'red',
      marginLeft: 5,
    },
    imgPreviewContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  })
);

const CreateCompanyDialog = ({
  companyDialogOpen,
  setCompanyDialogOpen,
  authState,
  editCompanyData,
  alerts,
}) => {
  const classes = useStylesDialog();
  const {
    handleSubmit,
    control,
    reset,
    defaultValues,
    formState: { errors },
  } = useForm({
    defaultValues: { employerName: editCompanyData[0]?.employeer_name },
  });
  const [imgId, setImgId] = useState(null);
  const [dialogPic, setDialogPic] = useState(null);
  const [browseErr, setBrowseErr] = useState('');
  var formData = new FormData();
  const browseBtnRef = useRef();

  const handleClose = () => {
    setCompanyDialogOpen(false);
    editCompanyData[1](null);
    reset({ defaultValues });
    setDialogPic(null);
    setBrowseErr('');
  };

  const updateCompany = data => {
    const companyData = {
      company_id: editCompanyData[0].id.toString(),
      company_name: data.companyName,
      employeer_name: data.employerName,
      email_id: data.email,
      contact_no: data.contactNo,
      city: data.city,
      country: data.country,
      admin_id: authState[0].admin_id,
      user_account_id: authState[0].user_account_id.toString(),
      company_logo: imgId ? imgId : editCompanyData[0]?.company_logo,
    };
    api({ path: '/admin/company/updateCompany', data: companyData }).then(
      res => {
        alerts[1]({
          error: false,
          message: 'Updated company data',
          state: true,
        });
        handleClose();
      }
    );
  };

  const createCompany = data => {
    const companyData = {
      company_name: data.companyName,
      employeer_name: data.employerName,
      email_id: data.email,
      contact_no: data.contactNo,
      city: data.city,
      country: data.country,
      admin_id: authState[0].admin_id,
      user_account_id: authState[0].user_account_id,
      company_logo: imgId,
    };

    api({ path: '/admin/company/createCompany', data: companyData }).then(
      res => {
        if (res.data.hasError) {
          alerts[1]({
            error: true,
            message: res.data.message,
            state: true,
          });
        }
        alerts[1]({
          error: false,
          message: res.data.message,
          state: true,
        });
        handleClose();
      }
    );
  };

  useEffect(() => {
    if (editCompanyData[0] === null) {
      reset({
        country: 'placeholder',
        city: 'placeholder',
      });
      return;
    } else {
      reset({
        companyName: editCompanyData[0]?.company_name,
        employerName: editCompanyData[0]?.employeer_name,
        email: editCompanyData[0]?.email_id,
        contactNo: editCompanyData[0]?.contact_no,
        country: editCompanyData[0]?.country,
        city: editCompanyData[0]?.city,
      });
      setDialogPic(`${url}/image/${editCompanyData[0]?.company_logo}`);
      setImgId(editCompanyData[0]?.company_logo);
    }
  }, [editCompanyData, reset]);

  const onSubmit = data => {
    if (!imgId) return;
    editCompanyData[0] ? updateCompany(data) : createCompany(data);
  };

  const handleFileUpload = e => {
    var image = isImage(e.target.files[0]);
    let fileSize = parseFloat(e.target.files[0]?.size / 1024 / 1024).toFixed(2);

    if (!image) {
      alerts[1]({
        error: true,
        message: 'Please select image',
        state: true,
      });
      return;
    }
    if (parseFloat(fileSize) > 15) {
      alerts[1]({
        error: true,
        message: 'Maximum 10Mb of size allowed',
        state: true,
      });
      return;
    }
    setDialogPic(URL.createObjectURL(e.target.files[0]));

    formData.append('image', e.target.files[0]);

    api({ path: '/image/upload', data: formData })
      .then(res => setImgId(res.data.data.imageId))
      .catch(err => console.log('ERR', err));
  };

  return (
    <Dialog
      open={companyDialogOpen}
      maxWidth="sm"
      TransitionComponent={Transition}
      fullWidth>
      <DialogTitle className={classes.dialogTitleContainer}>
        <div className={classes.dialogTitleContainerDiv}>
          <div className={classes.inlineContainer}>
            <IconButton>
              <ArrowBackIcon style={{ color: '#FFF' }} />
            </IconButton>
            <Typography variant="subtitle1" color="textSecondary">
              {editCompanyData[0] ? 'Edit Company' : 'Create Company'}
            </Typography>
          </div>
          <IconButton onClick={() => handleClose()}>
            <CloseIcon style={{ color: '#FFF' }} />
          </IconButton>
        </div>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {dialogPic ? (
                <div className={classes.imgPreviewContainer}>
                  <img src={dialogPic} alt="pic" height={70} width={70} />
                  <IconButton
                    onClick={() => {
                      setDialogPic(null);
                      setImgId(null);
                    }}>
                    <CloseIcon />
                  </IconButton>
                </div>
              ) : (
                <div
                  className={classes.uploadFileContainer}
                  style={{ textAlign: 'center' }}>
                  <IconButton>
                    <BackupIcon style={{ fontSize: 70 }} />
                  </IconButton>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 'normal' }}
                    align="center">
                    Click here to browse or <br /> drag and drop a file
                  </Typography>
                  <Button
                    style={{ padding: '15px 55px', margin: '10px 5px' }}
                    variant="contained"
                    onClick={() => browseBtnRef?.current?.click()}>
                    <Typography variant="subtitle1">Browse</Typography>
                  </Button>
                  <input
                    type="file"
                    id="file"
                    style={{ display: 'none' }}
                    onChange={e => handleFileUpload(e)}
                    ref={browseBtnRef}
                    accept="image/*"
                  />
                  <Typography style={{ marginBottom: 5 }} variant="body1">
                    File must be an image format. The maximum file-size is 10 MB
                  </Typography>
                </div>
              )}
              {browseErr && (
                <Typography
                  style={{ marginBottom: 5 }}
                  className={classes.helperText}
                  variant="body1">
                  {browseErr}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} className={classes.companyInfoContainer}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    placeholder="Company Name"
                    fullWidth
                  />
                )}
                rules={{ required: true }}
                control={control}
                name="companyName"
              />
              {errors && errors['companyName']?.type === 'required' && (
                <FormHelperText>
                  <Typography variant="body1" className={classes.helperText}>
                    Please enter company name
                  </Typography>
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={6}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    placeholder="Employer Name"
                    className={classes.rowCompanyInput}
                  />
                )}
                name="employerName"
                control={control}
                rules={{ required: true }}
              />
              {errors && errors['employerName']?.type === 'required' && (
                <FormHelperText>
                  <Typography variant="body1" className={classes.helperText}>
                    Please enter employer name
                  </Typography>
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={6}>
              <Controller
                render={({ field }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Email Address"
                    className={classes.rowCompanyInput}
                    {...field}
                  />
                )}
                name="email"
                control={control}
                rules={{
                  required: true,
                  pattern: new RegExp('^[A-Za-z0-9+_.-]+@(.+)$'),
                }}
              />
              {errors && errors['email']?.type === 'required' ? (
                <FormHelperText>
                  <Typography variant="body1" className={classes.helperText}>
                    Please enter email address
                  </Typography>
                </FormHelperText>
              ) : (
                errors['email']?.type === 'pattern' && (
                  <FormHelperText>
                    <Typography variant="body1" className={classes.helperText}>
                      Please enter valid email
                    </Typography>
                  </FormHelperText>
                )
              )}
            </Grid>
            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    placeholder="Contact no"
                    type="number"
                    onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                    fullWidth
                  />
                )}
                name="contactNo"
                control={control}
                rules={{
                  required: 'Please enter contact no',
                  minLength: {
                    value: 10,
                    message: 'Please enter valid contact no',
                  },
                  maxLength: {
                    value: 10,
                    message: 'Please enter valid contact no',
                  },
                }}
              />
              {errors?.contactNo && (
                <FormHelperText>
                  <Typography variant="body1" className={classes.helperText}>
                    {errors.contactNo?.message}
                  </Typography>
                </FormHelperText>
              )}
              {console.log(errors)}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" align="left">
                Address and location
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Controller
                render={({ field }) => (
                  <TextField
                    fullWidth
                    {...field}
                    variant="outlined"
                    className={classes.rowCompanyInput}
                    style={{ color: '#000' }}
                    select>
                    <MenuItem value="placeholder">Country/ Region</MenuItem>
                    <MenuItem value="India">India</MenuItem>
                    <MenuItem value="USA">USA</MenuItem>
                    <MenuItem value="UK">UK</MenuItem>
                  </TextField>
                )}
                name="country"
                control={control}
                defaultValue="placeholder"
                rules={{
                  required: ' Country/Region is required',
                  validate: value =>
                    value === 'placeholder'
                      ? 'Please select Country/Region'
                      : true,
                }}
              />
              {errors && (
                <FormHelperText>
                  <Typography variant="body1" className={classes.helperText}>
                    {errors?.country?.message}
                  </Typography>
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={6}>
              <Controller
                render={({ field }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    className={classes.rowCompanyInput}
                    select
                    {...field}>
                    <MenuItem value="placeholder">City/District</MenuItem>
                    <MenuItem value="Delhi">Delhi</MenuItem>
                    <MenuItem value="New York">New York</MenuItem>
                    <MenuItem value="London">London</MenuItem>
                  </TextField>
                )}
                name="city"
                control={control}
                defaultValue="placeholder"
                rules={{
                  required: 'City/District Name is required',
                  validate: value =>
                    value === 'placeholder'
                      ? 'Please enter City/Restrict'
                      : true,
                }}
              />
              {errors && (
                <FormHelperText>
                  <Typography variant="body1" className={classes.helperText}>
                    {errors?.city?.message}
                  </Typography>
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions className={classes.dialogActionContainer}>
          <Button
            variant="contained"
            className={classes.dialogActionButton}
            style={{ color: '#000' }}
            size="large"
            onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.dialogActionButton}
            color="primary"
            size="large"
            type="submit"
            onClick={() =>
              !imgId ? setBrowseErr('Please select logo') : true
            }>
            {editCompanyData[0] ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateCompanyDialog;
