import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Dialog,
  Button,
  IconButton,
  Typography,
  TextField,
  Slide,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  createStyles,
  FormHelperText,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';

const useStylesDialog = makeStyles(theme =>
  createStyles({
    dialogTitleContainer: {
      backgroundColor: theme.palette.ternary.main,
      padding: '10px 15px',
    },
    dialogTitleContainerDiv: {
      display: 'flex',
      alignItems: 'space-between',
      justifyContent: 'space-between',
      width: '100%',
    },

    dialogActionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 10,
    },
    dialogActionButton: {
      margin: '5px 13px',
      color: '#FFF',
      padding: '10px 25px',
    },

    select: {
      marginTop: 10,
    },
    autoComplete: {
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] ': {
        paddingTop: 3,
        marginTop: 10,
      },
    },
    msg: {
      marginTop: 10,
    },
    helperText: {
      color: 'red',
      marginLeft: 5,
    },
    optionContainer: {
      opacity: 1,
    },
  })
);

const skills = [
  { name: 'Client Management', value: 'Client Management' },
  { name: 'Community Relations B', value: 'Community Relations B' },
  { name: 'Conflict Management', value: 'Conflict Management' },
  { name: 'Project Manager', value: 'Project Manager' },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateSkillDialog = ({
  skillDialogOpen,
  handleClose,
  onClickFunc,
  error,
  setError,
  editSkills,
}) => {
  const classes = useStylesDialog();
  const [skillName, setSkillName] = React.useState('');

  const getOptionLabel = option => {
    if (option.hasOwnProperty('name')) {
      return option.name;
    }
    return option;
  };

  return (
    <Dialog
      open={skillDialogOpen}
      maxWidth="sm"
      TransitionComponent={Transition}
      fullWidth>
      <DialogTitle className={classes.dialogTitleContainer}>
        <div className={classes.dialogTitleContainerDiv}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton>
              <ArrowBackIcon style={{ color: '#FFF' }} fontSize="medium" />
            </IconButton>
            <Typography variant="subtitle1" style={{ color: '#FFF' }}>
              {editSkills ? 'Update Skills' : 'Create Skills'}
            </Typography>
          </div>

          <IconButton onClick={() => handleClose()}>
            <CloseIcon style={{ color: '#FFF' }} fontSize="medium" />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1">Skill Name</Typography>
        <Autocomplete
          options={skills}
          onInputChange={(e, value) => {
            setSkillName(value);
            setError();
          }}
          defaultValue=""
          value={editSkills?.skill}
          classes={{ paper: classes.optionContainer }}
          getOptionLabel={option => getOptionLabel(option)}
          freeSolo
          renderInput={params => (
            <TextField
              variant="outlined"
              placeholder="Skill Name"
              className={classes.textField}
              fullWidth
              {...params}
              defaultValue=""
            />
          )}
          className={classes.autoComplete}
        />
        {error && (
          <FormHelperText>
            <Typography variant="body1" className={classes.helperText}>
              {error}
            </Typography>
          </FormHelperText>
        )}

        <Typography variant="body1" className={classes.msg}>
          Type skill name we will show you suggestion
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActionContainer}>
        <Button
          variant="contained"
          className={classes.dialogActionButton}
          style={{ color: '#000' }}
          size="large"
          onClick={() => handleClose()}>
          Cancel
        </Button>
        <Button
          variant="contained"
          className={classes.dialogActionButton}
          color="primary"
          size="large"
          onClick={() =>
            editSkills
              ? onClickFunc(skillName, editSkills?.id)
              : onClickFunc(skillName)
          }>
          {editSkills ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateSkillDialog;
