import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import BackupIcon from '@material-ui/icons/Backup';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import clsx from 'clsx';
import {
  Container,
  InputAdornment,
  Dialog,
  Button,
  IconButton,
  Paper,
  TableContainer,
  TableRow,
  Table,
  TableCell,
  TableBody,
  Typography,
  TextField,
  Slide,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableHead,
  Avatar,
  Grid,
  Checkbox,
  MenuItem,
  TablePagination,
  Stepper,
  Step,
  StepLabel,
} from '@material-ui/core';

import ImportCandidatesDialog from './ImportCandidatesDialog';

const useStyles = makeStyles(theme =>
  createStyles({
    titleRow: {
      minHeight: 130,
      backgroundColor: '#f1f1f1',
    },
    container: {
      maxHeight: 500,
      marginTop: 25,
    },
    titleDiv: {
      paddingLeft: 25,
    },
    tablecell: {
      paddingLeft: 5,
      paddingRight: 5,
    },
    divider: {
      borderLeft: '1px solid #0000004D',
      margin: '0 2px',
    },
    inlineContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      color: '#6f6f6f',
    },
    tablePagination: {
      '& .MuiTablePagination-select': {
        backgroundColor: '#FFF',
      },
    },
  })
);

const useStylesDialog = makeStyles(theme =>
  createStyles({
    dialogTitleContainer: {
      backgroundColor: theme.palette.ternary.main,
      padding: '0px 15px',
    },
    dialogTitleContainerDiv: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    dialogContainer: {
      padding: '8px 0px',
    },
    dialogActionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 10,
    },
    dialogActionButton: {
      margin: '5px 13px',
      color: '#FFF',
      padding: '10px 25px',
    },

    dialogTextField: {
      margin: '5px 0',
    },
    uploadFileContainer: {
      border: '1px solid #00000060',
      borderRadius: 4,
      minHeight: 230,
    },
    adminInfoContainer: {
      marginTop: 10,
    },
    rowAdminInput: {
      margin: '15px 0px',
    },
    rowInputContainer: {
      display: 'flex',
      width: '100%',
    },
    permission: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '50%',
    },
    adminInfoRow: {
      display: 'flex',
      backgroundColor: '#f1f1f1',
      width: '100%',
      minHeight: 150,
    },
    adminInfo: {
      margin: '15px 15px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    divider: {
      borderLeft: '1px solid #000000',
      margin: '0 5px',
    },
    stepper: {
      backgroundColor: theme.palette.ternary.main,
      padding: '10px 0px',
    },
    stepTwoTextField: {
      marginTop: 15,
    },
    inlineContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    stepTwoInlineContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 15,
    },
    stepTwoBtnContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 15,
    },
    stepTwoBtn: {
      fontWeight: 600,
      marginLeft: 5,
      marginRight: 5,
    },
    addMoreBtn: {
      marginTop: 30,
      borderTop: '1px dashed #00000033',
    },
  })
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const candidateArr = [
  {
    name: 'Hunter Pena',
    email: 'jeap@emerit.edu',
    mobileNo: '+91 7014183712',
    status: 'imported',
    address: '1582 Arkip Extension',
    company: 'Google.inc',
    jobTitle: 'UI Designer',
    fromYear: '5 Years',
    fromMonth: '3 Month',
  },
  {
    name: 'Rosa Massey',
    email: 'ewiun@emerit.edu',
    mobileNo: '+91 7014183712',
    status: 'created',
    address: '393 Ahnu Circle',
    company: 'Google.inc',
    jobTitle: 'UI Designer',
    fromYear: '5 Years',
    fromMonth: '3 Month',
  },
  {
    name: 'Lella Coldwell',
    email: 'wozvois@emerit.edu',
    mobileNo: '+91 7014183712',
    status: 'imported',
    address: '1582 Arkip Extension',
    company: 'Google.inc',
    jobTitle: 'UI Designer',
    fromYear: '5 Years',
    fromMonth: '3 Month',
  },
  {
    name: 'Phoebe Castilio',
    email: 'leeg@emerit.edu',
    mobileNo: '+91 7014183712',
    status: 'imported',
    address: '1582 Arkip Extension',
    company: 'Google.inc',
    jobTitle: 'UI Designer',
    fromYear: '5 Years',
    fromMonth: '3 Month',
  },
];

const useStepIconStyles = makeStyles(theme => ({
  root: {
    border: '1px solid #FFF',
    zIndex: 1,
    color: '#fff',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 0px',
  },
  avatar: {
    height: theme.spacing(3),
    width: theme.spacing(3),
  },
  active: {
    backgroundColor: '#FFF',
    color: '#000',
  },
  completed: {
    backgroundColor: theme.palette.ternary.main,
    color: '#FFF',
    opacity: 0.5,
    border: 0,
  },
  other: {
    backgroundColor: theme.palette.ternary.main,
    color: '#FFF',
    opacity: 0.3,
    border: 'none',
  },
}));

const ManageCandidates = () => {
  const classes = useStyles();
  const DialogClasses = useStylesDialog();
  const candidateDialog = useState(false);
  const [activeStep, setActiveStep] = React.useState(1);
  const importDialogOpen = useState(false);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const Row = ({ row }) => {
    return (
      <>
        <TableRow hover align="left">
          <TableCell className={classes.tablecell} width="1%">
            <Checkbox style={{ paddingLeft: 5, paddingRight: 5 }} />
          </TableCell>
          <TableCell className={classes.tablecell}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar>{row?.name?.charAt(0)}</Avatar>
              <div>
                <Typography variant="body1" style={{ marginLeft: 10 }}>
                  {row?.name}
                </Typography>
                <div style={{ display: 'flex' }}>
                  <Typography variant="body1" style={{ margin: '0px 6px' }}>
                    {row?.email}
                  </Typography>
                  <div className={classes.divider} />
                  <Typography variant="body1">{row?.mobileNo}</Typography>
                </div>
              </div>
            </div>
          </TableCell>
          <TableCell className={classes.tablecell} variant="subtitle2">
            <Typography variant="body1">{row?.status}</Typography>
          </TableCell>
          <TableCell className={classes.tablecell} variant="subtitle2">
            <Typography variant="body1">{row?.address}</Typography>
          </TableCell>
          <TableCell className={classes.tablecell} variant="subtitle2">
            <Typography variant="body1">{row?.company}</Typography>
          </TableCell>
          <TableCell className={classes.tablecell} variant="subtitle2">
            <Typography variant="body1">{row?.jobTitle}</Typography>
          </TableCell>
          <TableCell className={classes.tablecell} variant="subtitle2">
            <Typography variant="body1">{row?.fromYear}</Typography>
          </TableCell>
          <TableCell className={classes.tablecell} variant="subtitle2">
            <Typography variant="body1">{row?.fromMonth}</Typography>
          </TableCell>
        </TableRow>
      </>
    );
  };

  const StepIcon = props => {
    const classes = useStepIconStyles();
    let { active, completed, icon } = props;
    active = icon === activeStep ? true : false;
    completed = icon < activeStep ? true : false;
    let other = activeStep < icon ? true : false;

    return (
      <div className={classes.root}>
        {completed || icon <= activeStep ? (
          <Avatar
            className={clsx(classes.avatar, {
              [classes.active]: active,
              [classes.completed]: completed,
              [classes.other]: other,
            })}>
            {icon}
          </Avatar>
        ) : (
          <Avatar
            className={clsx(classes.avatar, {
              [classes.active]: active,
              [classes.completed]: completed,
              [classes.other]: other,
            })}>
            {icon}
          </Avatar>
        )}
      </div>
    );
  };

  const StepOneContent = () => {
    return (
      <div>
        <div className={DialogClasses.uploadFileContainer}>
          <div style={{ textAlign: 'center' }}>
            <IconButton>
              <BackupIcon style={{ fontSize: 70 }} />
            </IconButton>
            <Typography variant="subtitle1" align="center">
              Click here to browse or <br /> drag and drop a file
            </Typography>
            <Button
              style={{ padding: '15px 55px', margin: '10px 5px' }}
              variant="contained">
              <label for="file">
                <Typography variant="subtitle1">Browse</Typography>
              </label>
            </Button>
            <input type="file" id="file" style={{ display: 'none' }} />
            <Typography style={{ marginBottom: 5 }} variant="body1">
              File must be an image format. The maximum file-size is 10 MB{' '}
            </Typography>
          </div>
        </div>
        <div className={DialogClasses.adminInfoContainer}>
          <div className={DialogClasses.rowInputContainer}>
            <TextField
              fullWidth
              variant="outlined"
              label="First Name"
              className={DialogClasses.rowAdminInput}
              style={{ marginRight: 5 }}
            />
            <TextField
              fullWidth
              variant="outlined"
              label="Last Name"
              className={DialogClasses.rowAdminInput}
              style={{ marginLeft: 5 }}
            />
          </div>
          <div className={DialogClasses.rowInputContainer}>
            <TextField
              fullWidth
              variant="outlined"
              label="Email Address"
              className={DialogClasses.rowAdminInput}
              style={{ marginRight: 5 }}
            />
            <TextField
              fullWidth
              variant="outlined"
              label="Phone Number"
              className={DialogClasses.rowAdminInput}
              style={{ marginLeft: 5 }}
            />
          </div>
          <div className={DialogClasses.rowInputContainer}>
            <TextField
              fullWidth
              variant="outlined"
              label="Alternative Number"
              className={DialogClasses.rowAdminInput}
              style={{ marginRight: 5 }}
            />
            <TextField
              fullWidth
              variant="outlined"
              label="WhatsApp Number"
              className={DialogClasses.rowAdminInput}
              style={{ marginLeft: 5 }}
            />
          </div>

          <Typography variant="subtitle1" align="left" style={{ marginTop: 8 }}>
            Address and Location{' '}
          </Typography>
          <div className={DialogClasses.rowInputContainer}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Country/ Region"
              defaultValue="placeholder"
              className={DialogClasses.rowAdminInput}
              style={{ marginRight: 5, color: '#000' }}
              select>
              <MenuItem value="placeholder">Country/ Region</MenuItem>
              <MenuItem value="in">India</MenuItem>
              <MenuItem value="us">USA</MenuItem>
              <MenuItem value="uk">UK</MenuItem>
            </TextField>
            <TextField
              fullWidth
              variant="outlined"
              defaultValue="placeholder"
              className={DialogClasses.rowAdminInput}
              style={{ marginRight: 5 }}
              select>
              <MenuItem value="placeholder">City/District</MenuItem>
              <MenuItem value="pun">Pune</MenuItem>
              <MenuItem value="mum">Mumbai</MenuItem>
              <MenuItem value="sa">Satara</MenuItem>
            </TextField>
          </div>
        </div>
      </div>
    );
  };

  const StepTwoContent = () => {
    return (
      <div>
        <Typography variant="subtitle1">Employment Details</Typography>
        <TextField
          fullWidth
          variant="outlined"
          label="Most Recent Job Title"
          className={DialogClasses.stepTwoTextField}
        />
        <TextField
          fullWidth
          variant="outlined"
          label="Employment Title"
          className={DialogClasses.stepTwoTextField}
        />
        <TextField
          fullWidth
          variant="outlined"
          label="Most Recent Category"
          className={DialogClasses.stepTwoTextField}
        />
        <div className={DialogClasses.stepTwoInlineContainer}>
          <TextField
            fullWidth
            variant="outlined"
            label="Working From Month"
            style={{ marginRight: 5 }}
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Working to Month"
            // className={DialogClasses.stepTwoTextField}
          />
        </div>
        <div className={DialogClasses.stepTwoBtnContainer}>
          <Button
            size="large"
            variant="contained"
            className={DialogClasses.stepTwoBtn}
            color="primary">
            Save
          </Button>
          <Button
            size="large"
            variant="contained"
            className={DialogClasses.stepTwoBtn}>
            Back
          </Button>
        </div>
        <div className={DialogClasses.addMoreBtn}>
          <Button size="large" color="primary">
            <AddOutlinedIcon fontSize="small" /> Add More
          </Button>
        </div>
      </div>
    );
  };

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 1:
        return <StepOneContent />;
      case 2:
        return <StepTwoContent />;
      default:
        return;
    }
  };

  const getSteps = () => {
    return ['', ''];
  };

  const CreateCandidateDialog = ({ candidateDialog }) => {
    const DialogClasses = useStylesDialog();
    const steps = getSteps();

    return (
      <Dialog
        open={candidateDialog[0]}
        maxWidth="sm"
        TransitionComponent={Transition}
        fullWidth>
        <DialogTitle className={DialogClasses.dialogTitleContainer}>
          <div className={DialogClasses.dialogTitleContainerDiv}>
            <div className={DialogClasses.inlineContainer}>
              <IconButton>
                <ArrowBackIcon style={{ color: '#FFF' }} />
              </IconButton>
              <Typography variant="subtitle1" style={{ color: '#FFF' }}>
                Create Candidate
              </Typography>
            </div>
            <div className={DialogClasses.inlineContainer}>
              <div>
                <Stepper activeStep={0} className={DialogClasses.stepper}>
                  {steps.map(label => (
                    <Step key={label}>
                      <StepLabel StepIconComponent={StepIcon}></StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </div>
              <div>
                <IconButton onClick={() => candidateDialog[1](false)}>
                  <CloseIcon style={{ color: '#FFF' }} />
                </IconButton>
              </div>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>{getStepContent(activeStep)}</DialogContent>
        <DialogActions className={DialogClasses.dialogActionContainer}>
          <Button
            variant="contained"
            className={DialogClasses.dialogActionButton}
            style={{ color: '#000' }}
            size="large"
            onClick={() => handleBack()}>
            Back
          </Button>
          <Button
            variant="contained"
            className={DialogClasses.dialogActionButton}
            color="primary"
            size="large"
            onClick={() => handleNext()}>
            Next Step
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Container maxWidth="xl" disableGutters>
      <Grid container>
        <Paper elevation={5} style={{ width: '100%' }}>
          <Grid item xs={12}>
            <Grid container alignItems="center" className={classes.titleRow}>
              <Grid item xs={8} className={classes.titleDiv}>
                <Typography variant="subtitle1">Manage Candidate</Typography>
                <Typography variant="body1">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Quisquam, nobis.
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                style={{
                  padding: '0px 10px',
                }}>
                <div
                  style={{
                    width: '100%',
                    textAlign: 'right',
                    paddingBottom: 10,
                  }}>
                  <Button
                    color="primary"
                    onClick={() => importDialogOpen[1](true)}>
                    Import / Export
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => candidateDialog[1](true)}>
                    Create
                  </Button>
                </div>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Search here"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: '#707070' }} />
                      </InputAdornment>
                    ),
                  }}
                  style={{
                    minWidth: 300,
                    alignSelf: 'center',
                    backgroundColor: '#FFF',
                    marginRight: 15,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} container md>
            <Grid item xs={12} md={12}>
              <TableContainer className={classes.container}>
                <Table>
                  <TableHead>
                    <TableRow
                      align="center"
                      style={{ backgroundColor: '#f1f1f1', padding: 0 }}>
                      <TableCell
                        variant="head"
                        className={classes.tablecell}
                        colspan={3}
                        style={{ padding: '5px 0px' }}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          <Checkbox />
                          <DeleteOutlineIcon className={classes.icon} />
                        </div>
                      </TableCell>
                      <TableCell
                        variant="head"
                        style={{ padding: '5px 0px' }}
                        colspan={5}
                        align="justify">
                        <TablePagination
                          component="div"
                          labelRowsPerPage="Show"
                          rowsPerPage={10}
                          count={100}
                          page={0}
                          className={classes.tablePagination}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width="30%" variant="head" colspan={2}>
                        <div className={classes.inlineContainer}>
                          <Typography
                            variant="subtitle1"
                            style={{ fontWeight: 'normal' }}>
                            SORT BY :
                          </Typography>
                          <TextField
                            defaultValue="placeholder"
                            InputProps={{ disableUnderline: true }}
                            select
                            style={{ marginLeft: 10, paddingTop: 1 }}>
                            <MenuItem value="placeholder">DEFAULT</MenuItem>
                            <MenuItem value="A">Ascending</MenuItem>
                            <MenuItem value="B">Descending</MenuItem>
                          </TextField>
                        </div>
                      </TableCell>
                      <TableCell variant="head" className={classes.tablecell}>
                        <Typography variant="subtitle1">Status</Typography>
                      </TableCell>

                      <TableCell variant="head" className={classes.tablecell}>
                        <Typography variant="subtitle1">Address</Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        variant="head"
                        className={classes.tablecell}>
                        <Typography variant="subtitle1" colspan={2}>
                          Company
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        variant="head"
                        className={classes.tablecell}>
                        <Typography variant="subtitle1" colspan={2}>
                          Job Title
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        variant="head"
                        className={classes.tablecell}>
                        <Typography variant="subtitle1" colspan={2}>
                          From Year
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        variant="head"
                        className={classes.tablecell}>
                        <Typography variant="subtitle1" colspan={2}>
                          From Month
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {candidateArr.map(row => (
                      <Row row={row} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <CreateCandidateDialog candidateDialog={candidateDialog} />
      <ImportCandidatesDialog importDialogOpen={importDialogOpen} />
    </Container>
  );
};

export default ManageCandidates;
