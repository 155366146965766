import React from 'react';
import {
  makeStyles,
  Typography,
  Avatar,
  Switch,
  TextField,
  Grid,
  Container,
  withStyles,
  MenuItem,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import Image from '../../../assets/images/requirementimage.png';

const useStyles = makeStyles(theme => ({
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  columnOptionContainer: {
    marginLeft: 60,
  },
  columnOption: {
    marginTop: 15,
  },
  smallIcon: {
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
    backgroundColor: 'transparent',
    marginRight: 5,
  },
  sideBarTitle: {
    marginLeft: 15,
  },

  //Right content classes

  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
  },
  editor: {
    marginTop: 15,
  },
  subtitle: {
    marginTop: 5,
    fontWeight: 'bold',
  },
  caption: {
    marginTop: 3,
  },
}));

const AntSwitch = withStyles(theme => ({
  root: {
    width: 29,
    height: 18,
    padding: 2,
    display: 'flex',
    margin: '0px 10px',
    borderRadius: 5,
  },
  switchBase: {
    padding: 3.5,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const StepTwoSidebar = () => {
  const classes = useStyles();
  return (
    <Container>
      <div className={classes.titleRow}>
        <CheckCircleIcon
          style={{ color: '#FFF' }}
          className={classes.smallIcon}
          fontSize="large"
        />
        <Typography
          variant="subtitle1"
          color="textSecondary"
          className={classes.sideBarTitle}>
          Education
        </Typography>
      </div>
      <div className={classes.columnOptionContainer}>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Graduation
        </Typography>

        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Post Graduation
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Doctorate
        </Typography>
      </div>
      <span
        style={{ display: 'flex', alignItems: 'center' }}
        className={classes.titleRow}>
        <Avatar
          className={classes.smallIcon}
          style={{ border: '0.5px solid #FFF' }}>
          2
        </Avatar>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          className={classes.sideBarTitle}>
          Job
        </Typography>
      </span>
      <div className={classes.columnOptionContainer}>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Industry
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Functional Area
        </Typography>
      </div>
      <span
        style={{ display: 'flex', alignItems: 'center' }}
        className={classes.titleRow}>
        <Avatar
          className={classes.smallIcon}
          style={{ border: '0.5px solid #FFF' }}>
          3
        </Avatar>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          className={classes.sideBarTitle}>
          Question
        </Typography>
      </span>
      <div className={classes.columnOptionContainer}>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Question
        </Typography>
      </div>
      <span
        style={{ display: 'flex', alignItems: 'center' }}
        className={classes.titleRow}>
        <Avatar
          className={classes.smallIcon}
          style={{ border: '0.5px solid #FFF' }}>
          4
        </Avatar>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          className={classes.sideBarTitle}>
          Contact Details
        </Typography>
      </span>
      <div className={classes.columnOptionContainer}>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.columnOption}>
          Contact Details
        </Typography>
      </div>
    </Container>
  );
};

const StepTwoContent = ({ Controller, useFormContext }) => {
  const { control } = useFormContext();
  const classes = useStyles();
  // const [keyword, setKeyword] = useState([]);

  return (
    <Container style={{ paddingRight: 5, position: 'relative' }}>
      <Grid container>
        <Grid item xs={6} md container>
          <Grid item xs={12}>
            <div className={classes.leftTitle}>
              <Typography variant="h1">Posting Details</Typography>
              <Typography variant="body1" className={classes.subtitle}>
                Lorem ipsum dolor sit amet.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <div className={classes.titleContainer}>
                <Typography variant="subtitle1">Graduation</Typography>
                <AntSwitch />
                <Typography variant="body1">Add to Job Post</Typography>
              </div>
              <Typography variant="body1" className={classes.caption}>
                Lorem ipsum dolor sit.
              </Typography>
              <Controller
                control={control}
                name="firstName"
                render={({ field }) => (
                  <TextField
                    id="first-name"
                    variant="outlined"
                    defaultValue="placeholder"
                    margin="normal"
                    fullWidth
                    {...field}
                    select>
                    <MenuItem value="postgraducation">
                      Any Post Graduation
                    </MenuItem>
                    <MenuItem value="placeholder">Any Graduation</MenuItem>
                    <MenuItem value="Doctorate">Any Doctorate</MenuItem>
                  </TextField>
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <div className={classes.titleContainer}>
                <Typography variant="subtitle1">Post Graduation</Typography>
                <AntSwitch />
                <Typography variant="body1">Add to Job Post</Typography>
              </div>
              <Typography variant="body1" className={classes.caption}>
                Lorem ipsum dolor sit.
              </Typography>
              <Controller
                control={control}
                name="firstName"
                render={({ field }) => (
                  <TextField
                    id="first-name"
                    variant="outlined"
                    defaultValue="placeholder"
                    margin="normal"
                    fullWidth
                    {...field}
                    select>
                    <MenuItem value="placeholder">Any Post Graduation</MenuItem>
                    <MenuItem value="Graduation">Any Graduation</MenuItem>
                    <MenuItem value="Doctorate">Any Doctorate</MenuItem>
                  </TextField>
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <div className={classes.titleContainer}>
                <Typography variant="subtitle1">Doctorate</Typography>
                <AntSwitch />
                <Typography variant="body1">Add to Job Post</Typography>
              </div>
              <Typography variant="body1" className={classes.caption}>
                Let's make it memorable
              </Typography>
              <Controller
                control={control}
                name="firstName"
                render={({ field }) => (
                  <TextField
                    id="first-name"
                    variant="outlined"
                    defaultValue="placeholder"
                    margin="normal"
                    fullWidth
                    {...field}
                    select>
                    <MenuItem value="placeholder">Any Post Graduation</MenuItem>
                    <MenuItem value="Graduation">Any Graduation</MenuItem>
                    <MenuItem value="Doctorate">Any Doctorate</MenuItem>
                  </TextField>
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ marginTop: 15 }}>
              <Typography variant="h1">Job Details</Typography>
              <Typography variant="body1" className={classes.subtitle}>
                Lorem ipsum dolor sit amet.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <div className={classes.titleContainer}>
                <Typography variant="subtitle1">Industry</Typography>
                <AntSwitch />
                <Typography variant="body1">Add to Job Post</Typography>
              </div>
              <Controller
                control={control}
                name="firstName"
                render={({ field }) => (
                  <TextField
                    id="first-name"
                    variant="outlined"
                    placeholder="Type Here"
                    margin="normal"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <div className={classes.titleContainer}>
                <Typography variant="subtitle1">Functional Area</Typography>
                <AntSwitch />
                <Typography variant="body1">Add to Job Post</Typography>
              </div>
              <Typography variant="body1" className={classes.caption}>
                Let's make it memorable
              </Typography>
              <Controller
                control={control}
                name="firstName"
                render={({ field }) => (
                  <TextField
                    id="first-name"
                    variant="outlined"
                    placeholder="Type Here"
                    margin="normal"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ marginTop: 15 }}>
              <Typography variant="h1">Questions</Typography>
              <Typography variant="body1" className={classes.subtitle}>
                Lorem ipsum dolor sit amet.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <div className={classes.titleContainer}>
                <Typography variant="subtitle1">
                  Have any question for the candidate?
                </Typography>
                <AntSwitch />
                <Typography variant="body1">Add to Job Post</Typography>
              </div>
              <Typography variant="body1" className={classes.caption}>
                Let's make it memorable
              </Typography>
              <Controller
                control={control}
                name="firstName"
                render={({ field }) => (
                  <TextField
                    id="first-name"
                    variant="outlined"
                    placeholder="Type Here"
                    margin="normal"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ marginTop: 15 }}>
              <Typography variant="h1">Questions </Typography>
              <Typography variant="body1" className={classes.subtitle}>
                Lorem ipsum dolor sit amet.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} style={{ marginBottom: 25 }}>
            <div>
              <Typography variant="subtitle1">
                Show contact details to job seekers
              </Typography>

              <Switch color="primary" />
            </div>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <div>
            <img
              src={Image}
              alt="right"
              height="30%"
              width="40%"
              style={{ position: 'absolute', right: 0, bottom: -56 }}
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export { StepTwoSidebar, StepTwoContent };
