import React, { useState } from 'react';
import {
  Button,
  DialogActions,
  Typography,
  IconButton,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  TextField,
  MenuItem,
  Avatar,
  Collapse,
  withStyles,
  Switch,
  Grid,
  Paper,
  Checkbox,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBackOutlined';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUpOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDownOutlined';
import person from '../../assets/images/face.jpg';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  dialogTitleContainer: {
    backgroundColor: theme.palette.ternary.main,
    padding: '0px 15px',
  },
  dialogTitleContainerDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: '#FFFFFFCC',
    padding: '0px 5px',
  },
  avatar: {
    height: theme.spacing(10),
    width: theme.spacing(10),
  },
  paper: {
    padding: 20,
  },
  perInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: 10,
    marginRight: 10,
  },
  inlineContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  applyBtn: {
    '&:hover': {
      backgroundColor: '#299c14',
    },
    backgroundColor: '#299c14',
    color: '#FFF',
    borderRadius: '4px 0px 0px 4px',
    fontSize: 12,
    textTransform: 'uppercase',
    padding: '4px 5px',
    marginTop: 15,
    fontWeight: 'bold',
  },
  rejectBtn: {
    padding: '4px 5px',
    marginTop: 15,
    fontSize: 12,
    borderRadius: '0px 4px 4px 0px',
    marginRight: 15,
    fontWeight: 'bold',
  },
  perInfoLeftContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  dialogContent: {
    padding: 0,
  },
  collapseTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#f3f3f3',
    padding: '5px 15px',
    marginBottom: 10,
  },
  gridItemLeft: {
    paddingLeft: 20,
  },
  gridItemRight: {
    paddingRight: 20,
  },
  jobDetailsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '15px 0px',
  },
  perInfoRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  borderBottom: {
    borderBottom: '1px dashed #00000033',
  },
  dialogActionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 20px 15px 5px',
    fontWeight: 600,
    backgroundColor: '#f3f3f3',
  },
  dialogActionBlueButton: {
    margin: '5px 13px',
    color: '#FFF',
    padding: '10px 25px',
    backgroundColor: theme.palette.ternary.main,
    fontWeight: 600,
  },
  dialogActionButton: {
    margin: '5px 13px',
    color: '#FFF',
    padding: '10px 25px',
  },
}));

const AntSwitch = withStyles(theme => ({
  root: {
    width: 29,
    height: 18,
    padding: 2,
    display: 'flex',
    margin: '0px 10px',
    borderRadius: 5,
  },
  switchBase: {
    padding: 3.5,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#299c14',
        borderColor: '#299c14',
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const ExportPdf = ({ open }) => {
  const classes = useStyles();
  const [expProfile, setExpProfile] = useState(true);
  return (
    <div>
      <Dialog
        open={open[0]}
        maxWidth="md"
        TransitionComponent={Transition}
        fullWidth>
        <DialogTitle className={classes.dialogTitleContainer}>
          <div className={classes.dialogTitleContainerDiv}>
            <div className={classes.dialogTitle}>
              <ArrowBackIcon className={classes.icon} />
              <Typography variant="subtitle1" style={{ color: '#FFF' }}>
                Export Profile
              </Typography>
            </div>
            <IconButton onClick={() => open[1](false)}>
              <CloseIcon style={{ color: '#FFF' }} />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container>
            <Grid item xs={12}>
              <Paper className={classes.paper} variant="outlined">
                <div className={classes.perInfo}>
                  <div className={classes.perInfoLeftContainer}>
                    <Avatar
                      src={person}
                      alt="person"
                      className={classes.avatar}
                    />
                    <div style={{ marginLeft: 15 }}>
                      <Typography variant="subtitle1">Rahul Sahu</Typography>
                      <Typography variant="body1">
                        UI & UX Designer & Front-end Developer
                      </Typography>
                      <div
                        className={classes.inlineContainer}
                        style={{ marginTop: 15 }}>
                        <span
                          className={classes.inlineContainer}
                          style={{ marginRight: 15 }}>
                          <DraftsOutlinedIcon color="primary" />
                          <Typography variant="body1" style={{ marginLeft: 5 }}>
                            rahulsahu@test.com
                          </Typography>
                        </span>
                        <span className={classes.inlineContainer}>
                          <PhoneOutlinedIcon color="primary" />
                          <Typography variant="body1" style={{ marginLeft: 5 }}>
                            +91 8237956789
                          </Typography>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Paper elevation={3} style={{ width: 200 }}>
                      <TextField
                        variant="outlined"
                        margin="none"
                        defaultValue="missed"
                        style={{ width: 200 }}
                        select>
                        <MenuItem value="placeholder">Set Call Status</MenuItem>
                        <MenuItem value="missed">Missed Call</MenuItem>
                        <MenuItem value="Available">Called</MenuItem>
                        <MenuItem value="Offline">Messaged</MenuItem>
                        <MenuItem value="Offline">Call Not Picked</MenuItem>
                        <MenuItem value="Offline">Not Reachable</MenuItem>
                        <MenuItem value="Offline">
                          Send Whatsapp Message
                        </MenuItem>
                      </TextField>
                    </Paper>
                    <div className={classes.applybtnContainer}>
                      <Button
                        variant="contained"
                        className={classes.applyBtn}
                        endIcon={
                          <ArrowDropDownIcon style={{ paddingRight: 3 }} />
                        }>
                        Applied - Matching
                      </Button>
                      <Button className={classes.rejectBtn} variant="contained">
                        Reject
                      </Button>
                    </div>
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.collapseTitleContainer}>
                <Typography variant="subtitle1">Job Details</Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <AntSwitch />
                  <Typography variant="body1">Select All</Typography>
                  <IconButton onClick={() => setExpProfile(!expProfile)}>
                    {expProfile ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </div>
              </div>
              <Collapse
                in={expProfile}
                className={classes.collapseOption}
                timeout="auto"
                unmountOnExit>
                <Grid container>
                  <Grid item xs={6} className={classes.gridItemLeft}>
                    <div className={classes.borderBottom}>
                      <Typography variant="subtitle1">
                        Personal Information
                      </Typography>
                      <Grid container>
                        <Grid item xs={6}>
                          <div className={classes.perInfoRow}>
                            <Typography variant="body1">
                              Profile Photo
                            </Typography>
                            <Checkbox style={{ color: '#29992d' }} />
                          </div>
                          <div className={classes.perInfoRow}>
                            <Typography variant="body1">Call Status</Typography>
                            <Checkbox style={{ color: '#29992d' }} />
                          </div>
                          <div className={classes.perInfoRow}>
                            <Typography variant="body1">Designation</Typography>
                            <Checkbox style={{ color: '#29992d' }} />
                          </div>
                          <div className={classes.perInfoRow}>
                            <Typography variant="body1">Phone No</Typography>
                            <Checkbox style={{ color: '#29992d' }} />
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className={classes.perInfoRow}>
                            <Typography variant="body1">Name</Typography>
                            <Checkbox style={{ color: '#29992d' }} />
                          </div>
                          <div className={classes.perInfoRow}>
                            <Typography variant="body1">
                              Profile Status
                            </Typography>
                            <Checkbox style={{ color: '#29992d' }} />
                          </div>
                          <div className={classes.perInfoRow}>
                            <Typography variant="body1">Email</Typography>
                            <Checkbox style={{ color: '#29992d' }} />
                          </div>
                          <div className={classes.perInfoRow}>
                            <Typography variant="body1">
                              Expected CTC
                            </Typography>
                            <Checkbox style={{ color: '#29992d' }} />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">Key Skills</Typography>
                      <AntSwitch />
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">Candidate Details</Typography>
                      <AntSwitch />
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">Add Comments</Typography>
                      <AntSwitch />
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">Download Log</Typography>
                      <AntSwitch />
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">Upload File</Typography>
                      <AntSwitch />
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">Comments</Typography>
                      <AntSwitch />
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    className={classes.gridItemRight}
                    style={{
                      borderLeft: '1px dashed #e2e2e2',
                      paddingLeft: 10,
                    }}>
                    <div className={classes.borderBottom}>
                      <div className={classes.jobDetailsRow}>
                        <Typography variant="body1">Work Experience</Typography>
                        <AntSwitch />
                      </div>
                      <div className={classes.jobDetailsRow}>
                        <Typography variant="body1">
                          Educational Qualification
                        </Typography>
                        <AntSwitch />
                      </div>
                      <div className={classes.jobDetailsRow}>
                        <Typography variant="body1">IT Skills</Typography>
                        <AntSwitch />
                      </div>
                      <div className={classes.jobDetailsRow}>
                        <Typography variant="body1">Language Known</Typography>
                        <AntSwitch />
                      </div>
                      <div className={classes.jobDetailsRow}>
                        <Typography variant="body1">Projects</Typography>
                        <AntSwitch />
                      </div>
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">
                        Reference Check Form
                      </Typography>
                      <AntSwitch />
                    </div>
                    <div className={classes.borderBottom}>
                      <div className={classes.perInfoRow}>
                        <Typography variant="body1">Checkform#12457</Typography>
                        <Checkbox style={{ color: '#29992d' }} />
                      </div>
                      <div className={classes.perInfoRow}>
                        <Typography variant="body1">designer check</Typography>
                        <Checkbox style={{ color: '#29992d' }} />
                      </div>
                      <div className={classes.perInfoRow}>
                        <Typography variant="body1">New hire</Typography>
                        <Checkbox style={{ color: '#29992d' }} />
                      </div>
                    </div>
                    <div className={classes.jobDetailsRow}>
                      <Typography variant="body1">Summary</Typography>
                      <AntSwitch />
                    </div>
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActionContainer}>
          <Button
            variant="contained"
            className={classes.dialogActionBlueButton}
            onClick={() => open[1](false)}>
            BACK
          </Button>

          <Button
            variant="contained"
            className={classes.dialogActionButton}
            color="primary">
            Export PDF
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ExportPdf;
